import { useState, useEffect } from "react";
import "./AssignRiderModal.css";
import { AiFillCloseCircle } from "react-icons/ai";
import SearchInput from "../../searchInput/SearchInput";
import Button from "../../button/Button";
import RiderDetailCard from "../../RiderDetailCard/RiderDetailCard";
import { popup } from "../../../vanilla-functions/model";
import { handleStringSearch } from "../../../utils";

type Props = {
  onClose?: () => void;
  onAssignRiderClick?: (arg?: any) => void;
  ridersData: any[];
  order: any;
};

function AssignRiderModal(props: Props) {
  const { onClose, ridersData, onAssignRiderClick, order } = props;

  const [active, setActive] = useState(-1);
  const [curRider, setCurRider] = useState<any>({});

  const [originalData, setOriginalData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleFilter = (val: string) => {
    handleStringSearch(val, originalData, (res) => {
      setFilteredData(res);
    });
  };

  useEffect(() => {
    if (order?.orderType === "Groceries") {
      setFilteredData([...ridersData.filter((e) => e?.rider_details?.rider_type === "Grocery")]);
      setOriginalData([...ridersData.filter((e) => e?.rider_details?.rider_type === "Grocery")]);
    } else if (order?.orderType === "Gas") {
      setFilteredData([...ridersData.filter((e) => e?.rider_details?.rider_type === "Gas")]);
      setOriginalData([...ridersData.filter((e) => e?.rider_details?.rider_type === "Gas")]);
    }
  }, [ridersData]);

  return (
    <div
      className="o-assign-rider-overview-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
          setCurRider(null);
          setActive(-1);
        }
      }}
    >
      <div className="o-assign-rider-overview-modal-container">
        <div className="o-assign-rider-overview-modal-header">
          <div>Available Riders in this Route</div>
        </div>

        <SearchInput onChange={(e) => handleFilter(e.target.value)} />
        <div className="o-assign-rider-overview-content-wrapper">
          {filteredData &&
            filteredData.map((rider: any, index: number) => {
              return (
                <RiderDetailCard
                  active={index === active}
                  onClick={() => {
                    if (index === active) {
                      setActive(-1);
                      setCurRider(null);
                      return;
                    }
                    setActive(index);
                    setCurRider(rider);
                  }}
                  imgUrl={rider?.profile_picture}
                  key={index}
                  name={`${rider?.first_name} ${rider?.last_name}`}
                  phone={rider?.mobile}
                  busType={rider?.rider_details?.vehicle_type}
                  estateID={rider.estateID}
                />
              );
            })}
        </div>
        <Button
          disable={!curRider}
          label="Assign Rider"
          style={{ width: "70%" }}
          onClick={() => {
            onAssignRiderClick && onAssignRiderClick(curRider);
            setCurRider(null);
            setActive(-1);
          }}
        />

        <AiFillCloseCircle
          className="o-assign-rider-overview-modal-close-icon"
          onClick={() => {
            onClose && onClose();
            setCurRider(null);
            setActive(-1);
          }}
        />
      </div>
    </div>
  );
}

export default AssignRiderModal;
