import React from 'react'
import './TabStatsCard.css'

type ITabStatsCard = {
  title: string,
  count: number | string,
  imageSrc: string,
  onClick?: (title?: string, count?: number | string) => void,
  width?: any
}

function TabStatsCard(props: ITabStatsCard)  {
  const { title, count, imageSrc, onClick, width } = props;
  return (
    <div className="tab-stats-container" style={{width, cursor: onClick? 'pointer': 'default'}} onClick={() => onClick && onClick(title, count)}>
      <div className="tab-stats-frame">
        <img className="tab-stats-icon" alt="Icon" src={imageSrc || "/dashboard-icons/onboarded.png"} />
        <div className="tab-stats-contents">
          <div className="tab-stats-title">{title}</div>
          <div className="tab-stats-count">{count}</div>
        </div>
      </div>
    </div>
  )
}



export default TabStatsCard