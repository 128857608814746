import { useState, useContext, useEffect } from "react";
import "./vendor-overview.css";
import { FaTimes } from "react-icons/fa";
import { PiProhibitBold } from "react-icons/pi";
import AppContext from "../../../context/app-context";
import VendorGeneralInfo from "./vendor-general-info";
import VendorFinanceInfo from "./vendor-finance-info";
import EditVendorName from './edit-vendor-info/edit-vendor-name';

const VendorOverview = ({ isOpen, setIsOpen, selectedVendor, fetchVendors, deactivateVendor, suspendOrRevive }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);
  const [userImage, setUserImage] = useState("");
  const [ openEditVendorName, setOpenEditVendorName ] = useState(false);

  const [toggleButton, setToggleButton] = useState("General information");

  const getSignedAwsUrl = async function (filename) {
    if (!filename) return;
    const url = `${resolveBackendUrl(
      selectedVendor?.estateID
    )}/file/get/profile-pictures/${filename}`;
    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const { data } = await (
      await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();

    return data;
  };

  useEffect(() => {
    (async () => {
      setUserImage(await getSignedAwsUrl(selectedVendor?.profile_picture));
    })();
  }, [selectedVendor?.profile_picture]);

  const totalAmount = selectedVendor?.wallet?.transactions.reduce((total, transaction) => {
    return total + transaction?.amount;
  }, 0);

  const colorStatus = (status) => {
    switch (status) {
      case "In Delivery":
        return { backgroundColor: "#E6FFE6", color: "#549186" };
      case "Awaiting Order":
        return { backgroundColor: "#FFE8B0", color: "#FFB400" };
      default:
        return { backgroundColor: "black", color: "white" };
    }
  };

  function formatAmountAsNaira(totalAmount) {
    const amountInNaira = totalAmount;
    const formattedAmount = amountInNaira?.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });

    return formattedAmount;
  }

  if (!isOpen) return null;

  return (
    <>
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="vendor-overview-container">
          <div className="vendor-detail-header" style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1, textAlign: "center" }}>Vendor Overview</span>
            <FaTimes onClick={() => setIsOpen(false)} style={{ cursor: "pointer" }} />
          </div>
          <div className="user-name">
            <div className="vendor-image">
              <img src={userImage || "https://via.placeholder.com/150?text=Avatar"} alt="userimg" />
            </div>
            <div className="vendor-name-number">
              <div className="vendor-name">{selectedVendor?.vendor_name}<button onClick={() => setOpenEditVendorName(true)} className="edit-vendor-name">Edit name</button></div>
              <div className="vendor-number">{selectedVendor?.vendor_owner?.mobile}</div>
            </div>
          </div>
          <div>
            <div className="vendor-earning">
              <span className="vendor-earning-text">Wallet Balance:</span>
              <span className="vendor-earned-amount">
                {formatAmountAsNaira(selectedVendor?.wallet?.balance)}
              </span>
            </div>
          </div>
          <div className="suspend">
            <button onClick={deactivateVendor}>
              <PiProhibitBold color="#D95069" />
              <span style={{ color: "#D95069", textTransform: 'capitalize' }}>{suspendOrRevive}</span>
            </button>
            {/* <button onClick={deactivateVendor}><FaTimes /> <span style={{ color: '#050505'}}>Deactivate</span></button> */}
          </div>

          <div className="vendor-information">
            <button
              onClick={() => setToggleButton("General information")}
              style={{
                borderBottom: toggleButton === "General information" ? "2px solid #444266" : "",
              }}
            >
              General information
            </button>
            <button
              onClick={() => setToggleButton("Finance information")}
              style={{
                borderBottom: toggleButton === "Finance information" ? "2px solid #444266" : "",
              }}
            >
              Finance Information
            </button>
          </div>
          {toggleButton === "General information" ? (
            <VendorGeneralInfo selectedVendor={selectedVendor} fetchVendors={fetchVendors}/>
          ) : (
            <VendorFinanceInfo selectedVendor={selectedVendor} />
          )}
        </div>
      </div>
    </div>
      <EditVendorName fetchVendors={fetchVendors} selectedVendor={selectedVendor} isOpen={openEditVendorName} setIsOpen={setOpenEditVendorName}/>
    </>
  );
};

export default VendorOverview;
