
export type Method =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK';


type IFetchApi = {
    body?: any;
    url: string;
    method: Method;
    baseUrl?: string;
    header?: any;
    token?: string
  };
  
  export const fetchApi = async ({body, method, url, baseUrl, header, token}: IFetchApi) => {
    const data = JSON.stringify(body);
    const pathUrl = `${baseUrl}/${url}`;
    const finalUrl = baseUrl? pathUrl: url;
    
    try {
      const tokenHeader = localStorage.getItem("omstoken")|| '';
  
      const mainHeaders = {
        'Content-Type': 'application/json',
        token: token || tokenHeader
      };
      const headers = {...mainHeaders, ...header};
      const response = await fetch(finalUrl, {
        body: data,
        method,
        headers: mainHeaders
      });
  
      const result = await response.json();
      return {
        data: result,
        hasError: !response.ok,
        status: response.status,
        statusText: response.statusText,
      };
    } catch (error: any) {
      return {
        data: error,
        hasError: true,
        status: error.status || 500,
        statusText: error.message || 'Internal Server Error',
      };
    }
  };
  