import { useState, useContext, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../../context/app-context";
import ReasonDetail from "./reason-details";

const DeleteReportModal = ({ isOpen, setIsOpen, selectedReason }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);
  const [userImage, setUserImage] = useState("");
  const [userDetails, setUserDetails] = useState({});

  const [toggleButton, setToggleButton] = useState("Account Summary");

  async function getUser() {
    const url = `${resolveBackendUrl(selectedReason?.estateID)}/user/get?email=${
      selectedReason?.email
    }`;
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        // body: JSON.stringify({category: 'delete'}),
      });

      const returned = await response.json();
      // popup(returned?.status || "Record Updated successfully");

      setUserDetails(returned?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  const getSignedAwsUrl = async function (filename) {
    if (!filename) return;
    const url = `${resolveBackendUrl(
      selectedReason?.estateID
    )}/file/get/profile-pictures/${filename}`;
    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const { data } = await (
      await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();

    return data;
  };

  useEffect(() => {
    (async () => {
      setUserImage(await getSignedAwsUrl(userDetails?.profile_picture));
    })();
  }, [selectedReason, userDetails]);

  useEffect(() => {
    getUser();
  }, [selectedReason]);

  useEffect(() => {
    return () => setUserDetails({});
  }, [isOpen]);

  if (!isOpen) return;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="delete-overview-container">
          <div
            className="delete-report-detail-header"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ flex: 1, textAlign: "center" }}>Reason for deleting account</span>
            <FaTimes onClick={() => setIsOpen(false)} style={{ cursor: "pointer" }} />
          </div>
          <div className="user-name">
            <div className="delete-report-image">
              <img src={userImage || "https://via.placeholder.com/150?text=Avatar"} alt="userimg" />
            </div>
            <div>
              <div className="delete-report-name">
                {selectedReason?.first_name} {selectedReason?.last_name}
              </div>
              <div className="delete-report-number">
                <span className="delete-report-number">{selectedReason?.email}</span>
              </div>
            </div>
          </div>
          <div>
            <div className="delete-report-earning">
              <span className="delete-report-earning-text">
                {userDetails?.address?.addressString}
              </span>
            </div>
          </div>

          <div className="report-information">
            <button
              onClick={() => setToggleButton("Account Summary")}
              style={{
                borderBottom: toggleButton === "Account Summary" ? "2px solid #444266" : "",
              }}
            >
              Account Summary
            </button>
            {/* <button onClick={() => setToggleButton('delete-report history')} style={{ borderBottom: toggleButton==='delete-report history' ? '2px solid #444266' : ''}}>delete-report history ({selectedReason?.wallet?.transactions.filter((transaction) => transaction?.status==='completed').length})</button> */}
          </div>
          {toggleButton === "Account Summary" ? (
            <ReasonDetail selectedReason={selectedReason} />
          ) : null}
        </div>
        <button onClick={() => setIsOpen(false)} className="close-reason-modal">
          Close
        </button>
      </div>
    </div>
  );
};

export default DeleteReportModal;