import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./estate-table.css";

let pseudoCheckBoxSelectedData = [];

function EstateTable({ data, setScreen, page, setPage, setCheckBoxSelectedData, setcsvData }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function workCSV(data) {
    setcsvData([
      ["Estate ID", "Estate Name", "Properties", "Residents", "Dues", "Admin", "Payouts"],
      ...data?.map(({ stat, estateDetails }) => {
        return [
          stat?.estateExtendedData?._id,
          estateDetails?.estate_name,
          stat?.totalNumberOfProperties,
          stat?.totalNumberOfResidents,
          stat?.totalNumberOfDues,
          stat?.allAdminsData?.length,
        ];
      }),
    ]);
  }

  function makeChecboxComeAlive(event) {
    if (
      pseudoCheckBoxSelectedData.find(
        (e) =>
          e?.stat?.estateExtendedData?._id ===
          event?.target?.closest(".MuiTableRow-root").children[1].textContent.trim()
      )
    ) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) =>
          e?.stat?.estateExtendedData?._id !==
          event?.target?.closest(".MuiTableRow-root").children[1].textContent.trim()
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find(
          (e) =>
            e?.stat?.estateExtendedData?._id ===
            event?.target?.closest(".MuiTableRow-root").children[1].textContent.trim()
        )
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#estate-checkbox-children").forEach((element) => {
      if (
        pseudoCheckBoxSelectedData.find(
          (e) =>
            e?.stat?.estateExtendedData?._id ===
            element?.closest(".MuiTableRow-root").children[1].textContent.trim()
        )
      ) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionPropertiesTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#estate-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find(
            (e) =>
              e?.stat?.estateExtendedData?._id ===
              element?.closest(".MuiTableRow-root").children[1].textContent.trim()
          )
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#estate-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      workCSV(data);
    }
  }

  if (!data) return;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  onClick={(e) => {
                    if (e.target.className.includes("estate-table-checkboxes-head")) {
                      if (!e.target.checked) allActionPropertiesTable(false);
                      else allActionPropertiesTable(true);
                    }
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            if (event.target.className.includes("estate-table-checkboxes")) {
                              event.stopPropagation();
                              return makeChecboxComeAlive(event);
                            }
                            setScreen({
                              screen: "estate",
                              data: event.target
                                .closest(".MuiTableRow-root")
                                ?.children[1]?.textContent.trim(),
                            });
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={sortData(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export { EstateTable, pseudoCheckBoxSelectedData };
