import { useState } from 'react';
import { FaTimes, FaUpload } from 'react-icons/fa';
import './add-new-batch.css';

const AddNewBatch = ({ setIsOpen, isOpen}) => {
  const [ batchDetails, setBatchDetails ] = useState({
    sourceVendor: '',
    quantityPurchased: '',
    costPrice: '',
    invoice: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setBatchDetails({
      ...batchDetails,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(batchDetails)
  }

  if (!isOpen) return null
  return (
    <div className='modal-overlay'>
      <div className='modal-content-body'>
        <form className='add-new-batch-form'>
            <div className='new-batch-header'>
              <h6 style={{ textAlign: 'center', flex: 1, fontWeight: 600, color: '#0D0D0D'}}>Add new batch</h6>
              <FaTimes onClick={() => setIsOpen(false)} style={{ cursor: "pointer" }} />
            </div>
  
            <div className="add-batch-form-input-div">
            <label>Source Vendor</label>
            <input
                type="text"
                name="last_name"
                placeholder='Enter source vendor'
                onChange={handleChange}
              />
            </div>

            <div className="add-batch-form-input-div">
            <label>Quantity Purchased</label>
            <input onChange={handleChange} name='quantityPurchased' type='text' placeholder='Enter quantity purchased' />
          </div>

          <div className="add-batch-form-input-div">
          <label>Cost Price</label>
            <input onChange={handleChange} name='costPrice' type='text' placeholder='Enter cost price' />
          </div>

          <div className="upload-container">
            <input type="file" className="hidden-input" id="file-upload" />
            <label htmlFor="file-upload" className="upload-label">
                <FaUpload className="upload-icon" />
                <span className="upload-text">Click to upload</span>
                <span className="upload-text">Max File size 200mb</span>
            </label>
        </div>
        <button type="submit">Add Inventory</button>
        </form>
    </div>
    </div>
  )
}

export default AddNewBatch