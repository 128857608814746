import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import { MdOutlinePrint } from "react-icons/md";
import * as XLSX from "xlsx";
import { transformArray } from "../table.utils";

type Props = {
  dataToPrint: any[];
  title?: string;
  className?: string;
  onClick?: () => void;
  transFormKeys?: ITablePrinterCustomKeys[];
};
export type ITablePrinterCustomKeys = {
  key: string;
  replace: string;
};

const TablePrinter = (props: Props) => {
  const {
    dataToPrint,
    title = `Table ${new Date()}`,
    onClick,
    className,
    transFormKeys,
  } = props;

  const exportToExcel = (_data: any[], filename: string): void => {
    const data =
      transFormKeys && transFormKeys.length > 0 ? transformArray(transFormKeys, _data) : _data;

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, filename + ".xlsx");
  };

  // Example usage

  const handlePrint = () => {
    dataToPrint.length > 0 && exportToExcel(dataToPrint, title);
  };
  return (
    <div
      className={className}
      title={title ? `Print Csv: ${title}` : "Print CSV"}
      onClick={() => {
        onClick && onClick();
        handlePrint();
      }}
    >
      <MdOutlinePrint size={24} />
    </div>
  );
};


export default TablePrinter;
