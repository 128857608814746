import React, { useState } from "react";
import "../styles/index.css";
import "./login.css";
import BeginReset from "./reset-password-kids/begin-reset";
import CodeScreen from "./reset-password-kids/code-screen";
import ResetPasswordScreen from "./reset-password-kids/reset-password";

const ResetPassword = function ({ setShowResetPassword }) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [stage, setStage] = useState("begin-reset");
  const [codeSentFromServer, setCodeSentFromServer] = useState("");

  return (
    <div className="login-container">
      <BeginReset
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "begin-reset" && true}
        setCodeSentFromServer={setCodeSentFromServer}
        setShowResetPassword={setShowResetPassword}
      />
      <CodeScreen
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "code-screen" && true}
        stage={stage}
        codeSentFromServer={codeSentFromServer}
        setCode={setCode}
        code={code}
        setCodeSentFromServer={setCodeSentFromServer}
      />
      <ResetPasswordScreen
        setEmail={setEmail}
        email={email}
        setStage={setStage}
        display={stage === "reset-password" && true}
        setShowResetPassword={setShowResetPassword}
        code={code}
      />
    </div>
  );
};

export default ResetPassword;
