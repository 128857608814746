import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../estatemgt.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import {
  EstateTable,
  pseudoCheckBoxSelectedData,
} from "../../../components/estate-table/estate-table";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../components/searchInput/SearchInput.tsx";
import { AppConfig } from "../../../utils/config";

const EstateHome = function ({ display, setScreen }) {
  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [allEstatesData, setAllEstatesData] = useState(null);
  const [oringinalData, setOriginalData] = useState([]);
  const [universalDataCounts, setUniversalDataCounts] = useState({});
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const { backendServer, usersData, setIsLoading, patternMatching } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData(noLoader) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#estate-checkbox-parent"))
      document.querySelector("#estate-checkbox-parent").checked = false;
    try {
      if (!noLoader) setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estates/full-data/get?real=${AppConfig.realData}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const theReversed = [...returned?.data?.eachEstate];
      theReversed.reverse();
      setAllEstatesData(theReversed);
      setOriginalData(theReversed);
      setUniversalDataCounts(returned?.data?.universalCounts);
      setIsLoading(false);

      workCSV(theReversed);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estates Data");
    }
  }

  function workCSV(data) {
    setcsvData([
      ["Estate ID", "Estate Name", "Properties", "Residents", "Dues", "Admin", "Payouts"],
      ...data?.map(({ stat, estateDetails }) => {
        return [
          stat?.estateExtendedData?._id,
          estateDetails?.estate_name,
          stat?.totalNumberOfProperties,
          stat?.totalNumberOfResidents,
          stat?.totalNumberOfDues,
          stat?.allAdminsData?.length,
        ];
      }),
    ]);
  }

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#estate-checkbox-parent"))
      document.querySelector("#estate-checkbox-parent").checked = false;
    const query = e.target.value;

    if (!query) return getData(true);

    const foundEstates = oringinalData?.filter((e) => {
      return (
        patternMatching(query, e?.estateDetails?.estate_name) ||
        patternMatching(query, e?.stat?.estateExtendedData?._id)
      );
    });

    setAllEstatesData(foundEstates);
    workCSV(foundEstates);
  }

  if (!display) return;

  return (
    <div>
      <div className="estates-stat-container flex-row">
        <div className="each-e-stat-container">
          <img src="/property-imgs/p-1.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Onboarded Estates<br></br>
            <span className="big e-stat-text">{oringinalData?.length || "-"}</span>
          </p>
        </div>

        <div className="each-e-stat-container">
          <img src="/property-imgs/p-2.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Total Residents<br></br>
            <span className="big e-stat-text">
              {universalDataCounts?.totalNumberOfResidents || "-"}
            </span>
          </p>
        </div>

        <div className="each-e-stat-container">
          <img src="/property-imgs/p-3.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Total Properties<br></br>
            <span className="big e-stat-text">
              {universalDataCounts?.totalNumberOfProperties || "-"}
            </span>
          </p>
        </div>

        <div
          className="each-e-stat-container cursor"
          onClick={() =>
            setScreen({
              screen: "all-admin",
              data: null,
            })
          }
        >
          <img src="/property-imgs/p-4.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Estate Admins<br></br>
            <span className="big e-stat-text">
              {universalDataCounts?.totalNumberOfAdmin || "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="e-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for an estate" onChange={searchPlease} />
          <CSVLink className="cursor small csv-link" filename={`Estate(s) Data`} data={csvData}>
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>

          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData(false)}
          />
        </div>
        <EstateTable
          checkBoxSelectedData={checkBoxSelectedData}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          page={page}
          setPage={setPage}
          data={allEstatesData}
          setScreen={setScreen}
          setcsvData={setcsvData}
        />
      </div>
    </div>
  );
};

export default EstateHome;
