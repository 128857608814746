import React, { useContext, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Table from "../../../../components/table/Table";
import InputField from "../../../../components/inputField/InputField";
import AppContext from "../../../../context/app-context";
import SelectInput from "../../../../components/selectInput/SelectInput";
import "./UserHistory.css";
import {
  formatDateToCustomString,
  formatDateToTime,
  formatToMoney,
  handleStringSearch,
} from "../../../../utils";
import { fetchApi } from "../../../../api";
import Button from "../../../../components/button/Button";
import { TableHeaderItem } from "../../../../components/table/table.utils";
import { FaUser } from "react-icons/fa";
import { RiMotorbikeFill } from "react-icons/ri";

type Props = {
  onClickBack?: () => void;
};

type IDataType = {
  orderId: string;
  amount: string;
  date: string;
  residentName: string;
  estateName: string;
  supermarketVendor: string;
  driverName: string;
  methodOfPayment: string;
  status: string;
};

export const UserHistory = (props: Props) => {
  const { onClickBack } = props;

  const {
    backendServer,
    setIsLoading,
    resolveBackendUrl,
    setStoredContext,
    estates,
  } = useContext(AppContext);

  const [estate, setEstate] = useState<any>();
  const [query, setQuery] = useState<string>("");
  const [user, setUser] = useState<any>();
  const [data, setData] = useState<IDataType[]>([]);
  const [tableData, setTableData] = useState<IDataType[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>("All");
  const [total, setTotal] = useState("0");
  const [totalRecord, setTotalRecord] = useState("0");

  const filters = ["Estate", "Single"];

  const fetchUsersByEstate = async (userId?: string) => {
    try {
      if (!estate?.backend_base_url) return;
      setIsLoading?.(true);
      const url = userId
        ? `${estate?.backend_base_url}/user-stats/${userId}`
        : `${estate?.backend_base_url}/users-stats`;

      const { data, hasError } = await fetchApi({ url, method: "GET" });
      setIsLoading?.(false);
      if (hasError) {
        alert(data?.status || "Something went wrong");
        return;
      }
      setData(data?.data);
      setTableData(data?.data);
      const t = data?.data?.reduce((t: any, cur: any) => t + (parseFloat(cur?.amount || "0")) , 0);
      setTotal(t)
      setTotalRecord(data?.data.length)
    } catch (error) {}
  };

  const handleSearchUser = (arg: string) => {
    handleStringSearch(arg, data, (res) => {
      setUser(res[0]);
    });
  };

  //   type IDataType = {
  //     orderId: string;
  //     amount: string;
  //     date: string;
  //     residentName: string;
  //     estateName: string;
  //     supermarketVendor: string;
  //     driverName: string;
  //     methodOfPayment: string;
  //     status: string;
  //   };

  //   const title = "Estility Finance team";
  const header: TableHeaderItem[] = [
    { key: "date", label: "Date", minWidth: "120px" },
    { key: "residentName", label: "Resident Name" },
    { key: "estateName", label: "Estate Name" },
    {
      key: "skuPurchased",
      label: "SKU purchased (list of items including gas)",
    },
    { key: "quantityPurchased", label: "Quantity Purchased" },
    { key: "amount", label: "Amount Purchased" },
    { key: "supermarketVendor", label: "Supermarket Vendor" },
    {
      key: "driverName",
      label: "Driver that delivered (or delivery vehicle plate number)",
    },
    { key: "methodOfPayment", label: "Method of payment." },
  ];

  return (
    <div>
      <div className="uh-container">
        <div className="financemgt-back-btn" onClick={() => onClickBack?.()}>
          <MdKeyboardArrowLeft size={25} />
          Back{" "}
        </div>
        <div className="estate-incentive-label">
          User History Generator - Finance
        </div>

        <div className="uh-card-wrapper">
          {filters.map((x, i) => (
            <div
              key={i}
              className={`uh-card ${filter === x ? "uh-card-active" : " "}`}
              onClick={() => {
                setFilter(x);
                if(filter !== x) {
                    setTableData([])
                }
              }}
            >
              Filter by {x === "Single"? "Resident": x}
            </div>
          ))}
        </div>
        {filter === "Estate" || filter === "Single" ? (
          <SelectInput
            name="estateName"
            options={estates}
            keyToRender="estate_name"
            keyValue="estate_id"
            placeholder="Select an estate"
            value={estate?.estate_name}
            onChange={(e) => {
              const val = estates?.find(
                (j: any) => j?.estate_id === e?.target?.value
              );
              val && setEstate(val);
            }}
          />
        ) : null}

        {filter === "Single" && (
          <>
            <InputField
              label="Search user"
              name="search"
              value={query}
              onChange={(e) => {
                const str = e.target.value;
                setQuery(str);
                handleSearchUser(str);
                setTotal("0")
                setTotalRecord("0")
              }}
            />
            {(user && query) && (
              <div
                onClick={() => fetchUsersByEstate(user?.userId)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "5px",
                  backgroundColor: "rgba(0,0,0,0.05)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <FaUser />
                {user?.residentName}
                <RiMotorbikeFill />
                {user?.driverName}
              </div>
            )}
          </>
        )}

        <Button label="Query" onClick={() => fetchUsersByEstate()} />
        <div className="estate-incentive-label">
          Total Record: {totalRecord} - Cumulative Amount: (NGN) {formatToMoney(total)} - Query Time: {formatDateToTime(new Date())}
        </div>
        <i>Filtering by {filter === "Single"? "Resident in an Estate": filter}</i>

        <Table
          sn
          showSearchBar
          header={header}
          data={tableData || []}
          unRenderField={["rider"]}
          customTdWrappers={[
            {
              rowKeyName: "date",
              element: (tx, row) => (
                <div>{formatDateToCustomString(new Date(tx))}</div>
              ),
            },
          ]}
          placeholder="User Finance History"
        />
      </div>
    </div>
  );
};
