
export type IQuickActionScreens = "estateIncentive" | "estatePayouts" | "riderPayouts" | "vendorPayouts" | "confirmAccountDetails" | "financeReport" | "gasPricingTable" | 'groceryPricingTable' | 'gasPriceMarkup' | "refundOrder" | "userHistory" | "transfer" |"chargeUser" |"serviceName" | "promoService";
type IQuickAction = {
    group: string;
    items: {
        title: string;
        description: string;
        imgUrl: string;
        screen?: IQuickActionScreens;
        available?: boolean
    }[]
}
export const quickActionData: IQuickAction[] = [
    {
        group: "Payouts",
        items: [
            {
                title: "Estate Payouts",
                description: "Confirm and approve withdrawal requests from estate admins",
                imgUrl: '/service-icons/dollar-img.png', 
                screen: 'estatePayouts',
                available: false
            },
            {
                title: "Rider Payouts",
                description: "Confirm and approve payouts for riders.",
                imgUrl: '/service-icons/dollar-img.png', 
                screen: 'riderPayouts',
                available: false
            },
            {
                title: "Vendor Payouts",
                description: "Confirm and approve withdrawal requests from Vendors",
                imgUrl: '/service-icons/dollar-img.png', 
                screen:'vendorPayouts',
                available: false
            },
        ]
    },
    {
        group: "Delivery Pricing",
        items: [
            {
                title: "Grocery Delivery Pricing",
                description: "Manage Grocery Delivery fee",
                imgUrl: '/service-icons/mdi_bike.png', 
                screen: 'groceryPricingTable',
                available: true
            },
            {
                title: "Gas Delivery Pricing",
                description: "Manage Gas Delivery Fee",
                imgUrl: '/service-icons/mdi_bike-fast.png',
                screen: 'gasPricingTable',
                available: true
            }
        ]
    },
    {
        group: "Pricing and Markups",
        items: [
            {
                title: "Product Pricing Markup",
                description: "Manage Grocery Products Price",
                imgUrl: '/service-icons/ion_pricetags-outline.png', 
                available: false
            },
            {
                title: "Gas Price Markup",
                description: "Manage Gas Sales Price",
                imgUrl: '/service-icons/fluent_gas-pump-24-regular.png', 
                screen: 'gasPriceMarkup',
                available: true
            }
        ]
    },
    {
        group: "Service Incentives",
        items: [
            {
                title: "Set rider incentive",
                description: "Manage grocery products price",
                imgUrl: '/service-icons/calendar.png', 
                available: false
            },
            {
                title: "Set estate incentive",
                description: "View and track payment progress across all platforms",
                imgUrl: '/service-icons/wallet.png', 
                screen: 'estateIncentive',
                available: false
            }
        ]
    },
    {
        group: "Users",
        items: [
            {
                title: "User Account history",
                description: "Generate finance report of user",
                imgUrl: '/service-icons/user.png', 
                screen: 'userHistory',
                available: true
            },
            {
                title: "Charge user",
                description: "Transfer wallet found",
                imgUrl: '/service-icons/dollar-img.png', 
                screen: 'chargeUser',
                available: false
            },
            {
                title: "Transfer",
                description: "Transfer wallet found",
                imgUrl: '/service-icons/dollar-img.png', 
                screen: 'transfer',
                available: false
            }
        ]
    },
    {
        group: "Others",
        items: [
            {
                title: "Finance Report",
                description: "View and generate cross sectional reports",
                imgUrl: '/service-icons/coin-stack.png', 
                screen: 'financeReport',
                available: false
            },
            {
                title: "Confirm Account Details",
                description: "Manage finance account setting for users (vendor, rider, estate)",
                imgUrl: '/service-icons/user.png', 
                screen: 'confirmAccountDetails',
                available: false
            },
            {
                title: "Refund Order Product",
                description: "Manage order",
                imgUrl: '/service-icons/user.png', 
                screen: 'refundOrder',
                available: false
            },
            {
                title: "Promo Service",
                description: "Manage promo",
                imgUrl: '/service-icons/user.png', 
                screen: 'promoService',
                available: true
            },
            // {
            //     title: "Estate Service",
            //     description: "Manage order",
            //     imgUrl: '/service-icons/user.png', 
            //     screen: 'serviceName',
            //     available: true
            // }
        ]
    },
]