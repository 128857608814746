import React, { useContext, useEffect, useState } from "react";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import { fetchApi } from "../../../../api";
import { AppConfig } from "../../../../utils/config";
import {
  formatDateDDMMYYY,
  formatDateToTime,
  spreadListObj,
} from "../../../../utils";
import RatingIcon from "./RatingIcon";
import ReviewFeedbackModal from "../../../../components/modal/reviewFeedbackModal/ReviewFeedbackModal";
import { TableHeaderItem } from "../../../../components/table/table.utils";


const ReviewTable = () => {
  const { backendServer, setIsLoading, estates } = useContext(AppContext);
  const [filterData, setFilterData] = useState<any[]>([]);
  const findEstate = (value:string) => {
    return estates?.find((estate:any) => estate?.estate_id == value)?.estate_name;
  }
  const [currentItem, setCurrentItem] = useState<any>();

  const orderTableHeaders: TableHeaderItem[] = [
    { key: "_id", label: "Review ID" },
    { key: "email", label: "User Email" },
  ];

  async function getReviews() {
    const url = `${backendServer}/feedback/global/get?category=order&real=${AppConfig.realData}`;
    try {
      setIsLoading(true);
      const { data } = await fetchApi({
        url,
        method: "GET",
        token: localStorage.getItem("omstoken") || "",
      });

      if (data?.data) {
        const _data = spreadListObj(data?.data, "user_details");
        setFilterData(_data);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getReviews();
  }, []);

  const handleRowClick = (row?: any) => {
    setCurrentItem(row);
  };

  // handle close modal on ESC button click
  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      !!currentItem && setCurrentItem(null)
    }
  };
  useEffect(() => {

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [currentItem]);
  // handle close modal on ESC button click

  return (
    <>
      <Table
        data={filterData}
        header={orderTableHeaders}
        showSearchBar
        onRefreshIconClick={() => {
          getReviews();
        }}
        onRowClick={(row) => handleRowClick(row)}
        customTdWrappers={[
          {rowKeyName: "_id",
            element: (txt) => <div title={txt}>{txt?.slice(-6).toUpperCase()}</div>
          }
        ]}
        customColumn={[
          {
            keyName: "estate_name",
            element: (_, index, row) => {
              console.log(row);
              let value: any = "0";
              const rating: any[] = row?.ratings || [];
              const temp = rating.find((e) => e?.type === "order");
              if (temp?.rate) {
                value = temp?.rate;
              }
              return (
                <div>
                  {findEstate(row?.estateID)}
                </div>
              );
            },
            title: () => "Estate Name",
            position: "end",
          },
          {
            keyName: "appRating",
            element: (_, index, row) => {
              let value: any = "0";
              const rating: any[] = row?.ratings || [];
              const temp = rating.find((e) => e?.type === "order");
              if (temp?.rate) {
                value = temp?.rate;
              }
              return (
                <div>
                  <RatingIcon rate={value} />
                </div>
              );
            },
            title: () => "App Rating",
            position: "end",
          },
          {
            keyName: "riderRating",
            element: (_, index, row) => {
              let value: any = "0";
              const rating: any[] = row?.ratings || [];
              const temp = rating.find((e) => e?.type === "rider");
              if (temp?.rate) {
                value = temp?.rate;
              }
              return (
                <div>
                  <RatingIcon rate={value} />
                </div>
              );
            },
            title: () => "Rider Rating",
            position: "end",
          },
          {
            keyName: "userComments",
            element: (_, index, row) => {
              return (
                <div title={row?.message}>{row?.message ? "Yes" : "No"}</div>
              );
            },
            title: () => "Comments",
            position: "end",
          },
          {
            keyName: "orderType",
            element: (_, index, row) => {
              return (
                <div title={row?.orderDetails?.orderType}>
                  {row?.orderDetails?.orderType}
                </div>
              );
            },
            title: () => "Order Type",
            position: "end",
          },
          {
            keyName: "orderType",
            element: (_, index, row) => {
              const item = row?.createdAt;
              const date = formatDateDDMMYYY(new Date(item));
              const time = formatDateToTime(new Date(item));
              return (
                <div title={date}>
                  {date}
                  {"  "}
                  {time}
                </div>
              );
            },
            title: () => "Date",
            position: "end",
          },
          {
            keyName: "actionOption",
            element: (_, index, row) => {
              return (
                <div
                  onClick={() => {
                    handleRowClick(row);
                  }}
                  title={"Click to view"}
                  style={{ cursor: "pointer", color: "#8883CC" }}
                >
                  View
                </div>
              );
            },
            title: () => "",
            position: "end",
          },
        ]}
      />
      {currentItem && (
        <ReviewFeedbackModal
          isVisible={!!currentItem}
          onClose={() => setCurrentItem(null)}
          arg={currentItem}
        />
      )}
    </>
  );
};

export default ReviewTable;
