export const services = [
  {
    title: "User Reviews",
    description: "View all order reviews gotten from E-Resident app users",
    isLocked: false,
    available: true,
    imageSrc: "/general-reports/star.png",
  },
  {
    title: "Reason for Account Deletion",
    description: "View the reasons users decided to delete their accounts",
    isLocked: false,
    available: true,
    imageSrc: "/general-reports/person.png",
  },

  {
    title: "Referrals",
    description: "View all users referral activites",
    isLocked: false,
    available: true,
    imageSrc: "/general-reports/referral-icon.svg",
  },
];
