import { useContext } from "react";
import "../../../styles/index.css";
import "../add-user/add-a-user.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./review-order.css";
import Button from "../../button/Button";
import { IoMdRadioButtonOn } from "react-icons/io";
import UserDetailCard from "../../userDetailCard/UserDetailCard";
import { ArrowSvg, BikeSvg, BoxSvg, NoteSvg } from "./svg/OrderSvg";
import { formatDateToTime, formatToMoney } from "../../../utils";
import { FaRegCheckCircle } from "react-icons/fa";
import { CiClock2 } from "react-icons/ci";

const ReviewOrderModal = function ({ display, setReviewOrderModal, order, url, clearState }) {
  const { setIsLoading } = useContext(AppContext);

  const { buyer, deliveryAddress, rider, status } = order;

  const backendServerUrl = url;

  async function markOrder(type) {
    try {
      if (type === "shipped") {
        setIsLoading(true);

        const returned = await (
          await fetch(`${url}/order/${type}/${order?._id}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        popup(`Order marked as ${type}!`);
        setIsLoading(false);
        clearState && clearState();
      }
    } catch (error) {
      setIsLoading(false);
      popup("I could not mark this order as shipped. Confirm the order and try again...");
    }
  }

  return (
    <div
      className="modal-container modal-on-modal-container"
      style={{ display: `${display && order && url ? "flex" : "none"}` }}
    >
      <div className="modal-body ro-modal-on-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setReviewOrderModal(false);
              clearState && clearState();
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="ro-order-overview-modal-header">
          <div>Order Overview</div>
        </div>

        <div className="ro-order-overview-content-wrapper">
          <div className="ro-order-overview-details">
            <UserDetailCard
              title="Resident Details"
              fullName={`${buyer?.first_name} ${buyer?.last_name}`}
              phoneNumber={buyer?.mobile}
              address={deliveryAddress?.addressString}
              imageUrl={buyer?.profile_picture}
              baseUrl={backendServerUrl}
              email={buyer?.email}
            />
            <UserDetailCard
              title="Rider Details"
              fullName={`${rider?.first_name || ""} ${rider?.last_name || ""}`}
              phoneNumber={rider?.mobile}
              imageUrl={rider?.profile_picture}
              address={status}
              baseUrl={backendServerUrl}
              email={rider?.email}
            />
          </div>

          <div className="ro-order-overview-sub-header">
            <div className="ro-order-overview-sub-header-title">Order Summary</div>
            <div className="ro-order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="ro-order-overview-summary-wrapper">
            <div className="ro-order-overview-summary-title">Delivery ID: {order?._id}</div>
            <div className="ro-order-overview-summary-from-to">
              <div>From {order?.products && order?.products[0]?.vendorID?.vendor_name} </div>
              <ArrowSvg />
              <div>To {order?.deliveryAddress?.addressString}</div>
            </div>

            <div className="ro-order-overview-summary-progress-wrapper">
              <div className="ro-order-overview-summary-progress-line">
                <IoMdRadioButtonOn className="ro-order-overview-summary-progress-line-child" />
                <IoMdRadioButtonOn className="ro-order-overview-summary-progress-line-child" />
                <IoMdRadioButtonOn className="ro-order-overview-summary-progress-line-child" />
              </div>

              <div className="ro-order-overview-summary-progress-icon-wrapper">
                <div>
                  <NoteSvg />
                  <div className="ro-order-overview-summary-progress-icon-label">Order Placed</div>
                  <div className="ro-order-overview-summary-progress-time">
                    <CiClock2 />
                    {formatDateToTime(new Date(order?.createdAt))}
                  </div>
                </div>
                <div>
                  <BikeSvg />
                  <div className="ro-order-overview-summary-progress-icon-label">
                    Order Picked Up
                  </div>
                  <div className="ro-order-overview-summary-progress-time"></div>
                </div>
                <div>
                  <BoxSvg />
                  <div className="ro-order-overview-summary-progress-icon-label">
                    Order Delivered
                  </div>
                  <div className="ro-order-overview-summary-progress-time">
                    <CiClock2 />
                    {order?.completedAt ? formatDateToTime(new Date(order?.completedAt)) : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ro-order-overview-sub-header">
            <div className="ro-order-overview-sub-header-title">Order Note</div>
            <div className="ro-order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="ro-order-overview-order-notes">{order?.orderNotes || " - "}</div>

          <div className="ro-order-overview-sub-header">
            <div className="ro-order-overview-sub-header-title">Order details</div>
            <div className="ro-order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="ro-order-overview-details-wrapper">
            <div>
              <div>Items</div>
              <div>Price</div>
            </div>

            {order?.products &&
              order?.products.map((product, index) => (
                <div className="ro-order-overview-details-item" key={index}>
                  <div>
                    {order?.orderType.toString().toLowerCase() === "gas"
                      ? `${product?.quantity}${product?.weight_or_size_or_type?.unit} Gas`
                      : product.productID?.product_name + ` (X${product?.quantity})`}
                  </div>
                  <div>
                    NGN
                    {`${order?.orderType}`.toLowerCase() === "gas"
                      ? formatToMoney(order?.totalAmount - order?.deliveryFee)
                      : formatToMoney(product?.productID?.minimumPrice * product?.quantity)}
                  </div>
                </div>
              ))}

            <div className="ro-order-overview-details-item">
              <div>Delivery</div>
              <div>NGN{formatToMoney(order?.deliveryFee)}</div>
            </div>

            <div className="ro-order-overview-details-total">
              <div>Grand Total</div>
              <div>NGN{formatToMoney(order?.totalAmount)}</div>
            </div>
          </div>

          <div className="flex-row" style={{ marginBottom: 20 }}>
            {(status === "pending" || status === "processing") && (
              <Button
                label="Mark Order as Shipped"
                btnType="outline"
                leftIcon={<FaRegCheckCircle />}
                style={{
                  color: "#5A9A8F",
                  borderColor: "#5A9A8F",
                  fontSize: "0.8rem",
                }}
                onClick={() => markOrder("shipped")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewOrderModal;
