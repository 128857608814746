import React, { useContext, useEffect, useRef, useState } from "react";
import TabStatsCard from "../../../../components/card/tabStatCard/TabStatsCard";
import "./VendorDashboard.css";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import { fetchApi } from "../../../../api";
import { popup } from "../../../../vanilla-functions/model";
import { TableHeaderItem } from "../../../../components/table/table.utils";
import { calculateVendorStats } from "../../vendor.dto";
import { shortenText } from "../../../../utils";
import { AppConfig } from "../../../../utils/config";
import { OverViewSvg, SuspendSvg } from "../../svg/vendor.svg";
import { FaRegCheckCircle } from "react-icons/fa";
import { GoPlusCircle } from "react-icons/go";
import VendorOverview from "../../vendor-overview/vendor-overview";
import AssignUser from "../../assign-user/assign-user";
import AddEstate from "../../add-estate/add-estate";
import { IActionCardClickTableVersion } from "../../../../components/table/table.interface";
import TableActionCard, {
  IActionCardClick,
} from "../../../../components/table/tableActionCard/TableActionCard";
import { SlOptionsVertical } from "react-icons/sl";

type Props = {
  onNavigateToTransaction?: (screenIndex: number, nextIndex?: number) => void;
  setVendor: any;
  setEstateId: any;
  setEstateName: any;
};

const VendorDashboard = (props: Props) => {
  const optionOverlayDivRef = useRef<HTMLDivElement>(null);

  const { resolveBackendUrl, backendServer, setIsLoading, isLoading } =
    useContext(AppContext);
  const { onNavigateToTransaction, setVendor, setEstateId, setEstateName } =
    props;
  const [showOverView, setShowOverView] = useState<boolean>(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [showAddestate, setShowAddEstate] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<any>({});
  const [state, setState] = useState<any>({});

  const [data, setData] = useState<any[]>([]);
  const [filterData, setFilterData] = useState<any[]>([]);
  const [typeOfVendor, setTypeOfVendor] = useState<string>("");
  const [stats, setStats] = useState<{
    totalVendor: number;
    groceryVendor: number;
    gasVendor: number;
    pendingProduct: number;
  }>({
    gasVendor: 0,
    groceryVendor: 0,
    pendingProduct: 0,
    totalVendor: 0,
  });
  const tableHeader: TableHeaderItem[] = [
    { key: "vendor_name", label: "Vendor Name" },
    { key: "estate_name", label: "Allocated Estate" },
    { key: "vendor_address", label: "Vendor Address", minWidth: "200px" },
    { key: "vendor_category", label: "Vendor Type" },
    { key: "vendor_owner", label: "Vendor Contact" },
  ];

  const handleFetchVendors = async () => {
    try {
      setIsLoading(true);
      const url = `${backendServer}/estates/vendors/get?real=${AppConfig.realData}`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      const fetchedVendor = data?.data;
      // const statusText = data?.status;
      setData(fetchedVendor);
      setFilterData(fetchedVendor);

      const newStats = calculateVendorStats(fetchedVendor);

      setStats((p) => ({ ...p, ...newStats }));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchVendors();
  }, []);

  const handleFilterByType = (type: string) => {
    if (type === "All") {
      setTypeOfVendor("");
      setFilterData(data);
      return;
    }
    setTypeOfVendor(type === "Groceries" ? "Grocery" : type);
    const _filteredData = data.filter((item) => {
      return `${item?.vendor_category}`.toLowerCase() === type.toLowerCase();
    });

    setFilterData(_filteredData);
  };

  const handleRowClick = (row: any) => {
    const category = `${row?.vendor_category}`.toLowerCase();
    const isGroceryVendor = category === "Groceries".toLowerCase();

    const estateID = row?.estateID;
    const estateNAME = row?.estate_name;

    setVendor && setVendor(row);
    setEstateId && setEstateId(estateID);
    setEstateName && setEstateName(estateNAME);
    if (isGroceryVendor) {
      onNavigateToTransaction && onNavigateToTransaction(1, 0);
    } else {
      // onNavigateToTransaction && onNavigateToTransaction(3);
    }
  };

  // useEffect(() => {
  //   // Assuming `data` is the complete list of vendors and is updated on fetch
  //   // This finds the updated vendor object that matches the selectedVendor
  //   const updatedSelectedVendor = data.find(
  //     (v) => v?._id === selectedVendor?._id
  //   );
  //   if (updatedSelectedVendor) {
  //     setSelectedVendor(updatedSelectedVendor);
  //   }
  // }, [data]); // Dependency on the vendor data

  const handleAddProductClick = (row: any) => {
    const category = `${row?.vendor_category}`.toLowerCase();
    const isGroceryVendor = category === "Groceries".toLowerCase();

    const estateID = row?.estateID;
    const estateNAME = row?.estate_name;

    setVendor && setVendor(row);
    setEstateId && setEstateId(estateID);
    setEstateName && setEstateName(estateNAME);
    if (isGroceryVendor) {
      onNavigateToTransaction && onNavigateToTransaction(1, 3);
    } else {
    }
  };

  async function deactivateVendor(vendor: any) {
    try {
      console.log(vendor?._id);
      if (!vendor?._id || !vendor?.estateID) return;
      const _suspendOrRevive = vendor?.is_suspended ? "revive" : "suspend";
      const url = `${resolveBackendUrl(vendor?.estateID)}/vendor/availability/${
        vendor?._id
      }/${_suspendOrRevive}`;
      setIsLoading(true);
      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        } as any,
      });

      const returned = await response.json();

      popup(returned?.status);
      handleFetchVendors();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  const tableActionOptions = (row: any): IActionCardClickTableVersion[] => [
    {
      label: "Vendor Overview",
      onClick: () => {
        setSelectedVendor(row);
        setShowOverView(true);
      },
      icon: <OverViewSvg />,
      color: "#7975B6",
    },
    {
      label: "Add Single Product",
      onClick: () => handleAddProductClick(row),
      icon: <FaRegCheckCircle size={18} />,
      color: "#16B1FF",
    },
    // {
    //   label: "Add Bulk Product",
    //   onClick: (row) => {},
    //   color: "#5A9A8F",
    //   icon: <FaRegCheckCircle size={18} />,
    // },
    {
      label: "Add Estate",
      onClick: () => {
        setShowAddEstate(true);
        setSelectedVendor(row);
      },
      color: "#47586E",
      icon: <GoPlusCircle size={18} />,
    },
    {
      label: "Assign User",
      onClick: () => {
        setSelectedVendor(row);
        setShowAddUser(true);
      },
      color: "#0D6A99",
      icon: <FaRegCheckCircle size={18} />,
    },
    {
      label: `${row?.is_suspended ? "Revive" : "Suspend"} Vendor`,
      onClick: () => {
        deactivateVendor(row);
      },
      color: "#C8CC66",
      icon: <SuspendSvg />,
    },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    setState({});
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="vendor-dashboard-container">
      <div className="vendor-dashboard-header-title">
        {typeOfVendor || "All"} vendors
      </div>
      <div className="vendor-stats-wrapper">
        <TabStatsCard
          count={stats.totalVendor}
          imageSrc="/dashboard-icons/all-acct.png"
          title="All Vendors"
          onClick={() => handleFilterByType("All")}
        />
        <TabStatsCard
          count={stats.groceryVendor}
          imageSrc="/dashboard-icons/vendor-acct.png"
          title="Grocery Vendors"
          onClick={() => handleFilterByType("Groceries")}
        />
        <TabStatsCard
          count={stats.gasVendor}
          imageSrc=""
          title="Gas Vendors"
          onClick={() => handleFilterByType("Gas")}
        />
        <TabStatsCard
          count={stats.pendingProduct}
          imageSrc=""
          title="Pending Products"
          onClick={() => onNavigateToTransaction && onNavigateToTransaction(1)}
        />
      </div>
      <>
        <Table
          showSearchBar
          searchPlaceholder={`Search ${typeOfVendor || "All"} vendors`}
          data={filterData}
          header={tableHeader}
          loading={isLoading}
          onRefreshIconClick={() => handleFetchVendors()}
          customTdWrappers={[
            {
              element: (item: any) => (
                <div
                  style={{ color: "#838383" }}
                  title={item?.addressString || "-"}
                >
                  {shortenText({
                    maxLen: 71,
                    text: item?.addressString,
                    replacer: "...",
                  }).text || ""}
                </div>
              ),
              rowKeyName: "vendor_address",
            },
            {
              element: (item: any) => (
                <div style={{ color: "#838383" }}>{item?.mobile || "-"}</div>
              ),
              rowKeyName: "vendor_owner",
            },
          ]}
          columnMaxLen={100}
          customColumn={[
            {
              element: (row) => {
                const category = `${row?.vendor_category}`.toLowerCase();
                const isGroceryVendor = category === "Groceries".toLowerCase();
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row);
                    }}
                    className={`${isGroceryVendor && "cursor"}`}
                    style={{
                      opacity: isGroceryVendor ? 1 : 0.2,
                      color: "#6853BC",
                      textAlign: "center",
                    }}
                  >
                    View
                  </div>
                );
              },
              keyName: "availableStock",
              position: "end",
              title: () => (
                <div style={{ textWrap: "nowrap", minWidth: "120px" }}>
                  Available Stock
                </div>
              ),
              onClick: (row: any) => {
                handleRowClick(row);
              },
            },
            {
              element: (row) => {
                const category = `${row?.vendor_category}`.toLowerCase();
                const isGroceryVendor = category === "Groceries".toLowerCase();
                const result = isGroceryVendor
                  ? `${row?.products?.length || 0}`
                  : `-`;
                return <div>{result}</div>;
              },
              keyName: "productCount",
              position: "end",
              title: () => (
                <div style={{ textWrap: "nowrap", minWidth: "30px" }}>
                  Product Count
                </div>
              ),
            },
            {
              element: (row) => {
                return <div style={{color: row?.is_suspended? "tomato": "teal", fontSize: "0.69rem" }}>{row?.is_suspended? "Suspended": "Active"}</div>;
              },
              keyName: "statjj",
              position: "end",
              title: () => (
                <div style={{ textWrap: "nowrap", minWidth: "30px"}}>
                  Status
                </div>
              ),
            },
            {
              element: (row, index) => {
                const stateName = row?._id + index;
                return (
                  <div
                    ref={optionOverlayDivRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      // Logic
                      setState((p: any) => ({
                        [stateName]:
                          state[stateName] === "open" ? "close" : "open",
                      }));
                    }}
                    style={{ position: "relative" }}
                  >
                    {
                      <div style={{ height: "0.7rem" }}>
                        <SlOptionsVertical size={"1rem"} />
                      </div>
                    }
                    {state[stateName] === "open" && (
                      <div
                        style={{
                          position: "absolute",
                          right: 2,
                          zIndex: 2,
                          marginTop: "0.7rem",
                        }}
                      >
                        <TableActionCard
                          others={tableActionOptions(row) as IActionCardClick[]}
                        />
                      </div>
                    )}
                  </div>
                );
              },
              keyName: "actions",
              position: "end",
              title: () => "",
            },
            
          ]}
          onRowClick={(row) => {
            handleRowClick(row);
            // console.log(row);
          }}
          // onActionOptionClicks={{
          //   onOthers: tableActionOptions,
          // }}
        />
      </>

      <AssignUser
        isOpen={showAddUser}
        setIsOpen={setShowAddUser}
        selectedVendor={selectedVendor}
      />
      <VendorOverview
        isOpen={showOverView}
        setIsOpen={setShowOverView}
        selectedVendor={selectedVendor}
        fetchVendors={handleFetchVendors}
        deactivateVendor={deactivateVendor}
        suspendOrRevive={selectedVendor?.is_suspended ? "revive" : "suspend"}
      />
      <AssignUser
        isOpen={showAddUser}
        setIsOpen={setShowAddUser}
        selectedVendor={selectedVendor}
      />
      <AddEstate
        selectedVendor={selectedVendor}
        isOpen={showAddestate}
        setIsOpen={setShowAddEstate}
        handleFetchVendors={handleFetchVendors}
      />
    </div>
  );
};

export default VendorDashboard;
