import { useContext } from "react";
import AppContext from "../../../../context/app-context";

const ReferralHistory = ({ selectedReferral }) => {
  const { estates } = useContext(AppContext);

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  };

  return (
    <div className="referral-finance-info">
      <div className="referral-financial-info-section">
        {selectedReferral?.users_referred?.length > 0 ? (
          selectedReferral?.users_referred.map((user, index) => (
            <div key={index} className="referral-finance-info-container">
              <div className="referral-history-data">
                <div className="ml-1 information-heading ">
                  <b>
                    {user?.user?.first_name} {user?.user?.last_name}
                  </b>
                </div>

                <div
                  style={{ color: "#5F5E5E" }}
                  className="ml-1 d-flex justify-content-between mt-3"
                >
                  <div className="d-flex flex-column align-items-start gap-3">
                    <div>
                      Estate:{" "}
                      <b className="referral-history-info">
                        {findEstate(selectedReferral?.estateID)}
                      </b>
                    </div>
                    <div className="">
                      Email:{" "}
                      <b className="referral-history-info">
                        {user?.user?.email}
                      </b>
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-start gap-3">
                    <div className="">
                      Mobile Number:{" "}
                      <b className="referral-history-info">
                        {user?.user?.mobile}
                      </b>
                    </div>
                    <div>
                      Status:{" "}
                      <span
                        style={{
                          color: user?.has_ordered ? "#347900" : "#996C00",
                        }}
                      >
                        {user?.has_ordered ? "Successful" : "Pending"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="referral-history-data">
            <div style={{ color: "#050505" }} className="ml-1">
              No Referral Data
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralHistory;
