import React, { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import "./EstateIncentiveTable.css";
import DeliveryPriceModal from "../../../../components/modal/deliveryPriceModal/DeliveryPriceModal";

type Props = {
  onClickBack?: () => void;
};

const EstateIncentiveTable = (props: Props) => {
  const { onClickBack } = props;
  const { backendServer, setIsLoading } = useContext(AppContext);

  const [data, setData] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const generateData = (): any => ({
    estateId: `TXN${Math.floor(Math.random() * 100000)}`,
    estateName: "Lakeview Estate",
    incentive: Math.floor(Math.random() * 20) + 1,
    residentCount: Math.floor(Math.random() * 1000) + 1,
    adminCount: Math.floor(Math.random() * 1000) + 1,
    payout: Math.floor(Math.random() * 1000) + 1,
  });

  useEffect(() => {
    (() => {
      setIsLoading(true);
      setTimeout(() => {
        const res = Array.from(Array(100).keys()).map(() => generateData());
        setData(res);
        setIsLoading(false);
      }, 2000);
    })();
  }, []);

  const handleSetIncentive = (price: number) => {
    if (price) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setShowModal(false);
      }, 2000);
    }
  };

  return (
    <div className="">
      <div
        className="financemgt-back-btn"
        onClick={() => onClickBack && onClickBack()}
      >
        <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <div className="estate-incentive-label">Estate incentives</div>
      <Table
        data={data}
        header={[
          "Estate ID",
          "Estate Name",
          "Incentive %",
          "Resident",
          "Admins",
          "Payouts",
        ]}
        onRowClick={(row) => {setActiveRow(row); setShowModal(true)}}
      />

      {showModal && (
        <DeliveryPriceModal
          onClose={() => setShowModal(false)}
          title={"Set Incentive Amount"}
          subTitle={`Current rate: ${activeRow?.incentive}%`}
          btnLabel={"Save Changes"}
          inputPlaceHolder={"type incentive amount here (in percentage)"}
          onActionBtnClick={(price) => handleSetIncentive(price)}
          inputProps={{
            maxLength: 2,
            formatValue: "number"
          }}
        />
      )}
    </div>
  );
};

export default EstateIncentiveTable;
