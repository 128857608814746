import React from "react";
import "./promoModal.css";
import InputField, { IInputField } from "../../inputField/InputField";
import Button from "../../button/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import SelectInput from "../../selectInput/SelectInput";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { convertDateToISO } from "../../../utils";

type Props = {
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  btnLabel?: string;
  onActionBtnClick?: (arg?: any) => void;
  onChange?: (arg?: any) => void;
  inputProps?: Partial<IInputField>;
  activeRow?: any;
};

function PromoModal(props: Props) {
  const {
    onClose,
    subTitle,
    title,
    btnLabel,
    onActionBtnClick,
    activeRow,
    onChange,
    inputProps,
  } = props;

  const [promoId, setPromoId] = React.useState<string>(activeRow?._id);
  const [name, setName] = React.useState<string>(activeRow?.name || "");
  const [maxKg, setMaxKg] = React.useState<string>(activeRow?.maxKg || 0);
  const [amount, setAmount] = React.useState<string>(activeRow?.amount || 0);
  const [startDate, setStartDate] = React.useState<any>(
    convertDateToISO(activeRow?.startDate)
  );
  const [endDate, setEndDate] = React.useState<any>(
    convertDateToISO(activeRow?.endDate)
  );
  const [statusTypeOptions, _setStatusTypeOptions] = React.useState([
    { name: "Activate", result: true },
    { name: "Deactivate", result: false },
  ]);

  const [selectedStatus, setSelectedStatus] = React.useState<string>(() => {
    const initialOption = statusTypeOptions.find(
      (option) => option.result === activeRow?.isActive
    );
    return initialOption ? initialOption.name : "";
  });

  const [statusType, setStatusType] = React.useState<boolean | undefined>(
    activeRow?.isActive || undefined
  ); // Stores the boolean result

  console.log(activeRow?.startDate);

  return (
    <div
      className="promo-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="promo-modal-container">
        <div className="promo-modal-header">
          <div className="promo-modal-header-title">{title || ""}</div>
          <div className="promo-modal-header-subtitle">{subTitle}</div>
        </div>
        <h6>{activeRow?.estateName}</h6>

        <SelectInput
          name="statusType"
          dropIcon={<MdOutlineKeyboardArrowDown />}
          value={selectedStatus} // Show the name in the select field
          onChange={(e) => {
            const selectedOption = statusTypeOptions.find(
              (option) => option.name === e.target.value
            );
            if (selectedOption) {
              setSelectedStatus(selectedOption.name);
              setStatusType(selectedOption.result);
            }
          }}
          options={statusTypeOptions?.map((option) => ({
            label: option.name,
            value: option.name,
          }))}
        />
        <InputField
          {...inputProps}
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {
            setPromoId(value);
            onChange && onChange(value);
          }}
          label=""
          name="promoId"
          value={activeRow?._id}
          placeholder={"Promo Id"}
          labelType="nested"
        />
        <InputField
          {...inputProps}
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {
            setName(value);
            onChange && onChange(value);
          }}
          label="Promo Name"
          name="name"
          value={name ?? activeRow?.name}
          placeholder={activeRow?.name || "Promo Name"}
          labelType="nested"
        />
        <InputField
          formatValue="number"
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {
            setMaxKg(value);
            onChange && onChange(value);
          }}
          label="Max Kg"
          name="maxKg"
          value={maxKg ?? activeRow?.maxKg}
          placeholder={activeRow?.maxKg || "Max Kg"}
          labelType="nested"
        />
        <InputField
          formatValue="number"
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {
            setAmount(value);
            onChange && onChange(value);
          }}
          label="Promo Price (NGN)"
          name="amount"
          value={amount ?? activeRow?.amount}
          placeholder={activeRow?.amount || "Promo price"}
          labelType="nested"
        />

        <InputField
          label="Start Date"
          name="startDate"
          placeholder="eg. 10/28/2024"
          type="date"
          value={startDate}
          onChange={({ target: { value } }) => {
            setStartDate(value);
            onChange && onChange(value);
          }}
          labelType="nested"
        />
        <InputField
          label="End Date"
          name="endDate"
          placeholder="eg. 10/16/2024"
          type="date"
          value={endDate}
          onChange={({ target: { value } }) => {
            setEndDate(value);
            onChange && onChange(value);
          }}
          labelType="nested"
        />

        <Button
          style={{ width: "70%" }}
          onClick={() =>
            onActionBtnClick &&
            onActionBtnClick({
              id: promoId,
              name,
              maxKg,
              amount,
              isActive: statusType,
              startDate,
              endDate,
            })
          }
          label={btnLabel || "Save changes"}
        />

        <AiFillCloseCircle
          className="promo-modal-close-icon"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
}

export default PromoModal;
