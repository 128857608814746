export const WarningSvg = () => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C13.785 0 16.4559 1.10633 18.4252 3.0756C20.3944 5.04487 21.5008 7.71578 21.5008 10.5007C21.5008 13.2857 20.3944 15.9566 18.4252 17.9259C16.4559 19.8952 13.785 21.0015 11 21.0015C8.21504 21.0015 5.54414 19.8952 3.57487 17.9259C1.60559 15.9566 0.499268 13.2857 0.499268 10.5007C0.499268 7.71578 1.60559 5.04487 3.57487 3.0756C5.54414 1.10633 8.21504 0 11 0ZM12.575 6.447C13.355 6.447 13.988 5.9055 13.988 5.103C13.988 4.3005 13.3535 3.759 12.575 3.759C11.795 3.759 11.165 4.3005 11.165 5.103C11.165 5.9055 11.795 6.447 12.575 6.447ZM12.8495 14.8875C12.8495 14.727 12.905 14.31 12.8735 14.073L11.6405 15.492C11.3855 15.7605 11.066 15.9465 10.916 15.897C10.848 15.872 10.7911 15.8234 10.7556 15.7602C10.7201 15.697 10.7084 15.6231 10.7225 15.552L12.7775 9.06C12.9455 8.2365 12.4835 7.485 11.504 7.389C10.4705 7.389 8.94952 8.4375 8.02402 9.768C8.02402 9.927 7.99402 10.323 8.02552 10.56L9.25702 9.1395C9.51202 8.874 9.80902 8.6865 9.95902 8.7375C10.0329 8.76402 10.0935 8.81847 10.1277 8.88914C10.1619 8.95981 10.167 9.04108 10.142 9.1155L8.10502 15.576C7.86952 16.332 8.31502 17.073 9.39502 17.241C10.985 17.241 11.924 16.218 12.851 14.8875H12.8495Z"
      fill="#F8B303"
    />
  </svg>
);
