import React, { useState, useContext } from 'react';
import './confirmation.css';
import question from '../../../assets/question.png';
import closeIcon from '../../../assets/Icon.svg';
import Success from '../success/Success';
import AppContext from '../../../context/app-context';

const Confirmation = ({ title, isOpen, setIsOpen, yes, cancel, onClickCancel, onClickYes,  closeAll, revoked, showSuccess }) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const { isLoading } = useContext(AppContext);
  const sendRevoke = () => {
    onClickYes()
    // setIsSuccessOpen(true)
  }

  if (!isOpen) return;
  return (
    <>
      <div className='modal-overlay'>
        <div className='modal-content-body'>
          <button onClick={()=>closeAll(false)} className='close-button'><img src={closeIcon} alt='close-icon' /></button>
          <div className='confirmation-container'>
            <div className='confirmation-image'><img src={question} alt='question' className='question' /></div>
            <div className='areyousure'>{title}</div>
            <div className='confirmation-text'>You’re about to {revoked?.is_active ? 'revoke' : 'activate'} {revoked?.first_name} {revoked?.last_name} access to Operation Management System </div>
            <div className='confirmation-buttons'>
              <button onClick={sendRevoke}>{yes}</button>
              <button onClick={()=>closeAll(false)}>{cancel}</button>
            </div>
          </div>
        </div>
      </div>
      <Success isOpen={showSuccess} setIsOpen={setIsSuccessOpen} title={revoked?.is_active ? 'Access Revoked' : 'Access Re-activated'} closeAll={closeAll}
        text={`${revoked?.first_name} ${revoked?.last_name} access to Operation Management system has been ${revoked?.is_active ? 'revoked' : 'activated'}`} buttonText='go to dashboard' />
    </>
  )
}

export default Confirmation