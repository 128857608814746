import React, { useState, useContext } from "react";
import "../../styles/index.css";
import "./vendormgt.css";
import AppContext from "../../context/app-context";
import VendorDashboard from "./children/vendorDashboard/VendorDashboard";
import ProductTable from "./children/productTable/ProductTable";
import ProductDashboard from "./products/productDashboard/ProductDashboard";
import GasProduct from "./products/productDashboard/gasProduct/GasProduct";

const Vendormgt = function ({ display }) {
  const [activeScreen, setActiveScreen] = useState(0);

  const [estateId, setEstateId] = useState("---");

  const [vendor, setVendor] = useState(null);

  const [estateName, setEstateName] = useState("");

  const [productScreenIndex, setProductScreenIndex] = useState(0);
  
  const [baseUrl, setBaseUrl] = useState("");

  const [productActiveScreenIndex, setProductActiveScreenIndex] = useState(0);

  const getOmsToke = () => {
    const token = localStorage.getItem("omstoken");
    if (token) return token;
    return "";
  };


  if (!display) return;

  const screens = [
    <VendorDashboard
      onNavigateToTransaction={(screenIndex, nextIndex) => {
        setProductScreenIndex(nextIndex);
        setActiveScreen(screenIndex);
      }}
      setVendor={setVendor}
      setEstateId={setEstateId}
      setEstateName={setEstateName}
    />,
    <ProductDashboard
      onNavigate={(s) => setActiveScreen(s)}
      initialScreenIndex={productScreenIndex}
      backendServerUrl={baseUrl}
      estateId={estateId}
      estateName={estateName}
      omsToken={getOmsToke()}
      productActiveScreenIndex={productActiveScreenIndex}
      setBaseUrl={setBaseUrl}
      setProductActiveScreenIndex={setProductActiveScreenIndex}
      vendor={vendor}

    />,
    <ProductTable onClickBack={() => setActiveScreen(0)}  />,
    <GasProduct onClickBack={() => setActiveScreen(0)} />,
  ];

  return <div className="screen-container">{screens[activeScreen]}</div>;
};

export default Vendormgt;
