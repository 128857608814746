import React, { useEffect } from "react";
import Table from "../../../../../components/table/Table";
import BackButton from "../../../../../components/backButton/BackButton";
import { formatDateDDMMYYY } from "../../../../../utils";
import { dummyData } from "./dummy.data";
import DeliveryPriceModal from "../../../../../components/modal/deliveryPriceModal/DeliveryPriceModal";
import GasConsignment from "../../../../../components/modal/gasConsignment/GasConsignment";

type Props = {
  onClickBack: () => void;
};

function GasProduct({ onClickBack }: Props) {
  const [data, setData] = React.useState([]);
  const [activeRow, setActiveRow] = React.useState<any>({});
  const [showModal, setShowModal] = React.useState(false);

  const headerTitle: { key: string; label: string }[] = [
    { key: "consignmentQty", label: "Consignment Qty" },
    { key: "vendor", label: "Source Vendor" },
    { key: "qtySold", label: "Qty Sold" },
    { key: "consignmentPrice", label: "Consignment Price (₦)" },
    { key: "estilityPrice", label: "Estility Price" },
    { key: "creationDate", label: "Creation Date" },
    { key: "terminationDate", label: "Termination Date" },
    { key: "editPrice", label: "" },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <BackButton onClick={onClickBack} />
      <h3>EG Sunview Estate</h3>
      <Table
        sn
        showSearchBar
        data={dummyData}
        header={headerTitle}
        customTdWrappers={[
          {
            rowKeyName: "estilityPrice",
            element: (text, row) => <div>{text}/KG</div>,
          },
          {
            rowKeyName: "creationDate",
            element: (text, row) => (
              <div>{formatDateDDMMYYY(new Date(text))}</div>
            ),
          },
          {
            rowKeyName: "terminationDate",
            element: (text, row) => (
              <div>{formatDateDDMMYYY(new Date(text))}</div>
            ),
          },
        ]}
      />
      {showModal && (
        <GasConsignment
          onClose={() => setShowModal(false)}
          title={"Set Incentive Amount"}
          subTitle={`Current rate: ${activeRow?.estilityPrice}%`}
          btnLabel={"Save Changes"}
          inputPlaceHolder={"type incentive amount here (in percentage)"}
          onActionBtnClick={(price) => {}}
          inputProps={{
            formatValue: "number",
          }}
        />
      )}
    </div>
  );
}

export default GasProduct;
