import React, { useState, useContext, useEffect } from "react";
import "../../styles/index.css";
import "./dashboard.css";
import AppContext from "../../context/app-context";
import { services } from "./service.data";
import TabStatsCard from "../../components/card/tabStatCard/TabStatsCard.tsx";
import ServiceCard from "../../components/serviceCard/ServiceCard.tsx";
import { fetchApi } from "../../api/index.ts";
import { AppConfig } from "../../utils/config";

const Dashboard = function ({ display }) {
  const { changeScreen, backendServer } = useContext(AppContext);
  // const {  setIsLoading, resolveBackendUrl } =
  //   useContext(AppContext);

  const [stats, setStats] = useState([
    { label: "Onboarded Estates", count: 0 },
    { label: "Users", count: 0 },
    { label: "Vendors", count: 0 },
    { label: "Riders", count: 0 },
  ]);

  const fetchStats = async () => {
    try {
      const { data, hasError } = await fetchApi({
        method: "GET",
        url: `${backendServer}/estates/full-data/get?real=${AppConfig.realData}`,
      });
      if (!hasError) {
        const estateCount = data?.data?.eachEstate?.length || 0;
        const totalNumberOfRiders = data?.data?.universalCounts?.totalNumberOfRiders || 0;
        const totalNumberOfVendors = data?.data?.universalCounts?.totalNumberOfVendors || 0;
        const totalNumberOfResidents = data?.data?.universalCounts?.totalNumberOfResidents || 0;
        const statData = [
          { label: "Onboarded Estates", count: estateCount },
          { label: "Residents", count: totalNumberOfResidents },
          { label: "Vendors", count: totalNumberOfVendors },
          { label: "Riders", count: totalNumberOfRiders },
        ];
        setStats(statData);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const getStateIcon = (name) => {
    const iconMap = {
      onboard: "/dashboard-icons/onboarded.png",
      user: "/dashboard-icons/users.png",
      rider: "/dashboard-icons/riders.png",
      vendor: "/dashboard-icons/vendors.png",
    };

    const lowercaseName = name.toLowerCase();

    const matchedKey = Object.keys(iconMap).find((key) => lowercaseName.includes(key));

    return matchedKey ? iconMap[matchedKey] : "";
  };

  if (!display) return <></>;

  return (
    <div className="screen-container">
      <div className="dashboard-stats-wrapper">
        {stats.map((stat, index) => (
          <TabStatsCard
            key={index}
            title={stat.label}
            count={stat.count}
            imageSrc={getStateIcon(stat.label)}
          />
        ))}
      </div>

      <div className="dashboard-services-wrapper">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            {...service}
            onClick={() => {
              if (service.title === "Manage Vendors") changeScreen("vendor-mgt");
              if (service.title === "Manage Orders") changeScreen("order-mgt");
              if (service.title === "Manage Fleet") changeScreen("fleet-mgt");
              if (service.title === "Manage Finances") changeScreen("finance-mgt");
              if (service.title === "Manage Estates") changeScreen("estate-mgt");
              if (service.title === "Broadcast Announcements") changeScreen("broadcast-mgt");
              if (service.title === "General Reports") changeScreen("general-reports");
            }}
            isLocked={service.isLocked}
            available={service.available}
          />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
