import { useState, useContext, useEffect } from "react";
import "./add-new-region.css";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const AddNewRegion = ({ isOpen, setIsOpen, selectedVendor, fetchVendors }) => {
  const { resolveBackendUrl, setIsLoading, isLoading } = useContext(AppContext);

  const [accountInfo, setAccountInfo] = useState({
    first_name: selectedVendor?.vendor_owner?.first_name || '',
    last_name: selectedVendor?.vendor_owner?.last_name || '',
    mobile: selectedVendor?.vendor_owner?.mobile || '',
    email: selectedVendor?.vendor_owner?.email || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const url = `${resolveBackendUrl(selectedVendor?.estateID)}/vendor/details/update/${selectedVendor?._id}`;
  const data = {
    vendor_owner: accountInfo
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify(data),
      });

      const returned = await response.json();
      await fetchVendors()
      popup(returned?.status || "Record Updated successfully");
      setIsOpen(false);
      setIsLoading(false);

    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="add-region-info-div">
          <div className="add-region-heading-div">
            <div className="add-region-heading">Add New Region</div>
            <FaTimes onClick={() => setIsOpen(false)} cursor='pointer' />
          </div>
          <form onSubmit={handleSubmit} className="add-region-info-form">
           
            <div className="add-region-form-input-div">
              <label>Region Name</label>
              <input
                type="text"
                name="last_name"
                placeholder={selectedVendor?.vendor_owner?.last_name}
                onChange={handleChange}
              />
            </div>
            <div className="add-region-form-input-div">
              <label>Select Estate</label>
              <input
                type="text"
                name="mobile"
                placeholder={selectedVendor?.vendor_owner?.mobile}
                onChange={handleChange}
              />
            </div>
            
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewRegion;

