import React, { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import "./pricing-table.css";
import DeliveryPriceModal from "../../../../components/modal/deliveryPriceModal/DeliveryPriceModal";
import { FiEdit2 } from "react-icons/fi";
import { AppConfig } from "../../../../utils/config";
import { popup } from "../../../../vanilla-functions/model";

type Props = {
  onClickBack?: () => void;
};

const GroceryPricingTable = (props: Props) => {
  const { onClickBack } = props;
  const { backendServer, setIsLoading, estate, resolveBackendUrl } = useContext(AppContext);

  const [data, setData] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  async function getAllEstatesSalesData() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estates/config/sales/get?real=${AppConfig?.realData}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setData(
        returned?.data?.map((e: any) => ({
          estate_id: e?.estate_id,
          estate_name: e?.estate_name,
          grocery_delivery_price: e?.grocery?.grocery_delivery_price,
        }))
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estates Data");
    }
  }

  useEffect(() => {
    getAllEstatesSalesData();
  }, [estate]);

  const handleSetIncentive = async (price: number) => {
    if (price) {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${resolveBackendUrl(activeRow?.estate_id)}/estate/update/config`, {
            method: "PUT",
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken") || "",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              groceryDeliveryFee: price,
            }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setIsLoading(false);

        setShowModal(false);

        popup("Grocery Delivery Price Updated!");

        getAllEstatesSalesData();
      } catch (err) {
        setIsLoading(false);
        popup("Error updating data");
      }
    } else popup("Please set a delivery price");
  };

  const orderTableHeaders = [
    { key: "estate_id", label: "Estate ID" },
    { key: "estate_name", label: "Estate Name" },
    { key: "grocery_delivery_price", label: "Current Price" },
  ];

  return (
    <div className="">
      <div className="financemgt-back-btn" onClick={() => onClickBack && onClickBack()}>
        <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <div className="estate-incentive-label">Grocery Delivery Pricing</div>
      <Table
        showSearchBar={true}
        data={data}
        header={orderTableHeaders}
        customColumn={[
          {
            element: (row: any) => (
              <button className="edit-price">
                <FiEdit2 size={15} />
                <span>Edit Price</span>
              </button>
            ),
            keyName: "status",
            title: () => " ",
            position: "end",
            onClick: (row: any) => {
              setActiveRow(row);
              setShowModal(true);
            },
          },
        ]}
        onRowClick={(row) => {
          setActiveRow(row);
          setShowModal(true);
        }}
        onRefreshIconClick={getAllEstatesSalesData}
      />

      {showModal && (
        <DeliveryPriceModal
          onClose={() => setShowModal(false)}
          title={"Set Product Delivery Price"}
          subTitle={`Current price: ${activeRow?.grocery_delivery_price} NGN`}
          btnLabel={"Save Changes"}
          inputPlaceHolder={"type product delivery price"}
          onActionBtnClick={(price) => handleSetIncentive(price)}
          inputProps={{
            formatValue: "number",
          }}
        />
      )}
    </div>
  );
};

export default GroceryPricingTable;
