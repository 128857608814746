type ICheck = {
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
};
export const CheckBox = ({ checked = false, onCheck }: ICheck) => {
  return checked ? (
    <svg
      width="17"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onCheck ? () => onCheck(!checked) : undefined}
    >
      <rect x="0.5" y="1" width="17" height="17" rx="1.5" fill="#7975B6" />
      <rect x="0.5" y="1" width="17" height="17" rx="1.5" stroke="#7975B6" />
      <path
        d="M14.3332 5.5L6.99984 12.8333L3.6665 9.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onCheck && onCheck(!checked)}
    >
      <rect width="18" height="18" rx="2" fill="white" />
      <rect
        x="0.75"
        y="0.75"
        width="16.5"
        height="16.5"
        rx="1.25"
        stroke="#3A3541"
        stroke-opacity="0.5"
        stroke-width="1.5"
      />
    </svg>
  );
};
