import React, { useState } from 'react'
import './PayoutRequestModal.css'
import { AiFillCloseCircle } from 'react-icons/ai';

type Props = {
    onClose?: () => void;
}

const PayoutRequestModal = (props: Props) => {
    const { onClose } = props;
    const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className={`payout-modal-overlay`} onClick={(e) => {
        if (e.target === e.currentTarget) {
            onClose && onClose();
        }
    }}>
        <div className="payout-modal-content">
            <h5>Payout Request</h5>
            <AiFillCloseCircle
                className="payout-modal-close-icon"
                onClick={() => {
                    onClose && onClose();
                }}
                />
        </div>
    </div>
  );
}

export default PayoutRequestModal

