import React from "react";
import { IoMdArrowBack } from "react-icons/io";

type Props = {
  onClick?: () => void;
  label?: string;
};

function BackButton(props: Props) {
  const { onClick, label } = props;
  return (
    <div
      className="flex-row space-between no-padding"
      style={{ marginBottom: 15 }}
    >
      <p
        onClick={() => onClick && onClick()}
        className="small removemargin cursor"
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" />{" "}
        &nbsp; {label || "Back"}
      </p>
    </div>
  );
}

export default BackButton;
