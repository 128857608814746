import React, { useContext, useState } from "react";
import "../../styles/index.css";
import "./add-a-user-modal.css";
import { secondaryResidentsData } from "./add-a-user-modal";
import AppContext from "../../context/app-context";

const SecondaryResidentsCard = ({ setFalseState, index }) => {
  const { validateEmail, validateMobile } = useContext(AppContext);
  const [sInputAlertMobile, setsInputAlertMobile] = useState("");
  const [sInputAlertEmail, setsInputAlertEmail] = useState("");

  return (
    <div className="flex-column align-column-left a-little-gap">
      <p className="small removemargin">Resident Type: Secondary Resident</p>

      <div className="flex-row" style={{ justifyContent: "space-between", width: "90%" }}>
        <input
          className="in a-a-u-inputs"
          placeholder="Resident Name"
          type="text"
          onChange={(e) => {
            secondaryResidentsData[index].full_name = e.target.value;
            setFalseState({});
          }}
        />
        <input
          className="in a-a-u-inputs"
          placeholder="Resident Email"
          type="text"
          maxLength="100"
          onChange={(e) => {
            if (!e.target.value || !validateEmail(e.target.value)) {
              setsInputAlertEmail("email is missing or invalid");
            } else setsInputAlertEmail("");
            secondaryResidentsData[index].email = e.target.value;
            setFalseState({});
          }}
        />
        <div className="flex-row" style={{ justifyContent: "flex-end" }}>
          <p className="redText xsmall removemargin alignTextLeft">{sInputAlertEmail}</p>
        </div>
      </div>

      <div className="flex-row" style={{ justifyContent: "space-between", width: "90%" }}>
        <input
          className="in a-a-u-inputs"
          placeholder="Resident Phone Number"
          type="number"
          onChange={(e) => {
            if (!e.target.value || !validateMobile(e.target.value)) {
              setsInputAlertMobile("mobile is missing or invalid");
            } else setsInputAlertMobile("");
            secondaryResidentsData[index].mobile = e.target.value;
            setFalseState({});
          }}
        />
        <input
          className="in a-a-u-inputs"
          placeholder=""
          type="FAKE INPUT"
          maxLength="100"
          value={""}
          style={{ opacity: 0 }}
          onChange={() => {}}
        />
        <div className="flex-row" style={{ justifyContent: "flex-start" }}>
          <p className="redText xsmall removemargin alignTextLeft">{sInputAlertMobile}</p>
        </div>
      </div>
    </div>
  );
};

export default SecondaryResidentsCard;
