import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import { popup } from "../../vanilla-functions/model";
import CountdownTimer from "./countdown";
import AppContext from "../../context/app-context";

const theCode = [];

const CodeScreen = function ({
  display,
  setStage,
  email,
  stage,
  code,
  setCode,
  setCodeSentFromServer,
}) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  const [disableBtn, setDisableBtn] = useState(false);

  const [codeExpired, setCodeExpired] = useState(false);

  useEffect(() => {
    if (code.length === 4) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function testCode() {
    try {
      setIsLoading(true);
      const codeVerify = await (
        await fetch(`${backendServer}/any/token/${email}`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ token: code }),
          credentials: "include",
        })
      ).json();

      if (codeVerify.status === "Internal server error") throw new Error(codeVerify.message);
      setStage("reset-password");
      setIsLoading(false);
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  function handleCodeChange(e) {
    if (!e.target.value)
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) - 1]?.children[0]?.focus();
    else
      document
        .querySelector("#codeboxcontainer")
        ?.children[Number(e.target.dataset.key) + 1]?.children[0]?.focus();

    theCode[e.target.dataset.key] = e.target.value;

    setCode(theCode.join(""));
  }

  const onFinish = () => {
    setCodeExpired(true);
    setCodeSentFromServer("");
  };

  if (!display) return;

  return (
    <div className="login-container-one">
      <div className="wavy-container">
        <img className="wavy-img" src="/wavy.png" alt="Estility" />
        <div className="wavy-content">
          <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
            <img className="wavy-logo" src="/logo.png" alt="Estility" />
            <p className="big whiteText removemargin" style={{ fontSize: "25px" }}>
              Estility
            </p>
          </div>
          {/* <p className="small blackText cursor">
                    <FiSettings style={{ marginTop: -5 }} /> &nbsp; Help
                  </p> */}
        </div>
      </div>

      <div className="login-cont">
        <p className="vbig boldText removemargin">OTP Code</p>
        <p className="small greyText">
          We've sent a code to your <span>{email}</span> to reset your password
        </p>

        <div id="codeboxcontainer" className="flex-row" style={{ width: "25%" }}>
          <div className="code-boxes">
            <input
              maxLength="1"
              data-key={0}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
          <div className="code-boxes">
            <input
              maxLength="1"
              data-key={1}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
          <div className="code-boxes">
            <input
              maxLength="1"
              data-key={2}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
          <div className="code-boxes">
            <input
              maxLength="1"
              data-key={3}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
        </div>

        <button disabled={disableBtn} onClick={testCode} className="themeBtn login-btn">
          Reset Password
        </button>

        <CountdownTimer stage={stage} onFinish={onFinish} />

        {codeExpired && (
          <p className="small">
            Code Expired{" "}
            <span
              onClick={() => setStage("begin-reset")}
              className="cursor"
              style={{ textDecoration: "underline", textDecorationColor: "#34204c" }}
            >
              Request Another
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default CodeScreen;
