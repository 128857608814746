import React, { useContext, useState, useEffect } from "react";
import Table from "../../../../components/table/Table";
import "./OrderTable.css";
import TabStatsCard from "../../../../components/card/tabStatCard/TabStatsCard";
import AppContext from "../../../../context/app-context";
import { fetchApi } from "../../../../api";
import { popup } from "../../../../vanilla-functions/model";
import {
  capitalizeEachWord,
  getColorBgByStatus,
  getColorByStatus,
  shortenText,
} from "../../../../utils";
import OrderOverviewModal from "../../../../components/modal/orderOverviewModal/OrderOverviewModal";
import AssignRiderModal from "../../../../components/modal/assignRiderModal/AssignRiderModal";
import { CancelSvg, OverViewSvg, ReAssignSvg } from "./svg/order.svg";
import Button from "../../../../components/button/Button";
import DeliveryCodeConfirmationModal from "../../../../components/modal/deliveryCodeConfirmationModal/DeliveryCodeConfirmationModal";
import { AppConfig } from "../../../../utils/config";
import { TableHeaderItem } from "../../../../components/table/table.utils";

type Props = {};

const OrderTable = ({}: Props) => {
  const { backendServer, setIsLoading, resolveBackendUrl, setStoredContext } =
    useContext(AppContext);

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [filterData, setFilterData] = useState<any[]>([]);

  const [data, setData] = useState<any[]>([]);
  const [activeRow, setActiveRow] = useState<any>({});
  const [openOverview, setOpenOverview] = useState(false);
  const [openAssignRider, setOpenAssignRider] = useState(false);
  const [openConfirmCodeModal, setOpenConfirmCodeModal] = useState(false);
  const [codeError, setCodeError] = useState({ show: false, msg: "" });

  const [riderData, setRiderData] = useState([]);

  const orderTableHeaders: TableHeaderItem[] = [
    { key: "_id", label: "Order ID" },
    { key: "createdAt", label: "Order Date" },
    { key: "estate_name", label: "Originating Estate" },
    { key: "products", label: "Vendor", minWidth: "200px" },
    { key: "buyer", label: "Resident", minWidth: "120px" },
    { key: "status", label: "Status" },
  ];

  const handleFetchOrder = async () => {
    try {
      setIsLoading(true);
      const url = `${backendServer}/estates/orders/get/${page}?real=${AppConfig?.realData}`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      const fetchedOrders = data?.data;
      setHasNextPage(Boolean(data?.hasNextPage));

      setData(fetchedOrders);
      setFilterData(fetchedOrders);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleLoadMoreOrder = async () => {
    try {
      const newPage = page + 1;
      setIsLoading(true);
      const url = `${backendServer}/estates/orders/get/${newPage}?real=${AppConfig?.realData}`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      setPage(newPage);
      const fetchedOrders = data?.data;
      setHasNextPage(Boolean(data?.hasNextPage));

      setData((prevState: any) => [...prevState, ...fetchedOrders]);
      setFilterData((prevState: any) => [...prevState, ...fetchedOrders]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchOrder();
  }, []);

  useEffect(() => {
    setStoredContext((p: any) => ({ ...p, refreshOrderScreen: handleFetchOrder }));
  }, []);

  const handleCancelOrder = async (orderID: string) => {
    setOpenAssignRider(false);
    popup("Order cancel request sent!");
  };

  const handleAssignRiderOrder = async (orderID: string, rider: any) => {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(rider?.estateID)}/order/assign/rider/${orderID}/${
        rider?._id
      }`;
      const { data, hasError } = await fetchApi({ url, method: "PATCH" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      popup("Rider Assigned to Order!");
      setIsLoading(false);
      setOpenAssignRider(false);
      setOpenOverview(false);
      handleFetchOrder();
    } catch (error: any) {
      setIsLoading(false);
      popup(error?.message || "Something went wrong");
    }
  };

  const handleCompleteOrderStatusFlow = async (order: any, status: string) => {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(order?.estateID)}/order/${status}/${order?._id}`;

      const { data, hasError } = await fetchApi({ url, method: "PATCH" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      popup(`Order marked as ${status}`);
      setIsLoading(false);
      setOpenAssignRider(false);
      setOpenOverview(false);
      handleFetchOrder();
      setOpenConfirmCodeModal(false);
    } catch (error: any) {
      setIsLoading(false);
      popup(error?.message || "Something went wrong");
    }
  };

  const closeAllModal = () => {
    // handle close all modal
    setOpenOverview(false);
    setOpenAssignRider(false);
  };
  const handleMarkOrder = async (order: any, status: string) => {
    if (status === "complete") {
      // show code confirm modal
      setOpenConfirmCodeModal(true);
      return;
    } else {
      handleCompleteOrderStatusFlow(order, status);
    }
  };

  const handleFetchRiders = async (estateID: string) => {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(estateID)}/user/get?role=rider`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }
      const fetchedRiders = data?.data;

      setRiderData(fetchedRiders);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      popup(error?.message || "Something went wrong");
    }
  };

  const handleOverview = (row: any) => {
    setActiveRow(row);
    setOpenOverview(true);
  };

  const handleBoxFilter = (type: "pending" | "shipped" | "delivered" | "all") => {
    if (type === "pending") {
      setFilterData(data?.filter((e: any) => e?.status === "pending"));
    }
    if (type === "shipped") {
      setFilterData(data?.filter((e: any) => e?.status === "shipped"));
    }
    if (type === "delivered") {
      setFilterData(data?.filter((e: any) => e?.status === "completed"));
    }
    if (type === "all") {
      setFilterData(data);
    }
  };

  const handleVerifyCode = async (order: any, code: string) => {
    const orderId = order?._id;

    const riderId = order?.rider?._id;

    const url = `${resolveBackendUrl(order?.estateID)}/order/code/verify/${riderId}/${orderId}`;

    try {
      setIsLoading(true);
      const { data, hasError } = await fetchApi({
        method: "POST",
        body: { code },
        url: url,
      });

      if (hasError) {
        setIsLoading(false);
        const msg = data?.message || data?.data?.status;
        popup(msg);
        return;
      }
      // trigger order completed
      handleCompleteOrderStatusFlow(order, "complete");
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div className="order-table-container">
      <div className="order-header-title">All Order</div>
      <div className="order-table-stats-wrapper">
        <TabStatsCard
          count={data?.length + `${hasNextPage ? "+" : ""}`}
          imageSrc="/dashboard-icons/all-acct.png"
          title="All order"
          onClick={() => handleBoxFilter("all")}
        />
        <TabStatsCard
          count={
            data?.filter((e: any) => e?.status === "pending").length + `${hasNextPage ? "+" : ""}`
          }
          imageSrc="/dashboard-icons/estate-acct.png"
          title="Pending order"
          onClick={() => handleBoxFilter("pending")}
        />
        <TabStatsCard
          count={
            data?.filter((e: any) => e?.status === "shipped").length + `${hasNextPage ? "+" : ""}`
          }
          imageSrc="/dashboard-icons/estate-acct.png"
          title="Shipped Orders"
          onClick={() => handleBoxFilter("shipped")}
        />
        <TabStatsCard
          count={
            data?.filter((e: any) => e?.status === "completed").length + `${hasNextPage ? "+" : ""}`
          }
          imageSrc=""
          title="Delivered Orders"
          onClick={() => handleBoxFilter("delivered")}
        />
      </div>

      <Table
        showSearchBar
        data={filterData}
        header={orderTableHeaders}
        onRefreshIconClick={() => handleFetchOrder()}
        onRowDoubleClick={(row) => handleOverview(row)}
        onRowClick={(row) => handleOverview(row)}
        columnMaxLen={15}
        customTdWrappers={[
          {
            element: (item: any) =>
              `${new Intl.DateTimeFormat("en-us", {
                day: "2-digit",
              }).format(new Date(item))}/${new Intl.DateTimeFormat("en-us", {
                month: "2-digit",
              }).format(new Date(item))}/${new Intl.DateTimeFormat("en-us", {
                year: "2-digit",
              }).format(new Date(item))}, ${new Intl.DateTimeFormat("en-us", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(item))}`,
            rowKeyName: "createdAt",
          },
          {
            element: (item: any) => (
              <div style={{ color: "#838383" }}>
                {shortenText({ text: `${item?.first_name} ${item?.last_name}`, maxLen: 30 }).text}
              </div>
            ),
            rowKeyName: "buyer",
          },
          {
            element: (item: any) => {
              const labelArr = item.map((e: any) => e?.vendorID?.vendor_name);
              const label = Array.from(new Set(labelArr)).join(", ");

              return (
                <div style={{ color: "#838383" }}>
                  {shortenText({ text: label, maxLen: 50 }).text}
                </div>
              );
            },
            rowKeyName: "products",
          },
          {
            element: (text) => (
              <div style={{ color: getColorByStatus(text) }}>
                <div
                  className="order-status-pill"
                  style={{
                    color: getColorByStatus(text),
                    backgroundColor: getColorBgByStatus(text),
                  }}
                >
                  {capitalizeEachWord(text)}
                </div>
              </div>
            ),
            rowKeyName: "status",
          },
        ]}
        checkAll
        addCheckBox
        onActionOptionClicks={{
          onOthers: [
            {
              label: "Overview",
              onClick: (row) => {
                handleOverview(row);
              },
              icon: <OverViewSvg />,
              color: "#7975B6",
            },
            {
              label: "Re-Assign Rider",
              onClick: (row) => {
                // handleFetchRiders();
                setActiveRow(row);
                setOpenAssignRider(true);
              },
              icon: <ReAssignSvg />,
              color: "#5A9A8F",
            },
            {
              label: "Cancel Order",
              onClick: (row) => {
                row?.id && handleCancelOrder(row?._id);
                setActiveRow(row);
              },
              icon: <CancelSvg />,
              color: "#D95069",
            },
          ],
        }}
        // pagination={{ rowsPerPage: 10, totalDataCount: data?.length, page: 1, }}
        hidePagination
      />

      <>
        {hasNextPage && (
          <Button
            label="Load more"
            onClick={() => {
              handleLoadMoreOrder();
            }}
          />
        )}
      </>
      {openOverview && activeRow && (
        <OrderOverviewModal
          onCancelOrderClick={() => {
            activeRow?._id && handleCancelOrder(activeRow?._id);
            setOpenAssignRider(false);
          }}
          onAssignRiderClick={async (estateID: string) => {
            handleFetchRiders(estateID);
            setOpenAssignRider(true);
            setOpenOverview(false);
          }}
          onChangeOrderStats={(status: string) => {
            activeRow?._id && handleMarkOrder(activeRow, status);
          }}
          onClose={() => setOpenOverview(false)}
          row={activeRow}
        />
      )}
      {openAssignRider && (
        <AssignRiderModal
          onAssignRiderClick={(rider) => {
            activeRow?._id && handleAssignRiderOrder(activeRow?._id, rider);
          }}
          ridersData={riderData}
          onClose={() => setOpenAssignRider(false)}
          order={activeRow}
        />
      )}
      <DeliveryCodeConfirmationModal
        isVisible={openConfirmCodeModal}
        onClose={() => {
          setOpenConfirmCodeModal(false);
        }}
        codeError={codeError}
        onVerifyClick={(code, codeType) => {
          if (codeType === "estlity") {
            if (code !== "42") {
              setCodeError({ show: true, msg: "Invalid code" });
              return;
            }
            activeRow._id && handleCompleteOrderStatusFlow(activeRow, "complete");
            return;
          }
          // verify code
          handleVerifyCode(activeRow, code);
        }}
      />
    </div>
  );
};

export default OrderTable;
