import { PaystackButton } from "react-paystack";

const PaystackHook = ({ config }) => {
  return (
    <div>
      <PaystackButton channels={["bank_transfer"]} className="themeBtn" {...config} />
    </div>
  );
};

export default PaystackHook;
