import { ArrowLeft } from "phosphor-react";
import React, { useContext, useEffect, useState } from "react";
import { popup } from "../../../../vanilla-functions/model";
import RenderImage from "../../../../components/renderImage/RenderImage";
import Button from "../../../../components/button/Button";
import AppContext from "../../../../context/app-context";

type Props = {
  onNavigate: (s: number) => void;
  curProduct: any;
  setEditProductDataFull: React.Dispatch<React.SetStateAction<{}>>;
  productActiveScreenIndex?: number;
  backendServerUrl: string;
};

const ProductDetails = ({
  onNavigate,
  curProduct,
  setEditProductDataFull,
  productActiveScreenIndex,
  backendServerUrl,
}: Props) => {
  const [theProduct, setTheProduct] = useState<{} | any>(null);
  const { setIsLoading, omsToken } = useContext(AppContext);

  useEffect(() => {
    getProduct();
  }, [curProduct, productActiveScreenIndex]);

  const getProduct = async () => {
    setIsLoading(true);
    try {
      const url = `${backendServerUrl}/vms/product/find/basic/${curProduct?.id}`;
      const res = await (
        await fetch(url, {
          headers: {
            token: omsToken,
          },
          method: "GET",
        })
      ).json();
      if (res?.status === "Internal server error") throw new Error(res.message);
      setTheProduct(res?.data);
      setEditProductDataFull(res?.data);
      setIsLoading(false);
    } catch {
      popup("Error Fetching Product Details");
      setIsLoading(false);
    }
  };

  if (!theProduct) return <></>;

  return (
    <div className="product-dashboard" style={{ padding: "1rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => onNavigate && onNavigate(0)}
      >
        <ArrowLeft
          size={"1.5rem"}
          onClick={() => onNavigate && onNavigate(0)}
        />{" "}
        Product Details
        {
          <span
            className={`small ${
              theProduct?.status === "pending" ? "redText" : "themeText"
            }`}
          >
            (
            {theProduct?.status === "pending"
              ? "This product is in review by Estility. It will be available for purchase when the review process is complete"
              : "Product is approved and visible to users on all platforms"}
            )
          </span>
        }
      </div>

      {/* details section start */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        {/* text area start */}
        <div
          style={{
            minWidth: "60%",
            minHeight: "10rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.2rem",
              minHeight: "10rem",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>Product name</div>
                <div className="small">{theProduct?.product_name}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>Product Category</div>
                <div className="small">{theProduct?.category}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>Quantity</div>
                <div className="small">{theProduct?.quantityAvailable}</div>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>SKU number</div>
                <div className="small">{theProduct?.sku || "none"}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>Price(₦)</div>
                <div className="small">{theProduct?.minimumPrice}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
              >
                <div style={{ fontWeight: "600" }}>Brand name</div>
                <div className="small">{theProduct?.brand_name || "none"}</div>
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontWeight: "600" }}>Specifications</div>

            {theProduct?.weight_or_size_or_type?.length > 0
              ? theProduct?.weight_or_size_or_type?.map(
                  (el: any, i: number) => (
                    <div
                      key={i}
                      style={{ flexDirection: "row", display: "flex" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.1rem",
                          width: "50%",
                        }}
                      >
                        <div style={{ fontWeight: "500" }}>
                          {`Value & Unit`}{" "}
                        </div>
                        <div className="small">
                          {el?.value} {el?.unit}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.1rem",
                        }}
                      >
                        <div style={{ fontWeight: "500" }}>{`Price`} </div>
                        <div className="small">{el?.price}</div>
                      </div>
                    </div>
                  )
                )
              : "none"}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "90%",
            }}
          >
            <div style={{ fontWeight: "600" }}>Product Description</div>
            <div className="small">{theProduct?.description}</div>
          </div>
        </div>
        {/* text area end */}

        {/* image area start */}
        <div
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          <div
            style={{
              display: "flex",
              padding: "0.5rem",
              border: "1px solid gray",
              borderRadius: "8px",
              justifyContent: "center",
              alignItems: "center",
              width: "18rem",
              height: "18rem",
            }}
          >
            <RenderImage
              baseUrl={backendServerUrl}
              imageUrl={theProduct?.images[0]}
              imageStyle={{ width: "15rem", maxHeight: "15rem" }}
              alt="additional image"
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <div style={{ fontWeight: "500" }}>Additional Images</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflowY: "auto",
                width: "18rem",
                gap: "0.5rem",
                scrollbarWidth: "none",
              }}
            >
              {theProduct?.images &&
                theProduct?.images.map((x: any, i: any) => (
                  <div
                    style={{
                      display: "flex",
                      padding: "0.5rem",
                      border: "1px solid gray",
                      borderRadius: "8px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <RenderImage
                      baseUrl={backendServerUrl}
                      imageUrl={x}
                      imageStyle={{ width: "6rem", maxHeight: "6rem" }}
                      alt="additional image"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* image area end */}
      </div>
      {/* details section end */}

      {/* main button */}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          minHeight: "3rem",
        }}
      >
        <Button
          label="Edit Product Details"
          onClick={() => onNavigate && onNavigate(2)}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
