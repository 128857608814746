import { useContext, useState, useEffect } from "react";
import AppContext from "../../../../context/app-context";
import { shortenText } from "../../../../utils";
import { AppConfig } from "../../../../utils/config";
import { IoMdArrowBack } from "react-icons/io";
import { popup } from "../../../../vanilla-functions/model";
import Table from "../../../../components/table/Table";
import ReferralsOverview from "./referrals-overview";
import Button from "../../../../components/button/Button";

const calculateReferralEarnings = (item) => {
  const transactions = item?.userID?.wallet?.transactions;
  const creditTransactions = transactions?.filter(transaction => transaction?.transaction_type === 'credit');
  const referralTransactions = creditTransactions?.filter(transaction => transaction?.paymentRef?.transaction === 'referral');
  const referralEarnings = referralTransactions?.reduce((acc, transaction) => acc + transaction.amount, 0);
  return referralEarnings || 0;
};

const ReferralsTable = ({ display, setScreen }) => {
  const { estates, backendServer, setIsLoading } = useContext(AppContext);

  const [page, setPage] = useState(1);
  const [referrals, setReferrals] = useState({ data: [], hasNextPage: false });
  const [data, setData] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [openReferral, setOpenReferral] = useState(false);

  const orderTableHeaders = [
    { key: "userID", label: "Name" },
  ];

  const spreadObj = (obj, keyToSpread) => {
    const newObj = { ...obj, ...obj[keyToSpread] };
    return newObj;
  };

  const spreadListObj = (arr, keyToSpread) => {
    return arr.map((obj) => spreadObj(obj, keyToSpread));
  };

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  };

  async function getReferrals() {
    const url = `${backendServer}/estates/referrals/get/${page}?real=${AppConfig.realData}`;
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
      });
  
      const returned = await response.json();
      setReferrals((prevReferrals) => ({
        data: page === 1 ? returned.data : [...prevReferrals.data, ...returned.data],
        hasNextPage: returned.hasNextPage,
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }
  
  const handleOverview = (row) => {
    setActiveRow(row);
    setOpenReferral(true);
  };
  
  useEffect(() => {
    getReferrals();
    window.scrollTo(0, 0);
  }, [page]);

  const countSuccessfulReferrals = () => {
    return (
      referrals?.data?.reduce((total, referral) => {
        return (
          total +
          referral.users_referred.filter((user) => user.has_ordered).length
        );
      }, 0) || 0
    );
  };

  const countPendingReferrals = () => {
    return (
      referrals?.data?.reduce((total, referral) => {
        return (
          total +
          referral.users_referred.filter((user) => !user.has_ordered).length
        );
      }, 0) || 0
    );
  };

  const countTotalReferrals = () => {
    return (
      referrals?.data?.reduce((total, referral) => {
        return total + referral.users_referred.length;
      }, 0) || 0
    );
  };

  const referralStats = [
    {
      id: 1,
      name: "All Referrals",
      count: countTotalReferrals(),
      icon: "/fleet-mgt/d-2.png",
      status: "All Referrals",
    },

    {
      id: 2,
      name: "Successful Referrals",
      count: countSuccessfulReferrals(),
      icon: "/fleet-mgt/p-2.png",
      status: "Successful Referrals",
    },

    {
      id: 3,
      name: "Pending Referrals",
      icon: "/fleet-mgt/p-3.png",
      status: "Awaiting Order",
      count: countPendingReferrals(),
    },
  ];

  if (!display) return null;
  return (
    <>
      <div
        className="flex-row space-between no-padding"
        style={{ marginBottom: 15 }}
      >
        <p
          onClick={() =>
            setScreen({
              screen: "home",
              data: null,
            })
          }
          className="small removemargin cursor"
        >
          <IoMdArrowBack
            style={{ marginTop: -3 }}
            size={17}
            className="cursor"
          />{" "}
          &nbsp; Back
        </p>
      </div>
      <p className="order-header-title">Referrals Report</p>
      <div className="residents-stat-container flex-row">
        {referralStats.map((stat) => (
          <div key={stat.id} className="r-each-d-stat-container">
            <img src={stat.icon} alt="estility-resident" />
            <p className="small greyText removemargin">
              {stat.name}
              <br></br>
              <span className="big r-stat-text">
                {stat.count || "0"}
                {referrals?.hasNextPage ? " + " : ""}
              </span>
            </p>
          </div>
        ))}
      </div>

      <div className="order-table-container">
        <Table
          showSearchBar
          data={referrals?.data || []}
          header={orderTableHeaders}
          onRefreshIconClick={() => getReferrals()}
          onRowDoubleClick={(row) => handleOverview(row)}
          onRowClick={(row) => handleOverview(row)}
          columnMaxLen={15}
          customTdWrappers={[
            {
              element: (item) => {
                return (
                  <div style={{ color: "#838383" }}>
                    {
                      shortenText({
                        text: `${item?.first_name} ${item?.last_name}`,
                        maxLen: 30,
                      }).text
                    }
                  </div>
                );
              },
              rowKeyName: "userID",
            },

            {
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {findEstate(item) || "N/A"}
                </div>
              ),
              rowKeyName: "estateID",
            },
          ]}
          customColumn={[
            {
              title: () => <div style={{ color: "#838383" }}>User Email</div>,
              element: (item) => (
                <div style={{ color: "#838383" }}>{item?.userID?.email}</div>
              ),
              keyName: "email",
              position: "end",
            },

            {
              title: () => <div style={{ color: "#838383" }}>Estate</div>,
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {findEstate(item?.estateID)}
                </div>
              ),
              keyName: "Estate",
              position: "end",
            },

            {
              title: () => (
                <div style={{ color: "#838383" }}>All Referrals</div>
              ),
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {item?.users_referred?.length}
                </div>
              ),

              keyName: "AllReferrals",
              position: "end",
            },

            {
              title: () => (
                <div style={{ color: "#838383" }}>Successful Referrals</div>
              ),
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {item?.users_referred?.filter((e) => e.has_ordered).length}
                </div>
              ),

              keyName: "SuccessfulReferrals",
              position: "end",
            },

            {
              title: () => <div style={{ color: "#838383" }}>Total Earnings</div>,
              element: (item) => {
                return (
                  <div style={{ color: "#838383" }}>
                    {calculateReferralEarnings(item) || 0}
                  </div>
                );
              },
              keyName: "TotalEarnings",
              position: "end",
            },            

            {
              title: () => "",
              element: (item) => <div style={{ color: "#8883CC" }}>View</div>,

              keyName: "View",
              position: "end",
            },
          ]}
          checkAll
          addCheckBox
          pagination={{
            rowsPerPage: 10,
            totalDataCount: data?.length,
            page: 1,
          }}
          hidePagination
        />

        {referrals?.hasNextPage && (
          <Button
            label="Load more"
            onClick={() => {
              setPage(page + 1);
            }}
          />
        )}
      </div>

      <ReferralsOverview
        isOpen={openReferral}
        setIsOpen={setOpenReferral}
        selectedReferral={activeRow}
        earnings={calculateReferralEarnings(activeRow)}
      />
    </>
  );
};

export default ReferralsTable;


