import React, { useState, useContext } from 'react';
import './new-member.css';
import closeIcon from '../../../assets/Icon.svg';
import Success from '../success/Success';
import AppContext from '../../../context/app-context';
import { popup } from '../../../vanilla-functions/model';

const NewMember = ({isOpen, setIsOpen, getStaff}) => {
  const [activeScreen, setActiveScreen] = useState('member-form')
  const [activeHeader, setActiveHeader] = useState('properties')
  const { usersData, setIsLoading, backendServer, isLoading } = useContext(AppContext);

  const closeAll = () => {
    setIsOpen(false)
    setIsSuccessOpen(false)
  }

  const [ userDetails, setUserDetails ] = useState(
    {
      first_name: "",
      last_name: "",
      email: "",
      about: "",
      mobile: "",
      address: {
        addressString: "Estility Ville, Ibadan",
        "geoLocation": {
          "coordinates": ["987390.01", "34545.01"]
        },
        "postCode": "111111"
      },
      staff_child_roles: []
    }
  )

  const handleToggle = (screen) => {
    setActiveScreen(screen)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserDetails({
      ...userDetails,
      [name]: value
    })
  }

  const styles = {
    background: activeScreen === 'member-form' ? '#716EAA' : '#fff',
    color: activeScreen === 'member-form' ? '#fff' : '#47586E'
  }

  const addRoleStyles = {
    background: activeScreen === 'add-role' ? '#716EAA' : '#fff',
    color: activeScreen === 'add-role' ? '#fff' : '#47586E'
  }

  const properties = [
    {
      id: 1,
      name: 'Estate Management',
      value: 'estate-mgt'
    },
    {
      id: 2,
      name: 'Finance Management',
      value: 'finance-mgt'
    },
    {
      id: 3,
      name: 'Fleet Management',
      value: 'fleet-mgt'
    },
    {
      id: 4,
      name: 'Vendor Management',
      value: 'vendor-mgt'
    },
    {
      id: 5,
      name: 'Order Management',
      value: 'order-mgt'
    },

    {
      id: 6,
      name: 'Users and Modules',
      value: 'users-and-modules'
    },

    {
      id: 7,
      name: 'Broadcast Mgt',
      value: 'broadcast-mgt'
    },

    {
      id: 8,
      name: 'General reports',
      value: 'general-reports'
    },
    {
      id: 9,
      name: 'Gas Inventory Mgt',
      value: 'gas-inventory-mgt'
    }
  ]

    const handleCheckboxChange = (value) => {
      setUserDetails((prevUserDetails) => {
        const updatedRoles = prevUserDetails.staff_child_roles.includes(value)
          ? prevUserDetails.staff_child_roles.filter((role) => role !== value)
          : [...prevUserDetails.staff_child_roles, value];
        return { ...prevUserDetails, staff_child_roles: updatedRoles };
      });
    };

    const createUser = async () => {
      try {
          setIsLoading(true);
          const response = await fetch(`${backendServer}/staff/signup`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  token: localStorage.getItem("omstoken"),
              },
              body: JSON.stringify(userDetails),
          });
          const data = await response.json();
          data?.status === "Staff Created" ? setIsSuccessOpen(true) : popup(data?.message);
          setIsLoading(false);
          getStaff()
      } catch (error) {
         throw error
          setIsLoading(false);
          popup("Error verifying password");
      }
  };

  const [isSuccessOpen, setIsSuccessOpen] = useState(false)
  const isValid = userDetails?.first_name==='' || userDetails?.last_name==='' || userDetails?.email==='' || userDetails?.mobile === ''

  if(!isOpen) return
  return (
    <>
    <div className='modal-overlay'>
    <div className='modal-content-body'>
    {/* <button onClick={() => setIsOpen(false)} className='close-button'><img src={closeIcon} alt='close-icon' /></button> */}
    <div className='new-member-container'>
      <div className='new-staff'>
        <button style={styles} onClick={() => handleToggle('member-form')}>Add staff details</button>
        <button style={addRoleStyles} onClick={() => handleToggle('add-role')}>Add Roles and permissions</button>
      </div>
      <div className='new-roles'>
        {activeScreen === 'member-form' ? 
          <div className='staff-form'>
          <div className='staff-form-title'>
            <span>Add Team Member</span>
            <button onClick={() => setIsOpen(false)} className='close-modal'><img src={closeIcon} alt='close-icon' /></button>
          </div>
          <form>
            <input onChange={handleChange} name='first_name' type='text' placeholder='Enter first name' />
            <input onChange={handleChange} name='last_name' type='text' placeholder='Enter last name' />
            <input onChange={handleChange} name='email' type='email' placeholder='Email' />
            <input onChange={handleChange} name='mobile' type='text' placeholder='Phone number' />
            <input onChange={handleChange} name='address' type='text' placeholder='Address' />
            <button disabled={isValid} className='next-btn' type='button' onClick={() => setActiveScreen('add-role')}>Next</button>
          </form>
        </div>
          :
          null
        }
        {activeScreen === 'add-role'
          &&
          <div className='roles-and-permission'>
<div className='staff-form-title'>
            <span>Add Modules</span>
            <button onClick={() => setIsOpen(false)} className='close-modal'><img src={closeIcon} alt='close-icon' /></button>
          </div>
        <div className='preferred-module'>Select the preferred module for this user below</div>
           
            <div className='role-options'>
            {properties.map((option) => (
          <div className='options' key={option.id}>
            <div className='options-input'>
              <input
                type='checkbox'
                checked={userDetails?.staff_child_roles.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
            </div>
            <div className='option-value'>{option.name}</div>
          </div>
        ))}
            </div>
            <div className='roles-button'><button onClick={createUser} type='submit'>Add staff</button></div>
          </div>
        }
      </div>
    </div>
    </div>
    </div>
        <Success closeAll={closeAll} isOpen={isSuccessOpen} setIsOpen={setIsSuccessOpen} title='success' text='Staff Created' buttonText='go to dashboard'/>
      </>
  )
}

export default NewMember