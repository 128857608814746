import { useContext } from "react";
import "../../../../styles/index.css";
import "./drt.css";
import AppContext from "../../../../context/app-context";
import { IoMdArrowBack } from "react-icons/io";
import DrtTable from "./drt-table";
import ReferralsTable from "../referrals/referrals-table";

const DeleteAccountReasons = function ({ display, setScreen }) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p
          onClick={() =>
            setScreen({
              screen: "home",
              data: null,
            })
          }
          className="small removemargin cursor"
        >
          <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Back
        </p>
      </div>
      <p className="order-header-title">Deleted Accounts</p>
      <DrtTable />
      {/* <ReferralsTable /> */}
    </div>
  );
};

export default DeleteAccountReasons;
