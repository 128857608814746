import React, { useContext, useEffect } from "react";
import "./BroadcastForm.css";
import BackButton from "../../../../components/backButton/BackButton";
import SelectInput from "../selectInput/SelectInput";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import InputField from "../../../../components/inputField/InputField";
import Button from "../../../../components/button/Button";
import { popup } from "../../../../vanilla-functions/model";
import AppContext from "../../../../context/app-context";
import { BgProps } from "../list/BroadcastTable";

type Props = {
  onBackClick?: () => void;
  onNavigate?: (screen: number) => void;
  display: boolean;
  refreshData?: BgProps;
  setRefreshData: React.Dispatch<React.SetStateAction<BgProps>>;
};

const BroadcastForm = (props: Props) => {
  const { onBackClick, onNavigate, display, refreshData, setRefreshData } = props;

  const { backendServer, setIsLoading, estates } = useContext(AppContext);

  const [section, setSection] = React.useState("edit");

  const [sendTo, setSendTo] = React.useState("");
  const [estate, setEstate] = React.useState({
    estate_name: "",
    estate_id: "",
  });
  const [userType, setUserType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");

  const [toOptions, _settoOptions] = React.useState(["E-Residents"]);
  const [inOptionsEstate, setinOptionsEstate] = React.useState([]);
  const [userTypeOptions, _setuserTypeOptions] = React.useState([
    "Primary Residents",
    "Secondary Residents",
    "Property Owner",
    "All Users",
  ]);

  const handleSendMessage = async () => {
    try {
      setIsLoading(true);

      const sendToToSend = sendTo === "E-Residents" ? "user" : sendTo === "E-Riders" ? "rider" : "";
      const userTypeToSend =
        userType === "Primary Residents"
          ? "primary"
          : userType === "Secondary Residents"
          ? "secondary"
          : userType === "Property Owner"
          ? "property_owner"
          : userType === "All Users"
          ? "all"
          : "";

      // console.log({
      //   sendToToSend,
      //   userTypeToSend,
      //   estate,
      //   subject,
      //   message,
      // });

      const returned = await (
        await fetch(
          `${backendServer}/estates/notifications/push/${sendToToSend}/${estate?.estate_id}/${userTypeToSend}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken") || "",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              title: subject,
              body: message,
            }),
          }
        )
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);

      popup("Notification sent!");

      setSection("edit");
      setUserType("");
      setSubject("");
      setMessage("");

      setSendTo("");
      setEstate({
        estate_name: "",
        estate_id: "",
      });

      onNavigate && onNavigate(0);

      refreshData && refreshData?.getData();
    } catch (err) {
      setIsLoading(false);
      popup("Something went wrong");
    }
  };

  useEffect(() => {
    setinOptionsEstate(estates?.map((e: any) => e?.estate_name));
  }, [estates]);

  useEffect(() => {
    if (refreshData?.editData?.title) {
      setSubject(refreshData?.editData?.title);
    }
    if (refreshData?.editData?.text) {
      setMessage(refreshData?.editData?.text);
    }

    return () => {
      setSubject("");
      setMessage("");
      setRefreshData((p: any) => ({
        ...p,
        editData: {
          title: "",
          text: "",
        },
      }));
    };
  }, [display]);

  function validateField() {
    if (!sendTo) {
      popup("Please select an application to send notification to");
      return false;
    }
    if (!estate?.estate_name || !estate?.estate_id) {
      popup("Please select an estate to send notification to");
      return false;
    }
    if (sendTo === "E-Residents" && !userType) {
      popup("Please select resident type");
      return false;
    }
    if (!subject) {
      popup("Please set a title for this notification");
      return false;
    }
    if (!message) {
      popup("Please set a message body for this notification");
      return false;
    }
    if (!sendTo) {
      popup("Please select an application to send notification to");
      return false;
    }

    return true;
  }

  if (!display) return <></>;

  return (
    <div className="broadcast-form-container">
      <BackButton
        onClick={() => {
          if (section === "preview") {
            setSection("edit");
          } else {
            onBackClick && onBackClick();
          }
        }}
      />
      {section === "edit" ? (
        <div className="broadcast-header-wrapper">
          <h2 className="broadcast-form-title">Send Push Notification</h2>
          <div className="broadcast-header-wrapper-inner">
            <div className="broadcast-form-select-filed">
              <span>Send To:</span>
              <div>
                <SelectInput
                  name="send-to"
                  dropIcon={<MdOutlineKeyboardArrowDown />}
                  value={sendTo}
                  onChange={(e) => setSendTo(e.target.value)}
                  options={toOptions}
                />
              </div>
            </div>

            <div className="broadcast-form-select-filed">
              <span>in:</span>
              <div>
                <SelectInput
                  name="estate"
                  dropIcon={<MdOutlineKeyboardArrowDown />}
                  value={estate?.estate_name}
                  onChange={(element) =>
                    setEstate({
                      estate_name: estates?.find(
                        (e: any) => e?.estate_name?.trim() === element.target.value.trim()
                      )?.estate_name,
                      estate_id: estates?.find(
                        (e: any) => e?.estate_name?.trim() === element.target.value.trim()
                      )?.estate_id,
                    })
                  }
                  options={inOptionsEstate}
                />
              </div>
            </div>

            <div className="broadcast-form-select-filed">
              <span>and are:</span>
              <div>
                <SelectInput
                  name="userType"
                  dropIcon={<MdOutlineKeyboardArrowDown />}
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                  options={userTypeOptions}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h2 className="broadcast-form-title">Preview Broadcast Message</h2>
      )}

      {section === "preview" && (
        <div className="broadcast-form-preview-wrapper">
          <div className="broadcast-form-preview-wrapper-title">{subject}</div>
          <div className="broadcast-form-preview-wrapper-desc">
            {`To ${sendTo} in ${estate?.estate_name} via ${toOptions} App`}
          </div>
          <div className="broadcast-form-preview-wrapper-message">{message}</div>
        </div>
      )}
      <div></div>
      {section === "edit" && (
        <>
          <InputField
            name="title"
            label="Title"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Your title here"
            maxLength={40}
            labelType="nested"
          />
          <InputField
            name="message"
            label="Title"
            type="textarea"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Your broadcast message"
            maxLength={140}
          />
        </>
      )}
      <div className="broadcast-form-buttons-wrapper">
        <Button
          label={section === "edit" ? "Cancel" : "Continue Editing"}
          btnType="outline"
          onClick={() => {
            if (section === "preview") {
              setSection("edit");
            } else {
              onBackClick && onBackClick();
            }
          }}
        />
        <Button
          label={section === "edit" ? "Preview" : "Send message"}
          onClick={() => {
            if (section === "edit") {
              validateField() && setSection("preview");
            } else {
              handleSendMessage();
            }
          }}
        />
      </div>
    </div>
  );
};

export default BroadcastForm;
