import React, { useState, useContext } from 'react'
import './identity.css';
import closeIcon from '../../../assets/Icon.svg';
import Confirmation from '../confirmation/confirmation';
import AppContext from '../../../context/app-context';
import { popup } from '../../../vanilla-functions/model';

const Identity = ({ isOpen, setIsOpen, onClick, revoked, showSuccess }) => {

  const [code, setCode] = useState('')
  const { usersData, setIsLoading, backendServer, isLoading } = useContext(AppContext);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const handleChange = (e) => {
    setCode(e.target.value)
  }

  const verifyPassword = async (e, userId, loginPass) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${backendServer}/staff/password/verify/${usersData?._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify({ loginPass: code }),
      });
      const data = await response.json();
      data?.status === "true" ? setIsConfirmationOpen(true) : popup(data?.data);
      setIsLoading(false);
    } catch (error) {
      throw error
      setIsLoading(false);
      popup("Error verifying password");
    }
  };

  if (!isOpen) return
  const revokeConfirmation = revoked?.is_active ? 'revoke' : 'activate'
  return (
    <>
      <div className='modal-overlay'>
        <div className='modal-content-body'>
          <button onClick={() => setIsOpen(false)} className='close-button'><img src={closeIcon} alt='close-icon' /></button>
          <div className='identity-container'>
            <div className='identity-header'>Verify Identity</div>
            <div className='identity-text'>To {revoked?.is_active ? 'revoke' : 'activate'} this users access, you need to provide your account password</div>
            <form onSubmit={verifyPassword}>
              <div>
                <input onChange={handleChange} type='password' placeholder='password' name='code' />
              </div>
              <button disabled={isLoading} type='submit'>Submit</button>
            </form>
          </div>
        </div>
      </div>
      <Confirmation showSuccess={showSuccess} revoked={revoked} onClickYes={onClick} closeAll={setIsOpen} onClickCancel={() => setIsConfirmationOpen(false)} isOpen={isConfirmationOpen} setIsOpen={setIsConfirmationOpen} title='Are you sure?' yes={`Yes, ${revokeConfirmation}`} cancel='Cancel' />
    </>
  )
}

export default Identity