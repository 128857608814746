import React, { useState } from "react";
import "../../styles/index.css";
import "./financemgt.css";
import FinancePage from "./children/FinancePage/FinancePage.tsx";
import TransactionTable from "./children/TransactionTable/TransactionTable.tsx";
import EstateIncentiveTable from "./children/estateIncentiveTable/EstateIncentiveTable.tsx";
import AccountConfirmation from "./children/accountConfirmation/AccountConfirmation.tsx";
import PayoutTable from "./children/payout/PayoutTable.tsx";
import GasPricingTable from "./children/PricingTable/gas-pricing-table.tsx";
import GroceryPricingTable from "./children/PricingTable/grocery-pricing.tsx";
import GasPriceMarkup from "./children/PricingTable/gas-price-markup.tsx";
import RefundForm from "./children/refund/RefundForm.tsx";
import { UserHistory } from "./children/history/UserHistory.tsx";
import { Transfer } from "./children/transfer/Transfer.tsx";
import { ChargeUser } from "./children/transfer/ChargeUser.tsx";
import { ServiceName } from "./children/serviceName/ServiceName.tsx";
import { PromoService } from "./children/promo/Promo.tsx";

const Financemgt = function ({ display }) {
  const [activeScreen, setActiveScreen] = useState(0);

  const [transactionType, setTransactionType] = useState("incoming");
  const [transactionTableLabel, setTransactionTableLabel] =
    useState("All Transactions");
  const [activeScreenType, setActiveScreenType] = useState();

  const quickActionScreenIdex = {
    estateIncentive: 2,
    confirmAccountDetails: 3,
    estatePayouts: 4,
    riderPayouts: 4,
    vendorPayouts: 4,
    gasPricingTable: 5,
    groceryPricingTable: 6,
    gasPriceMarkup: 7,
    refundOrder: 8,
    userHistory: 9,
    transfer: 10,
    chargeUser: 11,
    serviceName: 12,
    promoService: 13,
  };

  const screens = [
    <FinancePage
      onNavigateToTransaction={(type) => {
        if (type.tableType === "transactions") {
          setActiveScreen(1);
          setTransactionType(type.transType);
          setTransactionTableLabel(type.tableLabel);
          return;
        }
      }}
      onNavigateToQuickAction={({ table, screen }) => {
        quickActionScreenIdex[screen] &&
          setActiveScreen(quickActionScreenIdex[screen]);
        screen && setActiveScreenType(screen);
      }}
    />,
    <TransactionTable
      transactionType={transactionType}
      onClickBack={() => setActiveScreen(0)}
      tableLabel={transactionTableLabel}
    />,
    <EstateIncentiveTable onClickBack={() => setActiveScreen(0)} />,
    <AccountConfirmation onClickBack={() => setActiveScreen(0)} />,
    <PayoutTable
    type={activeScreenType}
    onClickBack={() => setActiveScreen(0)}
    />,
    <GasPricingTable onClickBack={() => setActiveScreen(0)} />,
    <GroceryPricingTable onClickBack={() => setActiveScreen(0)} />,
    <GasPriceMarkup onClickBack={() => setActiveScreen(0)} />,
    <RefundForm onClickBack={() => setActiveScreen(0)}  />,
    <UserHistory onClickBack={() => setActiveScreen(0)} />,
    <Transfer onClickBack={() => setActiveScreen(0)} />,
    <ChargeUser onClickBack={() => setActiveScreen(0)} />,
    <ServiceName onClickBack={() => setActiveScreen(0)} />,
    <PromoService onClickBack={() => setActiveScreen(0)} />

  ];

  if (!display) return;

  return <div className="screen-container">{screens[activeScreen]}</div>;
};

export default Financemgt;
