import React, { useContext } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "./ProductTable.css";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";

type Props = {
  onClickBack?: () => void;
};

function ProductTable({ onClickBack }: Props) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  const tableHeader = [
    "Vendor Name",
    "Request Date",
    "Vendor Address",
    "Pending Products",
    "Available Stock",
  ];
  return (
    <div className="product-table-container">
      <div
        className="product-table-back-btn"
        onClick={() => onClickBack && onClickBack()}
      >
        <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <div className="vendor-dashboard-header-title">
        Pending Products Requests
      </div>
      <Table
        placeholder={"No Pending Products Requests"}
        onRefreshIconClick={() => {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }}
        hidePagination
        data={[]}
        header={tableHeader}
        showSearchBar
        addCheckBox
        checkAll
      />
    </div>
  );
}

export default ProductTable;
