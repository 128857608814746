import React, { useContext, useEffect, useState } from "react";
import "./UserDetailCard.css";
import AppContext from "../../context/app-context";

type Props = {
  title: string;
  fullName: string;
  phoneNumber: string;
  address: string;
  imageUrl?: string;
  baseUrl?: string;
  email?: string;
};

const UserDetailCard = (props: Props) => {
  const { fullName, phoneNumber, address, imageUrl, title, baseUrl, email } = props;

  const [mainImage, setMainImage] = useState("");
  const { getSignedAwsUrl } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (imageUrl) setMainImage(await getSignedAwsUrl(imageUrl, "profile-pictures", baseUrl));
      else setMainImage("/user_placeholder.png");
    })();
  }, [getSignedAwsUrl, imageUrl, baseUrl]);

  return (
    <div className="user-detail-card-container">
      <div className="user-detail-card-title">{title}</div>
      <div className="user-detail-card-contents">
        <img className="user-detail-card-img" alt="user" src={mainImage} />
        <div className="user-detail-card-contact-wrapper">
          <div className="user-detail-card-contact-name">{fullName}</div>
          <div className="user-detail-card-contact-phone">{phoneNumber}</div>
          <div className="user-detail-card-contact-phone">{email}</div>
          <div className="user-detail-card-contact-phone">{address}</div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailCard;
