import { useState, useContext } from "react";
import "./edit-account-number.css";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const EditAccountNumber = ({ isOpen, setIsOpen, selectedRider }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);
  const [riderDetails, setRiderDetails] = useState({
    bank: selectedRider?.rider_details?.bank_accounts[0]?.bank_name || "",
    accountNumber:
      selectedRider?.rider_details?.bank_accounts[0]?.account_number || "",
    accountName:
      selectedRider?.rider_details?.bank_accounts[0]?.account_name || "",
  });

  const nigerianBanks = [
    "Access Bank",
    "Citibank",
    "Diamond Bank",
    "Ecobank",
    "Fidelity Bank",
    "First Bank",
    "First City Monument Bank",
    "Guaranty Trust Bank",
    "Heritage Bank",
    "Jaiz Bank",
    "Keystone Bank",
    "Polaris Bank",
    "Providus Bank",
    "Stanbic IBTC Bank",
    "Standard Chartered Bank",
    "Sterling Bank",
    "Suntrust Bank",
    "Union Bank of Nigeria",
    "United Bank for Africa",
    "Unity Bank",
    "Wema Bank",
    "Zenith Bank",
  ];

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  };

  const handleSave = (e) => {
    e.preventDefault();
    editAccount();
  };

  async function editAccount() {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(
        selectedRider?.estateID
      )}/rider/bank/update/${selectedRider?._id}`;

      const riderBankDetails = {
        bank_name: riderDetails.bank,
        account_number: riderDetails.accountNumber,
        account_name: riderDetails.accountName,
      };

      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify(riderBankDetails),
      });

      const returned = await response.json();
      // if (response.status !== 200) {
      //   throw new Error(returned.message || "Internal server error");
      // }

      popup(returned?.status || "Account number updated successfully");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  const validate =
    riderDetails?.bank === "" ||
    riderDetails?.accountNumber.length !== 10 ||
    riderDetails?.accountName === "";

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content-body edit-complains-modal-body">
        <button
          onClick={() => setIsOpen(false)}
          className="close-modal close-item"
        >
          <FaTimes />
        </button>

        <div className="edit-account-container">
          <div>
            <div className="edit-header">Edit Account Number</div>
          </div>
          <div className="rider-info">
            <div>
              <span>Rider: </span>
              {selectedRider?.first_name} {selectedRider?.last_name}
            </div>
            <div>
              <span>Account Number: </span>{" "}
              {selectedRider?.rider_details?.bank_accounts[0]?.account_number ||
                "No data"}
            </div>
            <div>
              <span>Rider email address: </span>
              {selectedRider?.rider_details?.vehicle_type}
            </div>
            <div>
              <span>Rider Axis: </span> {findEstate(selectedRider?.estateID)}
            </div>
          </div>

          <div className="current-info">Current payment information</div>
          <div>
            <div className="current-bank">
              <label>Rider's Bank</label>
              <select
                value={
                  selectedRider?.rider_details?.bank_accounts[0]?.bank_name
                }
                disabled
              >
                <option>
                  {selectedRider?.rider_details?.bank_accounts[0]?.bank_name ||
                    "NO DATA"}
                </option>
              </select>
            </div>

            <div className="current-number">
              <label>Rider's Account Number</label>
              <input
                type="text"
                value={
                  selectedRider?.rider_details?.bank_accounts[0]
                    ?.account_number || "0000000000"
                }
                disabled
              />
            </div>

            <div className="current-number">
              <label>Rider's Account Name</label>
              <input
                type="text"
                value={
                  selectedRider?.rider_details?.bank_accounts[0]
                    ?.account_name || "NO DATA"
                }
                disabled
              />
            </div>
          </div>

          <div className="current-info">New account information</div>
          <form onSubmit={handleSave}>
            <div className="new-info">
              <label>Rider's Bank</label>
              <select
                value={riderDetails.bank}
                onChange={(e) =>
                  setRiderDetails({ ...riderDetails, bank: e.target.value })
                }
              >
                <option value="">Select Bank</option>
                {nigerianBanks.map((bank, index) => (
                  <option key={index} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </div>

            <div className="new-info">
              <label>Rider's Bank Account Number</label>
              <input
                type="text"
                value={riderDetails.accountNumber}
                onChange={(e) =>
                  setRiderDetails({
                    ...riderDetails,
                    accountNumber: e.target.value,
                  })
                }
              />
            </div>

            <div className="new-info">
              <label>Account Name</label>
              <input
                type="text"
                value={riderDetails.accountName}
                onChange={(e) =>
                  setRiderDetails({
                    ...riderDetails,
                    accountName: e.target.value,
                  })
                }
              />
            </div>

            <div className="save-info-div">
              <button className="save-info" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAccountNumber;
