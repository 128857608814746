import { useState, useContext } from "react";
import "./assign-user.css";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const AssignUser = ({ isOpen, setIsOpen, selectedVendor }) => {
  const { resolveBackendUrl, setIsLoading, isLoading } = useContext(AppContext);

  const [accountInfo, setAccountInfo] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const url = `${resolveBackendUrl(selectedVendor?.estateID)}/vendor/doall/${selectedVendor?._id}`;

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await fetch(url, {
        method: "PUT",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify({
          ...accountInfo,
          address: {
            addressString: selectedVendor?.vendor_address?.addressString,
            geoLocation: {
              coordinates: ["", ""],
            },
            postCode: "000000",
          },
          login_token: {
            token_id: "1",
            token: "1",
          },
        }),
      });

      const returned = await response.json();

      popup(returned?.status || "User assigned successfully");

      setIsOpen(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  const isValid =
    accountInfo.first_name === "" ||
    accountInfo.last_name === "" ||
    accountInfo.mobile === "" ||
    accountInfo.email_address === "";
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="account-info-div">
          <div className="account-heading-div">
            <div className="account-heading">VMS User Account Information</div>
            <FaTimes onClick={() => setIsOpen(false)} />
          </div>
          <form onSubmit={handleSubmit} className="account-info-form">
            <div className="form-input-div">
              <label>First Name</label>
              <input
                type="text"
                name="first_name"
                required
                placeholder="first name"
                onChange={handleChange}
              />
            </div>

            <div className="form-input-div">
              <label>Last Name</label>
              <input
                type="text"
                name="last_name"
                required
                placeholder="last name"
                onChange={handleChange}
              />
            </div>

            <div className="form-input-div">
              <label>Mobile</label>
              <input
                type="text"
                name="mobile"
                placeholder="mobile"
                required
                onChange={handleChange}
              />
            </div>

            <div className="form-input-div">
              <label>Email Address</label>
              <input
                type="text"
                name="email"
                required
                placeholder="email address"
                onChange={handleChange}
              />
            </div>

            <button disabled={isValid || isLoading} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignUser;
