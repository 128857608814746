import React from "react";
import { EyeeSvg, ThumbDown, ThumbUpSvg } from "./svg/rate.svg";

type Props = {
  rate: number;
};

export default function RatingIcon({ rate }: Props) {
  const _rate = parseInt(rate as any);
  return _rate === 1 ? <ThumbUpSvg /> : _rate === 2 ? <EyeeSvg /> : <ThumbDown />;
}
