import { useContext, useState, useEffect } from "react";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import {
  shortenText,
} from "../../../../utils";
import { AppConfig } from "../../../../utils/config";
import DeleteReportModal from "./delete-report-modal";


const DrtTable = () => {
  const { estates, backendServer, setIsLoading, resolveBackendUrl } = useContext(AppContext);

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [deleteReasons, setDeleteReaasons] = useState([]);
  const [data, setData] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [openOverview, setOpenOverview] = useState(false);
  const [openAssignRider, setOpenAssignRider] = useState(false);
  const [openConfirmCodeModal, setOpenConfirmCodeModal] = useState(false);
  const [codeError, setCodeError] = useState({ show: false, msg: "" });

  const [riderData, setRiderData] = useState([]);

  const orderTableHeaders = [
    { key: "user_details", label: "Name" },
    { key: "email", label: "Residents Email" },
    { key: "createdAt", label: "Date of Deletion" },
    { key: "message", label: "Reason for deleting", minWidth: "200px" },
    { key: "estateID", label: "Estate" },
  ];

  const spreadObj = (obj, keyToSpread) => {
    const newObj = { ...obj, ...obj[keyToSpread] };
    return newObj;
  }

  const spreadListObj = (arr, keyToSpread) => {
    return arr.map((obj) => spreadObj(obj, keyToSpread));
  }

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  }


  async function getDeleteReasons() {
    const url = `${backendServer}/feedback/global/get?category=delete&real=${AppConfig.realData}`;
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        // body: JSON.stringify({category: 'delete'}),
      });

      const returned = await response.json();
      // popup(returned?.status || "Record Updated successfully");
      setDeleteReaasons(spreadListObj(returned?.data, 'user_details'))
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  const closeAllModal = () => {
    // handle close all modal
    setOpenOverview(false);
    setOpenAssignRider(false);
  };

  const handleOverview = (row) => {
    setActiveRow(row);
    setOpenOverview(true);
  };

  useEffect(() => {
    getDeleteReasons();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="order-table-container">
        <Table
          showSearchBar
          data={deleteReasons}
          header={orderTableHeaders}
          onRefreshIconClick={() => getDeleteReasons()}
          onRowDoubleClick={(row) => handleOverview(row)}
          onRowClick={(row) => handleOverview(row)}
          columnMaxLen={15}
          customTdWrappers={[
            {
              element: (item) =>
                `${new Intl.DateTimeFormat("en-us", {
                  day: "2-digit",
                }).format(new Date(item))}/${new Intl.DateTimeFormat("en-us", {
                  month: "2-digit",
                }).format(new Date(item))}/${new Intl.DateTimeFormat("en-us", {
                  year: "2-digit",
                }).format(new Date(item))}, ${new Intl.DateTimeFormat("en-us", {
                  hour: "2-digit",
                  minute: "2-digit",
                }).format(new Date(item))}`,
              rowKeyName: "createdAt",
            },
            {
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {shortenText({ text: `${item?.first_name} ${item?.last_name}`, maxLen: 30 }).text}
                </div>
              ),
              rowKeyName: "user_details",
            },

            {
              element: (item) => (
                <div style={{ color: "#838383" }}>
                  {findEstate(item) || "N/A"}
                </div>
              ),
              rowKeyName: "estateID",
            },


          ]}
          checkAll
          addCheckBox
          // pagination={{ rowsPerPage: 10, totalDataCount: data?.length, page: 1, }}
          hidePagination
        />

        {/* <>
        {hasNextPage && (
          <Button
            label="Load more"
            onClick={() => {
              handleLoadMoreOrder();
            }}
          />
        )}
      </> */}

      </div>
      <DeleteReportModal isOpen={openOverview} setIsOpen={setOpenOverview} selectedReason={activeRow} />
    </>
  );
};

export default DrtTable
