import { useState, useEffect, useContext } from "react";
import "./add-estate.css";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const AddEstate = ({ isOpen, setIsOpen, selectedVendor, handleFetchVendors }) => {
  const {
    backendServer,
    setIsLoading,
    estates: estatesFromContext,
    resolveBackendUrl,
  } = useContext(AppContext);

  const [selectedEstates, setSelectedEstates] = useState([]);
  const [showEstates, setShowEstates] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [estates, setEstates] = useState([]);
  const [filteredEstates, setFilteredEstates] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);

        const returned = await (
          await fetch(
            `${backendServer}/estates/vendor/false/get/${selectedVendor?.vendor_name?.replaceAll(
              " ",
              "-"
            )}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                token: localStorage.getItem("omstoken"),
              },
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setEstates(returned?.data);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Unable to fetch filtered estates...");
      }
    })();
  }, [estatesFromContext, selectedVendor?.vendor_name]);

  useEffect(() => {
    setFilteredEstates(
      estates.filter((estate) =>
        estate?.estate_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )
    );
  }, [estates, searchTerm]);

  const toggleDropdown = () => {
    setShowEstates(!showEstates);
  };

  const handleCheckboxChange = (estateID) => {
    if (selectedEstates.includes(estateID)) {
      setSelectedEstates(selectedEstates.filter((item) => item !== estateID));
    } else {
      setSelectedEstates((p) => [...p, estateID]);
    }
  };

  const Selections = () => (
    <div className="ae-selections-container">
      {filteredEstates?.map((estate) => (
        <div className="ae-selections" key={estate?.estate_id}>
          <input
            type="checkbox"
            id={estate?.estate_id}
            checked={selectedEstates.includes(estate?.estate_id)}
            onChange={(element) => {
              handleCheckboxChange(element?.target?.id);
            }}
          />
          <label htmlFor={estate?.estate_name}>{estate?.estate_name}</label>
        </div>
      ))}
    </div>
  );

  async function sendEstatesForVendor() {
    try {
      if (selectedEstates.length === 0) return popup("Please select one or more estate(s)");

      popup("Please wait, this may take a few minutes. Don't close this window!");

      setIsLoading(true);

      for (let theSelectedEstate of selectedEstates) {
        await fetch(`${resolveBackendUrl(theSelectedEstate)}/vendor/create`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            vendor_name: selectedVendor?.vendor_name,
            vendor_category: selectedVendor?.vendor_category,
            vendor_description: selectedVendor?.vendor_description,
            vendor_address: selectedVendor?.vendor_address,
            vendor_owner: selectedVendor?.vendor_owner,
          }),
        });
      }

      setIsLoading(false);

      popup("Vendor added to all estates");

      setIsOpen(false);
      setSelectedEstates([]);

      handleFetchVendors && handleFetchVendors();
    } catch (err) {
      setIsLoading(false);
      popup("Error sending vendor to estates. Please try again later.");
    }
  }

  if (!isOpen) return null;

  return (
    <div className="estate-modal-overlay">
      <div className="estate-modal-content-body">
        <div
          className="add-estate-container"
          style={{ paddingBottom: selectedEstates.length > 0 && "20px" }}
        >
          <div className="select-and-vendor">
            <div className="ae-heading">
              <div className="ae-select-estates">Select Estate(s)</div>
              <FaTimes onClick={() => setIsOpen(false)} style={{ cursor: "pointer" }} />
            </div>
            <input
              type="text"
              placeholder="Search for estates..."
              value={searchTerm}
              className="ea-vendor-category"
              onClick={toggleDropdown}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IoIosArrowDown
              color="#4F4F4F"
              onClick={toggleDropdown}
              className="ae-clickable-icon"
            />
            {showEstates ? <Selections /> : null}
          </div>
          <div className="ae-estates">
            {selectedEstates.map((estateID) => (
              <div key={estateID} className="ae-estate-item">
                <span className="permission-item">
                  {estates?.find((e) => e?.estate_id === estateID)?.estate_name}
                </span>
                <FaTimes
                  className="ae-remove-icon"
                  onClick={() => handleCheckboxChange(estateID)}
                />
              </div>
            ))}
          </div>
          <button onClick={sendEstatesForVendor} type="button" className="ae-proceed-button">
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEstate;
