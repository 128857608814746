import React, { useState, useContext, useEffect } from "react";
import "../../../../styles/index.css";
import "../../../estatemgt/estatemgt.css";
import AppContext from "../../../../context/app-context";
import {
  ResidentsTable,
  pseudoCheckBoxSelectedData,
} from "../../../../components/residents-table/residents-table";
import { popup } from "../../../../vanilla-functions/model";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../../components/searchInput/SearchInput.tsx";
import { IoMdArrowBack } from "react-icons/io";

const EstateResidents = function ({ display, screen, setOnShow, setUserTransactionData }) {
  const [page, setPage] = useState(0);
  const [allResidents, setAllResidents] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const [csvData, setcsvData] = useState([]);

  const { setIsLoading, patternMatching, estates, resolveBackendUrl } = useContext(AppContext);

  function workCSV(data) {
    setcsvData([
      ["Name", "Email", "Wallet Balance (₦)", "Unit Code", "Phone Number", "Resident Level", "Registration Date"],
      ...data?.map(
        ({ first_name, last_name, wallet, mobile, resident_type, property_code, email, createdAt }) => {
          return [
            first_name + ` ${last_name}`,
            email,
            new Intl.NumberFormat("en-us").format(Math.trunc(wallet?.balance)) || 0,
            property_code || "-",
            mobile,
            resident_type ? resident_type : "none",
            createdAt? new Intl.DateTimeFormat("en-us", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }).format(new Date(createdAt)): "-"
          ];
        }
      ),
    ]);
  }

  async function getData() {
    if (!screen?.data) return;

    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;

    (async () => {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${resolveBackendUrl(screen?.data)}/user/get?role=user`, {
            method: "GET",
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken"),
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setAllResidents(returned?.data?.filter((e) => e.is_active));

        setOriginalData(returned?.data?.filter((e) => e.is_active));

        workCSV(returned?.data?.filter((e) => e.is_active))

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        popup("Error fetching estate Residents...");
      }
    })();
  }

  useEffect(() => {
    getData();
  }, [screen?.data]);

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#resident-checkbox-parent"))
      document.querySelector("#resident-checkbox-parent").checked = false;

    const query = e.target.value;

    if (!query) return getData();

    const foundResidents = originalData?.filter((e) => {
      return (
        patternMatching(query, e.first_name + ` ${e.last_name}`) ||
        patternMatching(query, e.first_name) ||
        patternMatching(query, e.last_name) ||
        patternMatching(query, e?.property_code) ||
        patternMatching(query, e?.mobile) ||
        patternMatching(query, e?.resident_type) ||
        patternMatching(query, e?.email)
      );
    });

    setAllResidents(foundResidents);

    workCSV(foundResidents);
  }

  function runBoxFilter(type) {
    if (type === "all") setAllResidents(originalData);
    else {
      setAllResidents(originalData.filter((e) => e.resident_type === type));

      workCSV(originalData.filter((e) => e.resident_type === type));
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p onClick={() => setOnShow("each-estate")} className="small removemargin cursor">
          <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Back
        </p>
      </div>

      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p className="h5 boldText blackText">
          {estates?.find((e) => e?.estate_id === screen?.data)?.estate_name}
        </p>
      </div>

      <div className="estates-stat-container flex-row">
        <div className="each-e-stat-container cursor" onClick={() => runBoxFilter("all")}>
          <img src="/residents-imgs/r-1.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Total Residents<br></br>
            <span className="big r-stat-text">{originalData.length || "-"}</span>
          </p>
        </div>

        <div
          className="each-e-stat-container cursor"
          onClick={() => {
            runBoxFilter("Primary");
          }}
        >
          <img src="/residents-imgs/r-2.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Primary Residents<br></br>
            <span className="big r-stat-text">
              {originalData.filter((e) => e.resident_type === "Primary").length || "-"}
            </span>
          </p>
        </div>

        <div className="each-e-stat-container cursor" onClick={() => runBoxFilter("Secondary")}>
          <img src="/residents-imgs/r-3.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Secondary Residents<br></br>
            <span className="big r-stat-text">
              {originalData.filter((e) => e.resident_type === "Secondary").length || "-"}
            </span>
          </p>
        </div>

        <div
          className="each-e-stat-container cursor"
          onClick={() => runBoxFilter("Property Owner")}
        >
          <img src="/residents-imgs/r-4.png" alt="estility-resident" />
          <p className="small greyText removemargin">
            Property Owners<br></br>
            <span className="big r-stat-text">
              {originalData.filter((e) => e.resident_type === "Property Owner").length || "-"}
            </span>
          </p>
        </div>
      </div>
      <div className="e-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for a resident" onChange={(e) => searchPlease(e)} />

          <CSVLink
            className="cursor small csv-link"
            filename={`${
              estates?.find((e) => e?.estate_id === screen?.data)?.estate_name
            } Residents Data`}
            data={csvData}
          >
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>
          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData()}
          />
        </div>

        <ResidentsTable
          page={page}
          setPage={setPage}
          data={allResidents}
          setCheckBoxSelectedData={setCheckBoxSelectedData}
          checkBoxSelectedData={checkBoxSelectedData}
          setcsvData={setcsvData}
          getData={getData}
          setUserTransactionData={setUserTransactionData}
          setOnShow={setOnShow}
        />
      </div>
    </div>
  );
};

export default EstateResidents;
