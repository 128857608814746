import React, { useContext } from 'react'
import './InDevelopmentModal.css'
import Button from '../button/Button';
import AppContext from '../../context/app-context';

type Props = {
  message?: string;
  isVisible?: boolean;
  onClose?: () => void;
}

function InDevelopmentModal({isVisible = true, message, onClose}: Props) {
  const { setInDevelopment} = useContext(AppContext);

  const handleClose = () => {
    setInDevelopment(false)
    onClose && onClose();
  }
  return (
    isVisible?
    <div className='in-development-modal-overlay' onClick={(e) => {
      if (e.target === e.currentTarget) {
        handleClose()
      }
    }} >
      <div className='in-development-modal-container'>
        <div>
          {message || 'Feature Coming Soon'}
        </div>
        <Button label='Close' onClick={() => handleClose()}  />
      </div>
    </div>
    : null
  )
}

export default InDevelopmentModal