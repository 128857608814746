import React, { useState, useEffect, useContext } from "react";
import "../styles/index.css";
import "./login.css";
import AppContext from "../context/app-context";
import { popup } from "../vanilla-functions/model";
import ResetPassword from "./reset-password";
import LoginCodeScreen from "./reset-password-kids/login-code-screen";

const Login = function ({}) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);

  const [onView, setOnView] = useState("login");
  const [code, setCode] = useState("");
  const [codeSentFromServer, setCodeSentFromServer] = useState("");

  const [deadline, setDeadline] = useState(Date.now() + 1000 * 60 * 0.2 * 24 * 2 + 1000 * 25);

  const { setLoggedIn, backendServer, setIsLoading, setUsersData, loggedIn } =
    useContext(AppContext);

  useEffect(() => {
    if (email && password) setDisableBtn(false);
    else setDisableBtn(true);
  });

  async function login() {
    setIsLoading(true);

    try {
      const returned = await (
        await fetch(`${backendServer}/staff/initiate/login`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: email?.toLowerCase(), loginPass: password }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setCodeSentFromServer(returned?.data);
      setOnView("code");
      setIsLoading(false);
      setDeadline(Date.now() + 1000 * 60 * 0.2 * 24 * 2 + 1000 * 25);
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  async function runAutoLogin() {
    setIsLoading(true);
    try {
      const data = await (
        await fetch(`${backendServer}/staff/get?email=${localStorage.getItem("omsemail")}`, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (!data.data?.is_active) throw new Error("Auth Error");

      setUsersData(data.data);

      setLoggedIn(true);
      setIsLoading(false);
    } catch (err) {
      popup("Login Failed. Please login again...");
      setIsLoading(false);
      setLoggedIn(false);
      localStorage.removeItem("omsusername");
      localStorage.removeItem("omsemail");
      localStorage.removeItem("omstoken");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("omsusername") && localStorage.getItem("omstoken")) runAutoLogin();
    else {
      setLoggedIn(false);
      document.location.hash = "auth";
    }
  }, []);

  if (loggedIn === "none") return;

  return (
    <>
      {!showResetPassword && (
        <div className="login-container">
          <div className="login-container-one">
            <div className="wavy-container">
              <img className="wavy-img" src="/wavy.png" alt="Estility" />
              <div className="wavy-content">
                <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
                  <img className="wavy-logo" src="/logo.png" alt="Estility" />
                  <p className="big whiteText removemargin" style={{ fontSize: "25px" }}>
                    Estility
                  </p>
                </div>
                {/* <p className="small blackText cursor">
                    <FiSettings style={{ marginTop: -5 }} /> &nbsp; Help
                  </p> */}
              </div>
            </div>

            {onView === "login" && (
              <div className="login-cont">
                <p className="vbig boldText removemargin">Estility Management System</p>
                <span className="small removemargin">
                  Elevate Customer Experiences with Our Management System
                </span>

                <input
                  className="in login-in"
                  type="text"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value?.toLowerCase())}
                  value={email}
                />
                <input
                  className="in login-in"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />

                <div className="flex-row space-between no-padding forgot-remember-login">
                  <p></p>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowResetPassword(true);
                    }}
                    href="#"
                    className="small themeText"
                  >
                    Reset Password
                  </a>
                </div>

                <button disabled={disableBtn} onClick={login} className="themeBtn login-btn">
                  Sign In
                </button>
              </div>
            )}

            <LoginCodeScreen
              display={onView === "code" && true}
              setStage={setOnView}
              stage={onView}
              email={email}
              code={code}
              setCode={setCode}
              codeSentFromServer={codeSentFromServer}
              setCodeSentFromServer={setCodeSentFromServer}
              parentLogin={login}
              deadline={deadline}
              setDeadline={setDeadline}
            />
          </div>
        </div>
      )}
      {showResetPassword && <ResetPassword setShowResetPassword={setShowResetPassword} />}
    </>
  );
};

export default Login;
