import React, { useState } from "react";
import "./DeliveryCodeConfirmationModal.css";
import { AiFillCloseCircle } from "react-icons/ai";
import Button from "../../button/Button";

type Props = {
  onChange?: (val: any, codeFrom: ICodeFrom) => void;
  value?: string;
  codeFrom?: ICodeFrom;
  onClose?: () => void;
  onVerifyClick?: (code: any, codeFrom: ICodeFrom) => void;
  isVisible?: boolean;
  codeError?: { show: boolean; msg: string };
};

type ICodeFrom = "estlity" | "residence";

const theCode: any[] = [];

const DeliveryCodeConfirmationModal = (props: Props) => {
  const { isVisible, onClose, onChange, onVerifyClick, codeError } = props;

  const [selectedType, setSelectedType] = useState<ICodeFrom>("residence");
  const [pin, setPin] = useState<string | null>(null);

  function handleCodeChange(e: any) {
    if (!e.target.value)
      (
        document.querySelector("#codeboxcontainer")?.children[Number(e.target.dataset.key) - 1]
          ?.children[0] as HTMLInputElement
      )?.focus();
    else
      (
        document.querySelector("#codeboxcontainer")?.children[Number(e.target.dataset.key) + 1]
          ?.children[0] as HTMLInputElement
      )?.focus();

    theCode[e.target.dataset.key] = e.target.value;

    const newValue = theCode.join("");
    setPin(newValue);
    onChange && onChange(newValue, selectedType);
  }

  return isVisible ? (
    <div
      className="delivery-code-confirmation-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="delivery-code-confirmation-modal-container">
        <div className="delivery-code-confirmation-modal-header">
          Complete/Deliver Order with
          <AiFillCloseCircle
            className="delivery-code-confirmation-modal-close-icon"
            onClick={() => {
              onClose && onClose();
            }}
          />
        </div>

        <div className="delivery-code-confirmation-option-wrapper">
          <Button
            style={{
              height: "2.8rem",
              fontSize: "0.85rem",
              border: "1px solid rgb(202, 202, 202)",
              borderRadius: "5px",
              width: "50%",
            }}
            btnType={selectedType === "estlity" ? "fill" : "outline"}
            label="Product Demo Code"
            onClick={() => setSelectedType("estlity")}
          />
          <Button
            style={{
              height: "2.8rem",
              fontSize: "0.85rem",
              border: "1px solid rgb(202, 202, 202)",
              borderRadius: "5px",
              width: "50%",
            }}
            btnType={selectedType === "residence" ? "fill" : "outline"}
            label="Resident Code"
            onClick={() => setSelectedType("residence")}
          />
        </div>

        <div id="codeboxcontainer" className="flex-row" style={{ width: "60%" }}>
          <div
            className="code-boxes delivery-code-confirmation-input-wrapper"
            style={{
              borderColor: codeError && codeError?.show ? "red" : "rgb(202, 202, 202)",
            }}
          >
            <input
              maxLength={1}
              data-key={0}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
          <div
            className="code-boxes delivery-code-confirmation-input-wrapper"
            style={{
              borderColor: codeError && codeError?.show ? "red" : "rgb(202, 202, 202)",
            }}
          >
            <input
              maxLength={1}
              data-key={1}
              placeholder="*"
              type="text"
              onChange={handleCodeChange}
            />
          </div>
        </div>
        <Button
          disable={!pin || !(pin !== null && pin.length > 1)}
          label="Verify Code"
          style={{
            height: "3rem",
            width: "90%",
          }}
          onClick={() => onVerifyClick && onVerifyClick(pin, selectedType)}
        />
      </div>
    </div>
  ) : null;
};

export default DeliveryCodeConfirmationModal;
