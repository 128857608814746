import React, { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import InputField from "../../../../components/inputField/InputField";
import AppContext from "../../../../context/app-context";
import SelectInput from "../../../../components/selectInput/SelectInput";
import "./ServiceName.css";
import { formatToMoney } from "../../../../utils";
import { fetchApi } from "../../../../api";
import Button from "../../../../components/button/Button";
import { FaUser, FaWallet } from "react-icons/fa";
import { popup } from "../../../../vanilla-functions/model";

type Props = {
  onClickBack?: () => void;
};

export const ServiceName = (props: Props) => {
  const { onClickBack } = props;

  const { setIsLoading, estates } = useContext(AppContext);

  const [estate, setEstate] = useState<any>();
  const [originalName, setOriginalName] = useState<string>("");
  const [customName, setCustomName] = useState<string>("");

  const handleSubmit = async () => {
    if (!estate?.backend_base_url || !estate?.estate_id) return;
    try {
      const url = `${estate?.backend_base_url}/estate/customname/edit`;

      const payload = {
        original_name: originalName,
        custom_name: customName,
      };
      setIsLoading?.(true);
      const { data, hasError } = await fetchApi({
        url,
        method: "PATCH",
        body: payload,
      });
      setIsLoading?.(false);
      popup(data?.message || data?.status);
      if (!hasError) {
        setCustomName("");
      }
    } catch (error: any) {
      popup(error?.message);
      setIsLoading?.(false);
    }
  };

  return (
    <div>
      <div className="uh-container">
        <div className="financemgt-back-btn" onClick={() => onClickBack?.()}>
          <MdKeyboardArrowLeft size={25} />
          Back{" "}
        </div>
        <div className="estate-incentive-label">
          {estate?.estate_name || "Estate"} service{" "}
        </div>
        <SelectInput
          name="estateName"
          options={estates}
          keyToRender="estate_name"
          keyValue="estate_id"
          placeholder="Select an estate"
          value={estate?.estate_name}
          labelType="nested"
          onChange={(e) => {
            const val = estates?.find(
              (j: any) => j?.estate_id === e?.target?.value
            );
            val && setEstate(val);
            setCustomName("");
          }}
        />
        <SelectInput
          name="originalName"
          options={["Grocery Purchase", "Gas Purchase", "Estate Due", "Visitors Pass", "Electricity Bill"]}
          placeholder="Select A Service"
          value={originalName}
          labelType="nested"
          onChange={(e) => {
            setOriginalName(e?.target?.value);
          }}
        />

        <InputField
          label="Custom Name"
          labelType="nested"
          name="customName"
          value={customName}
          onChange={(e) => {
            const str = e.target.value;
            setCustomName(str);
          }}
        />

        <Button
          label="Submit"
          disable={!customName || !originalName}
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
};
