import { useContext, useEffect, useState } from "react";
import "../../styles/index.css";
import "./gas-inventory-mgt.css";
import AppContext from "../../context/app-context";
import { BsPlus } from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import AssignBulkStock from "./assign-bulk-stock/assign-bulk-stock";
import AddNewBatch from "./add-new-batch/add-new-batch";
import ManageRegions from "./manage-regions/manage-regions";
import TabStatsCard from "../../components/card/tabStatCard/TabStatsCard";
import { shortenText } from "../../utils";
import { fetchApi } from "../../api";
import { AppConfig } from "../../utils/config";
import { popup } from "../../vanilla-functions/model";
import { calculateVendorStats } from "../vendormgt/vendor.dto";
import Table from "../../components/table/Table";

const GasInventoryMgt = function ({ display }) {
  const [ openAssignStock, setOpenAssignStock ] = useState(false)
  const [ openNewBatch, setOpenNewBatch ] = useState(false)
  const [ gasInventory, setGasInventory ] = useState([])
  const [ selectedStock, setSelectedStock ] = useState({});
  const [ activeScreen, setActiveScreen ] = useState('Gas inventory')
  const [estateId, setEstateId] = useState("---");

  

  const [estateName, setEstateName] = useState("");
  const tableHeader = [
    { key: "vendor_name", label: "Vendor Name" },
    { key: "estate_name", label: "Allocated Estate" },
    { key: "vendor_address", label: "Vendor Address", minWidth: "200px" },
    { key: "vendor_category", label: "Vendor Type" },
    { key: "vendor_owner", label: "Vendor Contact" },
  ];

  const actionButtons = [
    {
      id: 1,
      title: 'Manage Regions',
      icon: <FaRegCheckCircle size={16}/>,
      onclick: () => {
        // setActiveScreen('manage regions')
        setInDevelopment?.(true)
      },
      style: { border: '1px solid #7975B6', color: '#7975B6', fontWeight: 600 }
    },

    {
      id: 2,
      title: 'Assign Bulk Stock',
      icon: <FaRegCheckCircle size={16}/>,
      onclick: () => {
        // setOpenAssignStock(true)
        setInDevelopment?.(true)
      },
      style: { border: '1px solid #5A9A8F', color: '#5A9A8F',  fontWeight: 600 }
    },

    {
      id: 3,
      title: 'Add new Batch',
      icon: <BsPlus size={20}/>,
      onclick: () => {
        // setOpenNewBatch(true)
        setInDevelopment?.(true)
      },
      style: { background: '#34204C', color: '#fff',  fontWeight: 400 }
    }
  ]
  const { resolveBackendUrl, backendServer, setIsLoading, setInDevelopment } = useContext(AppContext);
  const [vendor, setVendor] = useState(null);

  // const { onNavigateToTransaction, setVendor, setEstateId, setEstateName } = props;
  const [showOverView, setShowOverView] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddestate, setShowAddEstate] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState({});


  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [typeOfVendor, setTypeOfVendor] = useState("");

  const [stats, setStats] = useState({
    totalVendor: 0,
    groceryVendor: 0,
    gasVendor: 0,
    pendingProduct: 0,
  });

  const handleFetchVendors = async () => {
    try {
      setIsLoading(true);
      const url = `${backendServer}/estates/vendors/get?real=${AppConfig.realData}`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      const fetchedVendor = data?.data;
      const statusText = data?.status;
      setData(fetchedVendor);
      setFilterData(fetchedVendor);

      const newStats = calculateVendorStats(fetchedVendor);

      setStats((p) => ({ ...p, ...newStats }));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchVendors();
  }, []);

  const handleFilterByType = (type) => {
    setInDevelopment?.(true)
    return;
    if (type === "All") {
      setTypeOfVendor("");
      setFilterData(data);
      return;
    }
    setTypeOfVendor(type === "Groceries" ? "Grocery" : type);
    const _filteredData = data.filter((item) => {
      return `${item?.vendor_category}`.toLowerCase() === type.toLowerCase();
    });

    setFilterData(_filteredData);
  };

  const handleRowClick = (row) => {
    setInDevelopment?.(true)
    return;
    const category = `${row?.vendor_category}`.toLowerCase();
    const isGroceryVendor = category === "Groceries".toLowerCase();

    const estateID = row?.estateID;
    const estateNAME = row?.estate_name;

    setVendor && setVendor(row);
    setEstateId && setEstateId(estateID);
    setEstateName && setEstateName(estateNAME);
    // if (isGroceryVendor) {
    //   onNavigateToTransaction && onNavigateToTransaction(1, 0);
    // } else {
    //   onNavigateToTransaction && onNavigateToTransaction(3);
    // }
  };

  const inventoryStats = [
    {
      id: 1,
      name: 'Total Inventory acquired',
      value: 200,
      icon: '/dashboard-icons/arrow-down.png',
      onClick: () => handleFilterByType("All"),
      count: stats?.totalVendor
    },

    {
      id: 2,
      name: 'Total Inventory sold',
      value: 100,
      icon: '/dashboard-icons/cart-icon.png',
      onClick: () => handleFilterByType("Groceries"),
      count: stats?.groceryVendor
    },

    {
      id: 3,
      name: 'Assigned Inventory',
      value: 50,
      icon: '/dashboard-icons/assigned.png',
      onClick: () => handleFilterByType("Gas"),
      count: stats?.gasVendor
    },

    {
      id: 4,
      name: 'Unassigned Inventory',
      value: 50,
      icon: '/dashboard-icons/unassigned.png',
      onClick: () => handleFilterByType("Pending"),
      count: stats?.pendingProduct
    }
  ]
  
  if (!display) return;

  return (
    <>
    {activeScreen === 'Gas inventory' ? <>
  <div className="screen-container">

    <div className="action-items">
    <div className="gas-title">Gas Inventory Mgt</div>
    <div className="action-buttons-container">
      {actionButtons.map((button) => (
        <button
          key={button.id}
          className="action-button"
          style={button.style}
          onClick={button.onclick}
        >
          <span>{button.icon}</span>
          <span>{button.title}</span>
        </button>
      ))}
      </div>
    </div>
    <div className="vendor-dashboard-container">
      <div className="vendor-stats-wrapper">
      {inventoryStats.map((stat) => (
          <div className="d-flex gap-2 gas-stats">
            <img className="tab-stats-icon" alt="Icon" src={stat?.icon || "/dashboard-icons/onboarded.png"} />
            <div className="tab-stats-contents">
              <div style={{fontWeight: 300}} className="">{stat?.name}</div>
              <div className="">{stat?.count} <sub>KG</sub></div>
            </div>
          </div>
        ))}
      </div>
      <>
        <Table
          showSearchBar
          searchPlaceholder={`Search ${typeOfVendor || "All"} vendors`}
          data={filterData}
          header={tableHeader}
          onRefreshIconClick={() => {
            // handleFetchVendors();
            setInDevelopment?.(true)
            return;}}
          customTdWrappers={[
            {
              element: (item) => (
                <div style={{ color: "#838383" }} title={item?.addressString || "-"}>
                  {shortenText({
                    maxLen: 71,
                    text: item?.addressString,
                    replacer: "...",
                  }).text || ""}
                </div>
              ),
              rowKeyName: "vendor_address",
            },
            {
              element: (item) => <div style={{ color: "#838383" }}>{item?.mobile || "-"}</div>,
              rowKeyName: "vendor_owner",
            },
          ]}
          columnMaxLen={100}
          customColumn={[
            {
              element: (row) => {
                const category = `${row?.vendor_category}`.toLowerCase();
                const isGroceryVendor = category === "Groceries".toLowerCase();
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row);
                    }}
                    className={`${isGroceryVendor && "cursor"}`}
                    style={{
                      opacity: isGroceryVendor ? 1 : 0.2,
                      color: "#6853BC",
                      textAlign: "center",
                    }}
                  >
                    View
                  </div>
                );
              },
              keyName: "availableStock",
              position: "end",
              title: () => (
                <div style={{ textWrap: "nowrap", minWidth: "120px" }}>Available Stock</div>
              ),
              onClick: (row) => {
                handleRowClick(row);
              },
            },
            {
              element:  (row) => {
                const category = `${row?.vendor_category}`.toLowerCase();
                const isGroceryVendor = category === "Groceries".toLowerCase();
                const result = isGroceryVendor? `${row?.products?.length || 0}`: `-`
                return (
                  <div>{result}</div>
                )
              },
              keyName:"productCount",
              position:"end",
              title: () => <div style={{ textWrap: "nowrap", minWidth: "30px" }}>Product Count</div>,              
            }
          ]}
          onRowClick={(row) => {
            handleRowClick(row);
          }}
          // onActionOptionClicks={{
          //   onOthers: tableActionOptions,
          // }}
        />
      </>

    </div>


  </div>
  <AssignBulkStock isOpen={openAssignStock} setIsOpen={setOpenAssignStock} selectedStock={setSelectedStock}/>
  <AddNewBatch isOpen={openNewBatch} setIsOpen={setOpenNewBatch} selectedStock={selectedStock}/>
  </> : <ManageRegions setActiveScreen={setActiveScreen}/> }
  </>
  );
};

export default GasInventoryMgt;
