import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "./edit-resident-details-modal.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const EditAUserModal = function ({ display, setEditAUserModal, data, getData }) {
  const { setIsLoading, resolveBackendUrl } = useContext(AppContext);

  const [theUser, setTheUser] = useState({});
  const [disableBtn, setdisableBtn] = useState(true);
  const [residentType, _setResidentType] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullAddress, setfullAddress] = useState("");
  const [mobile, setmobile] = useState("");
  const [unitsOccupied, setunitsOccupied] = useState("");
  const [pCode, setPCode] = useState("");
  const [allPropertiesComp, setallPropertiesComp] = useState([]);

  useEffect(() => {
    if (!residentType && !fullAddress && !mobile && !firstName && !lastName && !unitsOccupied)
      setdisableBtn(true);
    else setdisableBtn(false);

    if (Number(unitsOccupied) < 0) setdisableBtn(true);
  });

  useEffect(() => {
    setTheUser(data);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    (async function () {
      const returned = await (
        await fetch(`${resolveBackendUrl(data?.estateID)}/property/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setallPropertiesComp(
        returned?.data?.map((e, i) => (
          <MDBDropdownItem
            key={i}
            pcode={e.property_code}
            id={e._id}
            link
            href="#estate-mgt"
            onClick={(e) => {
              setfullAddress(e.target.textContent);
              setPCode(e.target.parentElement.getAttribute("pcode"));
            }}
          >
            {e?.full_address?.house_number + " " + e?.full_address?.street_name}
          </MDBDropdownItem>
        ))
      );
    })();
  }, [display]);

  async function updateUser() {
    const obj = {};

    if (firstName) obj.first_name = firstName;
    if (lastName) obj.last_name = lastName;
    if (unitsOccupied) obj.units_occupied = unitsOccupied;
    if (mobile) obj.mobile = mobile;
    if (fullAddress) {
      obj.address = fullAddress;
      obj.property_code = pCode;
    }
    if (residentType) obj.resident_type = residentType;

    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${resolveBackendUrl(data?.estateID)}/user/update/profile/${theUser?._id}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(obj),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setIsLoading(false);
      popup("Resident Updated");
      setEditAUserModal(false);
      if (getData) getData();
    } catch (err) {
      setIsLoading(false);
      popup("Error updating resident, try again later");
    }
  }

  if (!display) return;

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ position: "fixed" }}
    >
      <div className="modal-body e-edit-a-user-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setEditAUserModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="e-a-user-modal-cont flex-column align-column-left">
          <div className="flex-row align-row-left">
            <p className="big">Update Resident Details Below</p>
          </div>

          <div className="flex-row">
            <div className="e-a-a-u-inputs">
              <p className="small">First Name</p>
              <input
                className="in"
                placeholder="First Name"
                type="text"
                defaultValue={theUser?.first_name}
                onChange={(e) => setfirstName(e.target.value)}
              />
            </div>
            <div className="e-a-a-u-inputs">
              <p className="small">Last Name</p>
              <input
                className="in"
                placeholder="Last Name"
                type="text"
                defaultValue={theUser?.last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex-row">
            <div className="e-a-a-u-inputs">
              <p className="small">Property Address</p>
              <MDBDropdown id="mdb-dropdown-custom-dropdown" style={{ width: "100%" }}>
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {fullAddress || theUser?.address?.addressString || "Property Address"}
                </MDBDropdownToggle>
                <MDBDropdownMenu id="edit-a-a-user-mdb-menu-custom-dropdown">
                  {allPropertiesComp}
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
            <div className="e-a-a-u-inputs">
              <p className="small">Resident Mobile</p>
              <input
                className="in"
                placeholder="Resident Mobile"
                type="text"
                maxLength="100"
                defaultValue={theUser?.mobile}
                onChange={(e) => setmobile(e.target.value)}
              />
            </div>
          </div>

          <div className="flex-row">
            <div className="e-a-a-u-inputs">
              <p className="small">Units Occupied</p>
              <input
                className="in"
                placeholder="Units Occupied"
                type="text"
                maxLength="100"
                defaultValue={theUser?.units_occupied || 0}
                onChange={(e) => setunitsOccupied(e.target.value)}
              />
            </div>
            <div className="e-a-a-u-inputs">
              <p className="small">Resident Type</p>
              <MDBDropdown id="e-mdb-dropdown-custom-dropdown">
                <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                  {residentType || theUser?.resident_type || "Resident Type"}
                </MDBDropdownToggle>

                <MDBDropdownMenu id="e-edit-a-a-user-mdb-menu-custom-dropdown">
                  <MDBDropdownItem
                    link
                    disabled={true}
                    onClick={(e) =>
                      popup("Sorry, you cannot change the resident type of a resident")
                    }
                  >
                    Primary
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    disabled={true}
                    onClick={(e) =>
                      popup("Sorry, you cannot change the resident type of a resident")
                    }
                  >
                    Secondary
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    link
                    disabled={true}
                    onClick={(e) =>
                      popup("Sorry, you cannot change the resident type of a resident")
                    }
                  >
                    Property Owner
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </div>

          <button onClick={updateUser} className="themeBtn e-a-a-u-btn" disabled={disableBtn}>
            Update Resident
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAUserModal;
