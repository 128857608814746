import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./create-gas-order-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IoMdArrowBack } from "react-icons/io";
import PaystackHook from "../paystack/paystack-hook";
import AddAnEstateUserModal from "./add-user/add-a-user";
import ModalOrderCreated from "./modal-order-created/modal-order-created";
import ReviewOrderModal from "./review-order/review-order";

const CreateGasOrderModal = function ({ display, setCreateOrderModal }) {
  const { setIsLoading, estates, formatNumber, storedContext } = useContext(AppContext);

  const [screen, setScreen] = useState(1);
  const [addAResident, setAddAResident] = useState(false);
  const [modalOrderCreated, setModalOrderCreated] = useState(false);
  const [reviewOrderModal, setReviewOrderModal] = useState(false);

  const [serverToContact, setServerToContact] = useState("");

  const [disableBtn, setdisableBtn] = useState(true);
  const [whatsWrong, setWhatsWrong] = useState("");

  const [allResidentsOptions, setAllResidentsOptions] = useState([]);
  const [allResidents, setAllResidents] = useState([]);
  const [gasPrice, setGasPrice] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [primaryGasProduct, setPrimaryGasProduct] = useState({});

  const [selectedEstate, setSelectedEstate] = useState("");
  const [selectedResident, setSelectedResident] = useState("");
  const [gasInKg, setGasInKg] = useState("");
  const [orderNotes, setOrderNotes] = useState("");

  const [orderCreated, setOrderCreated] = useState("");

  async function fetchEstateUsers(selectResidentID) {
    if (!serverToContact) return;
    setSelectedResident("");

    try {
      setIsLoading(true);
      const returnedUsers = await (
        await fetch(`${serverToContact}/user/get?role=user`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returnedUsers.status === "Internal server error") throw new Error(returnedUsers.message);

      setAllResidentsOptions(
        returnedUsers?.data
          ?.filter((e) => e?.is_active)
          ?.map((e) => ({ label: `${e?.first_name} ${e?.last_name} (${e?.email})`, id: e?.email }))
      );

      setAllResidents(returnedUsers?.data?.filter((e) => e?.is_active));

      if (selectResidentID)
        setSelectedResident(
          returnedUsers?.data?.filter((e) => e?.is_active)?.find((e) => e?._id === selectResidentID)
        );

      const returnedEstateConfig = await (
        await fetch(`${serverToContact}/estate/get/details`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returnedEstateConfig.status === "Internal server error")
        throw new Error(returnedEstateConfig.message);

      setDeliveryFee(returnedEstateConfig?.data?.estateConfig?.gasDeliveryFee);

      const returnedPrimaryGasProduct = await (
        await fetch(`${serverToContact}/estate/gas/primary`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returnedPrimaryGasProduct.status === "Internal server error")
        throw new Error(returnedPrimaryGasProduct.message);

      setGasPrice(returnedPrimaryGasProduct?.data?.minimumPrice);

      setPrimaryGasProduct(returnedPrimaryGasProduct?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching estate details, try again later...");
    }
  }

  useEffect(() => {
    fetchEstateUsers();
  }, [serverToContact]);

  function runValidation() {
    if (!selectedEstate) return setWhatsWrong("Please select an estate");
    if (!selectedResident?.email) return setWhatsWrong("Please select a resident for the order");
    if (!gasInKg || Number(gasInKg) < 2) return setWhatsWrong("Please set a valid gas quantity");

    setWhatsWrong("");
  }

  useEffect(() => {
    runValidation();

    if (!selectedEstate || !selectedResident?.email || !gasInKg || Number(gasInKg) < 2)
      setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function sendOrder(reference) {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${serverToContact}/oms/gasorder/${selectedResident?._id}`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            products: [
              {
                productID: primaryGasProduct?._id,
                vendorID: primaryGasProduct?.vendor?._id,
                quantity: `${gasInKg}`,
                weight_or_size_or_type: {
                  unit: "kg",
                  value: `${gasInKg}`,
                },
              },
            ],
            deliveryAddress: {
              addressString: selectedResident?.address?.addressString || "",
              estateName: selectedEstate,
              geoLocation: selectedResident?.address?.geoLocation,
            },
            deliveryFee: Number(deliveryFee),
            orderNotes: orderNotes,
            orderType: "Gas",
            paymentMethod: "paystack",
            taxFee: 0,
            totalAmount: Number(gasInKg) * Number(gasPrice) + Number(deliveryFee),
            paymentRef: reference,
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setOrderCreated(returned?.data);

      setModalOrderCreated(true);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("This order is now processing...");
      clearState();
    }
  }

  function clearState() {
    setCreateOrderModal(false);
    setScreen(1);
    setModalOrderCreated(false);
    setAddAResident(false);
    setReviewOrderModal(false);
    setOrderCreated("");
    setSelectedResident("");
    setGasInKg("");
    setOrderNotes("");
    storedContext?.refreshOrderScreen();
  }

  const successPayment = (reference) => {
    sendOrder(reference);
  };

  const closedPayment = () => {
    console.log("");
  };

  const config = {
    email: selectedResident?.email,
    amount: (Number(gasInKg) * Number(gasPrice) + Number(deliveryFee)) * 100,
    publicKey: "pk_live_e9c4886dedb16d0120aa768d430d0c58017534b5",
    metadata: {
      order: {
        products: [
          {
            productID: primaryGasProduct?._id,
            vendorID: primaryGasProduct?.vendor?._id,
            quantity: `${gasInKg}`,
            weight_or_size_or_type: {
              unit: "kg",
              value: `${gasInKg}`,
            },
          },
        ],
        deliveryAddress: {
          addressString: selectedResident?.address?.addressString || "",
          estateName: selectedEstate,
          geoLocation: selectedResident?.address?.geoLocation,
        },
        deliveryFee: Number(deliveryFee),
        orderNotes: orderNotes,
        orderType: "Gas",
        taxFee: 0,
        totalAmount: Number(gasInKg) * Number(gasPrice) + Number(deliveryFee),
      },
    },
  };

  const componentProps = {
    ...config,
    text: " Proceed to Pay",
    onSuccess: (reference) => successPayment(reference),
    onClose: closedPayment,
  };

  return (
    <div className="modal-container" style={{ display: `${display ? "flex" : "none"}` }}>
      <div className="modal-body edit-a-order-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setCreateOrderModal(false);
              clearState();
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        {/* screen 1 */}
        <div
          className="flex-row align-row-left"
          style={{ display: `${screen === 1 ? "flex" : "none"}`, gap: 0 }}
        >
          <h4 className="big">Create Gas Order</h4>

          <div className="flex-row aa-order-row-grp" style={{ gap: 10 }}>
            <div className="flex-column align-column-left aa-order-col-grp">
              <p className="small removemargin">Select Estate</p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: "100%" }}
                options={estates?.map((e) => ({ label: e?.estate_name, id: e?.estate_id }))}
                renderInput={(params) => <TextField {...params} label="Search by Estate Name" />}
                value={selectedEstate}
                onInputChange={(_event, newInputValue) => {
                  setSelectedEstate(newInputValue);
                  setServerToContact(
                    estates?.find((e) => e?.estate_name === newInputValue)?.backend_base_url
                  );
                }}
              />
            </div>

            <div className="flex-column align-column-left aa-order-col-grp">
              <p className="small removemargin">Select Resident</p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: "100%" }}
                options={allResidentsOptions}
                value={
                  selectedResident?.email
                    ? `${selectedResident?.first_name} ${selectedResident?.last_name} (${selectedResident?.email})`
                    : ""
                }
                renderInput={(params) => <TextField {...params} label="Search by Name or Email" />}
                onChange={(_event, newValue) => {
                  setSelectedResident(allResidents?.find((e) => e?.email === newValue?.id));
                }}
              />
            </div>

            <div className="flex-row" style={{ justifyContent: "flex-end" }}>
              <a
                onClick={() => {
                  if (!serverToContact) return popup("Please select an estate!");
                  setAddAResident(true);
                }}
                href="#order-mgt"
                className="small removemargin"
                style={{ color: "#9178b1" }}
              >
                Add New Resident
              </a>
            </div>
          </div>

          <h4 className="big">Resident's Details</h4>

          <div className="flex-row align-row-left aa-order-row-grp">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small  removemargin">
                Name: {selectedResident?.first_name || "-"} {selectedResident?.last_name}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small  removemargin">Phone number: {selectedResident?.mobile || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small  removemargin">Email: {selectedResident?.email || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small  removemargin">
                Home Address: {selectedResident?.address?.addressString || "-"}
              </p>
            </div>
          </div>

          <div className="flex-column align-column-left aa-order-col-grp-one-in">
            <p className="small removemargin">Please input how much gas you would like to buy</p>
            <input
              className="in a-order-m-inputs"
              style={{ width: "100%" }}
              placeholder="input the gas amount here"
              type="number"
              min="2"
              value={gasInKg}
              onChange={(e) => setGasInKg(e.target?.value)}
            />

            <div className="flex-row" style={{ justifyContent: "space-between" }}>
              <p className="small removemargin">
                Minimum: 2kg{" "}
                <span className="redText">
                  {gasInKg &&
                    Number(gasInKg) < 2 &&
                    " note: gas quantity must be greater than or equal to 2kg"}
                </span>
              </p>
              <p className="small removemargin">Unit Price: NGN{gasPrice}/kg</p>
            </div>
          </div>

          <div className="flex-column align-column-left aa-order-col-grp-one-in">
            <p className="small removemargin">Note</p>
            <textarea
              className="in a-order-m-inputs"
              style={{ width: "100%", height: 100 }}
              placeholder="Leave a note for the rider"
              type="text"
              maxLength="100"
              value={orderNotes}
              onChange={(e) => setOrderNotes(e.target?.value)}
              rows={40}
            />
          </div>

          <div
            className="flex-row"
            style={{ justifyContent: "flex-end", padding: "20px 15px 0px 20px" }}
          >
            <button onClick={() => setScreen(2)} className="themeBtn" disabled={disableBtn}>
              Proceed to create Gas Order
            </button>
          </div>

          <div
            className="flex-row"
            style={{ justifyContent: "flex-end", padding: "0px 15px 20px 20px" }}
          >
            <span className="small redText">{whatsWrong}</span>
          </div>
        </div>

        {/* screen 2 */}
        <div
          className="flex-column align-column-left"
          style={{ display: `${screen === 2 ? "flex" : "none"}`, gap: 0 }}
        >
          <p className="small cursor" style={{ margin: 25 }} onClick={() => setScreen(1)}>
            <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> &nbsp; Back
          </p>

          <h4 className="">Order Details</h4>

          <h4 className="small boldText">Resident's Details</h4>

          <div className="flex-row align-row-left aa-order-row-grp">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">
                Name: {selectedResident?.first_name || "-"} {selectedResident?.last_name}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">Phone number: {selectedResident?.mobile || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">Email: {selectedResident?.email || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">
                Home Address: {selectedResident?.address?.addressString || "-"}
              </p>
            </div>
          </div>

          <div className="flex-row align-row-left aa-order-row-grp" style={{ marginTop: 30 }}>
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">Order Notes</p>
              <p className="small removemargin boldText">{orderNotes || "-"}</p>
            </div>
          </div>

          <div className="flex-row align-row-left aa-order-row-grp">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">Item Cost</p>
              <p className="small removemargin boldText">
                ₦{formatNumber(Number(gasInKg) * Number(gasPrice)) || "-"}
              </p>
            </div>
          </div>

          <div className="flex-row align-row-left aa-order-row-grp">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="small removemargin">Delivery Fee</p>
              <p className="small removemargin boldText">
                ₦{formatNumber(Number(deliveryFee)) || "-"}
              </p>
            </div>
          </div>

          <div className="flex-row align-row-left aa-order-row-grp">
            <div className="flex-column align-column-left width-fit-content property-d-max-width">
              <p className="big removemargin boldText">Total</p>
              <p className="big removemargin boldText">
                ₦{formatNumber(Number(gasInKg) * Number(gasPrice) + Number(deliveryFee)) || "-"}
              </p>
            </div>
          </div>

          <div
            className="flex-row"
            style={{ padding: "20px 15px 20px 20px", gap: 30, justifyContent: "center" }}
          >
            <button onClick={() => setScreen(1)} className="themeBtn themeBorder whiteBg blackText">
              Edit Order
            </button>
            <PaystackHook config={componentProps} />
          </div>
        </div>
      </div>

      <AddAnEstateUserModal
        display={addAResident}
        setAddAnEstateUserModal={setAddAResident}
        url={serverToContact}
        updateState={fetchEstateUsers}
      />

      <ModalOrderCreated
        display={modalOrderCreated}
        setModalOrderCreated={setModalOrderCreated}
        url={serverToContact}
        order={orderCreated}
        clearState={clearState}
        setReviewOrderModal={setReviewOrderModal}
      />

      <ReviewOrderModal
        display={reviewOrderModal}
        setReviewOrderModal={setReviewOrderModal}
        url={serverToContact}
        order={orderCreated}
        clearState={clearState}
      />
    </div>
  );
};

export { CreateGasOrderModal };
