import React from "react";
import "./GasConsignment.css";
import InputField, { IInputField } from "../../inputField/InputField";
import Button from "../../button/Button";
import { AiFillCloseCircle } from "react-icons/ai";

type Props = {
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  btnLabel?: string;
  inputPlaceHolder?: string;
  onActionBtnClick?: (arg?: any) => void;
  onChange?: (arg?: any) => void;
  inputProps?: Partial<IInputField>
};

function GasConsignment(props: Props) {
  const {
    onClose,
    subTitle,
    title,
    btnLabel,
    onActionBtnClick,
    inputPlaceHolder,
    onChange,
    inputProps
  } = props;

  const [price, setPrice] = React.useState("");
  return (
    <div className="gas-consignment-modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) {
        onClose && onClose();
    }
    }} >
      <div className="gas-consignment-modal-container">
        <div className="gas-consignment-modal-header">
          <div className="gas-consignment-modal-header-title">
            {title || "Add Gas Consignment"}
          </div>
        </div>

        <InputField
          {...inputProps}
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {setPrice(value); onChange && onChange(value)}}
          label="Set Price per KG"
          labelType="nested"
          name="gasPrice"
          value={price}
          placeholder={
            inputPlaceHolder || "Input price"
          }
        />

        <InputField
          {...inputProps}
          inputStyle={{ height: "40px", fontSize: "0.8rem" }}
          onChange={({ target: { value } }) => {setPrice(value); onChange && onChange(value)}}
          label="Source Vendor"
          labelType="nested"
          name="gasPrice"
          value={price}
          placeholder={
            inputPlaceHolder || "Type incentive amount here (in percentage)"
          }
        />

        <Button
          style={{ width: "70%" }}
          onClick={() => onActionBtnClick && onActionBtnClick(price)}
          label={btnLabel || "Save changes"}
        />

        <AiFillCloseCircle
          className="gas-consignment-modal-close-icon"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
}

export default GasConsignment;
