import React, { useState, useImperativeHandle, forwardRef, useEffect, useContext } from "react";
import "../styles/index.css";
import "./engine.css";
import EngineTopBar from "../components/engine-top-bar/engine-top-bar";
import EngineBottomBar from "../components/engine-bottom-bar/engine-bottom-bar";
import AppContext from "../context/app-context";
import Dashboard from "../screens/dashboard/dashboard";
import Estatemgt from "../screens/estatemgt/estatemgt";
import Financemgt from "../screens/financemgt/financemgt";
import Fleetmgt from "../screens/fleetmgt/fleetmgt";
import Ordermgt from "../screens/ordermgt/ordermgt";
import UserAndModules from "../screens/user-and-modules/user-and-modules";
import Vendormgt from "../screens/vendormgt/vendormgt";
import Broadcastmgt from "../screens/broadcastmgt/broadcastmgt";
import GeneralReports from "../screens/general-reports/general-reports";
import GasInventoryMgt from "../screens/gasinventormgt/gas-inventory-mgt";

const Engine = forwardRef(function ({ sideNav }, ref) {
  const { loggedIn, usersData, estates } = useContext(AppContext);

  const [onView, setOnView] = useState(
    document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() || "dashboard"
  );

  useEffect(() => {
    if (!usersData?.username) return;

    //force default route by access
    if (
      !usersData?.staff_child_roles?.includes(
        document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim()
      )
    ) {
      setOnView("dashboard");
      document.location.hash = "dashboard";
    }
  }, [loggedIn, usersData, estates]);

  useImperativeHandle(ref, () => {
    return {
      changeScreen(screen) {
        setOnView(screen?.toLowerCase());
        sideNav?.setActiveNav(screen?.toLowerCase());
        document.location.hash = screen?.toLowerCase();
      },
    };
  });

  return (
    <div className="engine-container">
      <EngineTopBar />
      <Dashboard sideNav={sideNav} display={onView === "dashboard" && true} />
      <Estatemgt display={onView === "estate-mgt" && true} />
      <Vendormgt display={onView === "vendor-mgt" && true} />
      <GasInventoryMgt display={onView === "gas-inventory-mgt" && true} />
      <Ordermgt display={onView === "order-mgt" && true} />
      <Fleetmgt display={onView === "fleet-mgt" && true} />
      <Financemgt display={onView === "finance-mgt" && true} />
      <Broadcastmgt display={onView === "broadcast-mgt" && true} />
      <UserAndModules display={onView === "users-and-modules" && true} />
      <GeneralReports display={onView === "general-reports" && true} />
      <EngineBottomBar />
    </div>
  );
});

export default Engine;
