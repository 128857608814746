import { useState } from "react";
import "../../styles/index.css";
import "./broadcastmgt.css";
import BroadcastForm from "./children/form/BroadcastForm";
import BroadcastTable from "./children/list/BroadcastTable";
import BroadcastView from "./children/broadcast-view/broadcast-view";

const Broadcastmgt = function ({ display }) {
  const [activeScreen, setActiveScreen] = useState(0);

  const [viewData, setViewData] = useState({});

  const [refreshData, setRefreshData] = useState({
    getData: () => {},
    editData: {
      title: "",
      text: "",
    },
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <BroadcastTable
        display={activeScreen === 0 && true}
        setViewData={setViewData}
        onNavigate={(s) => setActiveScreen(s)}
        setRefreshData={setRefreshData}
      />

      <BroadcastForm
        display={activeScreen === 1 && true}
        onNavigate={(s) => setActiveScreen(s)}
        onBackClick={() => setActiveScreen(0)}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />

      <BroadcastView
        display={activeScreen === 2 && true}
        viewData={viewData}
        onNavigate={(s) => setActiveScreen(s)}
        setRefreshData={setRefreshData}
      />
    </div>
  );
};

export default Broadcastmgt;
