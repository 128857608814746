import { useContext, useState } from "react";
import AppContext from "../../../../context/app-context";
import SelectInput from "../../../../components/selectInput/SelectInput";
import { fetchApi } from "../../../../api";
import Button from "../../../../components/button/Button";
import InputField from "../../../../components/inputField/InputField";

export const PromoScript = () => {
  const { setIsLoading, estates } = useContext(AppContext);

  const [estate, setEstate] = useState<any>();
  const [state, setState] = useState<any>({});
  const [isDisabled, setIsDisabled] = useState(false);

  const handleRunScript = async () => {
    if (!estate || !state?.startDate || !state?.endDate || !state?.capPrice) {
      alert("Please fill out all fields.");
      return;
    }

    const urlParams = new URLSearchParams({
      startDate: state.startDate,
      endDate: state.endDate,
      capPrice: state.capPrice,
      // estateId: estate?.estate_id,
    }).toString();

    const url = `${estate?.backend_base_url}/promo-script?${urlParams}`;

    try {
      setIsLoading?.(true);
      setIsDisabled(true);

      const { data, hasError } = await fetchApi({
        url,
        method: "GET",
      });

      setIsLoading?.(false);
      setIsDisabled(false);

      if (hasError) {
        alert(data?.message || "Something went wrong");
        return;
      }

      alert("Success");
    } catch (error: any) {
      console.error("Error running the script:", error);
      setIsLoading?.(false);
      setIsDisabled(false);
      alert("Something went wrong, please try again later.");
    }
  };

  return (
    <div className="uh-container">
      <div className="refund-form">
        <SelectInput
          name="estateName"
          options={estates}
          keyToRender="estate_name"
          keyValue="estate_id"
          placeholder="Select an estate"
          value={estate?.estate_name}
          onChange={(e) => {
            const selectedEstate = estates?.find(
              (j: any) => j?.estate_id === e?.target?.value
            );
            setEstate(selectedEstate);
          }}
        />
        <InputField
          label="Cap Price Type"
          name="capPrice"
          placeholder="eg. 1000"
          formatValue="number"
          value={state?.capPrice || ""}
          onChange={(e) =>
            setState((s: any) => ({ ...s, [e.target.name]: e.target.value }))
          }
          labelType="nested"
        />
        <InputField
          label="Start Date"
          name="startDate"
          placeholder="eg. 10/28/2024"
          type="date"
          value={state?.startDate || ""}
          onChange={(e) =>
            setState((s: any) => ({ ...s, [e.target.name]: e.target.value }))
          }
          labelType="nested"
        />
        <InputField
          label="End Date"
          name="endDate"
          placeholder="eg. 10/16/2024"
          type="date"
          value={state?.endDate || ""}
          onChange={(e) =>
            setState((s: any) => ({ ...s, [e.target.name]: e.target.value }))
          }
          labelType="nested"
        />
        <Button
          label="Run Script"
          btnType="fill"
          onClick={handleRunScript}
          disable={isDisabled}
        />
      </div>
    </div>
  );
};
