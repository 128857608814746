import React, { useState } from "react";
import "./SkillsInput.css";
import { popup } from "../../../../vanilla-functions/model";

interface SkillInputProps {
  onSkillsChange: (skills: string[]) => void;
  placeholder?: string;
  name?: string;
  label?: string;
  value?: string | string[];
}

const SkillInput = ({ onSkillsChange, label, name, placeholder, value }: SkillInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const _value = typeof value === "string"? (value?.split(",").filter((x) => x !== "") || []): 
            Array.isArray(value) ? value.filter((x) => x !== "") : [];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      if (inputValue.split("").some((e) => e === "," || e === ".")) {
        popup("Invalid character");
        return;
      }
      setInputValue("");
      onSkillsChange([..._value, inputValue.trim()]);
    }
  };

  const handleSkillRemove = (skillToRemove: string) => {
    const updatedSkills = _value.filter((skill) => skill !== skillToRemove);
    onSkillsChange(updatedSkills);
  };

  return (
    <div className="skill-input-wrapper">
      {label && <div className="skill-input-wrapper">{label}</div>}
      <div className="skill-input-container">
        <input
          type="text"
          className="skill-input"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={placeholder}
        />
        {value && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0.5rem",
              height: "fit-content",
            }}
          >
            {_value.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill}
                <button onClick={() => handleSkillRemove(skill)}>x</button>
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillInput;
