import React, { useContext, useEffect } from "react";
import "./BroadcastTable.css";
import Table from "../../../../components/table/Table";
import { TableHeaderItem } from "../../../../components/table/table.utils";
import Button from "../../../../components/button/Button";
import { MdAdd } from "react-icons/md";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import { AppConfig } from "../../../../utils/config";

export type BgProps = {
  getData: () => void;
  editData: {
    title: string;
    text: string;
  };
};

type Props = {
  onNavigate?: (screen: number) => void;
  setViewData: React.Dispatch<React.SetStateAction<any>>;
  display: boolean;
  setRefreshData: React.Dispatch<React.SetStateAction<BgProps>>;
};

const BroadcastTable = (props: Props) => {
  const { onNavigate, setViewData, display, setRefreshData } = props;
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const tableHeader: TableHeaderItem[] = [
    { key: "title", label: "Title" },
    { key: "_id", label: "Message ID" },
    { key: "recipients", label: "Recipients" },
    { key: "estate_name", label: "Estate" },
    { key: "medium", label: "Medium" },
    { key: "readBy", label: "Read By" },
    { key: "createdAt", label: "Date" },
  ];

  async function getData() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/estates/broadcasts/get?real=${AppConfig?.realData}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setData(
        returned?.data?.map((e: any) => ({
          title: e?.title,
          _id: e?._id,
          recipients: e?.recipients || "-",
          estate_name: e?.estate_name || "-",
          medium: e?.html ? "EMS" : e?.notification_type ? "VMS" : "E-Residents",
          readBy: new Intl.DateTimeFormat("en-gb", {
            dateStyle: "medium",
          }).format(new Date(e?.createdAt)),
          createdAt: new Intl.DateTimeFormat("en-gb", {
            dateStyle: "medium",
          }).format(new Date(e?.createdAt)),
          text: e?.text || e?.message,
          html: e?.html,
          notification_type: e?.notification_type,
        }))
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching estates data");
    }
  }

  useEffect(() => {
    getData();
  }, [backendServer]);

  useEffect(() => {
    setRefreshData((p: any) => ({ ...p, getData: getData }));
  }, []);

  if (!display) return <></>;

  return (
    <div className="broadcast-table-container">
      <h2 className="broadcast-table-title">Broadcast Management</h2>
      <Table
        data={data}
        header={tableHeader}
        showSearchBar
        searchHeaderMenus={{
          right: [
            <Button
              label="New Broadcast"
              leftIcon={<MdAdd />}
              onClick={() => onNavigate && onNavigate(1)}
            />,
          ],
        }}
        addCheckBox
        onRefreshIconClick={() => getData()}
        searchPlaceholder="Search for a broadcast"
        onRowClick={(row) => {
          setViewData(row);
          onNavigate && onNavigate(2);
        }}
      />
    </div>
  );
};

export default BroadcastTable;
