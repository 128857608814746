import React, { useState, useContext, useEffect } from 'react'
import logo from '../../../assets/rider-overview/bank-logo.png';
import './rider-overview.css';
import { FaTimes } from 'react-icons/fa';
import RiderInformation from './rider-information/rider-information';
import RiderHistory from './rider-history/rider-history';
import { PiProhibitBold } from 'react-icons/pi';
import { popup } from '../../../vanilla-functions/model';
import AppContext from '../../../context/app-context';

const RiderOverview = ({ isOpen, setIsOpen, selectedRider }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);
  const [ userImage, setUserImage ] = useState('');

  const [ toggleButton, setToggleButton] = useState('General information')

  const getSignedAwsUrl = async function (filename) {
    if (!filename) return;
    const url = `${resolveBackendUrl(selectedRider?.estateID)}/file/get/profile-pictures/${filename}`;
    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const { data } = await (
      await fetch( url , {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();

    return data;
  };

  useEffect(() => { 
    (async ()=>{
      setUserImage(await getSignedAwsUrl(selectedRider?.profile_picture))
      })()
  }, [selectedRider?.profile_picture]);


  const totalAmount = selectedRider?.wallet?.transactions.reduce((total, transaction) => {
    return total + transaction?.amount;
  }, 0);

  const colorStatus = (status) => {
    switch (status) {
        case "In Delivery":
            return { backgroundColor: "#E6FFE6", color: "#549186" };
        case "Awaiting Order":
            return { backgroundColor: "#FFE8B0", color: "#FFB400" };
        default:
            return { backgroundColor: "black", color: "white" };
    }
  }

  function formatAmountAsNaira(totalAmount) {
    const amountInNaira = totalAmount;
    const formattedAmount = amountInNaira.toLocaleString('en-NG', {
      style: 'currency',
      currency: 'NGN'
    });
  
    return formattedAmount;
  }

  async function deactivateRider() {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(selectedRider?.estateID)}/user/delete/${selectedRider?._id}`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
      });
  
      const returned = await response.json();
      // if (response.status !== 200) {
      //   throw new Error(returned.message || "Internal server error");
      // }
      popup(returned?.status);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  if (!isOpen) return null
  
  return (
    <div className='modal-overlay'>
      <div className='modal-content-body'>
        <div className='fleet-overview-container'>
        <div className='rider-detail-header' style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ flex: 1, textAlign: 'center' }}>Rider details</span>
      <FaTimes onClick={() => setIsOpen(false)}  style={{ cursor: 'pointer'}} />
    </div>
          <div className='user-name'>
            <div className='rider-image'><img src={userImage || 'https://via.placeholder.com/150?text=Avatar'} alt='userimg' /></div>
            <div>
              <div className='rider-name'>{selectedRider?.first_name} { " "} {selectedRider?.last_name}</div>
              <div className='rider-number'>
                <span className='rider-number'>{selectedRider?.mobile}</span>
                <span style={colorStatus(selectedRider?.status)} className='rider-status'>{selectedRider?.status}</span>
              </div>
            </div>
          </div>
          <div>
            <div className='rider-earning'><span className='rider-earning-text'>Total Earnings:</span>
            <span className='rider-earned-amount'>{formatAmountAsNaira(totalAmount)}</span></div>
          </div>
          {selectedRider?.is_active ?
          <div className='suspend'>
            <button onClick={deactivateRider}><PiProhibitBold color='#D95069' /><span style={{ color: '#D95069'}}>Suspend</span></button>
            {/* <button onClick={deactivateRider}><FaTimes /> <span style={{ color: '#050505'}}>Deactivate</span></button> */}
          </div>
          : null }

          <div className='rider-information'>
           <button onClick={() => setToggleButton('General information')} style={{ borderBottom: toggleButton==='General information' ? '2px solid #444266' : ''}}>General information</button>
           <button onClick={() => setToggleButton('Rider history')} style={{ borderBottom: toggleButton==='Rider history' ? '2px solid #444266' : ''}}>Rider history ({selectedRider?.wallet?.transactions.filter((transaction) => transaction?.status==='completed').length})</button>
          </div>
          {toggleButton === 'General information' ? <RiderInformation selectedRider={selectedRider}/> : <RiderHistory selectedRider={selectedRider} />}
        </div>
      </div>
    </div>
  )
}

export default RiderOverview