import { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "./manage-region.css";
import { FiEdit2 } from "react-icons/fi";
import { AppConfig } from "../../../utils/config";
import Table from "../../../components/table/Table";
import DeliveryPriceModal from "../../../components/modal/deliveryPriceModal/DeliveryPriceModal";
import { popup } from "../../../vanilla-functions/model";
import AppContext from "../../../context/app-context";
import { BsPlus } from "react-icons/bs";
import { HiEllipsisVertical } from "react-icons/hi2";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
// import { OverViewSvg, SuspendSvg } from "../../svg/vendor.svg";

import AddNewRegion from "../add-new-region/add-new-region";
import AssignStock from "./assign-stock";
import AddEstate from "../../vendormgt/add-estate/add-estate";

const ManageRegions = ({ setActiveScreen }) => {
  const { backendServer, setIsLoading, estate, resolveBackendUrl } = useContext(AppContext);
  const [ selectedRegion, setSelectedRegion ] = useState({});
  const [ showAssignStock, setShowAssignStock ] = useState(false);
  const [ showAddEstate, setShowAddEstate] = useState(false);
  
  const [data, setData] = useState([]); 
  const [activeRow, setActiveRow] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [ openAddRegion, setOpenAddRegion ] = useState(false);

  async function getAllEstatesSalesData() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estates/config/sales/get?real=${AppConfig?.realData}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setData(
        returned?.data?.map((e) => ({
          estate_id: e?.estate_id,
          estate_name: e?.estate_name,
          gas_delivery_price: e?.gas?.gas_delivery_price,
        }))
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estates Data");
    }
  }

  useEffect(() => {
    getAllEstatesSalesData();
  }, [estate]);

  const deactivateVendor = () => {
    console.log('hi')
  }

  const handleSetIncentive = async (price) => {
    if (price) {
      try {
        setIsLoading(true);
        const returned = await (
          await fetch(`${resolveBackendUrl(activeRow?.estate_id)}/estate/update/config`, {
            method: "PUT",
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken") || "",
              "content-type": "application/json",
            },
            body: JSON.stringify({
              gasDeliveryFee: price,
            }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);

        setIsLoading(false);

        setShowModal(false);

        popup("Gas Delivery Price Updated!");

        getAllEstatesSalesData();
      } catch (err) {
        setIsLoading(false);
        popup("Error updating data");
      }
    } else popup("Please set a delivery price");
  };

  const orderTableHeaders = [
    { key: "estate_id", label: "Estate ID" },
    { key: "estate_name", label: "Estate Name" },
    { key: "gas_delivery_price", label: "Current Price" },
  ];
  
  const tableActionOptions = [
    {
      label: "Assign Stock",
      onClick: (row) => {
        setSelectedRegion(row);
        setShowAssignStock(true);
      },
      icon: <FaRegCheckCircle size={16}/>,
      color: "#7975B6",
    },
    
    {
      label: "Add Estate",
      onClick: (row) => {
        setShowAddEstate(true);
        setSelectedRegion(row);
      },
      color: "#5A9A8F",
      icon: <FaRegCheckCircle size={16}/>,
    },
    
    {
      label: 'Deactivate Region',
      onClick: (row) => {
        setSelectedRegion(row)
        deactivateVendor(); 
      },
      color: "#FF4C51",
      icon: <MdOutlineCancel size={16} />,
    },
  ];

  return (
    <>
    <div style={{ padding: '0 10px '}}>
      <div className="financemgt-back-btn" onClick={() => setActiveScreen('Gas inventory')}>
      <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <div className="manage-regions-heading">
      <div className="estate-incentive-label">Manage Regions</div>
      <div><button onClick={() => setOpenAddRegion(true)}><BsPlus size={20}/><span>Add region</span></button></div>
      </div>
      <Table
        showSearchBar={true}
        data={data}
        header={orderTableHeaders}
        customColumn={[
          // {
          //   element: (row) => (
          //       <HiEllipsisVertical size={20}/>
          //   ),
          //   keyName: "status",
          //   title: () => " ",
          //   position: "end",
          //   onClick: (row) => {
          //     setActiveRow(row);
          //     setShowModal(true);
          //   },
          // },
        ]}
        onRowClick={(row) => {
          setActiveRow(row);
          setShowModal(true);
        }}
        onActionOptionClicks={{
          onOthers: tableActionOptions,
        }}
        onRefreshIconClick={getAllEstatesSalesData}
      />
    </div>
    <AddNewRegion setIsOpen={setOpenAddRegion} isOpen={openAddRegion} />
    <AssignStock isOpen={showAssignStock} setIsOpen={setShowAssignStock} />
    <AddEstate isOpen={showAddEstate} setIsOpen={setShowAddEstate} selectedRegion={selectedRegion} />
    </>
  );
};

export default ManageRegions;