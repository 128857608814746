import { useState, useContext } from "react";
import "../../../../styles/index.css";
import "./gr-home.css";
import AppContext from "../../../../context/app-context";
import { services } from "./service.data";
import ServiceCard from "./serviceCard/ServiceCard";

const GeneralReportsHome = function ({ display, setScreen }) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  if (!display) return;

  return (
    <div>
      <p className="order-header-title">General Reports</p>
      <p className="gr-page-services-title">Quick actions</p>
      <div className="gr-services-wrapper">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            {...service}
            onClick={() => {
              if (service.title === "User Reviews")
                setScreen({
                  screen: "reviews",
                  data: null,
                });
              if (service.title === "Reason for Account Deletion")
                setScreen({
                  screen: "delete-reasons",
                  data: null,
                });

                if (service.title === "Referrals")
                  setScreen({
                    screen: "referrals",
                    data: null,
                  });
            }}
            isLocked={service.isLocked}
            available={service.available}
          />
        ))}
      </div>
    </div>
  );
};

export default GeneralReportsHome;
