import React, { useState, useEffect } from "react";
import { Col, Row, Statistic } from "antd";
import "../../styles/index.css";
const { Countdown } = Statistic;

const LoginCountdown = ({ onFinish, stage, deadline, setDeadline }) => {
  useEffect(() => {
    if (stage === "code") setDeadline(Date.now() + 1000 * 60 * 0.2 * 24 * 2 + 1000 * 25);
  }, [stage]);
  if (stage !== "code") return;
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Countdown
          valueStyle={{ fontSize: "small", color: "#34204c" }}
          title=""
          value={deadline}
          onFinish={onFinish}
        />
      </Col>
    </Row>
  );
};
export default LoginCountdown;
