const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "role", label: "Role", minWidth: 100 },
  {
    id: "estate",
    label: "Estate",
    minWidth: 100,
    align: "left",
  },
  {
    id: "last_active",
    label: "Last Active",
    minWidth: 100,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
  },
];

function sortData(data, reinstateRevokeAccess) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      name: e?.first_name + " " + e?.last_name,
      email: e?.email,
      role: e?.estate_child_roles[0],
      estate: e?.estate,
      last_active: new Intl.DateTimeFormat("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(e?.createdAt)),
      action: (
        <p
          onClick={(el) => {
            el.stopPropagation();
            reinstateRevokeAccess(e?._id, e?.estateID, e?.is_active);
          }}
          className={`${e?.is_active ? "redText" : "greenText"} small cursor removemargin`}
        >
          {e?.is_active ? "Revoke Access" : "Re-instate Acess"}
        </p>
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
