import React from "react";
import "./TitleLabel.css";

type Props = {
  onClick?: () => void;
  label: string;
};

function TitleLabel(props: Props) {
  const { onClick, label } = props;
  return (
    <div
      className="page-title-label"
      onClick={() => onClick && onClick()}
    >
      {label}
    </div>
  );
}

export default TitleLabel;
