export  const dummyData = [
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
    {
      "consignmentQty": 100,
      "vendor": "OANDO",
      "qtySold": 100,
      "consignmentPrice": 100,
      "estilityPrice": 100,
      "creationDate": "2021-09-22T14:30:00.000Z",
      "terminationDate": "2021-09-22T14:30:00.000Z",
      "editPrice": "Edit"
    },
  ]