import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./add-a-rider-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";

const AddARiderModal = function ({ display, setAddARiderModal, url }) {
  const { setIsLoading, validateEmail, validateMobile } = useContext(AppContext);

  const [paystackBanks, setPayStackBank] = useState([]);

  const [whatsWrong, setWhatsWrong] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);

  const [riderData, setriderData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    address: {
      addressString: "Estility, Lekki",
      geoLocation: {
        coordinates: [],
      },
      postCode: "00000",
    },
    login_token: {
      token_id: "1",
      token: "1",
    },
    rider_details: {
      rider_type: "",
      vehicle_type: "",
    },
  });

  const [riderBank, setRiderBank] = useState({
    account_name: "",
    bank_name: "",
    account_number: "",
  });

  useEffect(() => {
    (async () => {
      const { data } = await (await fetch(`https://api.paystack.co/bank`)).json();
      setPayStackBank(
        data.map((el, i) => (
          <MDBDropdownItem
            key={i}
            link
            href="#fleet-mgt"
            onClick={() => {
              setRiderBank((p) => ({
                ...p,
                bank_name: el?.name?.trim(),
              }));
            }}
          >
            {el?.name}
          </MDBDropdownItem>
        ))
      );
    })();
  }, []);

  function runValidation() {
    if (!riderData?.first_name) return setWhatsWrong("Please set a first name for this rider");
    if (!riderData?.last_name) return setWhatsWrong("Please set a last name");
    if (!riderData?.email) return setWhatsWrong("Email address is missing");
    if (!validateEmail(riderData?.email))
      return setWhatsWrong("You have an invalid Rider Email Address");
    if (!riderData?.mobile) return setWhatsWrong("Mobile number is missing");
    if (!validateMobile(riderData?.mobile))
      return setWhatsWrong("You have an invalid Rider Mobile Number");
    if (!riderData?.rider_details?.rider_type) return setWhatsWrong("Please select a rider type");
    if (!riderData?.rider_details?.vehicle_type)
      return setWhatsWrong("Please select a vehicle type");

    if (!riderBank?.bank_name) return setWhatsWrong("Please select a bank name");
    if (!riderBank?.account_number) return setWhatsWrong("Rider account number is missing");
    if (!riderBank?.account_name) return setWhatsWrong("Rider account name is missing");

    setWhatsWrong("");
  }

  useEffect(() => {
    runValidation();

    if (
      !riderData?.first_name ||
      !riderData?.last_name ||
      !riderData?.email ||
      !riderData?.mobile ||
      !riderData?.rider_details?.rider_type ||
      !riderData?.rider_details?.vehicle_type ||
      !riderBank?.account_name ||
      !riderBank?.bank_name ||
      !riderBank?.account_number ||
      !validateEmail(riderData?.email) ||
      !validateMobile(riderData?.mobile)
    )
      setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function addRider() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${url}/signup/rider`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(riderData),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const returnedBank = await (
        await fetch(`${url}/rider/bank/update/${returned?.data}`, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(riderBank),
        })
      ).json();

      if (returnedBank.status === "Internal server error") throw new Error(returned.message);

      setriderData({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        address: {
          addressString: "",
          geoLocation: {
            coordinates: [],
          },
          postCode: "00000",
        },
        login_token: {
          token_id: "1",
          token: "1",
        },
        rider_details: {
          rider_type: "",
          vehicle_type: "",
        },
      });
      setRiderBank({
        account_name: "",
        bank_name: "",
        account_number: "",
      });
      setIsLoading(false);
      setAddARiderModal(false);
      popup("Rider Created!");
    } catch (err) {
      setIsLoading(false);
      popup("Error creating rider, try again later");
    }
  }

  return (
    <div className="modal-container" style={{ display: `${display && url ? "flex" : "none"}` }}>
      <div className="modal-body edit-a-rider-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setAddARiderModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="flex-row">
          <p className="big blackText boldText" style={{ marginTop: 30 }}>
            Add Rider
          </p>
        </div>

        <div className="flex-row align-row-left" style={{ marginLeft: 25, marginTop: 20 }}>
          <p className="small blackText">Provide Rider Information</p>
        </div>

        <div className="flex-column r-input-col">
          <input
            className="in a-a-u-inputs"
            placeholder="Rider's First Name"
            type="text"
            value={riderData?.first_name}
            onChange={(e) => {
              setriderData((p) => ({ ...p, first_name: e.target.value }));
            }}
          />
          <input
            className="in a-a-u-inputs"
            placeholder="Rider's Last Name"
            type="text"
            maxLength="100"
            value={riderData?.last_name}
            onChange={(e) => {
              setriderData((p) => ({ ...p, last_name: e.target.value }));
            }}
          />
          <input
            className="in a-a-u-inputs"
            placeholder="Rider's Email"
            type="email"
            maxLength="100"
            value={riderData?.email}
            onChange={(e) => {
              setriderData((p) => ({ ...p, email: e.target.value }));
            }}
          />
          <input
            className="in a-a-u-inputs"
            placeholder="Rider's Mobile"
            type="text"
            value={riderData?.mobile}
            onChange={(e) => {
              setriderData((p) => ({ ...p, mobile: e.target.value }));
            }}
          />

          <div className="flex-row align-row-left">
            <p className="small blackText removemargin" style={{ marginLeft: 25 }}>
              Vehicle Settings
            </p>
          </div>

          <MDBDropdown id="mdb-dropdown-custom-dropdown" style={{ width: "90%" }}>
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {riderData?.rider_details?.rider_type || "Rider Type"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "100%" }}>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: { ...p.rider_details, rider_type: e.target.textContent.trim() },
                  }));
                }}
              >
                Grocery
              </MDBDropdownItem>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: { ...p.rider_details, rider_type: e.target.textContent.trim() },
                  }));
                }}
              >
                Gas
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <MDBDropdown id="mdb-dropdown-custom-dropdown" style={{ width: "90%" }}>
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {riderData?.rider_details?.vehicle_type || "Vehicle Type"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "100%" }}>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: {
                      ...p.rider_details,
                      vehicle_type: e.target.textContent.trim(),
                    },
                  }));
                }}
              >
                Bus
              </MDBDropdownItem>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: {
                      ...p.rider_details,
                      vehicle_type: e.target.textContent.trim(),
                    },
                  }));
                }}
              >
                Bicycle
              </MDBDropdownItem>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: {
                      ...p.rider_details,
                      vehicle_type: e.target.textContent.trim(),
                    },
                  }));
                }}
              >
                Motorcycle
              </MDBDropdownItem>
              <MDBDropdownItem
                link
                href="#fleet-mgt"
                onClick={(e) => {
                  setriderData((p) => ({
                    ...p,
                    rider_details: {
                      ...p.rider_details,
                      vehicle_type: e.target.textContent.trim(),
                    },
                  }));
                }}
              >
                Other
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>

          <div className="flex-row align-row-left">
            <p className="small blackText removemargin" style={{ marginLeft: 25 }}>
              Payment Information
            </p>
          </div>

          <MDBDropdown id="mdb-dropdown-custom-dropdown" style={{ width: "90%" }}>
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {riderBank?.bank_name || "Rider's Bank"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
              {paystackBanks}
            </MDBDropdownMenu>
          </MDBDropdown>

          <input
            className="in a-a-u-inputs"
            placeholder="Rider's Account Number"
            type="number"
            value={riderBank?.account_number}
            onChange={(e) => {
              setRiderBank((p) => ({ ...p, account_number: e.target.value }));
            }}
          />
          <input
            className="in a-a-u-inputs"
            placeholder="Rider's Account Name"
            type="text"
            value={riderBank?.account_name}
            onChange={(e) => {
              setRiderBank((p) => ({ ...p, account_name: e.target.value }));
            }}
          />
        </div>

        <div className="flex-row" style={{ marginTop: 30 }}>
          <button disabled={disableBtn} onClick={addRider} className="themeBtn a-a-u-btn">
            Complete
          </button>
        </div>
        <div className="flex-row" style={{ marginBottom: 30 }}>
          <p className="small redText removemargin">{whatsWrong}</p>
        </div>
      </div>
    </div>
  );
};

export { AddARiderModal };
