import React from 'react';
import './TableActionCard.css';
import { Eye, GearSix, PencilSimpleLine, ToggleLeft, Trash } from 'phosphor-react';

export type ITableActionCard = {
    onView?: IActionCardClick;
    onEdit?: IActionCardClick;
    onSetAsDefault?: IActionCardClick;
    onDeactivate?: IActionCardClick;
    onDelete?: IActionCardClick;
    others?: IActionCardClick[];
}


export type IActionCardClick = {
    onClick: (arg?: any) => void;
    label: string;
    color?: string;
    icon?: JSX.Element;
}



const  TableActionCard = ({onView, onEdit, onSetAsDefault, onDeactivate, onDelete, others}: ITableActionCard)  =>{
  return (
    <div className='action-card-table'>
        {
            onView && onView.label &&
            <div className='action-card-table-list' style={{color: `${onView?.color}`}} onClick={(e) => {e.stopPropagation(); onView.onClick()}}>
                <div className='action-card-table-list-icon-wrapper'>
                    <Eye size={'1rem'} />
                </div>
                <div className='action-card-table-list-label'>{onView.label}</div>
            </div>
        }
        {
            onEdit && onEdit.label && 
            <div className='action-card-table-list' style={{color: `${onEdit?.color}`}} onClick={(e) => {e.stopPropagation(); onEdit.onClick()}}>
                <div className='action-card-table-list-icon-wrapper'>
                    <PencilSimpleLine size={'1rem'} />
                </div>
                <div className='action-card-table-list-label'>{onEdit.label}</div>
            </div>
        }
        {
            onSetAsDefault && onSetAsDefault.label &&
            <div className='action-card-table-list' style={{color: `${onSetAsDefault?.color}`}} onClick={(e) => {e.stopPropagation(); onSetAsDefault.onClick()}}>
                <div className='action-card-table-list-icon-wrapper'>
                    <GearSix size={'1rem'} />
                </div>
                <div className='action-card-table-list-label'>{onSetAsDefault.label}</div>
            </div>
        }
        {
            onDeactivate && onDeactivate.label &&
            <div className='action-card-table-list'>
                <div className='action-card-table-list-icon-wrapper' style={{color: `${onDeactivate?.color}`}} onClick={() => onDeactivate.onClick()}>
                    <ToggleLeft size={'1rem'} />
                </div>
                <div className='action-card-table-list-label'>{onDeactivate.label}</div>
            </div>
        }
        {
            onDelete && onDelete.label &&
            <div className='action-card-table-list' style={{color: `${onDelete?.color}`}} onClick={() => onDelete.onClick()}>
                <div className='action-card-table-list-icon-wrapper'>
                    <Trash size={'1rem'} />
                </div>
                <div className='action-card-table-list-label'>{onDelete.label}</div>
            </div>
        }
        {
            others && others.length > 0 &&
            others.map((x, i) => {
                return (
                    x.label && x.label.length > 0 &&
                    <div key={i} className='action-card-table-list'
                    style={{color: `${x.color}`}} onClick={(e) => {e.stopPropagation(); x.onClick && x.onClick()}}>
                        {
                            x.icon &&
                            <div className='action-card-table-list-icon-wrapper'>
                                {x.icon || <></>}
                            </div>
                        }
                        <div className='action-card-table-list-label'>{x.label}</div>
                    </div>
                )
            })            
        }
    </div>
  )
}

export default TableActionCard