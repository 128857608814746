import React, { useEffect, useRef, useState } from "react";
import "./SelectInput.css";
import { AiOutlineCaretDown } from "react-icons/ai";
import { popup } from "../../vanilla-functions/model";

interface CustomDropdownProps {
  options: any[];
  placeholder?: string;
  label?: string;
  name: string;
  value?: string;
  onChange?: (e: ITarget) => void;
  keyToRender?: string;
  keyValue?: string;
  labelType?: "inline" | "nested";
  dropIcon?: JSX.Element;
  disable?: boolean;
  optionWrapper?: (option: any, index?: number) => React.ReactNode;
  showSearchInput?: boolean;
}

type ITarget = {
  target: {
    name: string;
    value: any;
  };
};

const SelectInput = ({
  options: _options,
  name,
  onChange,
  value,
  keyToRender,
  keyValue,
  label,
  placeholder,
  labelType,
  dropIcon,
  disable,
  optionWrapper,
  showSearchInput = false,
}: CustomDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<any | null>(value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [options, setOptions] = useState<any[]>(_options);
  const [searchValue, setSearchValue] = useState("");

  const handleSelect = (option: any) => {
    onChange && onChange({ target: { name, value: option } });
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchValue.trim() !== "") {
      if (searchValue.split("").some((e) => e === "," || e === ".")) {
        popup("Invalid character");
        return;
      }
      if (optionWrapper || typeof options[0] === "string") {
        handleSelect(searchValue);
        setOptions(_options);
        setSearchValue("");
      }
    }
  };

  const handleSearchChange = (e: any) => {
    if (optionWrapper || typeof options[0] === "string") {
      setSearchValue(e.target.value);
      setIsDropdownOpen(true);
      const filterOptions = _options.filter((x) =>
        x.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setOptions(filterOptions);
    }
  };

  return (
    <div className="slp-custom-select" ref={dropdownRef}>
      {labelType === "nested" && (label || placeholder) && (
        <div>{label || placeholder}</div>
      )}
      <div
        className={`slp-select-box ${disable && "slp-select-box-disable"}`}
        onClick={() => !disable && setIsDropdownOpen(!isDropdownOpen)}
      >
        {showSearchInput ? (
          <div className="select-input-field">
            <input
              type="text"
              value={searchValue}
              onKeyDown={handleInputKeyDown}
              name="search"
              onChange={(e) => {
                handleSearchChange(e);
              }}
              placeholder={
                selectedOption ? value : label || placeholder || "Search..."
              }
            />
          </div>
        ) : selectedOption ? (
          value
        ) : (
          placeholder || label || "Select an option"
        )}
        {dropIcon || <AiOutlineCaretDown />}
      </div>
      {isDropdownOpen && (
        <div className="slp-option-list">
          {(showSearchInput? options: _options).map((option, index) =>
            optionWrapper ? (
              <div key={index} className="slp-option">
                {optionWrapper(option, index)}
              </div>
            ) : typeof option === "string" ? (
              <div
                key={index}
                className="slp-option"
                onClick={() => handleSelect(option)}
              >
                {option}
              </div>
            ) : (
              <div
                key={index}
                className="slp-option"
                onClick={() => {
                  const firstKey = Object.keys(option)[0];
                  const val = keyValue
                    ? option[keyValue]
                    : keyToRender
                    ? option[keyToRender]
                    : option[firstKey];
                  handleSelect(val);
                }}
              >
                {keyToRender
                  ? option[keyToRender]
                  : keyValue
                  ? option[keyValue]
                  : option[Object.keys(option)[0]]}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
