import React, { useState } from 'react'
import closeIcon from '../../../assets/Icon.svg';
import successIcon from '../../../assets/Light.png';

import './success.css';

const Success = ({ title, isOpen, closeAll, setIsOpen, text, buttonText }) => {
  const closeModals  = () => {
    setIsOpen(false)
    closeAll(false)
    document.location.reload()
  }

  const goToDashboard = () => {
    setIsOpen(false)
    closeAll(false)
    // document.location.reload()
  }

  if (!isOpen) return;
  return (
    <div className='modal-overlay'>
      <div className='modal-content-body'>
        <button onClick={closeModals} className='close-button'><img src={closeIcon} alt='close-icon' /></button>
        <div className='success-container'>
          <div className='success-title'>{title}</div>
          <div className='success-button'><img src={successIcon} alt='success-icon' /></div>
          <div className='success-text'><p>{text}</p></div>
          <div className='buttons-div'>
            <button onClick={goToDashboard} type='button'>{buttonText}</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Success