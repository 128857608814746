import React, { useState, useContext, useEffect } from "react";
import "../../styles/index.css";
import "./user-and-modules.css";
import AppContext from "../../context/app-context";
import Identity from "../../components/popup/identity/identity";
import NewMember from "../../components/popup/new-member/new-member";
import { FaPlus, FaTimes } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowBack } from "react-icons/io";
import TablePagination from "@mui/material/TablePagination";
// import { useNavigate } from "react-router-dom";

const UserAndModules = function ({ display }) {
  // const navigate = useNavigate();
  const [csvData, setcsvData] = useState([]);
  const { backendServer, setIsLoading } = useContext(AppContext);
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedNewMember, setIsOpenedNewMember] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [ revoked, setRevoked ] = useState('');
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ originalData, setOriginalData ] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getStaff = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`${backendServer}/staff/get`, {
        method: "GET",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      });
      const data = await response.json();
      setTableData(data?.data);
      setOriginalData(data?.data);
      setIsLoading(false)
    }
    catch (error) {
      throw error
      setTableData([]);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getStaff();
  }, []);

  function workCSV(data) {
    setcsvData([
      ["Name", "Address", "Unit Code", "Phone Number", "Email", "Resident Level"],
      ...data?.map(
        ({ first_name, last_name, address, mobile, resident_type, property_code, email }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            property_code,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }
      ),
    ]);
  }

  const revokeStaff = async () => {
    const revoked_or_activate = revoked?.is_active ? 'delete' : 'activate'
    try {
      setIsLoading(true);
      const response = await fetch(`${backendServer}/staff/${revoked_or_activate}/${revoked?._id}`, {
        method: revoked?.is_active ? "DELETE" : 'PATCH',
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      });
      const data = await response.json();
      setIsLoading(false);
      getStaff()
      setShowSuccess(true);
    }
    catch (error) {
      throw error
      setIsLoading(false);
      setShowSuccess(false);
    }
  };

  const tableHeaders = ["Name", "Email", "Permissions", "Active", "Action"];

  const openIdModal = (user) => {
    setRevoked(user);
    setIsOpened(true);
  }

  

  const colorStatus = (status) => {
    switch (status) {
        case "estate-mgt":
            return { backgroundColor: "#DFDDF6", color: "#7975B6" };
        case "fleet-mgt":
            return { backgroundColor: "#D3ECE7", color: "#325751" };
        case "finance-mgt":
            return { backgroundColor: "#FAFEB8", color: "#96994C" };
        case "vendor-mgt":
            return { backgroundColor: "#EBE9ED", color: "#34204D" };
        case "order-mgt":
            return { backgroundColor: "#D2E7EA", color: "#3170FE" };
        case "users-and-modules":
            return { backgroundColor: "#FFC8C9", color: "#FF4C51" };
        case "broadcast-mgt": 
            return { backgroundColor: "#F9F3C0", color: "#996C00" };
        default:
            return { backgroundColor: "black", color: "white" };
    }
  }

const searchUser = (e) => {
  const value = e?.target?.value.trim().toLowerCase();
  setSearchValue(value);
  if (value === "") {
    setTableData(originalData);
  } else {
  const filteredData = tableData.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const emailaddress = user?.email.toLowerCase()
      const permissions = user.staff_child_roles.map(permission => permission.toLowerCase());
      return (
          user.first_name.toLowerCase().includes(searchValue) ||
          user.last_name.toLowerCase().includes(searchValue) ||
          fullName.includes(searchValue) || 
          emailaddress.includes(value) ||
          permissions.some(permission => permission.includes(searchValue))
      );
  });
  setTableData(filteredData);
}
};

useEffect(() => {
  if(searchValue === ""){
  setTableData(originalData);
  }else {
  const filteredData = originalData.filter((user) => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const emailaddress = user?.email.toLowerCase()
      const permissions = user.staff_child_roles.map(permission => permission.toLowerCase());
      return (
          user.first_name.toLowerCase().includes(searchValue) ||
          user.last_name.toLowerCase().includes(searchValue) ||
          fullName.includes(searchValue) || 
          emailaddress.includes(searchValue) ||
          permissions.some(permission => permission.includes(searchValue))
      );
      
  });
  setTableData(filteredData)
  }
}, [searchValue]);

const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
};

const [sortOrder, setSortOrder] = useState(sortDirections.ASC);
const sort = () => {
  const sortedData = [...tableData].sort((a, b) => {
      const firstNameA = a.first_name.toLowerCase();
      const firstNameB = b.first_name.toLowerCase();
      if (sortOrder === sortDirections.ASC) {
          if (firstNameA < firstNameB) {
              return -1;
          }
          if (firstNameA > firstNameB) {
              return 1;
          }
      } else {
          if (firstNameA > firstNameB) {
              return -1;
          }
          if (firstNameA < firstNameB) {
              return 1;
          }
      }
      return 0;
  });
  const nextSortOrder = sortOrder === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC;
  setSortOrder(nextSortOrder);
  setTableData(sortedData);
};

const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
      const propName = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(acc, flattenObject(obj[key], propName));
      } else {
          acc[propName] = obj[key];
      }
      return acc;
  }, {});
};

const flattenedTableData = tableData.map((item) => flattenObject(item));

  if (!display) return;
  return (
    <div className="screen-container users-modules-container3">
      <div className="users-and-modules">Users and Modules</div>
      <div className="user-modules-table-container">
        <div className="search-container">
        <div className="search-input-box-wrapper">
          <CiSearch />
          <input
            placeholder="Search for user" type='text' onChange={searchUser}
          />
        </div>
        <CgSortAz
            onClick={sort} 
            className="cursor" title="Sort By Resident Level" size={30} />
          {/* <CSVLink
            className="cursor small csv-link"
          filename={`${estate?.estate_name} Residents Data`}
            data={flattenedTableData}
          >
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink> */}
          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={getStaff}
          />
        </div>
        <table className="users-table">
          <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.length > 0 ? tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <tr key={index}>
                <td className="table-row-name">{row?.first_name}{" "} {row?.last_name}</td>
                <td className="table-row-name">{row?.email}</td>
                <td className="permissions-td">
                  <div className="permissions-container">
                    {row?.staff_child_roles.map((permission, idx) => (
                      <div style={colorStatus(permission)} key={idx} className="permission-tag">
                        <span className="permission-item">{permission}</span>
                        <FaTimes className="remove-icon" style={{ border: `1px solid ${colorStatus(permission).color}`}} />
                      </div>
                    ))}
                  </div>
                </td>
                <td className="table-row-lastActive">{row?.is_active ? 'True' : 'False'}</td>
                <td>
                  <button onClick={() => openIdModal(row)} className="action-button">{row?.is_active ? 'Revoke Access' : 'Re activate'}</button>
                </td>
              </tr>
            )): <tr><td colSpan={4} className="no-data">No data</td></tr>}
          </tbody>
        </table>
       
         <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </div>
      <div className="add-new">
        <button onClick={() => setIsOpenedNewMember(true)}><FaPlus /><span>Add new</span></button>
      </div>
      <Identity showSuccess={showSuccess} revoked={revoked} isOpen={isOpened} setIsOpen={setIsOpened} onClick={revokeStaff} />
      <NewMember isOpen={isOpenedNewMember} setIsOpen={setIsOpenedNewMember} getStaff={getStaff}/>
    </div>
  );
};

export default UserAndModules;
