import { useContext } from "react";
import AppContext from "../../../../context/app-context";

const ReferrerInformation = ({ selectedReferral }) => {
  const { estates } = useContext(AppContext);

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  };

  return (
    <div className="referral-information-container">
      <div className="referral-information-details">
        <div className="information-heading">
          <b className="ml-1">Personal Information</b>
        </div>
        <div className="referral-data ml-1 mt-3">
          <div className="mt-3">
            Full Name:{" "}
            <b>
              {selectedReferral?.userID?.first_name}{" "}
              {selectedReferral?.userID?.last_name}
            </b>
          </div>
          <div className="mt-3">
            Mobile number: <strong>{selectedReferral?.userID?.mobile}</strong>
          </div>
        </div>
        <div className="ml-1 mt-3">
          email: <b>{selectedReferral?.userID?.email}</b>
        </div>
      </div>

      <div className="mt-4">
        <div className="information-heading">
          <b className="ml-1">Estate Name</b>
        </div>
        <div className="referral-data ml-1 mt-3">
          <b>{findEstate(selectedReferral?.estateID)}</b>
        </div>
      </div>

      <div className="">
        <div className="information-heading mt-3">
          <b className="ml-1">User Address</b>
        </div>
        <div className="ml-1 mt-2">
          <strong>
            {selectedReferral?.userID?.address?.addressString || "No data"}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default ReferrerInformation;
