import React, { useContext, useState, useEffect } from "react";
import "../../styles/index.css";
import "./engine-top-bar.css";
import AppContext from "../../context/app-context";
import { HiChevronDown } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import { AddAUserModal } from "../add-a-user-modal/add-a-user-modal";
import AddAPropertyModal from "../add-a-property-modal/add-a-property-modal";
import { AddAVendorModal } from "../add-a-vendor-modal/add-a-vendor-modal";
import { AddARiderModal } from "../add-a-rider-modal/add-a-rider-modal";
import { CreateGasOrderModal } from "../create-an-order-modal/create-gas-order-modal";

function EngineTopBar({}) {
  const [userImage, setUserImage] = useState("");
  const [addAUserModal, setAddAUserModal] = useState(false);
  const [addAPropertyModal, setAddAPropertyModal] = useState(false);
  const [addAVendorModal, setAddAVendorModal] = useState(false);
  const [addARiderModal, setAddARiderModal] = useState(false);
  const [createGasOrderModal, setCreateGasOrderModal] = useState(false);
  const [estateUrlContext, setEstateUrlContext] = useState("");
  const { usersData, getSignedAwsUrl, getCurrentHash, setSelectEstateModal } =
    useContext(AppContext);
  const [showDropDown, setShowDropDown] = useState(false);

  const [showOrderDropdown, setShowOrderDropdown] = useState(false);

  useEffect(() => {
    (async function () {
      setUserImage(await getSignedAwsUrl(usersData?.profile_picture, "profile-pictures"));
    })();
  }, [usersData]);

  useEffect(() => {
    document
      .querySelector(".header-dropdown")
      ?.addEventListener("mouseleave", () => setShowDropDown(false));
  }, []);

  useEffect(() => {
    document.querySelector("body")?.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("drop-down-button") ||
        e.target.classList.contains("top-bar-btn")
      )
        return;
      setShowDropDown(false);
      setShowOrderDropdown(false);
    });
  }, []);

  function decideToBarButtonActions() {
    if (getCurrentHash() === "estate-mgt") setShowDropDown(true);

    if (getCurrentHash() === "order-mgt") setShowOrderDropdown(true);

    if (getCurrentHash() === "vendor-mgt")
      setSelectEstateModal({
        display: true,
        data: {
          title: "Choose Estate for New Vendor",
          buttonText: "Create New Vendor",
          onSelect: (url) => {
            setAddAVendorModal(true);
            setEstateUrlContext(url);
          },
        },
      });
    if (getCurrentHash() === "fleet-mgt")
      setSelectEstateModal({
        display: true,
        data: {
          title: "Choose Estate for New Rider",
          buttonText: "Create New Rider",
          onSelect: (url) => {
            setAddARiderModal(true);
            setEstateUrlContext(url);
          },
        },
      });
  }

  return (
    <div className="top-bar-container">
      <p className="big removemargin media-engine-intro">
        Welcome back, {usersData?.last_name} 👋
        <br></br>{" "}
        <span className="small removemargin">Here's what is happening in Estility today.</span>
      </p>

      <div className="flex-row" style={{ width: "fit-content", gap: 15 }}>
        {(getCurrentHash() === "estate-mgt" ||
          getCurrentHash() === "vendor-mgt" ||
          getCurrentHash() === "fleet-mgt") && (
          <button onClick={decideToBarButtonActions} className="top-bar-btn themeBtn">
            + &nbsp; Add New &nbsp; <HiChevronDown />
          </button>
        )}

        {getCurrentHash() === "order-mgt" && (
          <button
            style={{ width: 190 }}
            onClick={decideToBarButtonActions}
            className="top-bar-btn themeBtn"
          >
            + &nbsp; Create New Order &nbsp; <HiChevronDown />
          </button>
        )}

        <div className={`header-dropdown ${!showDropDown && "hide"}`}>
          {/* <p className="drop-down-button" onClick={() => {}}>
            <AiOutlinePlus size={15} /> Create Estate
          </p> */}
          <p
            className="drop-down-button"
            onClick={() => {
              setSelectEstateModal({
                display: true,
                data: {
                  title: "Choose Estate for New Property",
                  buttonText: "Create New Property",
                  onSelect: (url) => {
                    setAddAPropertyModal(true);
                    setEstateUrlContext(url);
                  },
                },
              });
            }}
          >
            <AiOutlinePlus size={15} /> Add Property
          </p>
          <p
            className="drop-down-button"
            onClick={() => {
              setSelectEstateModal({
                display: true,
                data: {
                  title: "Choose Estate for New Resident",
                  buttonText: "Create New Resident",
                  onSelect: (url) => {
                    setAddAUserModal(true);
                    setEstateUrlContext(url);
                  },
                },
              });
            }}
          >
            <AiOutlinePlus size={15} /> Add Resident
          </p>
        </div>

        <div style={{ width: 190 }} className={`header-dropdown ${!showOrderDropdown && "hide"}`}>
          <p
            className="drop-down-button"
            onClick={() => {
              setCreateGasOrderModal(true);
              setShowOrderDropdown(false);
            }}
          >
            <AiOutlinePlus size={15} /> Create Gas Order
          </p>
          <p
            className="drop-down-button"
            style={{ opacity: 0.5 }}
            onClick={() => {
              setShowOrderDropdown(false);
            }}
          >
            <AiOutlinePlus size={15} /> Create Grocery Order
          </p>
        </div>
        <img
          src={userImage || "/user_placeholder.png"}
          alt="estility admin"
          className="top-bar-profile-pic"
        />
        <div className="flex-column align-column-left" style={{ width: "fit-content" }}>
          <p className="small removemargin">{usersData?.last_name}</p>
          <p className="top-bar-status small removemargin">{usersData?.role}</p>
        </div>
      </div>

      <AddAUserModal
        url={estateUrlContext}
        display={addAUserModal}
        setAddAUserModal={setAddAUserModal}
      />

      <AddAPropertyModal
        url={estateUrlContext}
        display={addAPropertyModal}
        setAddAPropertyModal={setAddAPropertyModal}
      />

      <AddAVendorModal
        url={estateUrlContext}
        display={addAVendorModal}
        setAddAVendorModal={setAddAVendorModal}
      />

      <AddARiderModal
        url={estateUrlContext}
        display={addARiderModal}
        setAddARiderModal={setAddARiderModal}
      />

      <CreateGasOrderModal
        display={createGasOrderModal}
        setCreateOrderModal={setCreateGasOrderModal}
      />
    </div>
  );
}

export default EngineTopBar;
