import "./Promo.css";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Table from "../../../../components/table/Table";
import { TableHeaderItem } from "../../../../components/table/table.utils";
import { fetchApi } from "../../../../api";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/app-context";
import InputField from "../../../../components/inputField/InputField";
import Button from "../../../../components/button/Button";
import { formatDate } from "../../../../utils";
import { FiEdit2 } from "react-icons/fi";
import PropmoModal from "../../../../components/modal/promoModal/promoModal";
import { popup } from "../../../../vanilla-functions/model";
import SelectInput from "../../../../components/selectInput/SelectInput";
import { PromoScript } from "./PromoScript";

type IPromoService = {
  onClickBack: () => void;
};

export const PromoService = (p: IPromoService) => {
  const { onClickBack } = p;
  const { backendServer, setIsLoading, estates } = useContext(AppContext);

  const [estate, setEstate] = useState<any>();

  const [data, setData] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    name: "",
    amount: "",
    estateId: "",
    productType: "gas",
    endDate: "",
    startDate: "",
    maxKg: "",
  });

  const [activeRow, setActiveRow] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const header: TableHeaderItem[] = [
    // { key: "estateId", label: "Estate Id" },
    { key: "name", label: "Promo Tag/Name" },
    { key: "estateName", label: "Estate Name" },
    { key: "productType", label: "Product Type" },
    { key: "maxKg", label: "Maximum Kg" },
    { key: "isActive", label: "Status" },
    { key: "amount", label: "Promo Price" },
    { key: "createdBy", label: "Created By" },
    { key: "modifiedBy", label: "Modify By" },
    { key: "startDate", label: "Date Created" },
    { key: "endDate", label: "Expiry Date" },
  ];

  const unrenderFileds = ["estateId"];

  const url = `${backendServer}/app-promo`;

  const fetchPromo = async () => {
    try {
      const { data, hasError, status, statusText } = await fetchApi({
        url,
        method: "GET",
      });

      setData(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPromo();
  }, []);

  // Generic change handler for form inputs
  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const formattedFormData = {
      ...formData,
      endDate: formatDate(formData.endDate),
      startDate: formatDate(formData.startDate),
    };

    try {
      // const response = await (
      //   await fetch(url, {
      //     method: "POST",
      //     credentials: "include",
      //     headers: {
      //       token: localStorage.getItem("omstoken") ?? "",
      //       "content-type": "application/json",
      //     },
      //     body: JSON.stringify(formattedFormData),
      //   })
      // ).json();

      const { data, hasError } = await fetchApi({
        url,
        method: "POST",
        body: formattedFormData,
      });

      setIsLoading(false);
      if (hasError) {
        popup(data?.status || "Error occur!");
      } else {
        popup(`Successfully submitted`!);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      popup("There was an error submitting the form.");
      setIsLoading(false)
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePromo = async (data: {
    id: string;
    name: string;
    maxKg: string;
    amount: string;
    isActive: boolean;
    endDate: string;
    startDate: string;
  }) => {
    const formattedFormData = {
      ...data,
      endDate: formatDate(data.endDate),
      startDate: formatDate(data.startDate),
    };

    try {
      setIsLoading(true);
      const returned = await (
        await fetch(url, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken") || "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...formattedFormData,
          }),
        })
      ).json();

      if (returned.status === "Internal server error")
        throw new Error(returned.message);

      setIsLoading(false);

      setShowModal(false);

      popup("Promo Updated!");

      fetchPromo();
    } catch (error) {
      setIsLoading(false);
      popup("Error updating data");
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [showScript, setShowScript] = useState(false);

  const rendereEst = (estates: any[]) => {
    const list: string[] = data?.map((e) => e?.estateId);
    return estates.filter((e: any) => {
      return !list.includes(e?.estate_id);
    });
  };

  return (
    <div>
      <div className="uh-container">
        <div className="financemgt-back-btn" onClick={onClickBack}>
          <MdKeyboardArrowLeft size={25} />
          Back
        </div>
        <h2>{showForm ? "Promo Screen Form" : "Promo Screen List"}</h2>
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          {!showScript && (
            <Button
              style={{ marginTop: "10px" }}
              type="submit"
              label={showForm ? "Hide Form - " : "Add New +"}
              onClick={() => setShowForm(!showForm)}
            />
          )}
          <Button
            style={{ marginTop: "10px" }}
            label={
              showScript
                ? "Hide - User history Control Tab"
                : "User History Control"
            }
            onClick={() => setShowScript(!showScript)}
          />
        </div>
        {showScript && <PromoScript />}
        {!showScript && (
          <>
            {showForm && (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.7rem",
                }}
              >
                <SelectInput
                  name="estateName"
                  options={rendereEst(estates)}
                  keyToRender="estate_name"
                  keyValue="estate_id"
                  labelType="nested"
                  placeholder="Select an estate"
                  value={estate?.estate_name}
                  onChange={(e) => {
                    const val = estates?.find(
                      (j: any) => j?.estate_id === e?.target?.value
                    );
                    if (!val) return;
                    setEstate(val);
                    setFormData((prev) => ({
                      ...prev,
                      estateId: val?.estate_id,
                    }));
                  }}
                />
                <InputField
                  label="Name"
                  name="name"
                  placeholder="eg. Promo name"
                  value={formData.name}
                  onChange={handleChange}
                  labelType="nested"
                />
                <InputField
                  label="Max Kg"
                  name="maxKg"
                  placeholder="eg. Max kg"
                  value={formData.maxKg}
                  onChange={handleChange}
                  labelType="nested"
                />
                <InputField
                  label="Promo Price"
                  name="amount"
                  placeholder="eg. 10000"
                  value={formData.amount}
                  onChange={handleChange}
                  formatValue="number"
                  labelType="nested"
                />

                {/* <InputField
                  label="Product Type"
                  name="productType"
                  placeholder="eg. gas"
                  value={formData.productType}
                  onChange={handleChange}
                  labelType="nested"
                /> */}
                <InputField
                  label="Start Date"
                  name="startDate"
                  placeholder="eg. 10/28/2024"
                  type="date"
                  value={formData.startDate}
                  onChange={handleChange}
                  labelType="nested"
                />
                <InputField
                  label="End Date"
                  name="endDate"
                  placeholder="eg. 10/16/2024"
                  type="date"
                  value={formData.endDate}
                  onChange={handleChange}
                  labelType="nested"
                />

                <Button
                  style={{ marginTop: "10px" }}
                  type="submit"
                  label="Save"
                />
              </form>
            )}

            {/* Table */}
            {!showForm && (
              <Table
                data={data}
                header={header}
                sn
                customTdWrappers={[
                  {
                    element: (text, row) => (
                      <div style={{ fontSize: "12px" }}>
                        {`${row?.isActive}` === "true" ? (
                          <p style={{ color: "teal" }}>Active</p>
                        ) : (
                          <p style={{ color: "tomato" }}>Inactive</p>
                        )}
                      </div>
                    ),
                    rowKeyName: "isActive",
                  },
                  {
                    element: (text, row) => (
                      <div style={{ fontSize: "12px" }}>{text || "-"}</div>
                    ),
                    rowKeyName: "modifiedBy",
                  },
                  {
                    element: (text, row) => (
                      <div style={{ fontSize: "12px" }}>{row?.endDate}</div>
                    ),
                    rowKeyName: "expiry",
                  },
                ]}
                customColumn={[
                  {
                    element: (row: any) => (
                      <button className="edit-price">
                        <FiEdit2 size={15} />
                        <span>Edit</span>
                      </button>
                    ),
                    keyName: "status",
                    title: () => "",
                    position: "end",
                    onClick: (row: any) => {
                      setActiveRow(row);
                      setShowModal(true);
                    },
                  },
                ]}
                onRowClick={(row) => {
                  setActiveRow(row);
                  setShowModal(true);
                }}
                hidePagination
                showSearchBar
                searchPlaceholder="promo"
              />
            )}
          </>
        )}
      </div>

      {showModal && (
        <PropmoModal
          activeRow={activeRow}
          onClose={() => setShowModal(false)}
          title={"Update Promo"}
          btnLabel={"Save Changes"}
          onActionBtnClick={(data) => handleUpdatePromo(data)}
          inputProps={{
            formatValue: "text",
          }}
        />
      )}
    </div>
  );
};

//   {
//     "amount": 100,
//     "name": "Test",
//     "estateId": "65074efaa93976573f2f6dcb",
//     "productType": "gas",
//     "endDate": "10/16/2024",
//     "startDate": "10/28/2024" mm/dd/yyyy
// }

// {
//     "id": "670f74d4d4298a141a407b8f",
//     "name": "Oct Promo",
//     "maxKg": 10
// }
