import React, { useContext, useEffect } from 'react'
import AppContext from '../../../context/app-context';

const VendorFinanceInfo = ({ selectedVendor }) => {
  const { resolveBackendUrl, backendServer, setIsLoading } = useContext(AppContext);
  return (
    <div className='finance-info'>
      <div className='finance-heading'><b className='ml-1'>Payout Account Details</b></div>
      <div className='financial-info-section'>
      {selectedVendor?.bank_accounts?.length > 0 ? (
  selectedVendor?.bank_accounts.map((account, index) => (
    <div key={index} className='finance-info-container'>
      <div className='finance-data'>
        <div style={{ color: '#050505' }} className='ml-1'>
          Payout Bank
        </div>
        <div style={{ color: '#5F5E5E', fontSize: '14px' }} className='ml-1'>
          {account?.bank_name}
        </div>
      </div>

      <div className='finance-data'>
        <div style={{ color: '#050505' }} className='ml-1'>
          Account Number
        </div>
        <div style={{ color: '#5F5E5E', fontSize: '14px' }} className='ml-1'>
          {account?.account_number}
        </div>
      </div>

      <div className='finance-data'>
        <div style={{ color: '#050505' }} className='ml-1'>
          Account Name
        </div>
        <div style={{ color: '#5F5E5E', fontSize: '14px' }} className='ml-1'>
          {account?.account_name}
        </div>
      </div>
    </div>
  ))
) : (
  <div className='finance-data'>
    <div style={{ color: '#050505' }} className='ml-1'>
      No Payout Account
    </div>
  </div>
)}
</div>

      <div className='finance-data'>
        <div className='finance-heading'><b className='ml-1'>Confirmed Payout</b></div>
        <div className='payout-data ml-1'><b>NGN 0</b></div>
      </div>

      <div className='finance-data'>
        <div className='finance-heading'><b className='ml-1'>Total</b></div>
        <div className='payout-data ml-1'><b>NGN 0</b></div>
      </div>
    </div>
  )
}

export default VendorFinanceInfo