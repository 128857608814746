import React, { useState, useContext, useEffect } from "react";
import "../../../../styles/index.css";
import "../../estatemgt.css";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import { AllAdminTable } from "../../../../components/alladmin-table/alladmin-table";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../../components/searchInput/SearchInput.tsx";
import { IoMdArrowBack } from "react-icons/io";

const EachEstate = function ({ display, setScreen, screen, setOnShow }) {
  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [allAdminData, setallAdminData] = useState(null);
  const [oringinalData, setOriginalData] = useState([]);
  const [fullEstateStat, setFullEstateStat] = useState([]);

  const { setIsLoading, patternMatching, resolveBackendUrl } = useContext(AppContext);

  useEffect(() => {
    getData();
  }, [screen?.data]);

  async function getData(noLoader) {
    if (!display) return;
    setPage(0);
    try {
      if (!noLoader) setIsLoading(true);
      const returned = await (
        await fetch(`${resolveBackendUrl(screen?.data)}/estate/get/fullstat`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setFullEstateStat(returned?.data);

      const returnedAdmins = await (
        await fetch(`${resolveBackendUrl(screen?.data)}/user/get?role=admin`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setallAdminData(
        returnedAdmins?.data?.map((e) => {
          e.estate = returned?.data?.full_estate_details?.estate_name;
          return e;
        })
      );

      setOriginalData(
        returnedAdmins?.data?.map((e) => {
          e.estate = returned?.data?.full_estate_details?.estate_name;
          return e;
        })
      );

      workCSV(returnedAdmins?.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estate Data");
    }
  }

  function workCSV(data) {
    setcsvData([
      ["Name", "Email", "Role", "Estate", "Last Active"],
      ...data?.map(({ first_name, last_name, estate, createdAt, estate_child_roles, email }) => {
        return [first_name + " " + last_name, email, estate_child_roles[0], estate, createdAt];
      }),
    ]);
  }

  function searchPlease(e) {
    setPage(0);
    const query = e.target.value;

    if (!query) return getData(true);

    const foundUsers = oringinalData?.filter((e) => {
      return (
        patternMatching(query, e.first_name + ` ${e.last_name}`) ||
        patternMatching(query?.split(" ")[0], e.first_name) ||
        patternMatching(query?.split(" ")[0], e.last_name) ||
        patternMatching(query?.split(" ")[1], e.first_name) ||
        patternMatching(query?.split(" ")[1], e.last_name) ||
        patternMatching(query, e?._id) ||
        patternMatching(query, e?.estate) ||
        patternMatching(query, e?.estate_child_roles[0]) ||
        patternMatching(query, e?.email)
      );
    });

    setallAdminData(foundUsers);
    workCSV(foundUsers);
  }

  async function reinstateRevokeAccess(userID, estatedID, is_active) {
    try {
      setIsLoading(true);
      let url;
      if (is_active) url = `${resolveBackendUrl(estatedID)}/admin/action/${userID}`;
      else url = `${resolveBackendUrl(estatedID)}/admin/action/${userID}/true`;

      const returned = await (
        await fetch(url, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup(`Admin ${is_active ? "Revoked" : "Re-Instated"}!`);
      getData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="estates-stat-container flex-row">
        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p
            onClick={() =>
              setScreen({
                screen: "home",
                data: null,
              })
            }
            className="small removemargin cursor"
          >
            <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Back
          </p>
        </div>

        <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
          <p className="h5 boldText blackText">
            {fullEstateStat?.full_estate_details?.estate_name}
          </p>
        </div>

        <div className="each-e-stat-container">
          <img src="/property-imgs/p-1.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Properties<br></br>
            <span className="big e-stat-text">
              {fullEstateStat?.properties?.total_number_of_properties || "-"}
            </span>
          </p>
        </div>

        <div className="each-e-stat-container cursor" onClick={() => setOnShow("estate-residents")}>
          <img src="/property-imgs/p-2.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Residents<br></br>
            <span className="big e-stat-text">
              {fullEstateStat?.total_number_of_residents || "-"}
            </span>
          </p>
        </div>

        <div className="each-e-stat-container">
          <img src="/property-imgs/p-3.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            Units<br></br>
            <span className="big e-stat-text">
              {fullEstateStat?.properties?.total_number_of_properties || "-"}
            </span>
          </p>
        </div>

        <div className="each-e-stat-container">
          <img src="/property-imgs/p-4.png" alt="estility-estates" />
          <p className="small greyText removemargin">
            EMS Users<br></br>
            <span className="big e-stat-text">
              {fullEstateStat?.total_number_of_estate_admins || "-"}
            </span>
          </p>
        </div>
      </div>

      <div
        className="flex-row space-between no-padding cursor"
        style={{ marginTop: 25, marginLeft: 10, marginBottom: 0 }}
      >
        <p className="small removemargin boldText blackText">EMS Users</p>
      </div>

      <div className="e-table-container" style={{ marginTop: 0 }}>
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for a user" onChange={searchPlease} />
          <CSVLink className="cursor small csv-link" filename={`Estates Admin Data`} data={csvData}>
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>

          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData(false)}
          />
        </div>
        <AllAdminTable
          page={page}
          setPage={setPage}
          data={allAdminData}
          reinstateRevokeAccess={reinstateRevokeAccess}
        />
      </div>
    </div>
  );
};

export default EachEstate;
