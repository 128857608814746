import { useState, useContext, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";

const AssignStock = ({ isOpen, setIsOpen, selectedVendor, fetchVendors }) => {
  const { resolveBackendUrl, setIsLoading, isLoading } = useContext(AppContext);

  const [accountInfo, setAccountInfo] = useState({
    first_name: selectedVendor?.vendor_owner?.first_name || '',
    last_name: selectedVendor?.vendor_owner?.last_name || '',
    mobile: selectedVendor?.vendor_owner?.mobile || '',
    email: selectedVendor?.vendor_owner?.email || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const url = `${resolveBackendUrl(selectedVendor?.estateID)}/vendor/details/update/${selectedVendor?._id}`;
  const data = {
    vendor_owner: accountInfo
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify(data),
      });

      const returned = await response.json();
      await fetchVendors()
      popup(returned?.status || "Record Updated successfully");
      setIsOpen(false);
      setIsLoading(false);

    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  const regionInfo = {
    name: 'Estility Gas Orchid',
    quantityAvailable: 100,
    availableForRegion: 21.0,
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="add-region-info-div">
          <div className="add-region-heading-div">
            <div className="add-region-heading">Assign Inventory to Region</div>
            <FaTimes onClick={() => setIsOpen(false)} cursor='pointer' />
          </div>
          <div>
          
            <div><span className="region-name">Region Name:</span>{" "} <span className="region-data">{regionInfo?.name}</span> </div>
            <div><span className="region-name">Availabe in Warehouse:</span>{" "} <span className="region-data">{regionInfo?.quantityAvailable}KG</span> </div>
            <div><span className="region-name">Available Stock for the region (KG):</span>{" "} <span className="region-data">{regionInfo?.availableForRegion}KG</span> </div>
          </div>
          <form onSubmit={handleSubmit} className="add-region-info-form mt-3">
           
            <div className="add-region-form-input-div">
              <label>Batch to Allocate from</label>
              <select className="add-stock-select">
                <option>Batch 1</option>
                <option>Batch 2</option>
                <option>Batch 3</option>
              </select>
            </div>
            <div className="add-region-form-input-div">
              <label>Amount to Assign</label>
              <select>
                <option>1KG</option>
                <option>2KG</option>
                <option>3KG</option>
              </select>
            </div>
            <div>
              <div style={{ gap: '10px'}} className="d-flex align-items-center justify-content-between">
                <input type='checkbox' /> 
                <span className="notify-text">Roll over the current Available Stock for the region (KG) from the previous allocation</span>
                </div>
            </div>
            <button type="submit">Assign To Region</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssignStock;

