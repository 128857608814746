import React, { useContext, useEffect } from "react";
import "./RiderDetailCard.css";
import AppContext from "../../context/app-context";

type Props = {
  name?: string;
  phone?: string;
  imgUrl?: string;
  busType?: string;
  active?: boolean;
  onClick?: () => void;
  estateID?: string;
};

function RiderDetailCard(props: Props) {
  const { name, phone, imgUrl, busType, active, onClick, estateID } = props;
  const { getSignedAwsUrl, resolveBackendUrl } = useContext(AppContext);
  const [imgSrc, setImgSrc] = React.useState("");

  useEffect(() => {
    (async function () {
      setImgSrc(await getSignedAwsUrl(imgUrl, "profile-pictures", resolveBackendUrl(estateID)));
    })();
  }, [getSignedAwsUrl, imgUrl]);

  return (
    <div
      className={`rider-details-card ${active && "rider-details-card-active"}`}
      onClick={() => onClick && onClick()}
    >
      <div className="rider-details-card-contact-wrapper">
        <img
          className="rider-detail-card-img"
          alt="rider-profile"
          src={imgSrc || "/user_placeholder.png"}
        />
        <div className="rider-derails-card-contact">
          <div className="rider-details-card-contact-name">{name}</div>
          <div className="rider-details-card-contact-phone">{phone}</div>
        </div>
      </div>
      <div className="rider-details-card-bus-type">{busType || "Mini Bus"}</div>
    </div>
  );
}

export default RiderDetailCard;
