import { ITablePrinterCustomKeys } from "./tablePrinter/TablePrinter";

type IShortenText = {
  text: string;
  maxLen: number;
  replacer?: string | any;
  callback?: (fullText: string) => void;
};

export const shortenText = (args: IShortenText) => {
  if (args.text.length < args.maxLen) {
    return { text: args.text, callback: null };
  } else {
    return {
      text: args.text.slice(0, args.maxLen) + (args.replacer || "..."),
      callback: args.callback && args.callback(args.text),
    };
  }
};

export const removeProperty = <T, K extends keyof T>(
  arr: T[],
  propName: K
): Omit<T, K>[] => {
  return arr.map(({ [propName]: prop, ...rest }) => rest);
};

type DataItem = {
  [key: string]: any;
};

export type TableHeaderItem = {
  key: string;
  label: string;
  minWidth?: any;
};

export const transformDataDTO = (
  data: DataItem[],
  tableHeader: TableHeaderItem[]
): DataItem[] => {
  return data.map((item) => {
    const transformedItem: DataItem = {};

    tableHeader.forEach(({ key, label }) => {
      transformedItem[key] = item[key];
    });

    return { ...transformedItem, ...item };
  });
};

export const isTableHeaderArrayOfTItem = (
  input: string[] | TableHeaderItem[]
): input is TableHeaderItem[] => {
  return (
    Array.isArray(input) &&
    input.every(
      (item) => typeof item === "object" && "key" in item && "label" in item
    )
  );
};
export const isEachTableHeaderArrayOfTItem = (
  input: string | TableHeaderItem
): input is TableHeaderItem => {
  return typeof input === "object" && "key" in input && "label" in input;
};

export const transformITablePrinterCustomKeysToTableHeaderItem = (
  arg: TableHeaderItem[]
): ITablePrinterCustomKeys[] => {
  return arg.map((e) => ({ key: e.key, replace: e.label }));
};

export const transformArray = (keys: ITablePrinterCustomKeys[], data: any[]): any[] =>  {
  return data.map((obj) => {
    let newObj: any = {};
    keys.forEach(({ key, replace }) => {
      newObj[replace] = obj[key];
    });
    return newObj;
  });
}
