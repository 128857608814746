interface ValidationError {
  errorMsg: string;
  error: boolean;
  field: string;
}

const requiredFields = {
  product_name: "Product Name",
  minimumPrice: "Minimum Price",
  description: "Description",
  tags: "Product Tag",
  quantityAvailable: "Quantity Available",
};

export const productSchemaCheck = (arg: {
  [key: string]: any;
}): ValidationError => {
  for (const field of Object.keys(requiredFields)) {
    if (!validateField(arg[field])) {
      const fieldLabel = requiredFields[field as keyof typeof requiredFields];
      return {
        errorMsg: `${fieldLabel} is required`,
        error: true,
        field,
      };
    }
  }

  return {
    errorMsg: "",
    error: false,
    field: "",
  };
};

function validateField(value: any) {
  return value !== undefined && value.trim().length > 0;
}
