import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./add-a-vendor-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";

const AddVendorUser = function ({ url, vendorID, setOnView }) {
  const { setIsLoading, validateEmail, validateMobile } = useContext(AppContext);

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");

  const [disableBtn, setdisableBtn] = useState(true);

  const [whatsWrong, setWhatsWrong] = useState("");

  function clearFields() {
    setemail("");
    setmobile("");
    setlastName("");
    setfirstName("");
  }

  function runValidation() {
    if (!firstName) return setWhatsWrong("We need the user's first name");

    if (!lastName) return setWhatsWrong("We need the user's last name");

    if (!email) return setWhatsWrong("You have a missing user Owner Email Adress");

    if (!validateEmail(email)) return setWhatsWrong("You have an invalid user Owner Email Adress");

    if (!mobile) return setWhatsWrong("You have a missing user Owner Mobile Number");

    if (!validateMobile(mobile))
      return setWhatsWrong("You have an invalid user Owner Mobile Number");

    setWhatsWrong("");
  }

  useEffect(() => {
    runValidation();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobile ||
      !validateEmail(email) ||
      !validateMobile(mobile)
    )
      setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function addUser() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${url}/vendor/doall/${vendorID?._id}`, {
          method: "PUT",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            email: email,
            mobile: mobile,
            address: {
              addressString: vendorID?.vendor_address?.addressString,
              geoLocation: {
                coordinates: ["", ""],
              },
              postCode: "000000",
            },
            login_token: {
              token_id: "1",
              token: "1",
            },
          }),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setIsLoading(false);
      clearFields();
      setOnView("step-3");
    } catch (err) {
      setIsLoading(false);
      popup("Error creating vendor, try again later");
    }
  }

  return (
    <div className="flex-column align-column-left a-little-gap">
      <div
        className="flex-row"
        style={{ marginTop: 20, justifyContent: "space-between", padding: "0px 20px 0px 20px" }}
      >
        <p className="small removemargin boldText blackText">Add User</p>
        <p
          onClick={() => {
            clearFields();
            setOnView("step-3");
          }}
          className="small cursor removemargin"
          style={{ color: "blue" }}
        >
          Skip
        </p>
      </div>

      <div className="flex-column v-input-col">
        <input
          className="in a-a-u-inputs"
          placeholder="First Name"
          type="text"
          value={firstName}
          onChange={(e) => setfirstName(e.target.value)}
        />
        <input
          className="in a-a-u-inputs"
          placeholder="Last Name"
          type="text"
          maxLength="100"
          value={lastName}
          onChange={(e) => setlastName(e.target.value)}
        />
        <input
          className="in a-a-u-inputs"
          placeholder="Email"
          type="email"
          maxLength="100"
          value={email}
          onChange={(e) => {
            setemail(e.target.value);
          }}
        />
        <input
          className="in a-a-u-inputs"
          placeholder="Mobile"
          type="text"
          value={mobile}
          onChange={(e) => {
            setmobile(e.target.value);
          }}
        />
      </div>

      <div className="flex-row" style={{ paddingBottom: 20 }}>
        <button disabled={disableBtn} onClick={addUser} className="themeBtn a-a-u-btn">
          Add User
        </button>
      </div>

      <div className="flex-row" style={{ margin: -10 }}>
        <p style={{ marginTop: -15 }} className="small redText removemargin">
          {whatsWrong}
        </p>
      </div>
    </div>
  );
};

export { AddVendorUser };
