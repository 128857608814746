import React, { useState } from "react";
import "../../../styles/index.css";
import "../estatemgt.css";
import EachEstate from "./kids/each-estate";
import EstateResidents from "./kids/estate-residents";
import UserTransactions from "./kids/transactions";

const AnEstate = function ({ display, setScreen, screen }) {
  const [onShow, setOnShow] = useState("each-estate");
  const [userTransactionData, setUserTransactionData] = useState({});

  if (!display) return;

  return (
    <>
      <EachEstate
        setOnShow={setOnShow}
        setScreen={setScreen}
        screen={screen}
        display={onShow === "each-estate" && true}
      />
      <EstateResidents
        setOnShow={setOnShow}
        screen={screen}
        setScreen={setScreen}
        display={onShow === "estate-residents" && true}
        setUserTransactionData={setUserTransactionData}
      />
      <UserTransactions
        data={userTransactionData}
        setOnShow={setOnShow}
        display={onShow === "transactions" && true}
        setUserTransactionData={setUserTransactionData}
      />
    </>
  );
};

export default AnEstate;
