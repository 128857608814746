const columns = [
  {
    id: "estate_checkbox",
    label: (
      <input
        id="estate-checkbox-parent"
        type="checkbox"
        className="table-checkboxes estate-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "estate_id", label: "Estate ID", minWidth: 170 },
  { id: "estate_name", label: "Estate Name", minWidth: 170 },
  {
    id: "properties",
    label: "Properties",
    minWidth: 100,
    align: "left",
  },
  {
    id: "residents",
    label: "Residents",
    minWidth: 100,
    align: "center",
  },
  {
    id: "dues",
    label: "Dues",
    minWidth: 100,
    align: "center",
  },
  {
    id: "admin",
    label: "Admin",
    minWidth: 100,
    align: "center",
  },
  {
    id: "payouts",
    label: "Payouts",
    minWidth: 100,
    align: "center",
  },
];

function sortData(data) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      estate_checkbox: (
        <input
          data={e?._id}
          id="estate-checkbox-children"
          type="checkbox"
          className="table-checkboxes estate-table-checkboxes"
        />
      ),
      estate_id: e?.stat?.estateExtendedData?._id,
      estate_name: e?.estateDetails?.estate_name,
      properties: e?.stat?.totalNumberOfProperties,
      residents: e?.stat?.totalNumberOfResidents,
      dues: e?.stat?.totalNumberOfDues,
      admin: e?.stat?.allAdminsData?.length,
      payouts: "-",
    };
  });

  return finalArray;
}

export { sortData, columns };
