import React, { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import InputField from "../../../../components/inputField/InputField";
import AppContext from "../../../../context/app-context";
import SelectInput from "../../../../components/selectInput/SelectInput";
import "./Transfer.css";
import { formatToMoney } from "../../../../utils";
import { fetchApi } from "../../../../api";
import Button from "../../../../components/button/Button";
import { FaUser, FaWallet } from "react-icons/fa";
import { popup } from "../../../../vanilla-functions/model";

type Props = {
  onClickBack?: () => void;
};

export const ChargeUser = (props: Props) => {
  const { onClickBack } = props;

  const { setIsLoading, estates } = useContext(AppContext);

  const [estate, setEstate] = useState<any>();
  const [users, setUsers] = useState<any[]>([]);
  const [narration, setNarration] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [amount, setAmount] = useState("");
  const [fromUser, setFromUser] = useState<any>("");

  const getUsers = async () => {
    try {
      if (!estate?.backend_base_url || !estate?.estate_id) return;
      setIsLoading?.(true);
      const url = `${estate?.backend_base_url}/user/get?estateID=${estate?.estate_id}`;
      const { data } = await fetchApi({ url, method: "GET" });
      setIsLoading?.(false);
      setUsers(data?.data || []);
      console.log(data?.data[0]);
    } catch (error) {
      setIsLoading?.(false);
    }
  };

  useEffect(() => {
    (async () => {
      setUsers([])
      try {
        if (!estate?.backend_base_url || !estate?.estate_id) return;
        setIsLoading?.(true);
        const url = `${estate?.backend_base_url}/user/get?estateID=${estate?.estate_id}`;
        const { data } = await fetchApi({ url, method: "GET" });
        setIsLoading?.(false);
        setUsers(data?.data || []);
        console.log(data?.data[0]);
      } catch (error) {
        setIsLoading?.(false);
      }
    })();
  }, [estate, setIsLoading]);

  const handleCharge = async () => {
    alert("ll")
    if (!estate?.backend_base_url || !estate?.estate_id) return;
    alert("22")
    try {
      const input = window.prompt("Kindly Supply passkey", "");
      alert("33")
      if (input !== "EST424") return;
      const url = `${estate?.backend_base_url}/charge`;

      const payload = {
        userToCharge: fromUser?._id,
        amount: amount,
        transactionTitle: narration,
        narration: narration,
      };
      setIsLoading?.(true);
      const { data, hasError } = await fetchApi({
        url,
        method: "POST",
        body: payload,
      });

      console.log(data)
      setIsLoading?.(false);
      popup(data?.message || data?.status);
      if (!hasError) {
        setFromUser(null);
        setAmount("");
        setNarration("");
        setTitle("");
        getUsers();
      }
    } catch (error: any) {
      popup(error?.message);
      setIsLoading?.(false);
    }
  };

  const disable =
    parseFloat(fromUser?.wallet?.balance || "0") < parseFloat(amount) ||
    !fromUser ||
    !estate ||
    !amount ||
    parseFloat(amount) <= 0;

  return (
    <div>
      <div className="uh-container">
        <div className="financemgt-back-btn" onClick={() => onClickBack?.()}>
          <MdKeyboardArrowLeft size={25} />
          Back{" "}
        </div>
        <div className="estate-incentive-label">Wallet Charge User - Finance</div>
        <SelectInput
          name="estateName"
          options={estates}
          keyToRender="estate_name"
          keyValue="estate_id"
          placeholder="Select an estate"
          value={estate?.estate_name}
          onChange={(e) => {
            const val = estates?.find(
              (j: any) => j?.estate_id === e?.target?.value
            );
            val && setEstate(val);
            setFromUser(null);
            setAmount("");
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "4px",
            gap: "0.7rem",
          }}
        >
          <SelectInput
            name="from"
            options={users}
            keyToRender="email"
            keyValue="_id"
            label="From:"
            placeholder="User to be debited"
            labelType="nested"
            value={`${fromUser?.first_name || ""} ${
              fromUser?.last_name || ""
            } ${fromUser?.email ? ` (${fromUser?.email})` : ""}`}
            onChange={(e) => {
              const usr = users?.find((j) => j?._id === e.target?.value);
              if (!usr) return;
              setFromUser(usr);
            }}
          />
        </div>

        {fromUser && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              padding: "5px",
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "5px",
            }}
          >
            From:
            <FaUser />
            {fromUser?.first_name} {fromUser?.last_name}
            {"    -   "}
            <FaWallet />
            NGN {formatToMoney(fromUser?.wallet?.balance || "0")}
          </div>
        )}

        {fromUser && (
          <>
            <InputField
              label="Amount To Transfer"
              labelType="nested"
              name="amount"
              value={amount}
              formatValue="number"
              onChange={(e) => {
                const str = e.target.value;
                setAmount(str);
              }}
            />
            {parseFloat(fromUser?.wallet?.balance || "0") <
              parseFloat(amount) && (
              <i style={{ color: "red", fontSize: "12px" }}>
                Insufficient fund
              </i>
            )}
          </>
        )}
        {fromUser && (
          <>
            <InputField
              label="Transaction Title"
              labelType="nested"
              name="title"
              value={title}
              onChange={(e) => {
                const str = e.target.value;
                setTitle(str);
              }}
            />
          </>
        )}
        {fromUser && (
          <>
            <InputField
              label="Narration"
              labelType="nested"
              name="narration"
              value={narration}
              onChange={(e) => {
                const str = e.target.value;
                setNarration(str);
              }}
            />
          </>
        )}

        {!disable && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "1rem",
              padding: "5px",
              backgroundColor: "rgba(0,0,0,0.05)",
              borderRadius: "5px",
            }}
          >
            Charge  {fromUser?.first_name} {fromUser?.last_name} NGN{formatToMoney(amount || "0")}?           
          </div>
        )}

        <Button
          label="Transfer"
          disable={disable}
          onClick={() => handleCharge()}
        />
      </div>
    </div>
  );
};
