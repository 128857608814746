import React, { useState, useContext } from "react";
import "../../styles/index.css";
import "./ordermgt.css";
import AppContext from "../../context/app-context";
import OrderTable from "./children/orderTable/OrderTable";

const Ordermgt = function ({ display }) {
  const { backendServer } = useContext(AppContext);

  const [activeScreen, setActiveScreen] = useState(0);

  if (!display) return;

  const screens = [<OrderTable />];
  return <div className="screen-container">{screens[activeScreen]}</div>;
};

export default Ordermgt;
