export const calculateVendorStats = (
    data: any[]
  ): {
    totalVendor: number;
    groceryVendor: number;
    gasVendor: number;
  } => {
    const totalVendor = data.length;
    const groceryVendor = data.filter((vendor) => `${vendor.vendor_category}`.toLowerCase() === "groceries").length;
    const gasVendor = data.filter((vendor) => `${vendor.vendor_category}`.toLowerCase() === "gas").length;
    return {
      totalVendor,
      groceryVendor,
      gasVendor,
    };
  };