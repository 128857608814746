import React, { useContext } from "react";
import "./OrderOverviewModal.css";
import InputField, { IInputField } from "../../inputField/InputField";
import Button from "../../button/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdRadioButtonOn } from "react-icons/io";
import UserDetailCard from "../../userDetailCard/UserDetailCard";
import { ArrowSvg, BikeSvg, BoxSvg, NoteSvg } from "./svg/OrderSvg";
import { capitalizeEachWord, formatDateToTime, formatToMoney } from "../../../utils";
import { FaRegCheckCircle } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import AppContext from "../../../context/app-context";
import { CiClock2 } from "react-icons/ci";

type Props = {
  onClose?: () => void;
  row: any;
  onCancelOrderClick?: (arg?: any) => void;
  onAssignRiderClick?: (arg?: any) => void;
  onChangeOrderStats?: (arg?: any) => void;
};

function OrderOverviewModal(props: Props) {
  const { onClose, onAssignRiderClick, onCancelOrderClick, row, onChangeOrderStats } = props;

  const { resolveBackendUrl } = useContext(AppContext);

  const backendServerUrl = (estateId: string) => resolveBackendUrl(estateId || "");

  const { buyer, deliveryAddress, rider, status, estateID, orderType } = row;

  const getItemsTotal = (arg: any): number => {
    const { products, deliveryFee } = arg;
    let total = 0;
    products.map((product: any) => {
      total += product?.productID?.minimumPrice * product?.quantity;
    });
    return total + deliveryFee;
  };

  return (
    <div
      className="order-overview-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="order-overview-modal-container">
        <div className="order-overview-modal-header">
          <div>Order Overview</div>
        </div>

        <div className="order-overview-content-wrapper">
          <div className="order-overview-details">
            <UserDetailCard
              title="Resident Details"
              fullName={`${buyer?.first_name} ${buyer?.last_name}`}
              phoneNumber={buyer?.mobile}
              address={deliveryAddress?.addressString}
              imageUrl={buyer?.profile_picture}
              baseUrl={backendServerUrl(buyer?.estateID)}
              email={buyer?.email}
            />
            <UserDetailCard
              title="Rider Details"
              fullName={`${rider?.first_name || ""} ${rider?.last_name || ""}`}
              phoneNumber={rider?.mobile}
              imageUrl={rider?.profile_picture}
              address={capitalizeEachWord(status)}
              baseUrl={backendServerUrl(rider?.estateID)}
              email={rider?.email}
            />
          </div>

          <div className="order-overview-sub-header">
            <div className="order-overview-sub-header-title">Order Summary</div>
            <div className="order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="order-overview-summary-wrapper">
            <div className="order-overview-summary-title">Delivery ID: {row?._id}</div>
            <div className="order-overview-summary-from-to">
              <div>From {row?.products[0]?.vendorID?.vendor_name} </div>
              <ArrowSvg />
              <div>To {row?.deliveryAddress?.addressString}</div>
            </div>

            <div className="order-overview-summary-progress-wrapper">
              <div className="order-overview-summary-progress-line">
                <IoMdRadioButtonOn className="order-overview-summary-progress-line-child" />
                <IoMdRadioButtonOn className="order-overview-summary-progress-line-child" />
                <IoMdRadioButtonOn className="order-overview-summary-progress-line-child" />
              </div>

              <div className="order-overview-summary-progress-icon-wrapper">
                <div>
                  <NoteSvg />
                  <div className="order-overview-summary-progress-icon-label">Order Placed</div>
                  <div className="order-overview-summary-progress-time">
                    <CiClock2 />
                    {formatDateToTime(new Date(row?.createdAt))}
                  </div>
                </div>
                <div>
                  <BikeSvg />
                  <div className="order-overview-summary-progress-icon-label">Order Picked Up</div>
                  <div className="order-overview-summary-progress-time"></div>
                </div>
                <div>
                  <BoxSvg />
                  <div className="order-overview-summary-progress-icon-label">Order Delivered</div>
                  <div className="order-overview-summary-progress-time">
                    <CiClock2 />
                    {row?.completedAt ? formatDateToTime(new Date(row?.completedAt)) : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="order-overview-sub-header">
            <div className="order-overview-sub-header-title">Order Note</div>
            <div className="order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="order-overview-order-notes">{row?.orderNotes || " - "}</div>

          <div className="order-overview-sub-header">
            <div className="order-overview-sub-header-title">Order details</div>
            <div className="order-overview-sub-header-line">
              <div />
            </div>
          </div>

          <div className="order-overview-details-wrapper">
            <div>
              <div>Items</div>
              <div>Price</div>
            </div>

            {row?.products.map((product: any, index: number) => (
              <div className="order-overview-details-item" key={index}>
                <div>
                  {row?.orderType.toString().toLowerCase() === "gas"
                    ? `${product?.quantity}${product?.weight_or_size_or_type?.unit} Gas`
                    : product.productID?.product_name + ` (X${product?.quantity})`}
                </div>
                <div>
                  NGN
                  {`${row?.orderType}`.toLowerCase() === "gas"
                    ? formatToMoney(row?.totalAmount - row?.deliveryFee)
                    : formatToMoney(product?.productID?.minimumPrice * product?.quantity)}
                </div>
              </div>
            ))}

            <div className="order-overview-details-item">
              <div>Delivery</div>
              <div>NGN{formatToMoney(row?.deliveryFee)}</div>
            </div>

            <div className="order-overview-details-total">
              <div>Grand Total</div>
              <div>NGN{formatToMoney(row?.totalAmount)}</div>
            </div>
          </div>

          <div className="order-overview-btn-wrapper">
            {!rider?._id && status === "pending" && (
              <Button
                label="Assign Rider"
                btnType="outline"
                leftIcon={<FaRegCheckCircle />}
                style={{
                  color: "#5A9A8F",
                  borderColor: "#5A9A8F",
                  fontSize: "0.8rem",
                }}
                onClick={() => onAssignRiderClick && onAssignRiderClick(estateID)}
              />
            )}
            {(status === "pending" || status === "processing") && (
              <Button
                label="Mark Order as Shipped"
                btnType="outline"
                leftIcon={<FaRegCheckCircle />}
                style={{
                  color: "#5A9A8F",
                  borderColor: "#5A9A8F",
                  fontSize: "0.8rem",
                }}
                onClick={() => onChangeOrderStats && onChangeOrderStats("shipped")}
              />
            )}
            {status === "shipped" && (
              <Button
                label="Mark Order as Delivered/Complete"
                btnType="outline"
                leftIcon={<FaRegCheckCircle />}
                style={{
                  color: "#5A9A8F",
                  borderColor: "#5A9A8F",
                  fontSize: "0.8rem",
                }}
                onClick={() => onChangeOrderStats && onChangeOrderStats("complete")}
              />
            )}
            {/* <Button
              label="Cancel Order"
              btnType="outline"
              leftIcon={<FcCancel />}
              style={{
                color: "#D95069",
                borderColor: "#D95069",
                fontSize: "0.8rem",
              }}
              onClick={() => onCancelOrderClick && onCancelOrderClick()}
            /> */}
          </div>
        </div>

        <AiFillCloseCircle
          className="order-overview-modal-close-icon"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
}

export default OrderOverviewModal;
