import { useState } from "react";
import "../../styles/index.css";
import "./general-reports.css";
import GeneralReportsHome from "./children/home/gr-home";
import Reviews from "./children/reviews/reviews";
import DeleteAccountReasons from "./children/delete-reason/drt";
import ReferralsTable from "./children/referrals/referrals-table";

const GeneralReports = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <GeneralReportsHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <Reviews setScreen={setScreen} display={screen.screen === "reviews" && true} />
      <DeleteAccountReasons
        setScreen={setScreen}
        display={screen.screen === "delete-reasons" && true}
      />
      <ReferralsTable
        setScreen={setScreen}
        display={screen.screen === "referrals" && true}
      />
    </div>
  );
};

export default GeneralReports;
