import React, { useEffect } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { IQuickActionScreens } from '../FinancePage/quick.action.data';
import Table from '../../../../components/table/Table';
import './PayoutTable.css'
import { format } from 'path';
import { capitalizeEachWord, formatDateToCustomString, formatDateToTime, getColorByStatus } from '../../../../utils';
import PayoutRequestModal from '../../../../components/modal/payoutRequestModal/PayoutRequestModal';

type Props = {
    onClickBack?: () => void;
    type: IQuickActionScreens
}

const PayoutTable = (props: Props) => {
    const {onClickBack, type} = props;

    const [data, setData] = React.useState<any>([]);
    const [headers, setHeaders] = React.useState<any>([]);

    const [showRequestModal, setShowRequestModal] = React.useState<boolean>(false)

    const tableHeader = {
      estate: [  "Payout ID", "Bank Name", "Initiator", "Estate", "Amount (N)", "Date" ],
      vendor: [  "Payout ID", "Bank Name", "Initiator", "Vendor", "Amount (N)", "Status", "Date" ],
      rider: [  "Payout ID", "Bank Name", "Rider Name", "Vehicle Type", "Amount (N)", "Date"]
    }

    const getRandomStatus = (): "pending" | "approved" | "rejected" => {
      const statusOptions = ["pending", "approved", "rejected"];
      const randomIndex = Math.floor(Math.random() * statusOptions.length);
      return statusOptions[randomIndex] as any;
    };
    const generateEstateData = (): any => ({
      payoutId: `BG00${Math.floor(Math.random() * 1000)}`,
      bankName: "Guarantee Trust Bank",
      initiator: "Aspen Rosser",
      estate: "Ebeanor",
      amount: Math.floor(Math.random() * 1000) + 1,
      date: formatDateToCustomString(new Date()),
    });

    const generateVendorData = (): any => ({
      payoutId: `BG00${Math.floor(Math.random() * 1000)}`,
      bankName: "Guarantee Trust Bank",
      initiator: "Aspen Rosser",
      vendor: "Ebeanor",
      amount: Math.floor(Math.random() * 1000) + 1,
      status: getRandomStatus(),
      date: formatDateToCustomString(new Date()),
    });

    const generateRiderData = (): any => ({
      payoutId: `BG00${Math.floor(Math.random() * 1000)}`,
      bankName: "Guarantee Trust Bank",
      riderName: "Aspen Rosser",
      vehicleType: "Ebeanor",
      amount: Math.floor(Math.random() * 1000) + 1,
      date: formatDateToCustomString(new Date()),
    });

    useEffect(() => {
      // ()
      if(type === "estatePayouts" ) {
        setData(Array.from(Array(100).keys()).map(() => generateEstateData()));
        setHeaders(tableHeader.estate);
        return;
      } else if(type === "vendorPayouts" ) {
        setData(Array.from(Array(100).keys()).map(() => generateVendorData()));
        setHeaders(tableHeader.vendor);
        return;
      } else if(type === "riderPayouts" ) {
        setData(Array.from(Array(100).keys()).map(() => generateRiderData()));
        setHeaders(tableHeader.rider);
        return;
      } else {
        setData([])
        setHeaders([])
        return;
      }
    }, [])

  return (
    <div>
        <div
        className="financemgt-back-btn"
        onClick={() => onClickBack && onClickBack()}
      >
        <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <>
        {type === "estatePayouts" && <div className='payout-table-title'>Estate Payouts</div>}
        {type === "riderPayouts" && <div className='payout-table-title'>Rider Incentive</div>}
        {type === "vendorPayouts" && <div className='payout-table-title'>Vendor Payouts</div>}
      </>

      <Table data={data}
       header={headers} 
       showSearchBar
       addCheckBox
       checkAll
       onRowClick={() => setShowRequestModal(true)}
       customTdWrappers={[
        {
          element: (text) => <div style={{color: getColorByStatus(text)}}>{capitalizeEachWord(text)}</div>,
          rowKeyName: "status"
        }
       ]}
        />

        {showRequestModal && <PayoutRequestModal onClose={() => setShowRequestModal(false)} />}
      
      </div>
  )
}

export default PayoutTable