import React from "react";
import SearchInput from "../../searchInput/SearchInput";
import TablePrinter, { ITablePrinterCustomKeys } from "../tablePrinter/TablePrinter";
import { HiOutlineRefresh } from "react-icons/hi";
import "./SearchHeader.css";
import { searchNested } from "../../../utils";

type Props = {
  onSearchResult?: (data: any[]) => void;
  mainData?: any[];
  data?: any[];
  dataToPrint?: any[];
  excelTitle?: string;
  searchPlaceholder?: string;
  onRefreshIconClick?: () => void;
  searchHeaderMenus?: { right?: JSX.Element[]; left?: JSX.Element[] };
  transFormKeys?: ITablePrinterCustomKeys[]
};

const SearchHeader = (props: Props) => {
  const {
    onSearchResult,
    mainData = [],
    dataToPrint,
    excelTitle,
    searchPlaceholder,
    onRefreshIconClick,
    searchHeaderMenus,
    data,
    transFormKeys
  } = props;

  const handleSearch = (
    searchItem: string,
    mainData: any[],
    onResult: (data: any[]) => void
  ) => {
    const _filteredData = mainData.filter(item => searchNested(item, searchItem))
    onResult && onResult(_filteredData);
  };


  return (
    <div className="search-header-container">
      <SearchInput
        placeholder={searchPlaceholder || "Search for an item"}
        onChange={(e) => {
          const { value } = e.target;
          handleSearch(value, mainData, (res) => {
            onSearchResult && onSearchResult(res);
          });
          if (value === "") {
            onSearchResult && onSearchResult(mainData);
          }
        }}
      />

      <TablePrinter
        dataToPrint={dataToPrint || []}
        title={excelTitle}
        className="cursor"
        transFormKeys={transFormKeys}
      />

      <HiOutlineRefresh
        size={25}
        className="cursor"
        onClick={() => {
          onRefreshIconClick && onRefreshIconClick();
        }}
      />
      {searchHeaderMenus && searchHeaderMenus?.right && (
        <div className="search-header-container-custom-menu-wrapper">
          {searchHeaderMenus?.right &&
            searchHeaderMenus?.right.map((el, i) => (
              <React.Fragment key={i}>{el}</React.Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchHeader;
