import React, { useContext, useState } from 'react'
import "./RefundForm.css"
import AppContext from '../../../../context/app-context';
import SelectInput from '../../../../components/selectInput/SelectInput';
import InputField from '../../../../components/inputField/InputField';
import Button from '../../../../components/button/Button';
import RenderImage from '../../../../components/renderImage/RenderImage';
import { fetchApi } from '../../../../api';
import RefundCode from './refund-code';
import { MdKeyboardArrowLeft } from 'react-icons/md';

type Props = {
  onClickBack?: () => void;
}

function RefundForm({ onClickBack }: Props) {
  const [openCodeModal, setOpenCodeModal] = useState(true)
  const { backendServer, setIsLoading, resolveBackendUrl, setStoredContext, estates } =
    useContext(AppContext);

  const [estate, setEstate] = useState<any>()

  const [orderId, setOrderId] = useState<string>('')

  const [product, setProduct] = useState<string>("")

  const [order, setOrder] = useState<any>()

  const handleFetchOrder = async () => {
    const url = `${estate?.backend_base_url}/order/get/${orderId}`

    console.log('bnmmmmmmmm')

    setIsLoading?.(true)
    const { data, hasError } = await fetchApi({ url, method: "GET" })
    console.log(data)
    setIsLoading?.(false);
    if (hasError) {
      alert("Error")
      return;
    }
    setOrder(data?.data)
    // console.log("PRoduct", data?.data?.order?.products[0])


    alert("Success")
    return;

  }
  const handleRefund = async (productID: string, orderID: string) => {
    const url = `${estate?.backend_base_url}/order/return/${orderID}/${productID}`

    setIsLoading?.(true)
    const { data, hasError } = await fetchApi({ url, method: "PATCH" })
    setIsLoading?.(false);
    console.log(data)
    if (hasError) {
      alert(data?.status || "Something went wrong");
      return;
    }
    alert("Sucecss")
    return;
  }

  const sendCode = async (code: string) => {
    console.log(code)
  }

  // 6690e62c95e1afbd5902b4bd
  return (
    <>
      <div className="refund-container">
        <div className="financemgt-back-btn" onClick={() => onClickBack?.()}>
          <MdKeyboardArrowLeft size={25} />
          Back{" "}
        </div>
        <div className="estate-incentive-label">Refunds</div>
        <div className="estate-incentive-label">Estate Name: {estate?.estate_name || "-"} </div>

        <div className='refund-form'>
          <SelectInput name='estateName'
            options={estates}
            keyToRender='estate_name'
            keyValue='estate_id'
            placeholder='Select an estate'
            value={estate?.estate_name}
            onChange={(e) => {
              const val = estates?.find((j: any) => j?.estate_id === e?.target?.value);
              val && setEstate(val)

            }} />
          <InputField label='Order id' name='orderId' value={orderId} onChange={(e) => setOrderId(e.target.value)} />

          <h5>Products: {"N"+order?.totalAmount}</h5>

          <div style={{ display: "flex", flexDirection: "row", gap: "2rem", overflow: "auto"}}>

            {
              order?.products?.map((x: any, i: number) => (
                <div className='refund-orderItem' key={i} style={{color:product === x?.productID?._id? "#fff": "#000", border: "1px solid black", backgroundColor: product === x?.productID?._id? "#34204C": "transparent"}} onClick={() => {
                  setProduct(x?.productID?._id)
                }}>
                  <div>
                    {estate?.backend_base_url
                      &&
                      <RenderImage
                        baseUrl={estate?.backend_base_url}
                        imageStyle={{
                          width: "6rem",
                          height: "auto",
                          maxHeight: "5rem",
                          borderRadius: "1px",

                        }}
                        imageUrl={x?.productID?.images?.[0]}
                        alt="img"
                      />
                    }
                  </div>
                  <div>
                    <h5><b>Name:  {x?.productID?.product_name}</b></h5>
                    <div>ID: {x?.productID?._id}</div>
                    <div>Amount: {Number(x?.quantity) * Number(x?.productID?.minimumPrice)} QUT: {Number(x?.quantity)}</div>
                  </div>
                  <div>{`${product === x?.productID?._id ? "📗" : " -"}`}</div>
                </div>
              ))
            }

          </div>

          <Button disable={!orderId || !estate} label='Get Order' onClick={() => {
            handleFetchOrder()
          }} />

          <Button disable={!orderId || !estate || !product} label='Refund Product' onClick={() => {
            const confirm = window.confirm("Are you sure")
            if (confirm) {
              handleRefund(product, orderId)
            }

          }} />



        </div>

      </div>
      <RefundCode onClickBack={onClickBack} isOpen={openCodeModal} setIsOpen={setOpenCodeModal} onClick={sendCode} showSuccess={sendCode} />
    </>
  )
}

export default RefundForm