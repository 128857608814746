import React from "react";
import { CheckBox } from "../checkBox/CheckBox";
import "./CheckWithLabel.css";

type Props = {
  name?: string;
  label?: any;
  value?: boolean;
  onChange?: (target: ITarget) => void;
  rightElement?: React.ReactNode;
  disable?: boolean;
};

type ITarget = {
  name: string;
  value: boolean;
};

// ${disable? " check-with-label-disabled": ""}
const CheckWithLabel = ({ label, name = "", onChange, value, rightElement, disable = false }: Props) => {
  return (
    <div
      className={`check-with-label ${disable? " check-with-label-disabled": ""}`}
      onClick={() => !disable && onChange?.({ name, value: !value })}
    >
      <CheckBox
        checked={value}
        onCheck={(ch) => !disable && onChange?.({ name, value: ch })}
      />{" "}
      {label}{" "}
      {rightElement}
    </div>
  );
};

export default CheckWithLabel;
