import { useContext, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { PiProhibitBold } from "react-icons/pi";
import RefferalHistory from "./referral-history";
import "./referrer.css";
import ReferrerInformation from "./referrer-info";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";

const ReferralsOverview = ({ isOpen, setIsOpen, selectedReferral, earnings }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);
  const [userImage, setUserImage] = useState("");

  const [toggleButton, setToggleButton] = useState("Personal Information");

  const getSignedAwsUrl = async function (filename) {
    if (!filename) return;
    setIsLoading(true);
    const url = `${resolveBackendUrl(
      selectedReferral?.estateID
    )}/file/get/profile-pictures/${filename}`;
    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http")
      return filename;

    const { data } = await (
      await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();
    setIsLoading(false);

    return data;
  };

  useEffect(() => {
    (async () => {
      setUserImage(
        await getSignedAwsUrl(selectedReferral?.userID?.profile_picture)
      );
    })();
  }, [selectedReferral?.userID?.profile_picture]);

  function formatAmountAsNaira(totalAmount) {
    const amountInNaira = totalAmount;
    const formattedAmount = amountInNaira?.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });

    return formattedAmount;
  }
  
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="fleet-overview-container">
          <div
            className="referral-detail-header"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ flex: 1, textAlign: "center" }}>
              {selectedReferral?.userID?.first_name}{" "}
              {selectedReferral?.userID?.last_name}'s Referral Overview
            </span>
            <FaTimes
              onClick={() => setIsOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="user-name mt-3">
            <div className="referral-image">
              <img
                src={userImage || "https://via.placeholder.com/150?text=Avatar"}
                alt="userimg"
              />
            </div>
            <div>
              <div className="referral-name">
                {selectedReferral?.userID?.first_name}{" "}
                {selectedReferral?.userID?.last_name}
              </div>
              <div className="referral-number">
                <span className="referral-number">
                  {selectedReferral?.userID?.mobile}
                </span>
                {/* <span style={colorStatus(selectedReferral?.status)} className='referral-status'>{selectedReferral?.status}</span> */}
              </div>
            </div>
          </div>

          <div>
            <div className="referral-earning">
              <span className="referral-earning-text">Total Earnings:</span>
              <span className="referral-earned-amount">
                {formatAmountAsNaira(earnings || 0)}
              </span>
            </div>
          </div>
          {/* {selectedReferral?.is_active ?
          <div className='suspend'>
            <button onClick={deactivatereferral}><PiProhibitBold color='#D95069' /><span style={{ color: '#D95069'}}>Suspend</span></button>
            <button onClick={deactivatereferral}><FaTimes /> <span style={{ color: '#050505'}}>Deactivate</span></button>
          </div>
          : null } */}

          <div className="referral-information">
            <button
              onClick={() => setToggleButton("Personal Information")}
              style={{
                borderBottom:
                  toggleButton === "Personal Information"
                    ? "2px solid #444266"
                    : "",
              }}
            >
              Personal Information
            </button>
            <button
              onClick={() => setToggleButton("referrals")}
              style={{
                borderBottom:
                  toggleButton === "referrals" ? "2px solid #444266" : "",
              }}
            >
              Referrals ({selectedReferral?.users_referred.length})
            </button>
          </div>
          {toggleButton === "Personal Information" ? (
            <ReferrerInformation selectedReferral={selectedReferral} />
          ) : (
            <RefferalHistory selectedReferral={selectedReferral} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralsOverview;
