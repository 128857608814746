import React, { useState, useContext, useEffect } from "react";
import "../../../../styles/index.css";
import "../../estatemgt.css";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import { IoMdArrowBack } from "react-icons/io";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import {
  TransactionsTable,
  pseudoCheckBoxSelectedData,
} from "../../../../components/transactions-table/transactions-table";
import SearchInput from "../../../../components/searchInput/SearchInput.tsx";

const UserTransactions = function ({ display, data, setOnShow, setUserTransactionData }) {
  const [filteredWalletData, setFilteredWalletData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [checkBoxSelectedData, setCheckBoxSelectedData] = useState([]);
  const { setIsLoading, resolveBackendUrl, patternMatching } = useContext(AppContext);
  const [csvData, setcsvData] = useState([]);
  const [page, setPage] = useState(0);
  const [theUser, setTheUser] = useState({});

  function sortArrayByDate(arr) {
    const array = arr?.sort((a, b) => {
      if (a?.initiated_on > b?.initiated_on) return -1;
      else return 1;
    });

    return array;
  }

  const getTransactionData = async () => {
    try {
      setPage(0);
      pseudoCheckBoxSelectedData.length = 0;
      if (document.querySelector("#f-checkbox-parent"))
        document.querySelector("#f-checkbox-parent").checked = false;

      setIsLoading(true);

      setTheUser(data);

      const returnedDues = await (
        await fetch(
          `${resolveBackendUrl(
            data?.estateID
          )}/dues/get?allpopulateonetimepayersPlusAllpopulateontimemap=true`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken"),
            },
          }
        )
      ).json();

      const initialOneTimeDuePaymentData = [...returnedDues?.data].reverse();

      const allTransactionsRegardingTheEstate = [];

      initialOneTimeDuePaymentData.forEach((theFullDue) => {
        if (theFullDue?.bill_frequency === "One Time") {
          theFullDue.one_time_payers.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.user?.first_name + ` ${e?.user?.last_name}`,
              initiated_on: e?.paymentRef?.initiated_on,
              bill_type: theFullDue?.bill_type,
              _id: e?._id,
              status: "completed",
              amount: e?.paymentRef?.amount,
              email: e?.user?.email,
            });
          });
        } else if (theFullDue?.mappedSubscriptions) {
          theFullDue?.mappedSubscriptions?.forEach((e) => {
            allTransactionsRegardingTheEstate.push({
              name: e?.customer?.first_name + ` ${e?.customer?.last_name}`,
              initiated_on: e?.most_recent_invoice?.created_at,
              bill_type: theFullDue?.bill_type,
              _id: e?.most_recent_invoice?.invoice_code,
              status: "completed",
              amount: theFullDue?.amount,
              email: e?.customer?.email,
            });
          });
        }
      });

      const allTransactionsForTheUser = allTransactionsRegardingTheEstate.filter(
        (e) => e?.email === data?.email
      );

      const paystackTransactions = await (
        await fetch(
          `${resolveBackendUrl(data?.estateID)}/paystack/transactions/get/${data?.email}`,
          {
            credentials: "include",
            headers: {
              token: localStorage.getItem("omstoken"),
            },
          }
        )
      ).json();

      const joined = sortArrayByDate([
        ...allTransactionsForTheUser,
        ...data?.wallet?.transactions,
        ...paystackTransactions?.data?.filter((e) => e.status === "success"),
      ]);

      setFilteredWalletData(joined);
      setOriginalData(joined);
      workCsv(joined);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error finding transaction data");
    }
  };

  function workCsv(data) {
    setcsvData([
      ["Bill Type", "Transaction ID", "Status", "Date", "Amount (₦)"],
      ...data?.map(
        ({ bill_type, _id, status, initiated_on, amount, transaction_type, paid_at }) => {
          return [
            bill_type || (transaction_type === "credit" ? "Wallet Funding" : "Service Purchase"),
            _id,
            status,
            paid_at ? new Date(paid_at).toDateString() : new Date(initiated_on).toDateString(),
            new Intl.NumberFormat("en-us", {
              currency: "NGN",
            }).format(amount),
          ];
        }
      ),
    ]);
  }

  useEffect(() => {
    if (!display || !data?.first_name) return;
    getTransactionData();
  }, [data, display]);

  function searchPlease(e) {
    setPage(0);
    pseudoCheckBoxSelectedData.length = 0;
    if (document.querySelector("#f-checkbox-parent"))
      document.querySelector("#f-checkbox-parent").checked = false;

    const query = e.target.value;

    if (!query) return getTransactionData();

    const foundTransactions = originalData?.filter((el) => {
      return (
        patternMatching(query, el?._id) ||
        patternMatching(
          query,
          el?.transaction_type === "credit" ? "Wallet Funding" : "Service Purchase"
        ) ||
        patternMatching(query, el?.transaction_type) ||
        patternMatching(query, el?.bill_type) ||
        patternMatching(query, el?.status)
      );
    });

    setFilteredWalletData(foundTransactions);
    workCsv(foundTransactions);
  }

  if (!display) return;

  return (
    <div>
      <div
        className="flex-row space-between no-padding cursor"
        style={{ marginBottom: 15 }}
        onClick={() => {
          setOnShow("estate-residents");
          setUserTransactionData({});
        }}
      >
        <p className="small removemargin cursor">
          <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Back
        </p>
      </div>
      <div className="flex-row align-row-left">
        <div>
          <div className="flex-row align-row-left">
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Full Name</p>
              <p className="small  removemargin">
                {theUser?.first_name} {theUser?.last_name}
              </p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Property Code</p>
              <p className="small  removemargin">{theUser?.property_code || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Unit Occupied</p>
              <p className="small  removemargin">{theUser?.property_code || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Email Address</p>
              <p className="small  removemargin">{theUser?.email || "-"}</p>
            </div>
            <div className="flex-column align-column-left width-fit-content">
              <p className="small boldText removemargin">Phone Number</p>
              <p className="small  removemargin">{theUser?.mobile || "-"}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row space-between no-padding cursor" style={{ marginTop: 15 }}>
        <p className="small removemargin boldText blackText">Recent Transactions</p>
      </div>

      <div className="thm-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for a transaction" onChange={(e) => searchPlease(e)} />
          <CSVLink
            className="cursor small csv-link"
            filename={`${data?.last_name} Transaction Data`}
            data={csvData}
          >
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>
          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getTransactionData()}
          />
        </div>
        {filteredWalletData.length === 0 ? (
          <p className="small">No Transactions yet by this user</p>
        ) : (
          <TransactionsTable
            setCheckBoxSelectedData={setCheckBoxSelectedData}
            checkBoxSelectedData={checkBoxSelectedData}
            setcsvData={setcsvData}
            data={filteredWalletData}
            page={page}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default UserTransactions;
