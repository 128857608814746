import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "../login.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";

const ResetPasswordScreen = function ({ display, setStage, email, setShowResetPassword, code }) {
  const [disableBtn, setDisableBtn] = useState(false);
  const [password, setPassword] = useState("");
  const [testPass, setTestPass] = useState("");
  const [error, setError] = useState("");

  const { backendServer, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    if (email && code && password) setDisableBtn(false);
    else setDisableBtn(true);

    if (password !== testPass && password && testPass) setError("Your passwords do not match");
    else setError("");
  });

  async function resetPassword() {
    if (password !== testPass) return popup("Passwords do not match");
    setIsLoading(true);
    try {
      const returned = await (
        await fetch(`${backendServer}/staff/password/change`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            token: code,
            password,
          }),
          credentials: "include",
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      popup("Password reset successful!");
      setStage("begin-reset");
      setShowResetPassword(false);
      setIsLoading(false);
    } catch (err) {
      popup(err.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  if (!display) return;

  return (
    <div className="login-container-one">
      <div className="wavy-container">
        <img className="wavy-img" src="/wavy.png" alt="Estility" />
        <div className="wavy-content">
          <div className="flex-row align-row-left" style={{ gap: 20, width: "50%" }}>
            <img className="wavy-logo" src="/logo.png" alt="Estility" />
            <p className="big whiteText removemargin" style={{ fontSize: "25px" }}>
              Estility
            </p>
          </div>
          {/* <p className="small blackText cursor">
                    <FiSettings style={{ marginTop: -5 }} /> &nbsp; Help
                  </p> */}
        </div>
      </div>

      <div className="login-cont">
        <p className="vbig boldText removemargin">Create New Password</p>
        <p className="small greyText removemargin">Please enter a new secure password</p>
        <p className="small removemargin redText">{error}</p>
        <input
          className="in login-in"
          type="password"
          placeholder="New Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="in login-in"
          type="password"
          placeholder="Re-enter New Password"
          onChange={(e) => setTestPass(e.target.value)}
        />

        <button disabled={disableBtn} onClick={resetPassword} className="themeBtn login-btn">
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
