/* eslint-disable guard-for-in */
export const makeArray = (
  num: number,
  range?: { from: number; to: number }
) => {
  const res = [];
  const start = (range && range.from) || 0;
  const stop = (range && range.to) || num;

  for (let i = start; i < stop; i++) {
    res.push(i);
  }
  return res;
};

type IShortenText = {
  text: string | number;
  maxLen: number;
  replacer?: string | any;
  callback?: (fullText: string) => void;
};
export const shortenText = (args: IShortenText) => {
  if (args.text.toString().length < args.maxLen) {
    return { text: args.text, callback: null };
  } else {
    return {
      text:
        args.text.toString().slice(0, args.maxLen) + (args.replacer || "..."),
      callback: args.callback && args.callback(args.text.toString()),
    };
  }
};

export const validateForm = (
  data: any[],
  emptyField?: (fName: string) => void,
  callback?: () => void
) => {
  let res = true;
  data.forEach((each) => {
    for (const key in each) {
      const value = each[key];
      if (value === "" || value === undefined || value === null) {
        emptyField && emptyField(key);
        return (res = false);
      }
    }
  });
  callback && callback();
  return res;
};

export const textSplitter = (text: string, skip: number) => {
  let res = "";
  text.split("").forEach((e, i) => {
    let curr = e;
    let cc = 0;
    if (i === skip || skip === cc) {
      curr = " ";
      cc += cc + skip;
    }
    res += curr;
  });
  return res;
};

export const makeStr = (str: string, count: number) => {
  let res = "";
  for (let i = 0; i < count; i++) {
    res += str;
  }
  return res;
};

export const formatToMoney = (sum: number | string) =>
  new Intl.NumberFormat("en-us").format(Number(sum));

export const formatDateToCustomString = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short", // Short representation of the day of the week (e.g., "Sun")
    month: "short", // Short representation of the month (e.g., "Jul")
    day: "numeric", // Numeric representation of the day (e.g., 23)
    year: "numeric", // Numeric representation of the year (e.g., 2023)
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const padZero = (number: number): string => {
  return number.toString().padStart(2, "0");
};

export const formatDateToTime = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${formattedHours}:${padZero(minutes)}${amOrPm}`;

  return formattedDate;
};

export function capitalizeEachWord(word: string) {
  const arr = word.split(" ");
  const mapped = arr.map((e) => {
    return e[0]?.toUpperCase() + e.slice(1).toLowerCase();
  });

  return mapped.join(" ");
}

export const calculatePercentage = (count: number, total: number): number => {
  if (total === 0) {
    return 0; // To avoid division by zero error
  }
  const percentage = (count / total) * 100;
  return Math.round(percentage);
};

export const getColorByStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "#FFB400";
    case "approved":
      return "#02B15A";
    case "rejected":
      return "#FF4C51";
    case "completed":
      return "#347900";
    case "shipped":
      return "#0D6A99";
    default:
      return "#FFB400";
  }
};
export const getColorBgByStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case "pending":
      return "#FFF4D9";
    case "approved":
      return "#E8FCF5";
    case "rejected":
      return "#FFE4E5";
    case "completed":
      return "#E6F7D9";
    case "shipped":
      return "#c7ecff";
    default:
      return "#FFF4D9";
  }
};

export const handleStringSearch = (
  searchItem: string,
  mainData: any[],
  onResult: (data: any[]) => void,
  useNestedSearch?: boolean
) => {
  const _filteredData = mainData.filter((item) => {
    const objectValues = Object.values(item).map((value) =>
      typeof value === "string" ? value.toLowerCase() : value
    );

    const searchValue = searchItem.toLowerCase();
    return objectValues.some((value: any) =>
      typeof value === "string"
        ? value.toLowerCase().includes(searchValue)
        : useNestedSearch
        ? searchNested(value, searchValue)
        : false
    );
  });
  onResult && onResult(_filteredData);
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result + "";
      if (base64Data) {
        resolve(base64Data);
      } else {
        reject(new Error("Base64 data not found"));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getImageUrlFromFile = (
  file: File,
  getRes?: (url: string) => void
) => {
  const reader = new FileReader();

  let res = "";

  reader.onload = (function (theFile) {
    return function (e) {
      const result = e.target?.result;
      if (typeof result === "string") {
        res = result;
        getRes && getRes(result);
      }
    };
  })(file);

  reader.readAsDataURL(file);

  return res;
};

type IValidateField = {
  fields: any[];
  onError?: (filed: string) => void;
  callback?: () => void;
};

export const validateField = ({
  fields,
  onError,
  callback,
}: IValidateField): boolean => {
  let status: boolean = true;

  fields.forEach((element) => {
    for (const key in element) {
      if (Object.prototype.hasOwnProperty.call(element, key)) {
        const val = element[key];
        if (val === "" || val === null || val === undefined) {
          status = false;
          onError && onError(key);
          return;
        }
      }
    }
  });

  status && callback && callback(); // if status is true and callback is supply

  return status;
};

export function formatDateDDMMYYY(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

interface NestedObject {
  [key: string]: string | number | boolean | NestedObject | NestedObject[];
}

export function searchNested(nestedObj: any, searchValue: string): boolean {
  if (
    typeof nestedObj === "string" ||
    typeof nestedObj === "boolean" ||
    typeof nestedObj === "number"
  ) {
    return nestedObj
      .toString()
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  }

  if (Array.isArray(nestedObj)) {
    for (let i = 0; i < nestedObj.length; i++) {
      if (searchNested(nestedObj[i], searchValue)) {
        return true;
      }
    }
    return false;
  }

  if (typeof nestedObj === "object") {
    for (let key in nestedObj) {
      if (searchNested(nestedObj[key], searchValue)) {
        return true;
      }
    }
    return false;
  }

  return false;
}

export function removeDuplicates(arr: string[], ignoreCase = false) {
  if (ignoreCase) {
    arr = arr.map((val) => val.toLowerCase());
  }

  return arr.filter((value, index) => {
    const searchValue = ignoreCase ? value.toLowerCase() : value;
    return arr.indexOf(searchValue) === index;
  });
}

export function removeDuplicatesStr(arr: string[]): string[] {
  return arr.filter(
    (value, index) =>
      arr.findIndex((item) => item.toLowerCase() === value.toLowerCase()) ===
      index
  );
}

export function convertStrToArrayOfStr(value: string | string[]) {
  return typeof value === "string"
    ? value?.split(",").filter((x) => x !== "") || []
    : Array.isArray(value)
    ? value.filter((x) => x !== "")
    : [];
}

export function isTheSame(str1: string, str2: string): boolean {
  const normalize = (str: string) => str.replace(/\s/g, "").toLowerCase();
  return normalize(str1) === normalize(str2);
}

export function removeSpace(str: string) {
  return str.replace(/\s/g, "");
}

export const spreadObj = (obj: any, keyToSpread: string) => {
  const newObj = { ...obj, ...obj[keyToSpread] };
  return newObj;
};

export const spreadListObj = (arr: any[], keyToSpread: string) => {
  return arr.map((obj) => {
    return { ...obj, ...obj[keyToSpread] };
  });
};


export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  
  // Extract the month, day, and year
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so +1
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  
  // Return the formatted date as MM/DD/YYYY
  return `${month}/${day}/${year}`;
};

// Function to convert MM/DD/YYYY to YYYY-MM-DD for the date input
export const convertDateToISO = (
  dateString: string | undefined
): string | undefined => {
  if (!dateString) return undefined;

  // Split the date string and ensure it's in the expected MM/DD/YYYY format
  const [month, day, year] = dateString.split("/");

  // Validate that the split components are valid
  if (
    !month ||
    !day ||
    !year ||
    month.length > 2 ||
    day.length > 2 ||
    year.length !== 4
  ) {
    console.error("Invalid date format. Expected MM/DD/YYYY.");
    return undefined;
  }

  // Return the date in YYYY-MM-DD format, ensuring month and day are properly padded
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};
