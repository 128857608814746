import React from "react";
import "./ImportSuccessModal.css";
import Button from "../../../button/Button";
import { AiFillCloseCircle } from "react-icons/ai";

const ImportSuccessModal = (props: {
  visible: boolean;
  onClose?: () => void;
}) => {
  const { visible, onClose } = props;

  return !visible ? null : (
    <div className="import-success-modal-overlay">
      <div className="import-success-modal-container">
        <div className="import-success-modal-title">Products Import Inprogress</div>
        <img alt="processing-import" src="/product-import/import-success.png"/>
        <Button label="Go Home" style={{width: '100%', height: '3rem'}} onClick={onClose} />

        <AiFillCloseCircle
          className="delivery-price-modal-close-icon"
          onClick={() => {
            onClose?.();
          }}
        />
      </div>
    </div>
  );
};

export default ImportSuccessModal;
