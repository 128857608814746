import { ArrowLeft } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import "./ProductEdit.css";
import { CiImageOn } from "react-icons/ci";
import { FiUploadCloud } from "react-icons/fi";
import RenderImage from "../../../../components/renderImage/RenderImage";
import { popup } from "../../../../vanilla-functions/model";
import InputField from "../../../../components/inputField/InputField";
import Button from "../../../../components/button/Button";
import AppContext from "../../../../context/app-context";
import SkillInput from "../productAdd/SkillsInput";
import {
  capitalizeEachWord,
  convertStrToArrayOfStr,
  removeDuplicates,
  removeSpace,
} from "../../../../utils";
import { fetchApi } from "../../../../api";
import CheckWithLabel from "../../../../components/checkWithLabel/CheckWithLabel";
import SelectInput from "../../../../components/selectInput/SelectInput";

type Props = {
  onNavigate: (s: number) => void;
  editProductDataFull: any;
  estateId: string;
};

const ProductEdit = ({ onNavigate, editProductDataFull, estateId }: Props) => {
  const [state, setState] = useState<any>({ _id: editProductDataFull?._id });

  const { setIsLoading, resolveBackendUrl, omsToken  } = useContext(AppContext);

  const backendServerUrl = resolveBackendUrl(estateId || "");
  const [subCategories, setSubCategories] = useState<any[]>(
    convertStrToArrayOfStr(editProductDataFull?.sub_categories)
  );
  const [lovCategories, setLovCategories] = useState<any[]>([]);

  const handleFetchCategory = async () => {
    const url = `${backendServerUrl}/product/category/get`;
    const { data, hasError } = await fetchApi({ url: url, method: "GET" });
    if (hasError) {
      return popup("Something went wrong");
    }
    const cateGories = data?.data?.map((x: any) => x.category);
    data?.data && cateGories && setLovCategories(cateGories);
  };

  useEffect(() => {
    handleFetchCategory();
  }, []);

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const url = `${backendServerUrl}/product/update/${editProductDataFull?._id}`;
      const res = await (
        await fetch(url, {
          headers: {
            token: omsToken,
            "content-type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify({
            ...state,
            sub_categories: subCategories.join(","),
          }),
        })
      ).json();
      if (res?.status === "Internal server error") throw new Error(res.message);
      popup("Product Details Updated");
      setIsLoading(false);
      onNavigate && onNavigate(1);
    } catch {
      popup("Error Update Product Details");
      setIsLoading(false);
    }
  };

  return (
    <div className="product-dashboard">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => onNavigate && onNavigate(1)}
      >
        <ArrowLeft
          size={"1.5rem"}
          onClick={() => onNavigate && onNavigate(1)}
        />{" "}
        Product Details Edit
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            gap: "1.3rem",
          }}
        >
          <InputField
            label="Product name"
            name="product_name"
            placeholder={editProductDataFull?.product_name}
            value={
              state?.product_name !== undefined
                ? state?.product_name
                : editProductDataFull?.product_name
            }
            labelType="nested"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              width: "100%",
            }}
          >
            <SelectInput
              label="Product categories"
              labelType="nested"
              name="sub_categories"
              value={subCategories.join(", ")}
              options={lovCategories}
              showSearchInput
              onChange={(e) => {
                const { value } = e?.target;
                const option = `${value}`;
                if (
                  subCategories
                    .map((j) => removeSpace(j).toLowerCase())
                    .includes(removeSpace(option).toLowerCase())
                ) {
                  setSubCategories(
                    removeDuplicates(
                      subCategories.filter(
                        (x) => removeSpace(x) !== removeSpace(option)
                      )
                    )
                  );
                } else {
                  const originalOption =
                    lovCategories.find(
                      (x) =>
                        removeSpace(x).toLowerCase() ===
                        removeSpace(option).toLowerCase()
                    ) || capitalizeEachWord(option);
                  setSubCategories((prev) => [
                    ...prev.filter(
                      (e) =>
                        removeSpace(e) !==
                        removeSpace(originalOption).toLowerCase()
                    ),
                    originalOption,
                  ]);
                }
              }}
              optionWrapper={(op, index) => {
                const option = `${op}`;
                return (
                  <CheckWithLabel
                    onChange={(e) => {
                      if (
                        subCategories
                          .map((j) => removeSpace(j).toLowerCase())
                          .includes(removeSpace(option).toLowerCase())
                      ) {
                        setSubCategories(
                          subCategories.filter(
                            (x) =>
                              removeSpace(x).toLowerCase() !==
                              removeSpace(option).toLowerCase()
                          )
                        );
                      } else {
                        setSubCategories((prv) => [
                          ...prv.filter(
                            (e) =>
                              removeSpace(e).toLowerCase() !==
                              removeSpace(option).toLowerCase()
                          ),
                          option,
                        ]);
                      }
                    }}
                    label={option}
                    key={index}
                    value={subCategories
                      .map((j) => removeSpace(j).toLowerCase())
                      .includes(removeSpace(option).toLowerCase())}
                  />
                );
              }}
            />
            {subCategories.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  height: "fit-content",
                }}
              >
                {subCategories.map((skill, index) => (
                  <span key={index} className="skill-tag">
                    {skill}
                    <button
                      onClick={() => {
                        setSubCategories(
                          subCategories.filter((x) => x !== skill)
                        );
                      }}
                    >
                      x
                    </button>
                  </span>
                ))}
              </div>
            )}
            <p
              className="small redText removemargin"
              style={{ marginTop: -10 }}
            >
              Please use the "enter" key on your keyboard to add a category
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <InputField
              label="Quantity"
              labelType="nested"
              name="quantityAvailable"
              placeholder={editProductDataFull?.quantityAvailable}
              value={
                state?.quantityAvailable !== undefined
                  ? state?.quantityAvailable
                  : editProductDataFull?.quantityAvailable
              }
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
            <InputField
              label="Price(₦)"
              name="minimumPrice"
              placeholder={editProductDataFull?.minimumPrice}
              value={
                state?.minimumPrice !== undefined
                  ? state?.minimumPrice
                  : editProductDataFull?.minimumPrice
              }
              labelType="nested"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <InputField
              label="Brand Name"
              name="brand_name"
              placeholder={editProductDataFull?.brand_name}
              value={state?.brand_name || editProductDataFull?.brand_name}
              labelType="nested"
              onChange={({ target: { name, value } }) => {
                setState((p: any) => ({ ...p, [name]: value }));
              }}
            />
          </div>

          <InputField
            label="Description"
            labelType="nested"
            placeholder={editProductDataFull?.description}
            name="description"
            value={
              state?.description !== undefined
                ? state?.description
                : editProductDataFull?.description
            }
            type="textarea"
            onChange={({ target: { name, value } }) => {
              setState((p: any) => ({ ...p, [name]: value }));
            }}
          />

          <SkillInput
            label="Product tags"
            name="tags"
            placeholder="Enter product tags"
            onSkillsChange={(_skills: string[]) => {
              const skills = removeDuplicates(_skills, true);
              const tags = skills.join(",");
              setState((p: any) => ({ ...p, tags: tags }));
            }}
            value={state["tags"] || editProductDataFull["tags"]}
          />
          <p className="small redText removemargin" style={{ marginTop: -10 }}>
            Please use the "enter" key on your keyboard to add a tag
          </p>
        </div>
        {/* image area start */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "0.5rem",
              border: "2px dotted rgba(58, 53, 65, 0.23)",
              borderRadius: "8px",
              justifyContent: "center",
              width: "22rem",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "0.5rem",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                width: "18rem",
                height: "18rem",
              }}
            >
              <RenderImage
                imageStyle={{ width: "18rem", maxHeight: "18rem" }}
                alt="product_image"
                imageUrl={editProductDataFull?.images[0]}
                baseUrl={backendServerUrl}
              />
            </div>
            <div className="product-edit-image">
              <input placeholder="" type="file" />
              <div className="product-edit-image-placeholder">
                <CiImageOn style={{ fontSize: "5rem" }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "#7975B6",
                  }}
                >
                  <FiUploadCloud size={"2rem"} />
                  Upload image
                </div>
                <div style={{ fontSize: "0.75rem" }}>
                  Upload a cover image for your product.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}
          >
            <div style={{ fontSize: "0.7rem", fontWeight: "500" }}>
              Additional Images
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "22rem",
                overflowY: "auto",
                gap: "1rem",
                scrollbarWidth: "none",
              }}
            >
              {editProductDataFull?.images &&
                editProductDataFull?.images.map((x: any, i: number) => (
                  <div
                    style={{
                      display: "flex",
                      padding: "0.5rem",
                      border: "2px dotted rgba(58, 53, 65, 0.23)",
                      borderRadius: "8px",
                      justifyContent: "center",
                      // width: "45%",
                      alignItems: "center",
                      position: "relative",
                    }}
                    key={i}
                  >
                    <RenderImage
                      imageUrl={x}
                      imageStyle={{ width: "10rem", maxHeight: "10rem" }}
                      alt=""
                      baseUrl={backendServerUrl}
                    />
                    <div className="product-edit-image">
                      <input
                        onChange={async (e: any) => {
                          try {
                            setIsLoading(true);
                            const form = new FormData();
                            form.append("image", e.target.files[0]);
                            const url = `${backendServerUrl}/product/image/update/${
                              editProductDataFull?._id
                            }/${e.target.getAttribute("data-imagename")}`;
                            const res = await (
                              await fetch(url, {
                                headers: {
                                  token: omsToken,
                                  enctype: "multipart/form-data",
                                },
                                method: "PATCH",
                                body: form,
                              })
                            ).json();
                            if (res?.status === "Internal server error")
                              throw new Error(res.message);
                            popup("Product Image Updated");
                            setIsLoading(false);
                            onNavigate && onNavigate(1);
                          } catch {
                            popup("Error Update Product Image");
                            setIsLoading(false);
                          }
                        }}
                        data-imagename={x}
                        placeholder=""
                        type="file"
                      />
                      <div className="product-edit-image-placeholder">
                        <CiImageOn style={{ fontSize: "2rem" }} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1px",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                            color: "#7975B6",
                          }}
                        >
                          <FiUploadCloud size={"1.5rem"} />
                          Upload image
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              textAlign: "center",
            }}
          >
            <Button label="Save Changes" onClick={handleSaveChanges} />
          </div>
        </div>
        {/* image area end */}
      </div>
    </div>
  );
};

export default ProductEdit;
