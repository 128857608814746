import React, { useState, useContext, useEffect } from "react";
import "../styles/index.css";
import "./side-nav.css";
import AppContext from "../context/app-context";

const SideNav = function ({ setSideNav, loggedIn }) {
  const [activeNav, setActiveNav] = useState(
    document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim() || "dashboard"
  );

  const { changeScreen, setIsLoading, usersData } = useContext(AppContext);

  useEffect(() => {
    if (!usersData?.username) return;

    //force default route by access
    if (
      !usersData?.staff_child_roles?.includes(
        document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim()
      )
    )
      setActiveNav("dashboard");
  }, [loggedIn, usersData]);

  useEffect(() => {
    setSideNav({ setActiveNav });
  }, [loggedIn]);

  return (
    <div className="side-nav-container">
      <div className="side-nav-intro flex-row">
        <img src="/logo.png" alt="Estility-Logo" className="estility-logo-side-nav" />
        <p className="big whiteText media-nav-text">Estility</p>
      </div>
      <div className="nav-items-container">
        <button
          onClick={() => {
            setActiveNav("dashboard");
            changeScreen("dashboard");
            document.location.hash = "dashboard";
          }}
          className={`each-nav media-nav ${activeNav === "dashboard" && "each-nav-active"}`}
        >
          <img src="/nav-icons/dashboard.png" alt="nav-icon" style={{ width: 20 }} />
          <p className="small whiteText removemargin media-nav-text">Dashboard</p>
        </button>

        {usersData?.staff_child_roles?.includes("estate-mgt") && (
          <button
            onClick={() => {
              setActiveNav("estate-mgt");
              changeScreen("estate-mgt");
              document.location.hash = "estate-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "estate-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/estate-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Estate MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("vendor-mgt") && (
          <button
            onClick={() => {
              setActiveNav("vendor-mgt");
              changeScreen("vendor-mgt");
              document.location.hash = "vendor-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "vendor-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/vendor-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Vendor MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("gas-inventory-mgt") && (
          <button
            onClick={() => {
              setActiveNav("gas-inventory-mgt");
              changeScreen("gas-inventory-mgt");
              document.location.hash = "gas-inventory-mgt";
            }}
            className={`each-nav media-nav ${
              activeNav === "gas-inventory-mgt" && "each-nav-active"
            }`}
          >
            <img src="/nav-icons/gas-inventory-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Gas Inventory Mgt</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("order-mgt") && (
          <button
            onClick={() => {
              setActiveNav("order-mgt");
              changeScreen("order-mgt");
              document.location.hash = "order-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "order-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/order-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Order MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("fleet-mgt") && (
          <button
            onClick={() => {
              setActiveNav("fleet-mgt");
              changeScreen("fleet-mgt");
              document.location.hash = "fleet-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "fleet-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/fleet-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Fleet MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("finance-mgt") && (
          <button
            onClick={() => {
              setActiveNav("finance-mgt");
              changeScreen("finance-mgt");
              document.location.hash = "finance-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "finance-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/finance-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Finance MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("broadcast-mgt") && (
          <button
            onClick={() => {
              setActiveNav("broadcast-mgt");
              changeScreen("broadcast-mgt");
              document.location.hash = "broadcast-mgt";
            }}
            className={`each-nav media-nav ${activeNav === "broadcast-mgt" && "each-nav-active"}`}
          >
            <img src="/nav-icons/broadcast-mgt.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Broadcast MGT</p>
          </button>
        )}

        {usersData?.staff_child_roles?.includes("users-and-modules") && (
          <button
            onClick={() => {
              setActiveNav("users-and-modules");
              changeScreen("users-and-modules");
              document.location.hash = "users-and-modules";
            }}
            className={`each-nav media-nav ${
              activeNav === "users-and-modules" && "each-nav-active"
            }`}
          >
            <img src="/nav-icons/users-and-module.png" alt="nav-icon" style={{ width: 20 }} />
            <p className="small whiteText removemargin media-nav-text">Users & Modules</p>
          </button>
        )}

        <button
          onClick={() => {
            setActiveNav("general-reports");
            changeScreen("general-reports");
            document.location.hash = "general-reports";
          }}
          className={`each-nav media-nav ${activeNav === "general-reports" && "each-nav-active"}`}
        >
          <img src="/nav-icons/general-reports.png" alt="nav-icon" style={{ width: 20 }} />
          <p className="small whiteText removemargin media-nav-text">General Reports</p>
        </button>

        <button
          onClick={() => {
            setIsLoading(true);
            localStorage.removeItem("omsusername");
            localStorage.removeItem("omsemail");
            localStorage.removeItem("omstoken");
            setTimeout(() => {
              document.location.reload();
            }, [1500]);
          }}
          className={`each-nav`}
        >
          <img src="/nav-icons/log-out.png" alt="nav-icon" style={{ width: 20 }} />
          <p className="small whiteText removemargin media-nav-text">Log Out</p>
        </button>
      </div>
    </div>
  );
};

export default SideNav;
