import React from "react";
import "./Button.css";

type Props = {
  label: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  btnType?: "outline" | "fill";
  style?: React.CSSProperties;
  disable?: boolean;
  type?: string;
};

const Button = ({
  label,
  onClick,
  leftIcon,
  rightIcon,
  btnType = "fill",
  style,
  disable = false,
  type = "button",
}: Props) => {
  return (
    <button
      type={type as any}
      disabled={disable}
      onClick={(e) => onClick && onClick(e)}
      className={`main-button ${
        btnType === "outline" ? "main-button-outline" : "main-button-fill"
      } ${disable && "main-button-disable"}`}
      style={style}
    >
      {leftIcon}
      {label}
      {rightIcon}
    </button>
  );
};

export default Button;
