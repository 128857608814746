export const DennyIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="143"
    height="142"
    viewBox="0 0 143 142"
    fill="none"
  >
    <path
      d="M71.5 0C32.45 0 0.5 31.95 0.5 71C0.5 110.05 32.45 142 71.5 142C110.55 142 142.5 110.05 142.5 71C142.5 31.95 110.55 0 71.5 0ZM71.5 15.7778C83.7278 15.7778 95.1667 20.1167 104.633 26.8222L27.3222 104.133C20.6167 94.6667 16.2778 83.2278 16.2778 71C16.2778 40.6278 41.1278 15.7778 71.5 15.7778ZM71.5 126.222C59.2722 126.222 47.8333 121.883 38.3667 115.178L115.678 37.8667C122.383 47.3333 126.722 58.7722 126.722 71C126.722 101.372 101.872 126.222 71.5 126.222Z"
      fill="#D95069"
    />
  </svg>
);

export const ApprovedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142"
    height="142"
    viewBox="0 0 142 142"
    fill="none"
  >
    <g clip-path="url(#clip0_14676_10048)">
      <path
        d="M133.125 88.753C133.134 84.3095 132.031 79.9345 129.916 76.0266C127.801 72.1187 124.741 68.8023 121.016 66.3796C117.291 63.9569 113.019 62.5051 108.59 62.1563C104.16 61.8076 99.7132 62.5731 95.655 64.3831C91.5968 66.193 88.0562 68.9898 85.3558 72.5186C82.6554 76.0474 80.8811 80.196 80.1946 84.5861C79.5082 88.9763 79.9314 93.4684 81.4257 97.6531C82.9201 101.838 85.438 105.582 88.75 108.544V142.003L106.5 133.598L124.25 142.003V108.544C127.04 106.057 129.272 103.007 130.802 99.5968C132.331 96.1862 133.123 92.4909 133.125 88.753ZM115.375 127.981L106.5 123.778L97.625 127.981V113.825C103.36 115.896 109.64 115.896 115.375 113.825V127.981ZM106.5 106.503C102.989 106.503 99.5576 105.462 96.6386 103.512C93.7196 101.561 91.4445 98.789 90.1011 95.5457C88.7576 92.3023 88.4061 88.7333 89.091 85.2902C89.7759 81.847 91.4664 78.6843 93.9488 76.2019C96.4312 73.7195 99.5939 72.029 103.037 71.3441C106.48 70.6592 110.049 71.0107 113.293 72.3542C116.536 73.6976 119.308 75.9727 121.259 78.8917C123.209 81.8106 124.25 85.2424 124.25 88.753C124.244 93.4588 122.372 97.9702 119.045 101.298C115.717 104.625 111.206 106.497 106.5 106.503Z"
        fill="#6853BC"
      />
      <path
        d="M110.938 22.1875H97.625V17.75C97.618 15.3984 96.6807 13.1451 95.0178 11.4822C93.3549 9.81932 91.1016 8.88202 88.75 8.875H53.25C50.8984 8.88202 48.6451 9.81932 46.9822 11.4822C45.3193 13.1451 44.382 15.3984 44.375 17.75V22.1875H31.0625C28.7109 22.1945 26.4576 23.1318 24.7947 24.7947C23.1318 26.4576 22.1945 28.7109 22.1875 31.0625V124.25C22.1945 126.602 23.1318 128.855 24.7947 130.518C26.4576 132.181 28.7109 133.118 31.0625 133.125H71V124.25H31.0625V31.0625H44.375V44.375H97.625V31.0625H110.938V53.25H119.812V31.0625C119.805 28.7109 118.868 26.4576 117.205 24.7947C115.542 23.1318 113.289 22.1945 110.938 22.1875ZM88.75 35.5H53.25V17.75H88.75V35.5Z"
        fill="#6853BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_14676_10048">
        <rect width="142" height="142" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
