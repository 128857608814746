const minimumStockLevel = 10;
const getQuality = (arg: any) => {};
export class ProductDto {
  id: string;
  images: string[];
  sku: string;
  product_name: string;
  quantitySold: string;
  quantityAvailable: number;
  totalQuantity: string;
  price: string;
  live: string;
  stockLevel: string;

  constructor(arg: any) {
    this.id = arg?._id || "";
    this.images = arg?.images || [""];
    this.sku = arg?.sku || "none";
    this.product_name = arg?.product_name || "";
    this.quantitySold = arg?.quantitySold || "-";
    this.quantityAvailable = arg?.quantityAvailable || 0;
    this.totalQuantity = arg?.totalQuantity || "";
    this.price = arg?.minimumPrice || "0";
    this.live = arg?.is_live || arg?.live || false;
    this.stockLevel = arg?.quantityAvailable
      ? arg?.quantityAvailable > minimumStockLevel
        ? "in-stock"
        : arg?.quantityAvailable > 1 && arg?.quantityAvailable <= minimumStockLevel
        ? "low-stock"
        : "out of stock"
      : "out of stock";
  }
}

export const calculateProductStats = (
  data: any[]
): {
  totalProduct: number;
  activeProduct: number;
  lowOnStock: number;
  soldOut: number;
} => {
  const totalProduct = data.length;
  const activeProduct = data.filter((product) => product.live).length;
  const lowOnStock = data.filter(
    (product) => product.quantityAvailable < 10 && product.quantityAvailable > 0
  ).length;
  const soldOut = data.filter((product) => product.quantityAvailable === 0).length;

  return {
    totalProduct,
    activeProduct,
    lowOnStock,
    soldOut,
  };
};
