import React, { useState, useEffect, useContext, useRef } from "react";
import "../../styles/index.css";
import "./add-a-vendor-modal.css";
import AppContext from "../../context/app-context";
import { popup } from "../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import stepone from "./assets/step-1.svg";
import steptwo from "./assets/step-2.svg";
import stepthree from "./assets/step-3.svg";
import { AddVendorUser } from "./vendor-user";
import vendorImg from "./assets/vendor.png";

const AddAVendorModal = function ({ display, setAddAVendorModal, url }) {
  const { setIsLoading, validateEmail, validateMobile } = useContext(AppContext);

  const [whatsWrong, setWhatsWrong] = useState("");
  const [disableBtn, setdisableBtn] = useState(true);

  const [vendorID, setVendorID] = useState("");

  const [vendorData, setVendorData] = useState({
    vendor_name: "",
    vendor_category: "Groceries",
    vendor_description: "",
    vendor_address: {
      addressString: "",
      geoLocation: {
        coordinates: [],
      },
      postCode: "000000",
    },
    vendor_owner: {
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
    },
  });

  const [onView, setOnView] = useState("step-1");

  function runValidation() {
    if (!vendorData?.vendor_name) return setWhatsWrong("We need the Vendor Name");
    if (!vendorData?.vendor_description)
      return setWhatsWrong("Please add a Short Description for the vendor");
    if (!vendorData?.vendor_address?.addressString)
      return setWhatsWrong("Please add a Vendor Address");

    if (!vendorData?.vendor_owner?.email)
      return setWhatsWrong("You have a missing Vendor Owner  Email Adress");

    if (!validateEmail(vendorData?.vendor_owner?.email))
      return setWhatsWrong("You have an invalid Vendor Owner  Email Adress");

    if (!vendorData?.vendor_owner?.first_name)
      return setWhatsWrong("The first name of the vendor owner is missing");

    if (!vendorData?.vendor_owner?.last_name)
      return setWhatsWrong("The last name of the vendor owner is missing");

    if (!vendorData?.vendor_owner?.mobile)
      return setWhatsWrong("Please set a mobile number for the Vendor Owner");

    if (!validateMobile(vendorData?.vendor_owner?.mobile))
      return setWhatsWrong("You have an invalid Vendor Owner Mobile Number");

    setWhatsWrong("");
  }

  useEffect(() => {
    runValidation();

    if (
      !vendorData?.vendor_name ||
      !vendorData?.vendor_description ||
      !vendorData?.vendor_address?.addressString ||
      !vendorData?.vendor_owner?.first_name ||
      !vendorData?.vendor_owner?.last_name ||
      !vendorData?.vendor_owner?.email ||
      !vendorData?.vendor_owner?.mobile ||
      !validateEmail(vendorData?.vendor_owner?.email) ||
      !validateMobile(vendorData?.vendor_owner?.mobile)
    )
      setdisableBtn(true);
    else setdisableBtn(false);
  });

  async function addVendor() {
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${url}/vendor/create`, {
          method: "POST",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
            "content-type": "application/json",
          },
          body: JSON.stringify(vendorData),
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setVendorID(returned?.data);
      setIsLoading(false);

      setVendorData({
        vendor_name: "",
        vendor_category: "Groceries",
        vendor_description: "",
        vendor_address: {
          addressString: "",
          geoLocation: {
            coordinates: [],
          },
          postCode: "000000",
        },
        vendor_owner: {
          first_name: "",
          last_name: "",
          mobile: "",
          email: "",
        },
      });

      setOnView("step-2");
    } catch (err) {
      setIsLoading(false);
      popup("Error creating vendor, try again later");
    }
  }

  return (
    <div className="modal-container" style={{ display: `${display && url ? "flex" : "none"}` }}>
      <div className="modal-body edit-a-vendor-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setAddAVendorModal(false);
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="flex-row">
          <p className="big blackText boldText" style={{ marginTop: 30 }}>
            Add Vendor
          </p>
        </div>

        <div className="flex-row v-svg-cont">
          <img className={`${onView === "step-1" ? "" : "hide"}`} src={stepone} alt="Vendors" />
          <img className={`${onView === "step-2" ? "" : "hide"}`} src={steptwo} alt="Vendors" />
          <img className={`${onView === "step-3" ? "" : "hide"}`} src={stepthree} alt="Vendors" />
        </div>

        {onView === "step-1" && (
          <>
            <div className="flex-row align-row-left" style={{ marginLeft: 20, marginTop: 20 }}>
              <p className="small">Provide Vendor Information</p>
            </div>

            <div className="flex-column v-input-col">
              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Name"
                type="text"
                value={vendorData?.vendor_name}
                onChange={(e) => {
                  setVendorData((p) => ({ ...p, vendor_name: e.target.value }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Description"
                type="text"
                value={vendorData?.vendor_description}
                maxLength="100"
                onChange={(e) => {
                  setVendorData((p) => ({ ...p, vendor_description: e.target.value }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Address"
                type="text"
                value={vendorData?.vendor_address?.addressString}
                onChange={(e) => {
                  setVendorData((p) => ({
                    ...p,
                    vendor_address: { ...p.vendor_address, addressString: e.target.value },
                  }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Owner Email"
                type="email"
                value={vendorData?.vendor_owner?.email}
                onChange={(e) => {
                  setVendorData((p) => ({
                    ...p,
                    vendor_owner: { ...p.vendor_owner, email: e.target.value },
                  }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Owner First Name"
                type="text"
                value={vendorData?.vendor_owner?.first_name}
                onChange={(e) => {
                  setVendorData((p) => ({
                    ...p,
                    vendor_owner: { ...p.vendor_owner, first_name: e.target.value },
                  }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Owner Last Name"
                type="text"
                value={vendorData?.vendor_owner?.last_name}
                onChange={(e) => {
                  setVendorData((p) => ({
                    ...p,
                    vendor_owner: { ...p.vendor_owner, last_name: e.target.value },
                  }));
                }}
              />

              <input
                className="in a-a-u-inputs"
                placeholder="Vendor Owner Mobile"
                type="number"
                value={vendorData?.vendor_owner?.mobile}
                onChange={(e) => {
                  setVendorData((p) => ({
                    ...p,
                    vendor_owner: { ...p.vendor_owner, mobile: e.target.value },
                  }));
                }}
              />

              <div className="flex-row">
                <button disabled={disableBtn} onClick={addVendor} className="themeBtn a-a-u-btn">
                  Proceed
                </button>
              </div>
              <div className="flex-row" style={{ margin: -10 }}>
                <p className="small redText removemargin">{whatsWrong}</p>
              </div>
            </div>
          </>
        )}

        {onView === "step-2" && (
          <>
            <AddVendorUser url={url} vendorID={vendorID} setOnView={setOnView} />
          </>
        )}

        {onView === "step-3" && (
          <div className="flex-row">
            <p className="big blackText boldText" style={{ marginTop: 30 }}>
              Vendor Successfully Added
            </p>
            <img src={vendorImg} alt="Estility Vendors" title="Estility Vendors" />

            <div className="flex-row" style={{ marginTop: 35 }}>
              <button
                onClick={() => {
                  setAddAVendorModal(false);
                  setOnView("step-1");
                }}
                className="themeBtn a-a-u-btn"
                style={{ width: "85%" }}
              >
                Go Home
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { AddAVendorModal };
