import "./broadcast-view.css";
import "../form/BroadcastForm.css";
import BackButton from "../../../../components/backButton/BackButton";
import Button from "../../../../components/button/Button";
import { BgProps } from "../list/BroadcastTable";

type Props = {
  onNavigate?: (screen: number) => void;
  viewData: any;
  display: boolean;
  setRefreshData: React.Dispatch<React.SetStateAction<BgProps>>;
};

const BroadcastView = (props: Props) => {
  const { onNavigate, viewData, display, setRefreshData } = props;

  if (!display) return <></>;

  return (
    <div className="broadcast-form-container">
      <BackButton onClick={() => onNavigate && onNavigate(0)} />

      <h4 className="big boldText removemargin">Preview Broadcast Message</h4>

      <div className="broadcast-form-preview-wrapper">
        <div className="broadcast-form-preview-wrapper-title">{viewData?.title}</div>
        <div className="broadcast-form-preview-wrapper-desc">
          To: {viewData?.recipients === "-" ? "" : viewData?.recipients}{" "}
          {viewData?.notification_type ? "Vendors" : "Residents"} in {viewData?.estate_name} via the{" "}
          {viewData?.html ? "EMS" : viewData?.notification_type ? "VMS" : "E-Residents"} App
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: viewData?.text?.replace(/\n/g, "<br />") }}
          className="broadcast-form-preview-wrapper-message"
        ></div>
      </div>
      <div className="broadcast-form-buttons-wrapper" style={{ marginBottom: 0 }}>
        <Button
          label={"Continue to Edit"}
          btnType="outline"
          onClick={() => {
            setRefreshData((p: any) => ({
              ...p,
              editData: {
                title: viewData?.title,
                text: viewData?.text,
              },
            }));
            onNavigate && onNavigate(1);
          }}
          disable={viewData?.html || viewData?.notification_type ? true : false}
        />
      </div>
      <div className="broadcast-form-buttons-wrapper" style={{ margin: 0 }}>
        <p className="small removemargin">
          Message sent on{" "}
          {new Intl.DateTimeFormat("en-gb", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(new Date(viewData?.createdAt))}
        </p>
      </div>
    </div>
  );
};

export default BroadcastView;
