import React, { useState, useEffect, useContext } from "react";
import "../../../styles/index.css";
import "../estatemgt.css";
import AppContext from "../../../context/app-context";
import { popup } from "../../../vanilla-functions/model";
import { AllAdminTable } from "../../../components/alladmin-table/alladmin-table";
import { BsPrinter } from "react-icons/bs";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import SearchInput from "../../../components/searchInput/SearchInput.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { AppConfig } from "../../../utils/config";

const AllAdmins = function ({ display, setScreen }) {
  const [page, setPage] = useState(0);
  const [csvData, setcsvData] = useState([]);
  const [allAdminData, setallAdminData] = useState(null);
  const [oringinalData, setOriginalData] = useState([]);

  const { backendServer, usersData, setIsLoading, patternMatching, resolveBackendUrl } =
    useContext(AppContext);

  useEffect(() => {
    getData();
  }, [usersData]);

  async function getData(noLoader) {
    setPage(0);
    try {
      if (!noLoader) setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estates/full-data/get?real=${AppConfig.realData}`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      const data = [
        ...returned?.data?.eachEstate?.flatMap((e) => [
          ...e?.stat?.allAdminsData.map((el) => {
            el.estate = e?.estateDetails?.estate_name;
            return el;
          }),
        ]),
      ];

      setallAdminData(data);
      setOriginalData(data);

      setIsLoading(false);

      workCSV(data);
    } catch (err) {
      setIsLoading(false);
      popup("Error fetching Estates Data");
    }
  }

  function workCSV(data) {
    setcsvData([
      ["Name", "Email", "Role", "Estate", "Last Active"],
      ...data?.map(({ first_name, last_name, estate, createdAt, estate_child_roles, email }) => {
        return [first_name + " " + last_name, email, estate_child_roles[0], estate, createdAt];
      }),
    ]);
  }

  function searchPlease(e) {
    setPage(0);
    const query = e.target.value;

    if (!query) return getData(true);

    const foundEstates = oringinalData?.filter((e) => {
      return (
        patternMatching(query, e.first_name + ` ${e.last_name}`) ||
        patternMatching(query?.split(" ")[0], e.first_name) ||
        patternMatching(query?.split(" ")[0], e.last_name) ||
        patternMatching(query?.split(" ")[1], e.first_name) ||
        patternMatching(query?.split(" ")[1], e.last_name) ||
        patternMatching(query, e?._id) ||
        patternMatching(query, e?.estate) ||
        patternMatching(query, e?.estate_child_roles[0]) ||
        patternMatching(query, e?.email)
      );
    });

    setallAdminData(foundEstates);
    workCSV(foundEstates);
  }

  async function reinstateRevokeAccess(userID, estatedID, is_active) {
    try {
      setIsLoading(true);
      let url;
      if (is_active) url = `${resolveBackendUrl(estatedID)}/admin/action/${userID}`;
      else url = `${resolveBackendUrl(estatedID)}/admin/action/${userID}/true`;

      const returned = await (
        await fetch(url, {
          method: "PATCH",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      popup(`Admin ${is_active ? "Revoked" : "Re-Instated"}!`);
      getData();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  if (!display) return;

  return (
    <div>
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p
          onClick={() =>
            setScreen({
              screen: "home",
              data: null,
            })
          }
          className="small removemargin cursor"
        >
          <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Go back
        </p>
      </div>
      <div className="e-table-container">
        <div
          style={{
            marginBottom: 15,
            width: "100%",
            gap: "1rem",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SearchInput placeholder="search for an estate or user" onChange={searchPlease} />
          <CSVLink className="cursor small csv-link" filename={`Estates Admin Data`} data={csvData}>
            <BsPrinter title="Print CSV" size={20} />
          </CSVLink>

          <FiRefreshCcw
            className="cursor"
            title="Refresh Data"
            size={20}
            onClick={() => getData(false)}
          />
        </div>
        <AllAdminTable
          page={page}
          setPage={setPage}
          data={allAdminData}
          reinstateRevokeAccess={reinstateRevokeAccess}
        />
      </div>
    </div>
  );
};

export default AllAdmins;
