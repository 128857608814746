import React, { useRef, useState, useEffect } from "react";
import "./styles/index.css";
import "./styles/index.theme.css";
import Engine from "./engine/enigine";
import SideNav from "./navigation/side-nav";
import AppContext from "./context/app-context";
import Loading from "./components/loading/loading";
import Login from "./auth/login";
import { SelectEstateModal } from "./components/select-estate/select-estate";
import InDevelopmentModal from "./components/inDevelopmentModal/InDevelopmentModal";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isInDevelopment, setInDevelopment] = useState(false);
  const [loggedIn, setLoggedIn] = useState("none");
  const [usersData, setUsersData] = useState({});
  const [sideNav, setSideNav] = useState({});
  const [estates, setEstates] = useState([]);
  const [storedContext, setStoredContext] = useState({});
  const [selecEstateModal, setSelectEstateModal] = useState({
    display: false,
    data: {
      buttonText: "",
      title: "",
      onSelect: null,
    },
  });
  const engineRef = useRef(null);

  const globalBookKey = "LTSQ1714rKvaqhLX";

  const backendServer = `https://estility-global-book-ba163757a34b.herokuapp.com/estility-global-book/api/v1/${globalBookKey}`;

  const getSignedAwsUrl = async function (filename, bucketname, endpoint) {
    if (!filename) return;

    if (filename.split(":")[0] === "https" || filename.split(":")[0] === "http") return filename;

    const imageFetchUrl = endpoint ? endpoint : backendServer;
    const { data } = await (
      await fetch(`${imageFetchUrl}/file/get/${bucketname}/${filename}`, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();

    return data;
  };

  function formatNumber(amount) {
    return new Intl.NumberFormat("en-us").format(amount);
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateMobile = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/);
  };

  const updateUsersData = async function (andEstate) {
    const data = await (
      await fetch(`${backendServer}/user/get?email=${localStorage.getItem("omsemail")}`, {
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      })
    ).json();

    setUsersData(data.data);

    if (andEstate) getEstate();
  };

  function patternMatching(wordone, wordtwo) {
    if (!wordone || !wordtwo) return false;
    //wrote this for name searching
    let result = wordone?.split("")?.some((e, i) => {
      return String(e)?.toUpperCase() !== String(wordtwo?.split("")[i])?.toUpperCase();
    });
    return !result;
  }

  async function getEstate() {
    if (!loggedIn || loggedIn === "none") return;
    try {
      setIsLoading(true);
      const returned = await (
        await fetch(`${backendServer}/estates/get`, {
          method: "GET",
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken"),
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);
      setEstates(returned?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getEstate();
  }, [loggedIn, usersData]);

  function shortenNumber(num, decimalPlaces) {
    if (!num) return null;
    var str,
      suffix = "";

    decimalPlaces = decimalPlaces || 0;
    num = +num;

    const factor = Math.pow(10, decimalPlaces);

    if (num < 9999) {
      str = num;
    } else if (num < 1000000) {
      str = Math.floor(num / (1000 / factor)) / factor;
      suffix = "K";
    } else if (num < 1000000000) {
      str = Math.floor(num / (1000000 / factor)) / factor;
      suffix = "M";
    } else if (num < 1000000000000) {
      str = Math.floor(num / (1000000000 / factor)) / factor;
      suffix = "B";
    } else if (num < 1000000000000000) {
      str = Math.floor(num / (1000000000000 / factor)) / factor;
      suffix = "T";
    }
    return new Intl.NumberFormat("en-us").format(Number(str)) + suffix;
  }

  function getCurrentHash() {
    return document?.location?.hash?.toLowerCase()?.replace("#", "")?.trim();
  }

  function resolveBackendUrl(estateID) {
    return estates.find((e) => e?.estate_id === estateID)?.backend_base_url;
  }

  const getOmsToken = () => {
    const token = localStorage.getItem("omstoken");
    if (token) return token;
    return "";
  };

  return (
    <AppContext.Provider
      value={{
        changeScreen: engineRef?.current?.changeScreen,
        setIsLoading,
        loggedIn,
        usersData,
        setUsersData,
        setLoggedIn,
        backendServer,
        getSignedAwsUrl,
        formatNumber,
        validateEmail,
        updateUsersData,
        patternMatching,
        validateMobile,
        estates,
        isLoading,
        shortenNumber,
        getCurrentHash,
        resolveBackendUrl,
        setSelectEstateModal,
        setInDevelopment,
        storedContext,
        setStoredContext,
        omsToken: getOmsToken()
      }}
    >
        <>
          {(!loggedIn || loggedIn === "none") && <Login />}

          {loggedIn === true && (
            <div>
              <div className="App">
                <SideNav loggedIn={loggedIn} setSideNav={setSideNav} />
                <Engine sideNav={sideNav} ref={engineRef} loggedIn={loggedIn} />
              </div>
            </div>
          )}

          <Loading show={isLoading} />
          <SelectEstateModal
            setSelectEstateModal={setSelectEstateModal}
            selecEstateModal={selecEstateModal}
          />
          <InDevelopmentModal isVisible={isInDevelopment} />
        </>
    </AppContext.Provider>
  );
}

export default App;
