import React, { useContext } from 'react'
import './rider-information.css';
import AppContext from '../../../../context/app-context';

const RiderInformation = ({selectedRider}) => {
  const { estates } = useContext(AppContext);

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  }

  return (
    <div className='rider-information-container'>

      <div className='rider-information-details'>
        <div className='information-heading'><b className='ml-1'>Rider Information</b></div>
        <div className='rider-data ml-1'>
          <div>Full Name: <b>{selectedRider?.first_name} { " "} {selectedRider?.last_name}</b></div>
          <div>Mobile number: <strong>{selectedRider?.mobile}</strong></div>
        </div>
        <div className='ml-1'>email: <b>{selectedRider?.email}</b></div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Delivery axis</b></div>
        <div className='rider-data ml-1'><b>{findEstate(selectedRider?.estateID)}</b></div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Account information</b></div>
        <div  className='ml-1 mt-2'><strong>{selectedRider?.rider_details?.bank_accounts[0]?.bank_name || 'No data'}</strong></div>
        <div  className='ml-1'><strong>{selectedRider?.rider_details?.bank_accounts[0]?.account_number || 'No data'}</strong></div>
        <div  className='ml-1'><strong>{selectedRider?.rider_details?.bank_accounts[0]?.account_name || 'No data'}</strong></div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Vehicle type</b></div>
        <div className='mt-2 ml-1'><b>{selectedRider?.rider_details?.vehicle_type}</b></div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Number of deliveries</b></div>
        <div className='mt-2 ml-1'><b>{selectedRider?.wallet?.transactions.filter((transaction) => transaction?.status==='completed').length}</b></div>
      </div>

    </div>
  )
}

export default RiderInformation