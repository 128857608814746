import React, { useState } from 'react';
import './reassign-axis.css';
import { FaTimes } from 'react-icons/fa';

const ReassignAxis = ({ isOpen, setIsOpen, selectedRider }) => {
  const [selectedAxis, setSelectedAxis] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const handleAxisChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedAxis(selectedValue);
    if (selectedValue && !selectedTags.includes(selectedValue)) {
      setSelectedTags([...selectedTags, selectedValue]);
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
  };

  if (!isOpen) return null;

  return (
    <div className='modal-overlay'>
      <div className='modal-content-body'>
        <button onClick={() => setIsOpen(false)} className='close-modal'><FaTimes /></button>
        <div className='reassign-container'>
          <div className='reassign-heading'>Re-assign axis</div>
          <div className='provide-info'>Provide driver information</div>
          <div className='driver'>
            <label>Rider's name</label>
            <input type='text' value={selectedRider?.RiderName} disabled />
          </div>
          <div className='driver'>
            <label>Rider's axis</label>
            <select value={selectedAxis} onChange={handleAxisChange}>
              <option>Select axis</option>
              <option value='axis a'>axis a</option>
              <option value='axis b'>axis b</option>
            </select>
          </div>
          <div className='axis'>
            {selectedTags.map(tag => (
              <div key={tag}>
                {tag}
                <span onClick={() => handleTagRemove(tag)}><FaTimes /></span>
              </div>
            ))}
          </div>
          <button className='reaassign-button'>Complete</button>
        </div>
      </div>
    </div>
  );
};

export default ReassignAxis;
