import React, { useContext, useEffect } from "react";
import "./ImportProductModal.css";
import Button from "../../button/Button";
import { AiFillCloseCircle } from "react-icons/ai";
import SearchInput from "../../searchInput/SearchInput";
import CheckWithLabel from "../../checkWithLabel/CheckWithLabel";
import { RiArrowDropDownLine } from "react-icons/ri";
import { groupVendorsBySubCategory } from "./category.dto";
import { IoMdArrowBack } from "react-icons/io";
import { WarningSvg } from "./warning.svg";
import AppContext from "../../../context/app-context";
import { fetchApi } from "../../../api";
import { popup } from "../../../vanilla-functions/model";

type Props = {
  onClose?: () => void;
  onActionBtnClick?: (arg?: any) => void;
  showSuccess?: (arg?: any) => void;
  vendorList?: any[];
  activeVendor: any;
};

const suspendCategory = true;

function ImportProductModal(props: Props) {
  const {
    onClose,
    vendorList: _vendorList,
    activeVendor,
    showSuccess
  } = props;

  const vendorList =
    _vendorList?.filter((e) => {
      return (
        e?._id !== activeVendor?._id &&
        e?.products.length > 0 &&
        e?.vendor_category.toLowerCase() !== "gas"
      );
    }) || [];

    const { backendServer, setIsLoading} = useContext(AppContext);


  const [vendors, setVendors] = React.useState<any[]>([]);

  const [searchQuery, setSearchQuery] = React.useState("");

  const [selectedVendor, setSelectedVendor] = React.useState<any[]>([]);

  const [showVendors, setShowVendors] = React.useState(true);

  const [importBy, setImportBy] = React.useState<string[]>([]);

  const [catIsOpen, setCatIsOpen] = React.useState(false);

  const [individualIsOpen, setIndividualIsOpen] = React.useState(false);

  const [cateSelectedOpt, setCateSelectedOpt] = React.useState<any[]>([]);
  const [cateOptions, setCateOptions] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [products, setProducts] = React.useState<any[]>([]);
  const [productsOptions, setProductsOptions] = React.useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<any[]>([]);

  useEffect(() => {
    setVendors(vendorList);
  }, [activeVendor]);

  useEffect(() => {
    const cate = groupVendorsBySubCategory(selectedVendor);
    setCateOptions(cate);
    setCategories(cate);

    const products = selectedVendor.reduce((acc, cur) => {
      return [...acc, ...cur?.products];
    }, []);

    setProducts(products);
    setProductsOptions(products);
  }, [selectedVendor]);

  const importByList = ["All Products", "Individually"];

  useEffect(() => {
    if (cateSelectedOpt.length === categories.length) {
      setImportBy((prev) => [
        ...prev.filter((e) => e !== "By Category"),
        "By Category",
      ]);
    } else {
      setImportBy((prev) => [...prev.filter((e) => e !== "By Category")]);
    }
  }, [cateSelectedOpt, categories]);

  const handleImport = async () => {

    const isIndividual = (selectedProducts.length > 0);

    // console.log({isIndividual, oth:selectedProducts.length > 0 , ist: !!importBy.includes("Individually")})

    const filter = selectedProducts.length === productsOptions.length? "ALL": isIndividual?"IND": "CAT";


    const filters = () => {
      switch (filter) {
        case "ALL": return {all: true};
        case "IND": return {individual: selectedProducts};
        case "CAT": return suspendCategory? false: {sub_categories: cateSelectedOpt.map((e) => e?.sub_categoryName)};
        default: return false
      }
    }

    const filterOption = filters();

    // console.log(filterOption, filter)

    if(filterOption === false) {
      popup("Kindly select a filter")
      return;
    }
    const payload = {
      vendorsInvolved: {
        fromVendor: {
          vendorID: selectedVendor?.[0]?._id,
          estateID: selectedVendor?.[0]?.estateID,
        },
        toVendor: {
          vendorID: activeVendor?._id,
          estateID: activeVendor?.estateID,
        },
      },
      filters: filterOption,
    };

    setIsLoading(true)
    const url = `${backendServer}/estates/products/import`;

    const {data, hasError, statusText} = await fetchApi({url, method:"POST", body: payload});
    if(hasError) {
      setIsLoading(false);
      popup(data?.message || statusText);
      return;
    }
    setIsLoading(false)
    popup(data?.message || "Product import in progress")
    onClose?.()
    showSuccess?.();
  };

  return (
    <div
      className="delivery-price-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="import-product-modal-container">
        <div className="delivery-price-modal-header-title">
          {"Product Import"}
        </div>

        <div className="import-product-warning-card">
          <WarningSvg />
          Please note that the imported product will go live automatically
        </div>

        {!showVendors && (
          <b style={{ width: "100%", fontSize: "0.85rem" }}>
            Select preferred products to import by:
          </b>
        )}
        {(showVendors || catIsOpen || individualIsOpen) && (
          <SearchInput
            searchIconPosition="right"
            placeholder={
              showVendors
                ? "Search Vendors"
                : catIsOpen
                ? "Search Categories"
                : "Search Products"
            }
            onChange={({ target: { value } }) => {
              setSearchQuery(value);
              if (showVendors) {
                const filteredVendors = vendorList.filter(
                  (vendor) =>
                    vendor?.vendor_name
                      .toLowerCase()
                      .includes(value.toLowerCase()) ||
                    vendor?.estate_name
                      .toLowerCase()
                      .includes(value.toLowerCase())
                );
                setVendors(filteredVendors);
              } else if (catIsOpen) {
                const filteredCategories = cateOptions.filter(
                  (category) =>
                    category.sub_categoryName
                      .toLowerCase()
                      .includes(value.toLowerCase()) ||
                    category?.vendor?.vendor_name
                      .toLowerCase()
                      .includes(value.toLowerCase())
                );
                setCategories(filteredCategories);
              } else {
                const filteredProducts = productsOptions.filter((each) =>
                  each?.product_name.toLowerCase().includes(value.toLowerCase())
                );
                setProducts(filteredProducts);
              }
            }}
          />
        )}
        {!showVendors && (
          <div
            onClick={() => {
              setCateSelectedOpt([])
              setSelectedProducts([])
              setImportBy([])
              setShowVendors(true)
            }}
            className="import-product-back-btn cursor"
            title={selectedProducts.length > 0 ?`NOTE: All selected products (${selectedProducts.length}) will be cleared`: "← back to vendors"}
          >
            <IoMdArrowBack size={17} className="cursor" /> &nbsp; {"Back"}
          </div>
        )}

        <div className="import-vendor-list">
          {showVendors &&
            vendors.map((x, index) => (
              <CheckWithLabel
                key={index}
                label={
                  <div className="import-product-list-tab-sub">{`${x?.vendor_name} (${x?.estate_name})`}</div>
                }
                value={!!selectedVendor.find((arg) => arg?._id === x?._id)}
                onChange={({ value }) => {
                  if (value === true) {
                    setSelectedVendor([x]);
                  } else {
                    setSelectedVendor([]);
                  }
                }}
              />
            ))}
          {showVendors && vendors.length === 0 && (
            <div className="import-product-no-records">
              No vendor found{" "}
              {showVendors && searchQuery !== ""
                ? `for: "${searchQuery.trim()}"`
                : ""}
            </div>
          )}

          {!showVendors &&
            importByList.map((x, i) => (
              <React.Fragment key={i}>
                <CheckWithLabel
                  key={i}
                  label={x}
                  disable={
                    x !== "All Products" &&
                    categories.length === cateSelectedOpt.length &&
                    importBy.length ===
                      importByList.filter((e) => e !== "All Products").length
                  }
                  value={
                    x === "All Products"
                      ? importBy.filter((e) => e !== "All Products").length ===
                        importByList.filter((e) => e !== "All Products").length
                        && (
                          selectedProducts.length === productsOptions.length
                        )
                      : x === "By Category"
                      ? categories.length === cateSelectedOpt.length
                      : x === "Individually"
                      ? productsOptions.length === selectedProducts.length
                      : !!importBy.find((item) => item === x)
                  }
                  onChange={({ value }) => {
                    if (x === "All Products") {
                      if (value === true) {
                        setCateSelectedOpt(categories);
                        setSelectedProducts(productsOptions.map(e => e?._id));
                        setImportBy(
                          importByList.filter((e) => e !== "All Products")
                        );
                      } else {
                        setCateSelectedOpt([]);
                        setSelectedProducts([])
                        setImportBy([]);
                      }
                    } else if (x === "By Category") {
                      if (value === true) {
                        setCateSelectedOpt(categories);
                        setImportBy(importBy.concat(x));
                      } else {
                        setCateSelectedOpt([]);
                        setImportBy(importBy.filter((e) => e !== x));
                      }
                    } else if (x === "Individually") {
                      if (value === true) {
                        setImportBy(importBy.concat(x));
                        setSelectedProducts(productsOptions.map((e) => e?._id));
                      } else {
                        setImportBy(importBy.filter((e) => e !== x));
                        setSelectedProducts([]);
                      }
                    } else {
                      if (value === true) {
                        setImportBy(importBy.concat(x));
                      } else {
                        setImportBy(importBy.filter((e) => e !== x));
                      }
                    }
                  }}
                  rightElement={
                    x === "By Category" ? (
                      <RiArrowDropDownLine
                        size={20}
                        cursor={"pointer"}
                        onClick={() => setCatIsOpen(!catIsOpen)}
                      />
                    ) : x === "Individually" ? (
                      <>
                        <RiArrowDropDownLine
                          size={20}
                          cursor={"pointer"}
                          onClick={() => setIndividualIsOpen(!individualIsOpen)}
                        />
                        {selectedProducts.length > 0 && (
                          <div className="import-product-item-selected">
                            {`(${selectedProducts.length})`} items selected
                          </div>
                        )}
                      </>
                    ) : null
                  }
                />
                {x === "By Category" && catIsOpen && (
                  <div className="import-product-list-tab">
                    {categories.map((x, i) => (
                      <CheckWithLabel
                        label={
                          <div
                            className="import-product-list-tab-sub"
                            title={`sold by: ${x?.vendor?.vendor_name} at ${x?.vendor?.estate_name}`}
                          >{`${x.sub_categoryName} (${x?.totalProductCount} products) by ${x?.vendor?.vendor_name}`}</div>
                        }
                        key={i}
                        value={
                          !!cateSelectedOpt.find(
                            (opt) =>
                              opt?.sub_categoryName === x?.sub_categoryName
                          )
                        }
                        onChange={({ value }) => {
                          if (value === true) {
                            setCateSelectedOpt(cateSelectedOpt.concat(x));
                          } else {
                            setCateSelectedOpt(
                              cateSelectedOpt.filter(
                                (opt) =>
                                  opt?.sub_categoryName !== x?.sub_categoryName
                              )
                            );
                          }
                        }}
                      />
                    ))}
                    {categories.length === 0 && (
                      <div className="import-product-no-records">
                        No categories found{" "}
                        {!showVendors && searchQuery !== ""
                          ? `for: "${searchQuery.trim()}"`
                          : ""}
                      </div>
                    )}
                  </div>
                )}
                {x === "Individually" && individualIsOpen && (
                  <div className="import-product-list-tab">
                    {products.map((x, i) => (
                      <CheckWithLabel
                        label={
                          <div
                            className="import-product-list-tab-sub"
                            title={`price: NGN${x?.minimumPrice} brand: ${x?.brand_name}`}
                          >{`${x?.product_name}`}</div>
                        }
                        key={i}
                        value={Boolean(selectedProducts.find((opt) => opt === x?._id))}
                        onChange={({ value }) => {
                          if (value === true) {
                            setSelectedProducts([...selectedProducts, x?._id]);
                          } else {
                            const _n = [...selectedProducts.filter((opt) => opt !== x?._id)]
                            setSelectedProducts(_n);
                          }
                        }}
                      />
                    ))}
                    {products.length === 0 && (
                      <div className="import-product-no-records">
                        No products found{" "}
                        {!showVendors && searchQuery !== ""
                          ? `for: "${searchQuery.trim()}"`
                          : ""}
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>

        <Button
          style={{ width: "70%" }}
          onClick={() => {
            const hasSelectAVendor = showVendors && selectedVendor.length > 0;
            const hasItem = ((selectedProducts.length > 0 || cateSelectedOpt.length > 0) && selectedVendor.length > 0)
            if(hasItem) {
              handleImport()
            } else if(hasSelectAVendor) {
              setSelectedProducts([])
              setCateSelectedOpt([])
              setImportBy([])
              setShowVendors(false);
            } else {
              return;
            }
          
          }}
          disable={
            !(showVendors && selectedVendor.length > 0) &&
            !(selectedVendor.length > 0 &&
                (importBy.includes("All Products") ||
                  importBy.includes("Individually") ||
                  cateSelectedOpt.length > 0))
          }
          label={
            showVendors
              ? "Proceed To Select Product (s) →"
              : selectedVendor.length > 0 &&
                (importBy.includes("All Products") ||
                  importBy.includes("Individually") ||
                  cateSelectedOpt.length > 0)
              ? "Import"
              : "Continue →"
          }
        />
        {
          selectedVendor.length > 0 &&
        <p style={{fontSize: "0.63rem", fontWeight: "bold", color: selectedProducts.length > 0? "green": "tomato", maxWidth: "60%", textAlign: "center"}}>
          {`Import ${importBy.includes("All Products")? "All products": importBy.includes("Individually")? "Selected products ("+selectedProducts.length+")" : "products"  } from ${selectedVendor.map(e => e?.vendor_name).join(",")}`}</p>
        }

        <AiFillCloseCircle
          className="delivery-price-modal-close-icon"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
}

export default ImportProductModal;
