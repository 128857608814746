import React, { useState } from "react";
import "./EstateConfirmationApprovedModal.css";
import Button from "../../../../components/button/Button";
import { ApprovedIcon, DennyIcon } from "./svg";
import InputField from "../../../../components/inputField/InputField";
import { FcCancel } from "react-icons/fc";

type Props = {
  activeUserFullName?: string;
  accountName?: string;
  type: "approved" | "denny";
  onClose?: () => void;
  onDenyReasonResponse?: (reason: string) => void;
};

const EstateConfirmationApprovedModal = (props: Props) => {
  const { accountName, activeUserFullName, onClose, type } = props;

  const [reason, setReason] = useState<string>("");

  return (
    <div
      className="estate-confirmation-approve-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="estate-confirmation-approve-modal-container">
        <div className="estate-confirmation-approve-title">
          {type === "approved" ? "Account Approved" : "Account Denied"}
        </div>
        {type === "approved" && ApprovedIcon}
        {type === "denny" && <FcCancel color="#6853BC" size={"6rem"} />}

        <div className="estate-confirmation-approve-description">
          {`Account belonging to ${accountName} as requested for approval by ${activeUserFullName} has been  approved`}
        </div>

        {type === "denny" && (
          <InputField
            label="Reason"
            name="reason"
            value={reason}
            labelType="nested"
            type="textarea"
          />
        )}

        <Button
          label="Okay"
          style={{ width: "70%" }}
          onClick={() => onClose && onClose()}
        />
      </div>
    </div>
  );
};

export default EstateConfirmationApprovedModal;
