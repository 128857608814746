import {
  capitalizeEachWord,
  formatDateToCustomString,
  formatDateToTime,
} from "../../../../utils";
import StatusPill from "../../../../components/statusPill/StatusPill";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "./TransactionTable.css";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/app-context";
import Table from "../../../../components/table/Table";

type Props = {
  onClickBack?: () => void;
  transactionType: string;
  tableLabel?: string;
};

const TransactionTable = (props: Props) => {
  const { onClickBack, transactionType, tableLabel } = props;

  const { backendServer } = useContext(AppContext);

  const [data, setData] = useState<any[]>([]);
  // const [taleHeader, setTableHeader] = useState<string[]>(["Name", "Transaction", "Estate", "Recipient", "Transaction ID", "Status", "Date and Time"]);

  const getEndpointByTransactionType = (
    transactionType: string
  ): { endpoint: string; tableTile: string } => {
    switch (transactionType) {
      case "allTransactions":
        return { endpoint: "/transactions/all", tableTile: "All Transaction" };
      case "walletTransactions":
        return {
          endpoint: "/transactions/wallet",
          tableTile: "Wallet Transactions",
        };
      case "payStackTransactions":
        return {
          endpoint: "/transactions/all",
          tableTile: "Paystack Transactions",
        };
      case "failedTransactions":
        return {
          endpoint: "/transactions/failed",
          tableTile: "Failed Transactions",
        };
      default:
        return {
          endpoint: "/transactions/all",
          tableTile: "All Transactions",
        };
    }
  };

  type IData = {
    name: string;
    transaction: number;
    estate: string;
    recipient: string;
    transactionId: string;
    status: "successful" | "failed";
    dateTime: string;
  };

  const _tableHeader = [
    { key: "name", label: "Name" },
    { key: "transaction", label: "Transaction" },
    { key: "estate", label: "Estate" },
    { key: "recipient", label: "Recipient" },
    { key: "transactionId", label: "Transaction ID" },
    { key: "status", label: "Status" },
    { key: "dateTime", label: "Date and Time" },
  ];

  const generateData = (): IData => ({
    name: "John Doe",
    transaction: Math.floor(Math.random() * 1000) + 1,
    estate: "Sample Estate",
    recipient: "Jane Doe",
    transactionId: `TXN${Math.floor(Math.random() * 100000)}`,
    status: Math.random() < 0.5 ? "successful" : "failed",
    dateTime: new Date().toISOString(),
  });

  useEffect(() => {
    const fetchData = async () => {
      const { endpoint, tableTile } =
        getEndpointByTransactionType(transactionType);
      const url = `${backendServer}/staff/get`;
      const response = await (
        await fetch(url, {
          credentials: "include",
          headers: {
            token: localStorage.getItem("omstoken") || "",
          },
        })
      ).json();
    };
    const newData = Array.from({ length: 22 }, () => generateData());
    setData(newData);
    fetchData();
  }, []);

  return (
    <div className="transaction-table-container">
      <div
        className="transaction-table-back-btn"
        onClick={() => onClickBack && onClickBack()}
      >
        <MdKeyboardArrowLeft size={25} />
        Back
      </div>
      <div className="transaction-table-title">{tableLabel}</div>
      <Table
        addCheckBox
        checkAll
        data={data || []}
        header={_tableHeader}
        // toRenderField={["first_name", "last_name", "role"]}
        capitalizeHeaders
        pagination={{ totalDataCount: data.length }}
        customTdWrappers={[
          {
            element: (text) => (
              <StatusPill
                type={text.includes("success") ? "success" : "error"}
                label={text}
              />
            ),
            rowKeyName: "status",
          },
          {
            element: (text) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "max-content",
                }}
              >
                <div>{formatDateToCustomString(new Date(text))}</div>
                <div>{formatDateToTime(new Date(text))}</div>
              </div>
            ),
            rowKeyName: "dateTime",
          },
        ]}
        tableTitle="Estility Transaction Data"
        showSearchBar
        searchPlaceholder="Search by Name, Estate, Recipient, Transaction ID"
        columnMaxLen={20}
        onRefreshIconClick={() => alert("Refresh")}
      />
    </div>
  );
};

export default TransactionTable;
