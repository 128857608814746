import { useState, useContext } from "react";
import "../../../../styles/index.css";
import "./reviews.css";
import AppContext from "../../../../context/app-context";
import { IoMdArrowBack } from "react-icons/io";
import ReviewTable from "./ReviewTable";

const Reviews = function ({ display, setScreen }) {
  const { backendServer, setIsLoading } = useContext(AppContext);

  if (!display) return;

  return (
    <div className="order-review-parent-container">
      <div className="flex-row space-between no-padding" style={{ marginBottom: 15 }}>
        <p
          onClick={() =>
            setScreen({
              screen: "home",
              data: null,
            })
          }
          className="small removemargin cursor"
        >
          <IoMdArrowBack style={{ marginTop: -3 }} size={17} className="cursor" /> &nbsp; Back
        </p>
      </div>
      <p className="order-header-title">User Reviews</p>
      <ReviewTable />
    </div>
  );
};

export default Reviews;
