import { useEffect, useState } from "react";

export const useCountAnimation = (targetCount: number): number => {
  const startFromTheLastTen =(num:number) => num - 10 > 0? num - 10 : 0;
  const [currentCount, setCurrentCount] = useState(startFromTheLastTen(targetCount));

  useEffect(() => {
    if (currentCount < targetCount) {
      const timeout = setTimeout(() => {
        setCurrentCount((prevCount) => Math.min(prevCount + 1, targetCount));
      }, 2);
      return () => clearTimeout(timeout);
    }
  }, [currentCount, targetCount]);

  return currentCount;
};
