
interface GroupedList {
  sub_categoryName: string;
  totalProductCount: number;
  vendor: any;
}

export const groupVendorsBySubCategory = (vendors: any[]): GroupedList[] => {
  const groupedVendors: Record<string, GroupedList> = {};

  vendors?.forEach((vendor) => {
    vendor?.products?.forEach((product:any) => {
      product?.sub_categories?.forEach((subCategory: any) => {
        if (!(subCategory in groupedVendors)) {
          groupedVendors[subCategory] = {
            sub_categoryName: subCategory,
            totalProductCount: 0,
            vendor: vendor,
          };
        }
        groupedVendors[subCategory].totalProductCount++;
      });
    });
  });

  return Object.values(groupedVendors);
};
