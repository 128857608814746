import React, { useEffect, useContext, useState } from "react";
import "../../styles/index.css";
import "./select-estate.css";
import AppContext from "../../context/app-context";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectEstateModal = function ({ setSelectEstateModal, selecEstateModal }) {
  const [dropdownValue, setDropdownValue] = useState("");
  const { estates } = useContext(AppContext);

  return (
    <div
      className="modal-container"
      style={{ display: `${selecEstateModal?.display ? "flex" : "none"}` }}
    >
      <div className="modal-body edit-a-user-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setSelectEstateModal({
                display: false,
                data: { buttonText: "", title: "", onSelect: null },
              });
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="estate-select-modal-cont  flex-column align-column-left">
          <div className="flex-row" style={{ marginTop: 40 }}>
            <p className="big">{selecEstateModal?.data?.title}</p>
          </div>
          <div className="flex-row">
            <img
              src="/estate-modal/ce.png"
              alt="Estility Estate Selection"
              title="Select an Estate"
            />
          </div>
        </div>

        <div className="flex-row">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={estates?.map((e) => ({ label: e?.estate_name, id: e?.estate_id }))}
            sx={{ width: "90%" }}
            renderInput={(params) => <TextField {...params} label="Search for an Estate" />}
            onInputChange={(_event, newInputValue) => {
              setDropdownValue(newInputValue);
            }}
          />
        </div>

        <div className="flex-row" style={{ paddingBottom: 20, marginTop: 40 }}>
          <button
            disabled={estates?.find((e) => e?.estate_name === dropdownValue) ? false : true}
            onClick={() => {
              setSelectEstateModal({
                display: false,
                data: { buttonText: "", title: "", onSelect: null },
              });
              selecEstateModal?.data?.onSelect &&
                selecEstateModal?.data?.onSelect(
                  estates?.find((e) => e?.estate_name === dropdownValue)?.backend_base_url
                );
            }}
            className="themeBtn a-a-u-btn"
          >
            {selecEstateModal?.data?.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export { SelectEstateModal };
