import React, { useEffect, useState, useContext } from "react";
import "./ProductDashboard.css";
import { ProductDto, calculateProductStats } from "../dto/product.dto";
import ProductList from "./ProductList/ProductList";
import ProductDetails from "../productDetails/ProductDetails";
import ProductEdit from "../productEdit/ProductEdit";
import ProductAdd from "../productAdd/ProductAdd";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";
import Button from "../../../../components/button/Button";
import SearchInput from "../../../../components/searchInput/SearchInput";
import BackButton from "../../../../components/backButton/BackButton";
import StatsCard from "../../../../components/statCard/StatsCard";
import { MdFolderOpen } from "react-icons/md";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { LuFolderCheck } from "react-icons/lu";
import { PiHandbagSimpleBold } from "react-icons/pi";
import { GiBattery25 } from "react-icons/gi";
import TitleLabel from "../../../../components/titleLabel/TitleLabel";
import { useCountAnimation } from "../../../../hoooks/useCountAnimation";
import { IoMdAdd } from "react-icons/io";
import ImportProductModal from "../../../../components/modal/importProductModal/ImportProductModal";
import { fetchApi } from "../../../../api";
import { AppConfig } from "../../../../utils/config";
import ImportSuccessModal from "../../../../components/modal/importProductModal/importSuccessModal/ImportSuccessModal";

type Props = {
  onNavigate: (s: number) => void;
  setCurProduct: (s: any) => void;
  omsToken: string;
  estateId: string;
  vendor: any;
  setBaseUrl: any;
  estateName: string;
};

const ProductDashboardFirstScreen = (props: Props) => {
  const {
    onNavigate,
    setCurProduct,
    omsToken,
    estateId,
    vendor,
    setBaseUrl,
    estateName,
  } = props;
  const { setIsLoading, resolveBackendUrl, setInDevelopment, backendServer } =
    useContext(AppContext);

  const backendServerUrl = resolveBackendUrl(estateId || "");

  setBaseUrl(backendServerUrl);

  const vendorID = vendor?._id || "";

  const [products, setProduct] = useState<any[]>([]);
  const [originalData, setOriginalData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>(products);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [editedForm, setEditedForm] = useState<any[]>([]);

  const [status, setStatus] = useState({
    all: 0,
    active: 0,
    lowStock: 0,
    sold: 0,
  });

  const [showImportModal, setShowImportModal] = useState(false);

  const [vendorList, setVendorList] = useState<any[]>([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      setIsLoading(true);
      const url = `${backendServerUrl}/vms/product/find/basic?vendor=${vendorID}`;
      const _res = await (
        await fetch(url, {
          headers: {
            token: omsToken,
          },
          method: "GET",
        })
      ).json();

      const { status, message, data } = _res;
      if (status && status.toLowerCase().includes("error")) {
        message && popup(message);
        setIsLoading(false);
        return;
      }

      setOriginalData(data);

      const temp: ProductDto[] = [];

      data.forEach((el: any, i: number) => {
        temp.push(new ProductDto(el));
      });

      setProduct(temp);
      setFilteredData(temp);
      const stats = calculateProductStats(temp);
      setStatus({
        all: stats.totalProduct,
        active: stats.activeProduct,
        lowStock: stats.lowOnStock,
        sold: stats.soldOut,
      });
    } catch (error: any) {
      popup(error.message);
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  function filterProducts(type: string) {
    if (type === "all") {
      const temp = originalData.map((el: any) => {
        return new ProductDto(el);
      });
      setProduct(temp);
      setFilteredData(temp);
      return;
    }

    if (type === "active") {
      const temp = originalData
        .filter((e: any) => e.is_live)
        .map((el: any) => {
          return new ProductDto(el);
        });
      setProduct(temp);
      setFilteredData(temp);
      return;
    }

    if (type === "low") {
      const temp = originalData
        .filter(
          (e: any) => e.quantityAvailable <= 10 && e.quantityAvailable > 0
        )
        .map((el: any) => {
          return new ProductDto(el);
        });
      setProduct(temp);
      setFilteredData(temp);
      return;
    }

    if (type === "sold") {
      const temp = originalData
        .filter((e: any) => e.quantityAvailable < 1)
        .map((el: any) => {
          return new ProductDto(el);
        });
      setProduct(temp);
      setFilteredData(temp);
      return;
    }
  }

  const handleSearch = (
    searchItem: string,
    mainData: any[],
    onResult: (data: any[]) => void
  ) => {
    const _filteredData = mainData.filter((item) => {
      const objectValues = Object.values(item).map((value) =>
        typeof value === "string" ? value.toLowerCase() : value
      );

      const searchValue = searchItem.toLowerCase();
      return objectValues.some((value: any) =>
        value.toString().toLowerCase().includes(searchValue)
      );
    });
    onResult && onResult(_filteredData);
  };

  const handleProductMassUpdate = async () => {
    const url = `${backendServerUrl}/product/mass/update`;
    const payload: any[] = [];
    editedForm.forEach((el) => {
      payload.push({
        _id: el.id,
        minimumPrice: el.price || el.minimumPrice,
        is_live: el.is_live || el.live,
        quantityAvailable: el.quantityAvailable,
      });
    });

    try {
      const res = await (
        await fetch(url, {
          headers: {
            token: omsToken,
            "content-type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(payload),
        })
      ).json();

      const { status, message } = res;

      if (status.toLowerCase().includes("error")) {
        message && popup(message);
        setIsLoading(false);
      }
      setEditedForm([]);
      popup("Data updated successfully!");
      await getProducts();
    } catch (error: any) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchVendors = async (callBack?: (data: any[]) => void) => {
    try {
      setIsLoading(true);
      const url = `${backendServer}/estates/vendors/get?real=${AppConfig.realData}`;
      const { data, hasError } = await fetchApi({ url, method: "GET" });

      if (hasError) {
        popup(data?.status || "Something went wrong");
        setIsLoading(false);
        return;
      }

      const fetchedVendor = data?.data;

      setVendorList(fetchedVendor);
      callBack && callBack(fetchedVendor);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [showSuccessImport, setShowSuccessImport] = useState(false);

  return (
    <div className="product-dashboard">
      <BackButton onClick={() => onNavigate && onNavigate(0)} />
      <TitleLabel label={`${vendor?.vendor_name} (${estateName})`} />
      {/* stat wrapper start */}
      <div className="product-dashboard-stat-wrapper">
        <StatsCard
          onClick={() => filterProducts("all")}
          count={useCountAnimation(status.all)}
          label={"All Products"}
          icon={(className) => <MdFolderOpen className={className} />}
          styleStr="all"
        />
        <StatsCard
          onClick={() => filterProducts("active")}
          count={useCountAnimation(status.active)}
          label={"Active Products"}
          icon={(className) => <LuFolderCheck className={className} />}
          styleStr="active"
        />
        <StatsCard
          onClick={() => filterProducts("low")}
          count={useCountAnimation(status.lowStock)}
          label={"Low on Stock"}
          icon={(className) => <GiBattery25 className={className} />}
          styleStr="low"
        />
        <StatsCard
          onClick={() => filterProducts("sold")}
          count={useCountAnimation(status.sold)}
          label={"Sold out"}
          icon={(className) => <PiHandbagSimpleBold className={className} />}
          styleStr="sold"
        />
      </div>
      {/* stat wrapper end */}
      {/* search and filer section start */}
      <div
        style={{
          display: "none",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <SearchInput
          placeholder="Search for a product"
          onChange={(e: any) => {
            const { value } = e.target;
            handleSearch(value, products, (res) => {
              setFilteredData(res);
            });
            if (value === "") {
              setFilteredData(products);
              getProducts();
            }
          }}
        />
        {editedForm.length > 0 && (
          <Button
            label={`Save Changes ${editedForm.length}`}
            onClick={() => {
              handleProductMassUpdate();
            }}
          />
        )}
        {editedForm.length > 0 && (
          <Button
            label="Cancel"
            btnType="outline"
            onClick={() => setEditedForm([])}
          />
        )}
      </div>
      {/* search and filer section end */}
      {/* table start */}
      <ProductList
        tableTitle={`${
          vendor?.vendor_name
        } at ${estateName} - Products ${new Date()}`}
        onRefresh={() => getProducts()}
        menus={[
          editedForm.length > 0 ? (
            <Button
              label={`Save Changes ${editedForm.length}`}
              onClick={() => {
                handleProductMassUpdate();
              }}
            />
          ) : (
            <></>
          ),
          editedForm.length > 0 ? (
            <Button
              label="Cancel"
              btnType="outline"
              onClick={() => setEditedForm([])}
            />
          ) : (
            <></>
          ),
          editedForm.length === 0 ? (
            <Button
              label="Import Product"
              btnType="outline"
              leftIcon={<IoArrowDownCircleOutline size={20} />}
              onClick={() => {
                setShowImportModal(true);
                handleFetchVendors()
                // setInDevelopment?.(true);
              }}
            />
          ) : (
            <></>
          ),
          editedForm.length === 0 ? (
            <Button
              label="Add Product"
              btnType="outline"
              leftIcon={<IoMdAdd size={20} />}
              onClick={() => onNavigate && onNavigate(3)}
            />
          ) : (
            <></>
          ),
        ]}
        baseUrl={backendServerUrl}
        mainData={filteredData}
        selected={selectedProducts}
        onSelectedUpdate={setSelectedProducts}
        innerFormUpdate={editedForm}
        onInnerFormUpdate={(form) => {
          setEditedForm(form);
        }}
        onRowSelect={(row) => {
          onNavigate && onNavigate(1);
          setCurProduct(row);
        }}
      />
      {/* table end */}
      {showImportModal && (
        <ImportProductModal
          vendorList={vendorList}
          onClose={() => setShowImportModal(false)}
          activeVendor={vendor}
          showSuccess={() => setShowSuccessImport(true)}
        />
      )}
      <ImportSuccessModal visible={showSuccessImport} onClose={() => setShowSuccessImport(false)} />
    </div>
  );
};

type IProductDashboard = {
  onNavigate: (s: number) => void;
  initialScreenIndex?: number;
  vendor: any;
  estateId: string;
  productActiveScreenIndex: number;
  backendServerUrl: string;
  setProductActiveScreenIndex: any;
  omsToken: string;
  setBaseUrl: any;
  estateName: string;
};

const ProductDashboard = (props: IProductDashboard) => {
  const {
    onNavigate,
    initialScreenIndex,
    vendor,
    estateId,
    productActiveScreenIndex,
    backendServerUrl,
    setProductActiveScreenIndex,
    omsToken,
    setBaseUrl,
    estateName,
  } = props;

  const [currProduct, setCurProduct] = useState<any>({});
  const [editProductDataFull, setEditProductDataFull] = useState<any>({});

  useEffect(() => {
    const index = initialScreenIndex || 0;
    return () => setProductActiveScreenIndex(index);
  }, [initialScreenIndex]);

  const handleNavigate = (s: number) => {
    if (s === 0 && productActiveScreenIndex === 0) {
      onNavigate && onNavigate(s);
    } else {
      setProductActiveScreenIndex(s);
    }
  };

  const screens: JSX.Element[] = [
    <ProductDashboardFirstScreen
      onNavigate={(s) => handleNavigate(s)}
      setCurProduct={(p) => setCurProduct(p)}
      estateId={estateId}
      estateName={estateName}
      omsToken={omsToken}
      setBaseUrl={setBaseUrl}
      vendor={vendor}
    />,
    <ProductDetails
      backendServerUrl={backendServerUrl}
      onNavigate={(s) => handleNavigate(s)}
      curProduct={currProduct}
      setEditProductDataFull={setEditProductDataFull}
      productActiveScreenIndex={productActiveScreenIndex}
    />,
    <ProductEdit
      onNavigate={(s) => handleNavigate(s)}
      editProductDataFull={editProductDataFull}
      estateId={estateId}
    />,
    <ProductAdd
      onNavigate={(s) => handleNavigate(s)}
      curProduct={currProduct}
      estateId={estateId}
      vendor={vendor}
    />,
  ];

  return screens[initialScreenIndex || productActiveScreenIndex];
};

export default ProductDashboard;
