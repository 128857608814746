import { useState, useContext } from 'react'
// import closeIcon from '../../../../assets/Icon.svg';

// import Confirmation from '../confirmation/confirmation';
import { popup } from '../../../../vanilla-functions/model';
import AppContext from '../../../../context/app-context';
import { FaTimes } from 'react-icons/fa';


const RefundCode = ({ isOpen, setIsOpen, onClick, showSuccess, onClickBack }:any) => {

  const [code, setCode] = useState('')
  const { usersData, setIsLoading, backendServer, isLoading } = useContext<any>(AppContext);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const passcode = '256EST72'

  const handleChange = (e:any) => {
    setCode(e.target.value)
  }

  const verifyCode = async (e:any) => {
    e.preventDefault();
    if (code !== passcode) {
      popup("Invalid code");
      return;
    }
    setIsOpen(false)
    // try {
    //   setIsLoading(true);
    //   const response = await fetch(`${backendServer}/staff/password/verify/${usersData?._id}`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       token: localStorage.getItem("omstoken"),
    //     } as any,
    //     body: JSON.stringify({ loginPass: code }),
    //   });
    //   const data = await response.json();
    //   data?.status === "true" ? setIsConfirmationOpen(true) : popup(data?.data);
    //   setIsLoading(false);
    // } catch (error) {
    //   throw error
    //   setIsLoading(false);
    //   popup("Error verifying password");
    // }
  };

  if (!isOpen) return
  return (
    <>
      <div className='modal-overlay'>
        <div className='modal-content-body'>
          <button onClick={() => onClickBack?.()} className='close-button'><FaTimes /></button>
          <div className='refund-code-container'>
            <div className='refund-code-header'>Enter Code</div>
            <div className='refund-code-text'>Enter access code to view this page</div>
            <form onSubmit={verifyCode}>
              <div>
                <input onChange={handleChange} type='text' placeholder='Enter code' name='code' />
              </div>
              <button disabled={isLoading} type='submit'>Submit</button>
            </form>
          </div>
        </div>
      </div>
      {/* <Confirmation showSuccess={showSuccess} onClickYes={onClick} closeAll={setIsOpen} onClickCancel={() => setIsConfirmationOpen(false)} isOpen={isConfirmationOpen} setIsOpen={setIsConfirmationOpen} title='Are you sure?' yes={`Yes, ${revokeConfirmation}`} cancel='Cancel' /> */}
    </>
  )
}

export default RefundCode