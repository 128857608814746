import { useState, useContext, useEffect } from "react";
import "./edit-vendor-info.css";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";

const EditVendorAddress = ({ isOpen, setIsOpen, selectedVendor, fetchVendors }) => {
  const { resolveBackendUrl, setIsLoading, isLoading } = useContext(AppContext);

  const nigerianStates = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "FCT - Abuja",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ]

  const [vendor_address, setVendorAddress] = useState("");

  const handleChange = (e) => {
   setVendorAddress(e.target.value)
  };

  const url = `${resolveBackendUrl(selectedVendor?.estateID)}/vendor/details/update/${selectedVendor?._id}`;
 
  async function handleSubmit(e) {
    // const joinStateToStreet = `${vendor_address?.street}, ${vendor_address?.state}`;
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify({vendor_address}),
      });

      const returned = await response.json();
      await fetchVendors()
      popup(returned?.status || "Record Updated successfully");
      setIsOpen(false);
      
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="vendor-info-div">
          <div className="vendor-heading-div">
            <div className="vendor-heading">Edit VMS Address</div>
            <FaTimes onClick={() => setIsOpen(false)} cursor='pointer' />
          </div>
          <form onSubmit={handleSubmit} className="vendor-info-form">
            <div className="edit-form-input-div">
              <label>Street</label>
              <input
                type="text"
                name="vendor_address"
                placeholder={selectedVendor?.vendor_address?.addressString}
                onChange={handleChange}
              />
            </div>
           
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditVendorAddress;

