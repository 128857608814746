import React, { useState, useContext } from "react";
import "../../styles/index.css";
import "./estatemgt.css";
import EstateHome from "./children/home";
import AnEstate from "./children/estate";
import AllAdmins from "./children/all-admins";

const Estatemgt = function ({ display }) {
  const [screen, setScreen] = useState({
    screen: "home",
    data: null,
  });

  if (!display) return;

  return (
    <div className="screen-container">
      <EstateHome setScreen={setScreen} display={screen.screen === "home" && true} />
      <AnEstate
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "estate" && true}
      />
      <AllAdmins
        screen={screen}
        setScreen={setScreen}
        display={screen.screen === "all-admin" && true}
      />
    </div>
  );
};

export default Estatemgt;
