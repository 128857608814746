import React, { useContext, useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { SlOptionsVertical } from "react-icons/sl";
import TabStatsCard from "../../../../components/card/tabStatCard/TabStatsCard";
import "./AccountConfirmation.css";
import Table from "../../../../components/table/Table";
import AppContext from "../../../../context/app-context";
import { capitalizeEachWord, getColorByStatus } from "../../../../utils";
import { AiOutlineEye } from "react-icons/ai";
import EstateConfirmationRequestModal from "./EstateConfirmationRequestModal";
import { popup } from "../../../../vanilla-functions/model";
import { FcCancel } from "react-icons/fc";
import { FaRegCheckCircle } from "react-icons/fa";
import EstateConfirmationApprovedModal from "./EstateConfirmationApprovedModal";
type Props = {
  onClickBack?: () => void;
};

const AccountConfirmation = (props: Props) => {
  const { onClickBack } = props;
  const { backendServer, setIsLoading, usersData } = useContext(AppContext);

  const activeUserFullName = `${usersData?.first_name} ${usersData?.last_name}`;

  const [tableHeader, setTableHeader] = useState<string[]>([
    "Initiator",
    "Bank Name",
    "Account Number",
    "Account Name",
    "Status",
  ]);
  const [data, setData] = useState<any[]>([]);

  const [activeRow, setActiveRow] = useState<any>({});
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showApprovedModal, setShowApprovedModal] = useState<boolean>(false);
  const [approvalType, setApproval] = useState<"denny" | "approved">("denny");

  type IData = {
    initiator: string;
    bankName: string;
    accountNumber: string;
    accountName: string;
    email: string;
    status: "pending" | "approved" | "rejected";
  };
  const getRandomStatus = (): "pending" | "approved" | "rejected" => {
    const statusOptions = ["pending", "approved", "rejected"];
    const randomIndex = Math.floor(Math.random() * statusOptions.length);
    return statusOptions[randomIndex] as any;
  };

  const generateData = (): IData => ({
    initiator: "Tomiwa Ayandele",
    bankName: "Access Bank",
    accountNumber: Math.floor(Math.random() * 10000000000)
      .toString()
      .padStart(10, "0"),
    accountName: "Chevy View Estate",
    email: "test@estility.com",
    status: getRandomStatus(),
  });
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setData(Array.from(Array(100).keys()).map(() => generateData()));
      setIsLoading(false);
    }, 2000);
  }, []);

  const handleApprove = () => {
    setIsLoading(true);
    setTimeout(() => {
      popup("Successfully Approved " + activeRow?.initiator);
      setApproval("approved");
      setIsLoading(false);
      setShowApprovedModal(true);
      setShowConfirmationModal(false);
    }, 2000);
  };
  const handleDenny = () => {
    setIsLoading(true);
    setTimeout(() => {
      popup("Successfully Denny " + activeRow?.initiator);
      setApproval("denny");
      setIsLoading(false);
      setShowApprovedModal(true);
      setShowConfirmationModal(false);
    }, 2000);
  };

  const handleDenyReason = (reason: string) => {
    setIsLoading(false);
    setShowApprovedModal(false);
    setShowApprovedModal(false);
    // send reason to bk
  };

  return (
    <div>
      <div
        className="financemgt-back-btn"
        onClick={() => onClickBack && onClickBack()}
      >
        <MdKeyboardArrowLeft size={25} />
        Back{" "}
      </div>
      <div className="account-confirmation-header-title">
        Account Confirmation
      </div>
      <div className="account-confirmation-stats-wrapper">
        <TabStatsCard
          width={"19rem"}
          title={"All Account"}
          count={150}
          imageSrc={"/dashboard-icons/all-acct.png"}
        />
        <TabStatsCard
          width={"19rem"}
          title={"All Account"}
          count={150}
          imageSrc={"/dashboard-icons/vendor-acct.png"}
        />
        <TabStatsCard
          width={"19rem"}
          title={"All Account"}
          count={150}
          imageSrc={"/dashboard-icons/estate-acct.png"}
        />
      </div>

      <Table
        data={data}
        header={tableHeader}
        showSearchBar
        searchPlaceholder="Search for a Account"
        addCheckBox
        checkAll
        unRenderField={["email"]}
        addCheckBoxTitle="Select All"
        customTdWrappers={[
          {
            element: (text) => (
              <div style={{ color: getColorByStatus(text) }}>
                {capitalizeEachWord(text)}
              </div>
            ),
            rowKeyName: "status",
          },
        ]}
        onActionOptionClicks={{
          onOthers: [
            {
              label: "Overview",
              color: "#7975B6",
              icon: <AiOutlineEye size={22} />,
              onClick: (row) => {
                setActiveRow(row);
                setShowConfirmationModal(true);
              },
            },
            {
              label: "Approve Account",
              color: "#5A9A8F",
              icon: <FaRegCheckCircle size={20} />,
              onClick: (row) => {
                setActiveRow(row);
                handleApprove();
              },
            },
            {
              label: "Deny Account",
              color: "#D95069",
              icon: <FcCancel size={20} />,
              onClick: (row) => {
                setActiveRow(row);
                handleDenny();
              },
            },
          ],
        }}
        onRowClick={(row) => {
          setActiveRow(row);
          setShowConfirmationModal(true);
        }}
        actionOptionIcon={<SlOptionsVertical />}
      />

      {showConfirmationModal && (
        <EstateConfirmationRequestModal
          accountName={activeRow?.accountName}
          accountNumber={activeRow?.accountNumber}
          bankName={activeRow?.bankName}
          mobileNumber=""
          fullName={activeRow?.initiator}
          imageUrl=""
          onApprove={handleApprove}
          onDenny={handleDenny}
          email={activeRow?.email}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
      {showApprovedModal && (
        <EstateConfirmationApprovedModal
          type={approvalType}
          accountName={activeRow?.accountName}
          activeUserFullName={activeUserFullName}
          onClose={() => setShowApprovedModal(false)}
          onDenyReasonResponse={(str) => {
            handleDenyReason(str);
          }}
        />
      )}
    </div>
  );
};

export default AccountConfirmation;
