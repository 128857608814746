import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import "./EstateConfirmationRequestModal.css";
import Button from "../../../../components/button/Button";

type Props = {
  onClose?: () => void;
  fullName?: string;
  mobileNumber: string;
  email: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  imageUrl?: string;
  onApprove?: () => void;
  onDenny?: () => void;
};

function EstateConfirmationRequestModal(props: Props) {
  const {
    onClose,
    accountName,
    accountNumber,
    bankName,
    email,
    mobileNumber,
    fullName,
    imageUrl,
    onApprove,
    onDenny,
  } = props;

  return (
    <div
      className="estate-confirmation-request-modal-overlay"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose && onClose();
        }
      }}
    >
      <div className="estate-confirmation-request-modal-container">
        <div className="estate-confirmation-request-title">
          {" "}
          Vendor Account Confirmation Request{" "}
        </div>

        <div className="estate-confirmation-request-modal-header-container">
          <img src="/user_placeholder.png" alt="Icon" />
          <h5>{fullName || "-"}</h5>
        </div>

        <div className="ecr-sub-header">
          <div className="ecr-sub-header-title">Request Details</div>
          <div className="ecr-sub-header-line">
            <div />
          </div>
        </div>

        <div className="ecr-content-container">
          <div className="erc-th-title">User Requesting</div>
          <div className="ecr-field-wrapper">
            <div className="ecr-field">
              Full Name: <div>{fullName}</div>
            </div>
            <div className="ecr-field">
              Mobile Number: <div>{mobileNumber}</div>
            </div>
          </div>
          <div className="ecr-field-wrapper">
            <div className="ecr-field">
              Email: <div>{email}</div>
            </div>
          </div>

          <div className="erc-th-title">Account Details</div>
          <div className="erc-bank-details">
            <div>{bankName}</div>
            <div>{accountNumber}</div>
            <div>{accountName}</div>
          </div>
        </div>

        <div className="ecr-btn-wrapper">
          <Button
            label="Approve"
            btnType="outline"
            leftIcon={<FaRegCheckCircle />}
            style={{ color: "#5A9A8F", borderColor: "#5A9A8F" }}
            onClick={() => onApprove && onApprove()}
          />
          <Button
            label="Denny"
            btnType="outline"
            leftIcon={<FcCancel />}
            style={{ color: "#D95069", borderColor: "#D95069" }}
            onClick={() => onDenny && onDenny()}
          />
        </div>

        <AiFillCloseCircle
          className="estate-confirmation-request-modal-close-icon"
          onClick={() => {
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
}

export default EstateConfirmationRequestModal;
