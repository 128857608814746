import React, { useContext, useEffect } from "react";
import "./ReviewFeedbackModal.css";
import { AiFillCloseCircle } from "react-icons/ai";
import UserDetailCard from "../../userDetailCard/UserDetailCard";
import AppContext from "../../../context/app-context";
import "../orderOverviewModal/OrderOverviewModal.css";
import { fetchApi } from "../../../api";
import { popup } from "../../../vanilla-functions/model";
import RatingIcon from "../../../screens/general-reports/children/reviews/RatingIcon";
import RenderImage from "../../renderImage/RenderImage";
import Button from "../../button/Button";

type Props = {
  isVisible?: boolean;
  onClose?: () => void;
  arg: any;
};

export default function ReviewFeedbackModal({
  isVisible,
  onClose,
  arg,
}: Props) {
  const { resolveBackendUrl, setIsLoading } = useContext(AppContext);

  const backendServerUrl = (estateId: string) =>
    resolveBackendUrl(estateId || "");

  const [orderDetails, setOrderDetails] = React.useState<any>({});

  useEffect(() => {
    (async () => {
      const orderId = arg?.orderDetails?.orderID;
      const url = `${backendServerUrl(arg.estateID)}/order/get/${orderId}`;
      setIsLoading?.(true);
      const { data, hasError } = await fetchApi({ url, method: "GET" });
      if (hasError) {
        setIsLoading?.(false);
        popup(
          "Error communicating with estate server. You can try again later."
        );
        return;
      }
      setIsLoading?.(false);
      setOrderDetails(data?.data);
    })();
  }, [arg]);

  return !isVisible ? null : (
    <div className="review-feedback-modal-overlay">
      <div className="review-feedback-modal-container">
        <div>Order Review</div>

        <div className="review-feedback-modal-flex-row">
          <UserDetailCard
            title="Resident Details"
            fullName={`${arg?.first_name} ${arg?.last_name}`}
            phoneNumber={arg?.mobile}
            address={""}
            imageUrl={orderDetails?.buyer?.profile_picture}
            baseUrl={backendServerUrl(arg?.estateID)}
            email={arg?.email}
          />
          <UserDetailCard
            title="Rider Details"
            fullName={`${orderDetails?.rider?.first_name} ${orderDetails?.rider?.last_name}`}
            phoneNumber={orderDetails?.rider?.mobile}
            address={""}
            imageUrl={orderDetails?.rider?.profile_picture}
            baseUrl={backendServerUrl(arg?.estateID)}
            email={orderDetails?.rider?.email}
          />
        </div>

        <div className="review-feedback-modal-rating-container">
          <h6>Order Id: {arg?.orderDetails?.orderID?.slice(-10)}</h6>

          <div className="review-feedback-modal-rating-wrapper">
            <div>
              <div>Rider Rating</div>
              <div>
                Rider name:{" "}
                <div>{`${orderDetails?.rider?.first_name} ${orderDetails?.rider?.last_name}`}</div>
              </div>

              <div className="review-feedback-modal-rating-icon">
                <RatingIcon
                  rate={
                    arg?.ratings?.find((x: any) => x?.type === "rider")?.rate ||
                    0
                  }
                />
                {(arg?.ratings?.find((x: any) => x?.type === "rider")?.rate ||
                  0) === 1
                  ? "Great!"
                  : (arg?.ratings?.find((x: any) => x?.type === "rider")
                      ?.rate || 0) === 2
                  ? "Just There!"
                  : "Bad"}
              </div>
            </div>
            <div>
              <div>Experience Rating</div>
              <div className="review-feedback-modal-rating-icon">
                <RatingIcon
                  rate={
                    arg?.ratings?.find((x: any) => x?.type === "order")?.rate ||
                    0
                  }
                />
                {(arg?.ratings?.find((x: any) => x?.type === "order")?.rate ||
                  0) === 1
                  ? "Great!"
                  : (arg?.ratings?.find((x: any) => x?.type === "order")
                      ?.rate || 0) === 2
                  ? "Just There!"
                  : "Bad"}
              </div>
            </div>
          </div>

          <div className="review-feedback-modal-order-details">
            <b>Order Items: </b>
            <div className="review-feedback-modal-order-items">
              {orderDetails?.products?.map((product: any, index: number) => (
                <div className="review-feedback-modal-order-item" key={index}>
                  <div className="review-feedback-modal-order-item-image">
                    <RenderImage
                      imageStyle={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "20px",
                        border: "2px solid whitesmoke",
                      }}
                      alt=""
                      className=""
                      baseUrl={backendServerUrl(arg?.estateID)}
                      imageUrl={product?.productID?.images[0]}
                    />
                  </div>
                  <div className="review-feedback-modal-order-item-label">
                    {product?.productID?.product_name}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="review-feedback-modal-msg-container">
            <div className="review-feedback-modal-msg-label">
              Comments
            </div>
            <div className="review-feedback-modal-msg">
              <p>{arg?.message}</p>
            </div>
          </div>
        </div>

        <Button label="Close Window" style={{width: "100%", height: "3.5rem"}} onClick={() => onClose?.()} />

        <AiFillCloseCircle
          className="order-overview-modal-close-icon"
          onClick={() => {
            onClose?.();
          }}
        />
      </div>
    </div>
  );
}

