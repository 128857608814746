import React from 'react'
import './StatusPill.css'

type Props = {
    type: 'success' | 'error' | 'warning' | 'info' | 'default' | 'primary' | 'link' | 'dark';
    label?: string;
}

const StatusPill = (props: Props) => {
    const { type, label } = props;
  return (
    <div className={`status-pill-warper status-pill-${type}`}>
        <div className={`status-pill-label -status-pill-label${type}`}>
            {label || type}
        </div>
    </div>
  )
}

export default StatusPill