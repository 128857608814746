import React, { useEffect, useState } from 'react'
import { Paper, TablePagination as TablePaginationHOC } from '@mui/material';
import { on } from 'events';

export type ITablePagination = {
    rowsPerPageOptions?: number[];
    totalDataCount: number;
    rowsPerPage?: number;
    page?: number;
    onPageChange?: (newPage: number) => void;
    onRowsPerPageChange?: (event: any) => void;
    onDataChange?: (data: any[]) => void;
    initialData?: any[];
}

const TablePagination = (props: ITablePagination) => {

    const {page, rowsPerPage, totalDataCount, rowsPerPageOptions, initialData, onDataChange, onPageChange, onRowsPerPageChange} = props;

    const [curRowsPerPage, setCurRowsPerPage] = useState(rowsPerPage || 10);
    const [curPage, setCurPage] = useState(page || 0);
  
    const handleChangePage = (_event: any, newPage: any) => {
      onPageChange && onPageChange(newPage);
      setCurPage(newPage);
    };
  
    
    const handleChangeRowsPerPage = (event: any) => {
      setCurRowsPerPage(+event.target.value);
      setCurPage(0);
      onRowsPerPageChange && onRowsPerPageChange(+event.target.value);
    };

    useEffect(() => {
        if(!initialData) return;
        const paginatedData = initialData?.slice(curPage * curRowsPerPage, curPage * curRowsPerPage + curRowsPerPage);
        onDataChange && onDataChange(paginatedData);
    }, [curRowsPerPage, curPage])

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", height: "fit-content" }}>
        <TablePaginationHOC
            rowsPerPageOptions={rowsPerPageOptions || [3, 10, 25, 100]}
            component={"div"}
            count={totalDataCount}
            rowsPerPage={curRowsPerPage}
            page={curPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
    </Paper>
  )
}

export default TablePagination