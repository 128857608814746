import { useState, useContext, useEffect } from "react";
import "./edit-vendor-info.css";
import { FaTimes } from "react-icons/fa";
import AppContext from "../../../../context/app-context";
import { popup } from "../../../../vanilla-functions/model";

const EditVendorName = ({ isOpen, setIsOpen, selectedVendor, fetchVendors }) => {
  const { resolveBackendUrl, setIsLoading, isLoading } = useContext(AppContext);

  const [vendor_name, setVendorName ] = useState(selectedVendor?.vendor_name || '');

  const handleChange = (e) => {
    setVendorName(e.target.value)
  };

  const url = `${resolveBackendUrl(selectedVendor?.estateID)}/vendor/details/update/${selectedVendor?._id}`;
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        body: JSON.stringify({vendor_name}),
      });

      const returned = await response.json();
      await fetchVendors()
      popup(returned?.status || "Record Updated successfully");
      setIsOpen(false);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content-body">
        <div className="vendor-info-div">
          <div className="vendor-heading-div">
            <div className="vendor-heading">Edit Vendor Name</div>
            <FaTimes onClick={() => setIsOpen(false)} cursor='pointer'/>
          </div>
          <form onSubmit={handleSubmit} className="vendor-info-form">
            <div className="edit-form-input-div">
              <label>Vendor Name</label>
              <input
                style={{ padding: '10px 10px', marginTop: '5px' }}
                type="text"
                name="vendor_name"
                onChange={handleChange}
                placeholder={selectedVendor?.vendor_name}
              />
            </div>
            <button disabled={isLoading}type="submit">Save</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditVendorName;

