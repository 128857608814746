export const services = [
  {
    title: "Manage Estates",
    description: "Create and manage Estates, Properties and Residents",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/briefcase.png",
  },
  {
    title: "Manage Vendors",
    description: "Create and manage all vendors in Estility",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/shopping-bag.png",
  },
  {
    title: "Manage Orders",
    description: "Manage all gas and grocery orders",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/wallet.png",
  },
  {
    title: "Manage Fleet",
    description: "Manage all of estility’s Vehicles and Riders",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/rocket.png",
  },
  {
    title: "Manage Finances",
    description: "Manage all Estility finances and payments",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/coin-stack.png",
  },
  {
    title: "General Reports",
    description: "See statistical overview of estility’s operations",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/user.png",
  },
  {
    title: "Content Management System",
    description: "Manage contents for the website, User app, EMS, VMS and Rider app",
    isLocked: true,
    available: false,
    imageSrc: "/service-icons/user.png",
  },
  {
    title: "Broadcast Announcements",
    description: "Manage and send out broadcast messages to all channels",
    isLocked: false,
    available: true,
    imageSrc: "/service-icons/user.png",
  },
];
