import React from "react";
import "../../../styles/index.css";
import "../add-user/add-a-user.css";
import { popup } from "../../../vanilla-functions/model";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./modal-order-created.css";

const ModalOrderCreated = function ({
  display,
  setModalOrderCreated,
  order,
  url,
  clearState,
  setReviewOrderModal,
}) {
  return (
    <div
      className="modal-container modal-on-modal-container"
      style={{ display: `${display ? "flex" : "none"}` }}
    >
      <div className="modal-body cf-modal-on-modal-body">
        <div className="close-container-modal">
          <AiOutlineCloseCircle
            onClick={() => {
              setModalOrderCreated(false);
              clearState && clearState();
            }}
            className="cursor"
            size={20}
            color="#34204C"
          />
        </div>

        <div className="flex-column align-column-left cd-add-user-in-cont">
          <div className="flex-row">
            <p className="big removemargin boldText">Gas Order Placed</p>
          </div>

          <div className="flex-row">
            <p
              className="small removemargin boldText"
              style={{ width: "70%", textAlign: "center" }}
            >
              You have successfully placed a gas order to {order?.deliveryAddress?.addressString}.
            </p>
          </div>

          <div className="flex-row">
            <img src="/kerfin7_nea_3147 1.png" alt="Estility Orders" title="Order Created!" />
          </div>

          <div className="flex-row">
            <a
              onClick={(e) => {
                e.preventDefault();
                if (!url || !order) return popup("Order cannot be reached now...");
                setReviewOrderModal(true);
              }}
              href="#order-mgt"
              className="small removemargin boldText"
              style={{ color: "#9178b1" }}
            >
              See Details
            </a>
          </div>
        </div>

        <div className="flex-column" style={{ gap: 5, marginTop: 40 }}>
          <button onClick={() => clearState && clearState()} className="themeBtn a-a-u-btn">
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalOrderCreated;
