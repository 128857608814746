import React, { useContext, useState } from "react";
import FinanceStatsCard from "../../../../components/card/financeStatsCard/FinanceStatsCard";
import { IQuickActionScreens, quickActionData } from "./quick.action.data";
import ServiceCard from "../../../../components/serviceCard/ServiceCard";
import AppContext from "../../../../context/app-context.js";
import "./FinancePage.css";
import DeliveryPriceModal from "../../../../components/modal/deliveryPriceModal/DeliveryPriceModal";

type Props = {
  onNavigateToTransaction?: (transType: TransactionParam) => void;
  onNavigateToQuickAction?: (transType: QuickActionParam) => void;
};

type QuickActionParam = {
  table: string;
  screen?: IQuickActionScreens;
};
type TransactionParam = {
  transType: string;
  tableType?: "transactions" | "quickActions";
  tableLabel?: string;
};

const FinancePage = (props: Props) => {
  const { onNavigateToTransaction, onNavigateToQuickAction } = props;
  const { setIsLoading, setInDevelopment } = useContext(AppContext);
  const [deliveryModalProps, setDeliveryModalProps] = useState<any>({});
  const [groceryDeliveryPrice, setGroceryDeliveryPrice] = useState<number>(0);
  const [gasDeliveryPrice, setGasDeliveryPrice] = useState<number>(0);
  const [productMarkupPrice, setProductMarkupPrice] = useState<number>(0);
  const [gasMarkupPrice, setGasMarkupPrice] = useState<number>(0);
  const [riderIncentivePrice, setRiderIncentivePrice] = useState<number>(0);

  const [showDeliveryPriceModal, setShowDeliveryPriceModal] = useState(false);

  const handleSavePrice = (price: number) => {
    setIsLoading(true);
    setTimeout(() => {
      deliveryModalProps?.activeModal === "groceryDeliveryPrice" && setGroceryDeliveryPrice(price);
      deliveryModalProps?.activeModal === "gasDeliveryPrice" && setGasDeliveryPrice(price);
      deliveryModalProps?.activeModal === "gasMarkupPrice" && setGasMarkupPrice(price);
      deliveryModalProps?.activeModal === "productMarkupPrice" && setProductMarkupPrice(price);
      deliveryModalProps?.activeModal === "riderIncentivePrice" && setRiderIncentivePrice(price);
      setShowDeliveryPriceModal(false);
      setIsLoading(false);
    }, 2000);
  };

  const handleIsInDevelopment = (callback?: () => void) => {
    const isDev = true;
    if (isDev) {
      setInDevelopment(true);
      return;
    } else {
      callback && callback();
    }
  };

  return (
    <>
      {/* <div className="finance-page-stats-wrapper">
        <FinanceStatsCard
          amount="NGN682.5M"
          title="Transactions"
          subTitle="1142 completed"
          icon={<img src="/service-icons/trans-up.png" alt="icon" />}
          onClick={() => {
            handleIsInDevelopment(() => {
              onNavigateToTransaction &&
                onNavigateToTransaction({
                  transType: "allTransactions",
                  tableType: "transactions",
                  tableLabel: "All Transactions",
                });
            });
          }}
        />
        <FinanceStatsCard
          amount="NGN682.5M"
          title="Wallet Payments"
          subTitle="789 completed"
          icon={<img src="/service-icons/trans-wallet.png" alt="icon" />}
          onClick={() =>
            handleIsInDevelopment(() => {
              onNavigateToTransaction &&
                onNavigateToTransaction({
                  transType: "walletTransactions",
                  tableType: "transactions",
                  tableLabel: "Wallet Transactions",
                });
            })
          }
        />
        <FinanceStatsCard
          amount="NGN682.5M"
          title="Paystack Payments"
          subTitle="1142 completed"
          icon={<img src="/service-icons/trans-paystack.png" alt="icon" />}
          onClick={() =>
            handleIsInDevelopment(() => {
              onNavigateToTransaction &&
                onNavigateToTransaction({
                  transType: "payStackTransactions",
                  tableType: "transactions",
                  tableLabel: "Paystack Transactions",
                });
            })
          }
        />
        <FinanceStatsCard
          amount="NGN682.5M"
          title="Failed transactions"
          subTitle="42 failed"
          icon={<img src="/service-icons/trans-down.png" alt="icon" />}
          onClick={() =>
            handleIsInDevelopment(() => {
              onNavigateToTransaction &&
                onNavigateToTransaction({
                  transType: "failedTransactions",
                  tableType: "transactions",
                  tableLabel: "Failed transactions",
                });
            })
          }
        />
      </div> */}

      <div className="finance-page-services-title">Quick actions</div>
      <div className="finance-page-services-wrapper">
        {quickActionData.map((service, index) => (
          <div key={index} className="finance-page-services-card-holder">
            <div className="finance-page-services-card-holder-title">{service.group}</div>
            <div className="finance-page-services-card-holder-content">
              {service.items.map((x, j) => (
                <ServiceCard
                  key={j}
                  title={x.title}
                  description={x.description}
                  imageSrc={x.imgUrl}
                  available={x.available}
                  cardWidth="11.2rem"
                  onClick={() => {
                    if (x.title.toLowerCase() === "product pricing markup") {
                      setDeliveryModalProps({
                        title: "Set Product Price Markup",
                        subTitle: `Current rate: ${productMarkupPrice}%`,
                        btnLabel: "Save changes",
                        activeModal: "productMarkupPrice",
                      });
                      setShowDeliveryPriceModal(true);
                      return;
                    } else if (x.title.toLowerCase() === "set rider incentive") {
                      setDeliveryModalProps({
                        title: "Set Incentive Amount",
                        subTitle: `Current price: NGN${riderIncentivePrice}`,
                        btnLabel: "Save changes",
                        activeModal: "riderIncentivePrice",
                      });
                      setShowDeliveryPriceModal(true);
                      return;
                    } else {
                      onNavigateToQuickAction &&
                        onNavigateToQuickAction({
                          table: "quickActions",
                          screen: x.screen,
                        });
                    }
                  }}
                  isLocked={!x.available}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* modals start */}
      {showDeliveryPriceModal && (
        <DeliveryPriceModal
          onClose={() => setShowDeliveryPriceModal(false)}
          title={deliveryModalProps?.title}
          subTitle={deliveryModalProps?.subTitle}
          btnLabel={deliveryModalProps?.btnLabel}
          inputPlaceHolder={deliveryModalProps?.inputPlaceHolder}
          onActionBtnClick={(price) => handleSavePrice(price)}
          inputProps={{
            formatValue: "number",
          }}
        />
      )}
      {/* modals end */}
    </>
  );
};

export default FinancePage;
