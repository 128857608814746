import { useContext, useEffect, useState } from 'react'
import './vendor-overview.css';
import AppContext from '../../../context/app-context';
import { FiEdit2 } from "react-icons/fi";
import EditVendorInfo from './edit-vendor-info/edit-vendor-info';
import EditVendorDescription from './edit-vendor-info/edit-vendor-description';
import EditVendorAddress from './edit-vendor-info/edit-vendor-address';

const VendorGeneralInfo = ({ selectedVendor, fetchVendors }) => {
  const { estates, backendServer, setIsLoading } = useContext(AppContext);
  
  const [vendorEstates, setVendorEstates] = useState([]);
  const [vendorManagers, setVendorManagers] = useState([]);

  const [ openVendorInfo, setOpenVendorInfo ] = useState(false);
  const [ openVendorDescription, setIsOpenOpenVendorDescription ] = useState(false);
  const [ openVendorAddress, setIsOpenOpenVendorAddress ] = useState(false);

  const [ editedInfo, setEditedInfo ] = useState('');

  async function getVendorAssignedEstates() {
    try {
      setIsLoading(true);
      const url = `${backendServer}/estates/allocations/get/${selectedVendor?.vendor_name.replace(/\s+/g, '-')}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
      });

      const returned = await response.json();
      setVendorEstates(returned?.data?.allEstatesVendorIsIn)
      setVendorManagers(returned?.data?.allVendorUserManagers)
      // if (response.status !== 200) {
      //   throw new Error(returned.message || "Internal server error");
      // }
      // popup(returned?.status);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // popup("Error communicating with estate server. You can try again later.");
    }
  }

  useEffect(() => {
    selectedVendor && getVendorAssignedEstates()
  }, [])

  const openForm = (editedVendorInfo) => {
    setEditedInfo(editedVendorInfo)
    if (editedVendorInfo === 'Vendor Owner Info') {
      setOpenVendorInfo(true)
    }else if (editedVendorInfo === 'Vendor Description') {
      setIsOpenOpenVendorDescription(true)
    }else if (editedVendorInfo === 'Vendor Address') {
      setIsOpenOpenVendorAddress(true)
    }
    return;
  }

  return (
    <>
    <div className='rider-information-container'>
      <div className='rider-information-details'>
        <div className='information-heading'><b className='ml-1'>Vendor Owner Information</b>{" "}<FiEdit2 onClick={() => openForm('Vendor Owner Info')} cursor='pointer'/></div>
        <div className='rider-data ml-1'>
          <div>Full Name: <b>{selectedVendor?.vendor_owner?.first_name} {" "} {selectedVendor?.vendor_owner?.last_name}</b></div>
          <div>Mobile number: <strong>{selectedVendor?.vendor_owner?.mobile}</strong></div>
        </div>
        <div className='rider-data'>
          <div className='ml-1'>Email: <b>{selectedVendor?.vendor_owner?.email}</b></div>
          <div>Category: <b>{selectedVendor?.vendor_category}</b></div>
        </div>
      </div>


      <div>
        <div className='information-heading'><b className='ml-1'>Vendor Description</b>{" "}<FiEdit2 onClick={() => openForm('Vendor Description')} cursor='pointer' /></div>
        <div className='mt-2 ml-1'><b>{selectedVendor?.vendor_description}</b></div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Vendor Address</b>{" "}<FiEdit2 onClick={() => openForm('Vendor Address')} cursor='pointer' /></div>
        {/* <div className='rider-data ml-1'><b>{findEstate(selectedVendor?.estateID)}</b></div> */}
        <div className='vendor-estates'>
          <div className='rider-data ml-1'>{selectedVendor?.vendor_address?.addressString}</div>
        </div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>VMS User Account information</b>{" "}</div>
         <div className='vms-user-account'>
        {vendorManagers.length > 0 ? vendorManagers?.map(manager => (
          <div key={manager} className='vendor-manager'>
            <div className='rider-data ml-1'>
              <div>Full Name: {" "}<b>{manager.first_name} {" "} {manager?.last_name}</b></div>
              <div>Mobile number: {" "}<strong>{manager?.mobile}</strong></div>
            </div>
            <div className='rider-data'>
              <div className='ml-1'>Email: {" "}<b>{manager?.email}</b></div>
              <div>Role:{" "} <b>{manager?.role}</b></div>
            </div>
          </div>
        )) : null}
        </div>
      </div>

      <div>
        <div className='information-heading'><b className='ml-1'>Allocated estates</b></div>
        <div className='vendor-estates'>
          {vendorEstates.length > 0 ? vendorEstates?.map(estate => (
            <div className='estate-item' key={estate}>
              <span className='permission-item'>{estate?.estate_name}</span>
              {/* <FaTimes
              className="remove-icon"
              onClick={() => handleCheckboxChange(estate)}
            /> */}
            </div>
          )) : null}
        </div>
      </div>
    </div>

    <EditVendorInfo 
    selectedVendor={selectedVendor}
     isOpen={openVendorInfo}
      setIsOpen={setOpenVendorInfo}
       editedInfo={editedInfo}
       fetchVendors={fetchVendors}
       />
    <EditVendorDescription fetchVendors={fetchVendors} selectedVendor={selectedVendor} isOpen={openVendorDescription} setIsOpen={setIsOpenOpenVendorDescription} editedInfo={editedInfo}/>
    <EditVendorAddress fetchVendors={fetchVendors} selectedVendor={selectedVendor} isOpen={openVendorAddress} setIsOpen={setIsOpenOpenVendorAddress} editedInfo={editedInfo}/>
    </>
  )
}

export default VendorGeneralInfo;