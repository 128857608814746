import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/app-context";

const ReasonDetail = ({ selectedReason }) => {
  const { estates, resolveBackendUrl, setIsLoading } = useContext(AppContext);

  const [userDetails, setUserDetails] = useState({});
  async function getUser() {
    const url = `${resolveBackendUrl(selectedReason?.estateID)}/user/get?email=${
      selectedReason?.email
    }`;
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
        // body: JSON.stringify({category: 'delete'}),
      });

      const returned = await response.json();
      // popup(returned?.status || "Record Updated successfully");
      setUserDetails(returned?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // popup("Error communicating with estate server. You can try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0, so add 1
    const year = date.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }

  function formatTime(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert to 12-hour format
    return `${adjustedHours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  }

  useEffect(() => {
    getUser();
  }, []);

  const AccounDetails = ({ date_created, time, title }) => (
    <div className="date-creation">
      <div className="date-creation-text">{title}</div>
      <div className="date-creation-date">{date_created}</div>
      <div className="date-creation-time">{time}</div>
    </div>
  );

  return (
    <div>
      <div className="deletion-details">
        <AccounDetails
          title="Date of Account creation"
          date_created={formatDate(userDetails?.createdAt)}
          time={formatTime(userDetails?.createdAt)}
        />
        <AccounDetails
          title="Date of Account deletion"
          date_created={formatDate(selectedReason?.createdAt)}
          time={formatTime(selectedReason?.createdAt)}
        />
      </div>

      <div className="reason-for-deleting-container">
        <div className="reason-for-deleting">Reasons for Deleting Account</div>
        <div className="reason-for-delete-text">
          <p>{selectedReason?.message}</p>
        </div>
      </div>
    </div>
  );
};

export default ReasonDetail;
