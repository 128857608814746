import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { sortData, columns } from "./data";
import "./residents-table.css";
let pseudoCheckBoxSelectedData = [];

function ResidentsTable({ data, page, setPage, setCheckBoxSelectedData, setcsvData, getData, setUserTransactionData, setOnShow }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function workCSV(data) {
    setcsvData([
      ["Name", "Wallet Balance (₦)", "Unit Code", "Phone Number", "Resident Level"],
      ...data?.map(({ first_name, last_name, wallet, mobile, resident_type, property_code }) => {
        return [
          first_name + ` ${last_name}`,
          new Intl.NumberFormat("en-us").format(Math.trunc(wallet?.balance)) || 0,
          property_code || "-",
          mobile,
          resident_type ? resident_type : "none",
        ];
      }),
    ]);
  }

  function makeChecboxComeAlive(event) {
    if (
      pseudoCheckBoxSelectedData.find(
        (e) =>
          e._id ===
          event?.target
            ?.closest(".MuiTableRow-root")
            ?.children[0]?.children[0]?.getAttribute("data-id")
      )
    ) {
      pseudoCheckBoxSelectedData = pseudoCheckBoxSelectedData.filter(
        (e) =>
          e._id !==
          event?.target
            ?.closest(".MuiTableRow-root")
            ?.children[0]?.children[0]?.getAttribute("data-id")
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.push(
        data?.find(
          (e) =>
            e?._id ===
            event?.target
              ?.closest(".MuiTableRow-root")
              ?.children[0]?.children[0]?.getAttribute("data-id")
        )
      );
      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);
      workCSV(pseudoCheckBoxSelectedData);
    }
  }

  useEffect(() => {
    document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
      if (
        pseudoCheckBoxSelectedData.find(
          (e) =>
            e?._id ===
            element?.closest(".MuiTableRow-root")?.children[0]?.children[0]?.getAttribute("data-id")
        )
      ) {
        element.checked = true;
      } else element.checked = false;
    });
  });

  function allActionResidentsTable(add) {
    if (add) {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
        pseudoCheckBoxSelectedData.push(
          data?.find(
            (e) =>
              e?._id ===
              element
                ?.closest(".MuiTableRow-root")
                ?.children[0]?.children[0]?.getAttribute("data-id")
          )
        );
        element.checked = true;
      });

      setCheckBoxSelectedData(pseudoCheckBoxSelectedData);

      workCSV(pseudoCheckBoxSelectedData);
    } else {
      pseudoCheckBoxSelectedData.length = 0;
      document.querySelectorAll("#resident-checkbox-children").forEach((element) => {
        element.checked = false;
      });
      workCSV(data);
    }
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "visible", height: "fit-content" }}>
        <TableContainer sx={{ maxHeight: 440, overflow: "visible" }}>
          <Table aria-label="sticky table">
            <TableHead style={{ overflow: "visible", backgroundColor: "rgba(113, 82, 243, 0.05)" }}>
              <TableRow style={{ overflow: "visible" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    onClick={(e) => {
                      if (e.target.className.includes("resident-table-checkboxes-head")) {
                        if (!e.target.checked) allActionResidentsTable(false);
                        else allActionResidentsTable(true);
                      }
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortData(data, getData, setUserTransactionData, setOnShow)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={i}
                      style={{ overflow: "visible" }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ cursor: "pointer", overflow: "visible" }}
                            key={column.id}
                            align={column.align}
                            onClick={(event) => {
                              if (event.target.className.includes("resident-table-checkboxes")) {
                                event.stopPropagation();
                                return makeChecboxComeAlive(event);
                              }
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortData(data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export { ResidentsTable, pseudoCheckBoxSelectedData };
