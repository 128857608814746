import React, { useContext, useEffect, useState } from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
import AppContext from "../../context/app-context";

type Props = {
  imageUrl?: string;
  loader?: JSX.Element;
  imageStyle?: React.CSSProperties;
  alt: string;
  className?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  defaultImg?: string;
  baseUrl?: string;
};

const RenderImage = ({
  imageUrl,
  imageStyle,
  loader,
  alt,
  className,
  onMouseOver,
  onMouseOut,
  defaultImg,
  baseUrl,
}: Props) => {
  const [mainImage, setMainImage] = useState("");
  const { getSignedAwsUrl } = useContext(AppContext);;

  useEffect(() => {
    (async () => {
      if (imageUrl) setMainImage(await getSignedAwsUrl(imageUrl, "products", baseUrl));
      else setMainImage(defaultImg || "/logo.png");
    })();
  }, [defaultImg, getSignedAwsUrl, imageUrl]);

  return mainImage ? (
    <img
      onMouseOver={(e) => {
        e.stopPropagation();
        onMouseOver && onMouseOver();
      }}
      onBlur={() => onMouseOut && onMouseOut()}
      className={className}
      alt={alt}
      style={{ width: "3rem", ...imageStyle }}
      src={mainImage}
    />
  ) : loader ? (
    loader
  ) : (
    <MDBSpinner size="sm" className="mx-2" color="primary">
      <span className="visually-hidden">Loading...</span>
    </MDBSpinner>
  );
};

export default RenderImage;
