import React, { useState, useContext, useEffect, useRef } from 'react';
import { HiOutlineEye } from 'react-icons/hi';
import { BiCheckCircle } from 'react-icons/bi';
import { CiEdit } from 'react-icons/ci';
import { PiProhibitBold } from 'react-icons/pi';
import './fleet-actions.css';
import RiderOverview from '../../components/popup/rider-overview/rider-overview';
import EditAccountNumber from '../../components/popup/edit-account-number/edit-account-number';
import ReassignAxis from '../../components/popup/reassign-axis/reassign-axis';
import AppContext from '../../context/app-context';
import { popup } from '../../vanilla-functions/model';

const FleetActions = ({ isOpen, selectedRider, setIsOpen, fetchData }) => {
  const { resolveBackendUrl, setIsLoading, isLoading} = useContext(AppContext);
  const [openRiderOverView, setRiderOverView] = useState(false);
  const [openReassignAxis, setReassignAxis] = useState(false);
  const [openEditAccountNumber, setEditAccountNumber] = useState(false);
  const ref = useRef(null);

  const fleetActions = [
    {
      id: 1,
      name: 'Rider overview',
      color: '#7975B6',
      icon: <HiOutlineEye color='#7975B6' />,
      // modal: <RiderOverview isOpen={openRiderOverView} setIsOpen={setRiderOverView}/>,
      onClick: () => {
        setRiderOverView(true)
      }
    },
    
    {
      id: 3,
      name: 'edit account number',
      icon: <CiEdit color='#C8CC66' />,
      color: '#C8CC66',
      // modal: <EditAccountNumber isOpen={openEditAccountNumber} setIsOpen={setEditAccountNumber} />,
      onClick: () => {
        setEditAccountNumber(true)
      }
    },
    {
      id: 4,
      name: isLoading ? 'Removing...' : 'deactivate Rider',
      icon: <PiProhibitBold color='#F00' />,
      color: '#F00',
      onClick: deactivateRider
    }
  ]

  async function deactivateRider() {
    try {
      setIsLoading(true);
      const url = `${resolveBackendUrl(selectedRider?.estateID)}/user/delete/${selectedRider?._id}`;
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("omstoken"),
        },
      });

      const returned = await response.json();
      // if (response.status !== 200) {
      //   throw new Error(returned.message || "Internal server error");
      // }
      popup(returned?.status);
      fetchData()
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      popup("Error communicating with estate server. You can try again later.");
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is outside of FleetActions container
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.closest('.modal-container') // Check if click is inside any modal
      ) {
        // Check if any modal is open, if yes, prevent closing
        if (!openRiderOverView && !openReassignAxis && !openEditAccountNumber) {
          setIsOpen(false);
        }
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen, openRiderOverView, openReassignAxis, openEditAccountNumber]);  

  if (!isOpen) return null
  return (
    <>
        <div  className='fleet-content-body'>
          <div className='fleet-actions-container' ref={ref}>
            <div className='fleet-action-div'>
              {fleetActions.map((action) => (
                <button key={action.id} onClick={action?.onClick}>
                  {action?.icon}
                  <span style={{ color: action?.color }}>{action?.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      <RiderOverview isOpen={openRiderOverView} setIsOpen={setRiderOverView} selectedRider={selectedRider} />
      <ReassignAxis isOpen={openReassignAxis} setIsOpen={setReassignAxis} selectedRider={selectedRider} />
      <EditAccountNumber isOpen={openEditAccountNumber} setIsOpen={setEditAccountNumber} selectedRider={selectedRider} />
    </>
  )
}

export default FleetActions