import React, { useState, useEffect, useContext } from "react";
import "../../styles/index.css";
import "./fleetmgt.css";
import AppContext from "../../context/app-context";
import { PiDotsThreeVerticalBold } from "react-icons/pi";

import { popup } from "../../vanilla-functions/model";
import { CgSortAz } from "react-icons/cg";
import { BsPrinter } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FiRefreshCcw } from "react-icons/fi";
import { CSVLink } from "react-csv";
import FleetActions from "./fleet-actions.js";
import TablePagination from "@mui/material/TablePagination";
import { AppConfig } from "../../utils/config";

const Fleetmgt = function ({ display }) {
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRider, setSelectedRider] = useState({});
  const [allRidersCopy, setAllRidersCopy] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [riders, setRiders] = useState({});
  const [allRiders, setAllRiders] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOutsideClick = (event) => {
    if (event.target.classList.contains('fleet-table')) {
      setIsOpen(false);
    }
  };

  const [csvData, setcsvData] = useState([]);
  const [hideResidentTable, setHideResidentTable] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const { backendServer, getSignedAwsUrl, setIsLoading, patternMatching, resolveBackendUrl, usersData, estates } =
    useContext(AppContext);

  function workCSV(data) {
    setcsvData([
      ["Name", "Address", "Unit Code", "Phone Number", "Email", "Resident Level"],
      ...data?.map(
        ({ first_name, last_name, address, mobile, resident_type, property_code, email }) => {
          return [
            first_name + ` ${last_name}`,
            address?.addressString,
            property_code,
            mobile,
            email,
            resident_type ? resident_type : "none",
          ];
        }
      ),
    ]);
  }

  const findEstate = (value) => {
    return estates?.find((estate) => estate?.estate_id == value)?.estate_name;
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${backendServer}/estates/rider/statuses/get/${pageNumber}?real=${AppConfig?.realData}`, {
        method: "GET",
        credentials: "include",
        headers: {
          token: localStorage.getItem("omstoken"),
        },
      });
      const data = await response.json();

      if (data.status === "Internal server error") {
        throw new Error(data.message);
      }

      setRiders(data);
      if (pageNumber > 1) {
        setAllRiders(prevData => [...prevData, ...data?.datafilter(item => item.is_active === true)]);
        setAllRidersCopy(prevData => [...prevData, ...data?.datafilter(item => item.is_active === true)]);
        setIsLoading(false);
      } else {
        setAllRiders(data?.data?.filter(item => item.is_active === true));
        setAllRidersCopy(data?.data?.filter(item => item.is_active === true));
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      popup("Error fetching Riders...");
    }
  };

  useEffect(() => {
    if (!display) return;
    fetchData();
  }, [display, usersData, pageNumber]);

  const selectRider = (rider) => {
    setIsOpen(true);
    setSelectedRider(rider);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedRider(null);
    }
  }, [isOpen]);

  const tableHeader = ['Select', 'Rider Name', 'Email', 'Rider Axis', 'Rider Status', 'Vehicle Type', 'Contact', " "];
  const colorStatus = (status) => {
    switch (status) {
      case "In Delivery":
        return '#549186'
      case "Awaiting Order":
        return '#FFB400'
      default:
        return '#FF0000';
    }
  }

  const searchUser = (e) => {
    const value = e.target.value.trim().toLowerCase();
    if (value === "") {
      setAllRiders(allRidersCopy);
    } else {
      const filteredData = allRidersCopy.filter((user) => {
        const fullName = `${user?.first_name} ${user.last_name}`.toLowerCase();
        const riderStatus = user?.status.toLowerCase();
        const vehicleType = user?.rider_details?.vehicle_type.toLowerCase();
        const riderAxis = findEstate(user?.estateID).toLowerCase();
        const riderEmail = user?.email.toLowerCase();
        return (
          user.first_name.toLowerCase().includes(value) ||
          user.last_name.toLowerCase().includes(value) ||
          fullName.includes(value) ||
          riderStatus.includes(value) ||
          vehicleType.includes(value) ||
          riderAxis.includes(value) ||
          riderEmail.includes(value)
        );
      });
      setAllRiders(filteredData);
    }
  };

  const sortDirections = {
    ASC: 'asc',
    DESC: 'desc',
  };

  const [sortOrder, setSortOrder] = useState(sortDirections.ASC);
  const sort = () => {
    const sortedData = [...allRiders].sort((a, b) => {
      const firstNameA = a.status.toLowerCase();
      const firstNameB = b.status.toLowerCase();
      if (sortOrder === sortDirections.ASC) {
        if (firstNameA < firstNameB) {
          return -1;
        }
        if (firstNameA > firstNameB) {
          return 1;
        }
      } else {
        if (firstNameA > firstNameB) {
          return -1;
        }
        if (firstNameA < firstNameB) {
          return 1;
        }
      }
      return 0;
    });
    const nextSortOrder = sortOrder === sortDirections.ASC ? sortDirections.DESC : sortDirections.ASC;
    setSortOrder(nextSortOrder);
    setAllRiders(sortedData);
  };

  const filterRiderStatus = (status) => {
    const filteredData = allRidersCopy.filter((user) => {
      return user?.status.toLowerCase() === status.toLowerCase();
    });
    setAllRiders(filteredData);
  }

  const handleSelectedItems = (rider) => {
    const newSelectedItems = selectedItems.includes(rider) ? selectedItems.filter((item) => item !== rider) : [...selectedItems, rider];
    setSelectedItems(newSelectedItems);
  }

  const selectAllItems = () => {
    setSelectedItems([...allRiders]);
    setSelectAll(true);
  };

  const unselectAllItems = () => {
    setSelectedItems([]);
    setSelectAll(false);
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      unselectAllItems();
    } else {
      selectAllItems();
    }
  };

  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const propName = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.assign(acc, flattenObject(obj[key], propName));
      } else {
        acc[propName] = obj[key];
      }
      return acc;
    }, {});
  };

  let flattenedTableData;

  if (selectedItems.length > 0) {
    flattenedTableData = selectedItems.map((item) => flattenObject(item));
  } else {
    flattenedTableData = allRiders.map((item) => flattenObject(item));
  }

  if (!display) return;

  return (
    <div className="screen-container">
      {!hideResidentTable && (
        <div className="residents-stat-container flex-row">
          {/* <div className="r-each-d-stat-container">
            <img src="/r-1.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Total Residents<br></br>
              <span className="big r-stat-text">{allRiders.length || "-"}</span>
            </p>
          </div> */}

          <div onClick={() => setAllRiders(allRidersCopy)} className="r-each-d-stat-container">
            <img src="/fleet-mgt/d-2.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              All Riders<br></br>
              <span className="big r-stat-text">
                {allRidersCopy.length || "0"}{riders?.hasNextPage ? " + " : ""}
              </span>
            </p>
          </div>

          <div onClick={() => filterRiderStatus('In Delivery')} className="r-each-d-stat-container">
            <img src="/fleet-mgt/p-2.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              In Delivery<br></br>
              <span className="big r-stat-text">
                {allRidersCopy.filter((e) => e.status === "In Delivery").length || "0"}{riders?.hasNextPage ? " + " : ""}
              </span>
            </p>
          </div>

          <div onClick={() => filterRiderStatus('Awaiting Order')} className="r-each-d-stat-container">
            <img src="/fleet-mgt/p-3.png" alt="estility-resident" />
            <p className="small greyText removemargin">
              Awaiting Order<br></br>
              <span className="big r-stat-text">
                {allRidersCopy.filter((e) => e.status === "Awaiting Order").length || "0"}{riders?.hasNextPage ? " + " : ""}
              </span>
            </p>
          </div>
        </div>
      )}
      <div className="search-container">
        {/* Search and action buttons */}
        <div className="search-input-box-wrapper">
          <CiSearch />
          <input placeholder="Search for user" type='text' onChange={searchUser} />
        </div>
        <CgSortAz onClick={sort} className="cursor" title="Sort By Resident Level" size={30} />
        <CSVLink className="cursor small csv-link" data={flattenedTableData}>
          <BsPrinter title="Print CSV" size={20} />
        </CSVLink>
        <FiRefreshCcw className="cursor" title="Refresh Data" size={20} onClick={fetchData} />
      </div>
      <table className="fleet-table" onClick={handleOutsideClick}>
        <thead>
          <tr>
            <th>
              <input type="checkbox" name='tableItem' onChange={toggleSelectAll}
                checked={selectAll || selectedItems.length === allRiders.length}
                indeterminate={selectedItems.length > 0 && selectedItems.length < allRiders.length}
              />
            </th>
            {tableHeader.slice(1).map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allRiders?.length > 0 && allRiders?.map((rider, index) => (
            <tr key={index}>
              <td>
                {/* Individual item checkbox */}
                <input type="checkbox" name='tableItem' onChange={() => handleSelectedItems(rider)} checked={selectedItems.includes(rider)} />
              </td>
              <td>{rider?.first_name} {" "} {rider?.last_name}</td>
              <td>{rider?.email}</td>
              <td>{findEstate(rider?.estateID)}</td>
              <td style={{ color: colorStatus(rider?.status) }}>{rider?.status}</td>
              <td>{rider?.rider_details?.vehicle_type}</td>
              <td>{rider?.mobile}</td>

              <td
                style={{ position: 'relative' }}
                onClick={() => selectRider(rider)}
                className="table-action"
              >
                <PiDotsThreeVerticalBold />
                {isOpen && selectedRider?._id === rider?._id ? (
                  <div><FleetActions isOpen={true} selectedRider={selectedRider} setIsOpen={setIsOpen} fetchData={fetchData}/></div>
                ) : null}
              </td>


            </tr>
          ))}
        </tbody>
      </table>
      {riders?.hasNextPage || pageNumber > 1 ? (
        <>
          {pageNumber > 1 && (
            <button
              className="loadless"
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{ float: 'left' }}
              disabled={pageNumber === 1}
            >
              Load Previous
            </button>
          )}
          {riders?.hasNextPage && (
            <button
              className="loadmore"
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{ float: 'right' }}
              disabled={!riders?.hasNextPage}
            >
              Load More
            </button>
          )}
        </>
      ) : null}

    </div>
  );
};

export default Fleetmgt;
