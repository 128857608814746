import { ResidentsOptionsSvg } from "../svgs/resident-options-svg";
const columns = [
  { id: "_id", label: "", minWidth: 0 },
  {
    id: "resident_checkbox",
    label: (
      <input
        id="resident-checkbox-parent"
        type="checkbox"
        className="table-checkboxes resident-table-checkboxes-head"
      />
    ),
    minWidth: 20,
  },
  { id: "name", label: "Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 170 },
  {
    id: "wallet_balance",
    label: "Wallet Balance (₦)",
    minWidth: 100,
    align: "left",
  },
  {
    id: "property_code",
    label: "Unit Code",
    minWidth: 130,
    align: "left",
  },
  {
    id: "mobile",
    label: "Phone Number",
    minWidth: 100,
    align: "left",
  },
  {
    id: "level",
    label: "Resident Level",
    minWidth: 100,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Registered On",
    minWidth: 100,
    align: "left",
  },
  {
    id: "options",
    label: "",
    minWidth: 15,
    align: "left",
  },
];

function sortData(data, getData, setUserTransactionData, setOnShow) {
  if (!data) return [];
  const finalArray = data?.map((e) => {
    return {
      _id: <span data-id={e?._id}></span>,
      resident_checkbox: (
        <input
          data={e?._id}
          id="resident-checkbox-children"
          type="checkbox"
          className="table-checkboxes resident-table-checkboxes"
        />
      ),
      name: e?.first_name + ` ${e.last_name}`,
      email: e?.email,
      wallet_balance: new Intl.NumberFormat("en-us").format(Math.trunc(e?.wallet?.balance)) || "0",
      property_code: e?.property_code || "-",
      mobile: e?.mobile,
      level: e?.resident_type ? e?.resident_type : "none",
      createdAt: new Intl.DateTimeFormat("en-us", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(e?.createdAt)),
      options: (
        <ResidentsOptionsSvg
          setUserTransactionData={setUserTransactionData}
          getData={getData}
          data={e}
          setOnShow={setOnShow}
        />
      ),
    };
  });

  return finalArray;
}

export { sortData, columns };
